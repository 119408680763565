import { Source } from './../../models/source';
import {
   getActiveSource,
   getActiveSourceNav,
} from './../state/selectors/report.selectors';
import { getCurrentUser } from './../../authModule/state/authentication.selectors';
import { Store } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';
import {
   Component,
   OnInit,
   ViewChild,
   ViewChildren,
   ElementRef,
   Inject,
   HostListener,
   QueryList,
   OnDestroy,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import * as reportActions from '../state/actions/report.actions';
import { getCurrentSubmission } from '../state/selectors/report.selectors';
import { Submissions } from 'src/app/models/submissions';
import { User } from 'src/app/models/user';
import { MatDialog } from '@angular/material/dialog';
import { SentenceInformationModalComponent } from './sentence-information-modal/sentence-information-modal.component';
import { SourcePreviewComponent } from './sourcePreview/source-preview/source-preview.component';
import { Observable, Subscription } from 'rxjs';
import * as Mark from 'mark.js';
import { SubmissionsService } from '../../services/submissions.service';
import { NgxSpinnerService } from 'ngx-spinner';
import swal from 'sweetalert2';
import { saveAs } from 'file-saver';
import { first } from 'rxjs/operators';
import { PDFDocumentProxy } from 'ng2-pdf-viewer';
import { DeleteModalComponent } from './delete-modal/delete-modal.component';
import { ProfessorDeleteModalComponent } from './professor-delete-modal/professor-delete-modal.component';

/**
 * Report component used for analyzing submission plagiarism.
 */

export interface CustomSourceEvent extends CustomEvent {
   source: any;
   pageNumber: number;
}
@Component({
   selector: 'app-report',
   templateUrl: './report.component.html',
   styleUrls: ['./report.component.scss'],
})
export class ReportComponent implements OnInit, OnDestroy {
   s3Json: any;
   deleteModal: boolean;
   /**
    * Component Constructor
    * @param document
    * @param route
    * @param store
    * @param dialog
    * @param submissionService
    * @param fileService
    * @param spinner
    */
   constructor(
      @Inject(DOCUMENT) private document: Document,
      private route: ActivatedRoute,
      private store: Store,
      public dialog: MatDialog,
      private submissionService: SubmissionsService,
      private spinner: NgxSpinnerService
   ) {}

   revealDiv = false;
   excludedSourcesShow: boolean = false;
   sentenceId: any;
   selectedPage;
   expandedPdf: boolean = false;
   selected;
   showText: boolean = false;
   documentLanguage: string;
   highlighQuotes;
   /**
    * Boolean variable used to show/hide scroll on top button on mobile view.
    */
   windowScrolled: boolean;
   MLPlagIndex: boolean = false;
   /**
    * Boolean variable use to check if download button is checked
    */
   plagiarismLabels;
   downloadClicked: boolean = false;
   expandedIndex: number = -1;
   selectedSources: boolean = false;
   MLplagSources;
   activePlagSources: any;
   crossPlag: number = 0;
   allSources: boolean = true;
   topSources: boolean = false;
   internetSources: boolean = false;
   documentSources: boolean = false;
   largest: number = 0;
   topThreeSources: Array<Source> = [];
   filteredSources: Array<any>;
   crossPlagIndex: boolean = true;
   filterSources: boolean = false;
   defaultSourceSelect: boolean = true;
   @ViewChildren('checkboxes') checkBoxes: QueryList<ElementRef>;
   pdfSrc;
   count = 0;
   @ViewChild('search', { static: false }) searchElemRef: ElementRef;
   sourceId;

   sources: Array<Source> = [];

   excludedSource = [];

   studentDeletionPermission;
   isDocumentInArchive;
   sourcesAreDeleted;
   translatedLanguage;

   languages = [
      {
         text: 'English',
         value: 'en',
      },
      {
         text: 'Albanian',
         value: 'sq',
      },
      {
         text: 'German',
         value: 'de',
      },
      {
         text: 'Italian',
         value: 'it',
      },
      {
         text: 'French',
         value: 'fr',
      },
      {
         text: 'Spanish',
         value: 'es',
      },
      {
         text: 'Greek',
         value: 'el',
      },
      {
         text: 'Czech',
         value: 'cs',
      },
      {
         text: 'Turkish',
         value: 'tr',
      },
      {
         text: 'Slovak',
         value: 'sk',
      },
      {
         text: 'Lithuanian',
         value: 'lt',
      },
      {
         text: 'Latvian',
         value: 'lv',
      },
      {
         text: 'Polish',
         value: 'pl',
      },
      {
         text: 'Serbian',
         value: 'sr',
      },
      {
         text: 'Macedonian',
         value: 'mk',
      },
      {
         text: 'Portuguese',
         value: 'pt',
      },
      {
         text: 'Dutch',
         value: 'nl',
      },
      {
         text: 'Russian',
         value: 'ru',
      },
      {
         text: 'Bulgarian',
         value: 'bg',
      },
      {
         text: 'Hungarian',
         value: 'hu',
      },
      {
         text: 'Romanian',
         value: 'ro',
      },
      {
         text: 'Slovenian',
         value: 'sl',
      },
      {
         text: 'Swedish',
         value: 'sv',
      },
      {
         text: 'Finnish',
         value: 'fi',
      },
      {
         text: 'Croatian',
         value: 'hr',
      },
      {
         text: 'Bosnian',
         value: 'bs',
      },
      {
         text: 'Norwegian',
         value: 'no',
      },
   ];

   /**
    * Id of the current submission that report is being generated. We used to store current submission id & get current submission details.
    */
   currentSubmissionId: string;
   /**
    * Current Submission Details Subscriber. Used to store state & to get state changes in case of changes.
    */
   currentSubmissionDetails$: Observable<Submissions>;
   /**
    * Current User Observable. Used to store user state & to get state changes in chase of changes.
    */
   currentUser$: Observable<User>;
   currentUserDetailsSubscriber$: Subscription;
   currentUserDetails: User;
   /**
    * A part of DOM that is used on @function doHighlight() to add highlight on selected DOM
    */
   markInstance;
   /**
    * Variable used to store all not found text within first try to highlight then it will be used on algorithm that gets text from each page and compare
    *  for each character till its same length of successful characters and it will be send to highlight again.
    */
   notFound = [];
   /**
    * Array used to store loaded pages.
    */
   loadedPages = [];
   scrolling = false;
   numberOfDifference = 2;
   pagesToDraw = [];
   pagesToDestroy = [];
   loadedPagesId = [];
   oldPage = 1;
   /**
    * Subscriber used to subscribe to crossplag sources from store.
    */
   crossSourcesSub$;
   /**
    * Subscriber used to subscribe to mlplag sources from store.
    */
   MLPlagSourcesSub$;
   /**
    * Subscriber used to subscribe to navigation data from store.
    */
   navigationSourceSub$;
   /**
    * Fist page of pdf.
    */
   page: any;
   pages: any;
   pagesDrawn = [];
   documentPages;
   dataClone;
   pdfLoadedPages = [];
   currentSubmissionDetailsSubscribe$;
   currentSubmissionDetails;
   canEdit: boolean;
   excludeSources;
   excludedSourceChecked = 0;
   displayAiText = false;
   aiSentences;
   totalSentencesWithAI = 0;
   ai_model = 'base';
   
   ngOnInit(): void {
      this.spinner.show();
      this.page = 1;
      this.selectedPage = 1;
      this.selected = false;
      this.currentSubmissionId = this.route.snapshot.paramMap.get('id');
      this.submissionService
         .getSubmissionPlagData(this.currentSubmissionId)
         .pipe(first())
         .subscribe(
            (data) => {
               this.spinner.hide();
               this.pdfSrc = data.fileUrl;
               this.studentDeletionPermission = data.studentDeletionPermission;
               this.isDocumentInArchive = data.isDocumentInArchive;
               this.sourcesAreDeleted = data.sourcesAreDeleted;
               this.aiSentences = data.sentencesWithAI;
               this.store.dispatch(
                  reportActions.getSubmissionPlag({
                     presignedUrl: data.presignedUrlJson,
                  })
               );
            },
            (error) => {
               console.log('error', error);
            }
         );
      this.currentSubmissionDetails$ = this.store.select(getCurrentSubmission);


      

      this.currentUser$ = this.store.select(getCurrentUser);
      this.currentUserDetailsSubscriber$ = this.store
         .select(getCurrentUser)
         .subscribe((data: User) => {
            this.currentUserDetails = data;

            this.canEdit = true;

            if(this.currentSubmissionId !== "clmpm7jud00090wjq5pjs5545" && this.currentSubmissionId !== "clnvm3cjk0009eod5m7iq0w1p") {
               if ( this.currentUserDetails.roleId === 2) {
                  this.canEdit = !!(
                     this.currentUserDetails.institutionId === null
                  );
               }
               if (this.currentUserDetails.roleId === 5 || this.currentUserDetails.roleId === 3) {
                  this.canEdit = false;
               }
            }


         });

      this.currentSubmissionDetailsSubscribe$ = this.store
         .select(getCurrentSubmission)
         .subscribe((data) => {
            this.currentSubmissionDetails = data;
            this.deleteModal = false;
            if(this.currentSubmissionId !== "clmpm7jud00090wjq5pjs5545" && this.currentSubmissionId !== "clnvm3cjk0009eod5m7iq0w1p") {
            if((this.currentUserDetails.roleId == 4 && !this.currentSubmissionDetails?.assignmentsId && !this.currentSubmissionDetails?.thesisId) || (this.currentUserDetails.roleId === 2 || this.currentUserDetails.institutionId === null) || this.currentUserDetails.roleId === 5) {
             this.deleteModal = true;
            }
         }
            if (data !== null) {
               this.highlighQuotes = this.currentSubmissionDetails.quotesToggle;
               this.documentLanguage = this.getDocumentLanguage(
                  this.currentSubmissionDetails.originalLanguage
               );
               this.translatedLanguage = this.getDocumentLanguage(
                  this.currentSubmissionDetails?.translatedLanguage
               );
            }
         });

      this.navigationSourceSub$ = this.store
         .select(getActiveSourceNav(this.crossPlagIndex))
         .pipe()
         .subscribe((data) => {
            if (this.plagiarismLabels == undefined) {
               this.plagiarismLabels = data;
            }
            if (data !== this.plagiarismLabels) {
               this.plagiarismLabels = data;
            }
         });

      // subscribing to sources in store
      this.crossSourcesSub$ = this.store
         .select(getActiveSource(this.crossPlagIndex))
         .pipe()
         .subscribe((data) => {
            console.log('data', data);
            if (this.activePlagSources == undefined) {
               this.activePlagSources = data[0];
               this.excludeSources = data[1];
            }
            this.notFound = [];
            if (data !== this.activePlagSources) {
               this.activePlagSources = data[0];
               this.excludeSources = data[1];
            }
            this.reCallHighlight();
         });
   }

   callBackFn(pdf: PDFDocumentProxy) {
      setTimeout(() => {
         this.getCrossPlag();
      }, 300);
   }

   ngOnDestroy(): void {
      this.crossSourcesSub$.unsubscribe();
      this.MLPlagSourcesSub$.unsubscribe();
      this.navigationSourceSub$.unsubscribe();
      this.currentSubmissionDetailsSubscribe$.unsubscribe();
   }

   toggleFilteredSource() {
      this.revealDiv = !this.revealDiv;
   }

   toggleExcludedSource() {
      this.excludedSourcesShow = !this.excludedSourcesShow;
   }

   	
   aiHighlights() {
      
      this.notFound = [];
      this.removeHighlight();
      this.totalSentencesWithAI = 0;
      this.plagiarismLabels.forEach(element => {
         element.numberOfSentences = 0;
      });
      console.log(this.aiSentences,"this.aiSentences");
      
      this.aiSentences.forEach(element => {
      if((element.aiText || element.aiTextPerformance ) && !element.withPlagiarism) {        
         this.doHighlight(
            element.text,
            element,
            this.notFound,
            []
         );
      }
      if(element.aiText && this.ai_model == 'base') {
         console.log(element.aiText,"here");
         
         this.totalSentencesWithAI++;
      } else if(element.aiTextPerformance && this.ai_model !== 'base') {
         this.totalSentencesWithAI++;
      } 
      
      this.plagiarismLabels.forEach(label => {
              
         if(label.page == element.page && element.aiText) {
            label.numberOfSentences++;
         }
      });
         // if(this.loadedPagesId.includes(element.page)) {
         // }
      });
   }

   changeView(toChange) {
      if( this.currentSubmissionDetails?.aiPercentage == -1 || this.currentSubmissionDetails?.checkAI == 0){
       return
      }
      if(toChange == 'similarity') {
         this.displayAiText = false;
         this.getCrossPlag();
      } else {
         this.displayAiText = true;
         this.aiHighlights();
      }
   }

   doHighlight(stringToHighlight, objToHighlight, notFound, source) {
      
      
      
      let mark = 0;
      let viewer = document.getElementById(`viewer`);
      this.markInstance = new Mark(viewer);
      // if (this.page === objToHighlight.page) {      
      
      
      this.markInstance.mark(stringToHighlight, {
         acrossElements: true,
         separateWordSearch: false,
         debug: true,
         limit: 1,
         exclude: ['.canvasWrapper', '[data-markjs="true"]'],
         each: (element) => {
            if (mark == 0) {
               var node = document.createElement('SPAN');
               node.classList.add('markQuote');

               if (source.isExcluded) {
                  node.style.background = 'rgb(128, 128, 128, 0.9)';
               } else {
                  if (
                     (objToHighlight.isExcluded == true &&
                        this.crossPlagIndex) ||
                     (objToHighlight.isTranslationExcluded == true &&
                        !this.crossPlagIndex)
                  ) {
                     node.style.background = 'rgb(128, 128, 128, 0.9)';
                  } else {
                     if (objToHighlight.isCitation) {
                        node.style.background = 'rgb(102, 255, 102, 0.9)';
                        node.style.color = 'black';
                     } else {
                        if (objToHighlight.sourceSentencePercentage > 84.5) {
                           node.style.background = 'rgb(240, 78, 103, 0.9)';
                        } else {
                           node.style.background = 'rgb(0, 207, 255, 0.9)';
                        }
                     }
                  }
               }

               if (
                  source.isSentenceExcluded === false &&
                  (!source.hide || source.hide === false)
               ) {
                  var textnode = document.createTextNode(source.no);
                  node.appendChild(textnode);
                  element.appendChild(node);
               }
            }
            mark = 1;
            

            element.style.color = 'transparent';
            element.style.position = 'relative';
            if(this.displayAiText) {                              
               if (objToHighlight.aiText) {
                  element.style.background = 'rgb(131, 102, 204,0.35)';
               }
               if(objToHighlight.aiTextPerformance && this.ai_model == 'performance'){
                  element.style.background = 'rgb(242, 198, 2,0.35)';
               }
            } else {
               if (source.isExcluded) {
                  element.style.background = 'rgb(128, 128, 128, 0.35)';
               } else {
                  if (
                     (objToHighlight.isExcluded == true && this.crossPlagIndex) ||
                     (objToHighlight.isTranslationExcluded == true &&
                        !this.crossPlagIndex)
                  ) {
                     element.style.background = 'rgb(128, 128, 128, 0.35)';
                  } else {
                     if (objToHighlight.isCitation) {
                        element.style.background = 'rgb(102, 255, 102, 0.35)';
                     } else {
                        if (objToHighlight.sourceSentencePercentage > 84.5) {
                           element.style.background = 'rgb(240, 78, 103, 0.45)';
                        } else {
                           element.style.background = 'rgb(0, 207, 255, 0.45)';
                        }
                     }
                  }
               }
            }

            $(element).css('cursor', 'pointer');
            $(element).attr('data-source-id', source.sourceId);
            $(element).attr('data-page-number', objToHighlight.page);
            $(element).attr('sentence-id', objToHighlight.id);
            $(element).on('click', (e) => {
               this.sourceId = e.target.attributes['data-source-id'].value;
               this.sentenceId = e.target.attributes['sentence-id'].value;
               let result;
               this.activePlagSources.forEach((element) => {
                  element.sentences.forEach((sentence) => {
                     if (sentence.id == this.sentenceId) {
                        result = sentence;
                     }
                  });
               });
               this.openModal(result);
            });
         },
         filter: function (txtNode, string, s, b) {
            return true;
         },
         noMatch: function (range) {
            notFound.push({ objToHighlight, source });
         },
      });
      // }
   }
   /**
    * Method used to highlight or remove highlight from quotes.
    */
   toggleQuotes() {
      this.highlighQuotes = !this.highlighQuotes;
      this.submissionService
         .toggleQuotes(this.currentSubmissionDetails.id)
         .pipe(first())
         .subscribe(
            (data) => {
               console.log('data', data);
            },
            (error) => {
               console.log('error', error);
            }
         );
      setTimeout(() => {
         this.reCallHighlight();
      }, 200);
   }

   /**
    * Method is used as second phase for of sentence highlights it is triggered only when a text is not found within a basic search via a Mark.Js
    */
   removeHighlight() {
      let viewer = document.getElementById('viewer');
      this.markInstance = new Mark(viewer);
      this.markInstance.unmark();
      document.querySelectorAll('.markQuote').forEach((item) => {
         item.remove();
      });
   }

   getTextFromPages() {
      let textForHighlight = [];
      let pageText = $($('[data-loaded="true"]')).text();
      let arrayOfTexts = pageText
         .match(/((?:[A-Z][a-z]\.|\w\.\w.|.)*?(?:[.!?]|$))(?:\s+|$)/g)
         .map((string) => string.trim());
      arrayOfTexts.pop();
      for (let x in this.notFound) {
         for (let j in arrayOfTexts) {
            let parsedSentences = arrayOfTexts[j];
            let notFoundWithRegEx = this.notFound[
               x
            ].objToHighlight.text.replace(/\s+/g, '');

            let successCounter = 0;
            let startSentence = 0;
            let endSentence = 0;
            let keepChecking = false;
            let found = false;
            let i = 0;
            let textToHighlight = '';

            while (i < parsedSentences.length && !found) {
               if (parsedSentences[i].indexOf(' ') != 0) {
                  if (notFoundWithRegEx[successCounter] == parsedSentences[i]) {
                     successCounter++;
                     if (keepChecking == false) {
                        startSentence = i;
                        keepChecking = true;
                     }
                  } else {
                     if (successCounter > 0) {
                        i = i - 1;
                     }
                     successCounter = 0;
                     keepChecking = false;
                  }

                  if (successCounter == notFoundWithRegEx.length) {
                     endSentence = i;

                     textToHighlight = parsedSentences.substring(
                        startSentence,
                        endSentence + 1
                     );

                     found = true;
                     textForHighlight.push({
                        textToHighlight: textToHighlight,
                        obj: this.notFound[x].objToHighlight,
                        source: this.notFound[x].source,
                     });
                  }
               }
               i++;
            }
         }
      }
      this.notFound = [];
      for (let x in textForHighlight) {
         this.doHighlight(
            textForHighlight[x].textToHighlight,
            textForHighlight[x].obj,
            this.notFound,
            textForHighlight[x].source
         );
      }
   }

   pageChanging(e: CustomSourceEvent) {
      this.calculateNextPage(e.pageNumber);
      this.page = e.pageNumber;
   }

   pageRendered(e) {
      this.pdfLoadedPages = [];
      this.notFound = [];
      // let pages = $($('.page'));
      this.documentPages = e.pageNumber;
      let same = 0;

   if (this.loadedPages.length !== 0) {
      for (let i = 0; i < this.loadedPages.length; i++) {
         if (this.loadedPages[i].id === e.source.id) {
            same++;
         }
      }
      if (same === 0) {
         this.loadedPages.push(e.source);
         this.loadedPagesId.push(e.source.id);
      }
   } else {
      this.loadedPagesId.push(e.source.id);
      this.loadedPages.push(e.source);
   }

   if(this.displayAiText) {
      
      this.aiHighlights();
   }

   if(!this.displayAiText){
   let arrayToHighlight = [];
   if (this.selectedSources === true) {
      arrayToHighlight = this.sources;
   } else {
      arrayToHighlight = this.activePlagSources;
   }
   
   for (let source of arrayToHighlight) {
      let j = this.plagiarismLabels.length;
      
      for (let sentence of source.sentences) {
         if (sentence.page < j) {
            j = sentence.page;
         }
         let sentenceCopy = { ...sentence };
         
            if (this.highlighQuotes === true) {
               if (this.loadedPagesId.includes(sentenceCopy.page)) {
                  this.doHighlight(
                     sentenceCopy.text,
                     sentenceCopy,
                     this.notFound,
                     source
                  );
               }
            } else {
               if (
                  this.loadedPagesId.includes(sentenceCopy.page) &&
                  sentenceCopy.isCitation === null
               ) {
                  this.doHighlight(
                     sentenceCopy.text,
                     sentenceCopy,
                     this.notFound,
                     source
                  );
               }
            }

      }
   }
      }




      if (this.notFound.length !== 0) {
         this.getTextFromPages();
      }
   }

   callHighlight(currentPage) {
      this.notFound = [];
      let arrayToHighlight = [];

      if(!this.displayAiText) {
         if (this.selectedSources === true) {
            arrayToHighlight = this.sources;
         } else {
            arrayToHighlight = this.activePlagSources;
         }
         for (let source of arrayToHighlight) {
            for (let sentence of source.sentences) {            
               if (currentPage == sentence.page) {
                     this.doHighlight(sentence.text, sentence, this.notFound, source);
               }
            }
         }
      }
     

      if(this.displayAiText) {         
         this.aiHighlights();
      }

      if (this.notFound.length !== 0) {
         this.getTextFromPages();
      }
   }

   calculateNextPage(currentPage) {
      if (this.loadedPagesId.length !== 0) {
         this.pagesToDraw = [];
         this.pagesToDestroy = [];

         let lowest = currentPage - this.numberOfDifference;
         let highest = currentPage + this.numberOfDifference;

         for (let i = 0; i < this.loadedPagesId.length; i++) {
            if (
               this.loadedPagesId[i] <= highest &&
               this.loadedPagesId[i] >= lowest
            ) {
               this.pagesToDraw.push(this.loadedPagesId[i]);
            } else {
               this.pagesToDestroy.push(this.loadedPagesId[i]);
            }
         }

         for (let i = 0; i < this.pagesToDestroy.length; i++) {
            for (let x in this.loadedPages) {
               if (this.loadedPages[x].id === this.pagesToDestroy[i]) {
                  let position = parseInt(x);
                  this.loadedPages[position].destroy();
                  this.loadedPages.splice(position, 1);
               }
            }
         }

         if (this.loadedPagesId.length >= 6) {
            for (let x in this.loadedPagesId) {
               if (this.loadedPagesId[x] === this.pagesToDestroy[0]) {
                  let position = parseInt(x);
                  this.loadedPagesId.splice(position, 1);
                  this.pagesToDestroy.splice(0, 1);
               }
            }
         }

         setTimeout(() => {
            if (this.oldPage > currentPage) {
               if (currentPage - 1 <= 1) {
                  this.callHighlight(currentPage - 1);
               }
            } else {
               this.callHighlight(currentPage);
            }
            this.oldPage = currentPage;
         }, 200);
      }
   }

   expandRow(index: number): void {
      this.expandedIndex = index === this.expandedIndex ? -1 : index;
   }

   /**
    * Method used to get multi language plagiarism sources of the current submission
    */
   getMLPlag() {
      this.store.dispatch(
         reportActions.resetFilter({ sources: this.activePlagSources })
      );
      this.MLPlagIndex = true;
      this.crossPlagIndex = false;
      this.MLPlagSourcesSub$ = this.store
         .select(getActiveSource(this.crossPlagIndex))
         .subscribe((data) => {
            this.activePlagSources = data[0];
            this.excludeSources = data[1];
            if (data !== this.activePlagSources) {
               this.activePlagSources = data[0];
               this.excludeSources = data[1];
            }
            this.reCallHighlight();
         });
      this.navigationSourceSub$.unsubscribe();
      this.navigationSourceSub$ = this.store
         .select(getActiveSourceNav(this.crossPlagIndex))
         .subscribe((data) => {
            this.plagiarismLabels = data;
         });
      this.allSources = true;
      this.topSources = false;
      this.internetSources = false;
      this.documentSources = false;
      this.filterSources = false;
      this.expandedIndex = -1;
      this.selectedSources = false;
      this.scrollPage(1);
   }

   /**
    * Method used to close source expanded details div.
    */
   close() {
      this.expandedIndex = -1;
   }

   /**
    * Method used to get cross plagiarism sources of the current submission
    */
   getCrossPlag() {
      this.store.dispatch(
         reportActions.resetFilter({ sources: this.activePlagSources })
      );
      this.MLPlagIndex = false;
      this.crossPlagIndex = true;
      this.documentLanguage = this.getDocumentLanguage(
         this.currentSubmissionDetails.originalLanguage
      );
      this.MLPlagSourcesSub$.unsubscribe();
      this.removeHighlight();
      this.navigationSourceSub$.unsubscribe();
      this.navigationSourceSub$ = this.store
         .select(getActiveSourceNav(this.crossPlagIndex))
         .subscribe((data) => {
            this.plagiarismLabels = data;
         });
      this.crossSourcesSub$ = this.store
         .select(getActiveSource(this.crossPlagIndex))
         .subscribe((data) => {
            this.activePlagSources = data[0];
            this.excludeSources = data[1];
            if (data !== this.activePlagSources) {
               this.activePlagSources = data[0];
               this.excludeSources = data[1];
            }
            this.reCallHighlight();
         });
      this.allSources = true;
      this.topSources = false;
      this.internetSources = false;
      this.documentSources = false;
      this.filterSources = false;
      this.expandedIndex = -1;
      this.selectedSources = false;
   }

   /**
    * Method used to filter plagiarism sources based on four filters.
    * All Sources
    * Top 3 Sources
    * Internet Sources
    * Document Sources
    * @param type
    */
   getFilteredSources(type) {
      type == 2
         ? (this.internetSources = true)
         : (this.internetSources = false);
      type == 1
         ? (this.documentSources = true)
         : (this.documentSources = false);
      type == 'top3' ? (this.topSources = true) : (this.topSources = false);
      type == 'all' ? (this.allSources = true) : (this.allSources = false);
      this.selectedSources = false;
      this.expandedIndex = -1;
      this.filteredSources = [];
      for (let source of this.activePlagSources) {
         if (source.type == type) {
            this.filteredSources.push(source);
         }
      }
      this.filteredSources = this.activePlagSources.filter(
         (source) => source.type == type
      );
      for (let source of this.filteredSources) {
         if (source.isChecked == true) {
            this.selectedSources = true;
         }
      }
      this.filterSources = true;
      if (type === 'all') {
         this.filterSources = false;
         for (let source of this.activePlagSources) {
            for (let sentence of source.sentences) {
               let sentenceCopy = { ...sentence };
               sentenceCopy.page = sentenceCopy.page.toString();
               if (this.highlighQuotes === true) {
                  if (this.loadedPagesId.includes(sentenceCopy.page)) {
                     this.doHighlight(
                        sentenceCopy.text,
                        sentenceCopy,
                        this.notFound,
                        source
                     );
                  }
               } else {
                  if (
                     this.loadedPagesId.includes(sentenceCopy.page) &&
                     sentenceCopy.isCitation === null
                  ) {
                     this.doHighlight(
                        sentenceCopy.text,
                        sentenceCopy,
                        this.notFound,
                        source
                     );
                  }
               }
            }
            if (source.isChecked == true) {
               this.selectedSources = true;
            }
         }
      } else if (type === 'top3') {
         this.filterSources = false;
         this.topThreeSources = [];
         if (this.topThreeSources.length == 0) {
            for (let source of this.activePlagSources) {
               if (!source.isExcluded) {
                  this.topThreeSources.push(source);
               }
            }
            this.topThreeSources = [...this.topThreeSources]
               .sort((a, b) => b.percentage - a.percentage)
               .slice(0, 3);

            for (let source of this.topThreeSources) {
               if (source.isChecked == true) {
                  this.selectedSources = true;
               }
            }
         }
      }
   }

   @HostListener('window:scroll', [])
   /**
    * Method used to show/hide scroll button on mobile if the window is scrolled or not.
    */
   onWindowScroll() {
      if (
         window.pageYOffset ||
         document.documentElement.scrollTop ||
         document.body.scrollTop > 100
      ) {
         this.windowScrolled = true;
      } else if (
         (this.windowScrolled && window.pageYOffset) ||
         document.documentElement.scrollTop ||
         document.body.scrollTop < 10
      ) {
         this.windowScrolled = false;
      }
   }

   /**
    * Method used to scroll at the top of the page in mobile view.
    */
   scrollPageToTop() {
      (function smoothScroll() {
         let currentScroll =
            document.documentElement.scrollTop || document.body.scrollTop;
         if (currentScroll > 0) {
            window.requestAnimationFrame(smoothScroll);
            window.scrollTo(0, currentScroll - currentScroll / 8);
         }
      })();
   }

   /**
    * Method used to select plagiarism sources.
    */
   checkedSources = [];

   startDocumentPreview(id) {
      this.submissionService
         .submissionPreview(
            id,
            this.currentSubmissionDetails.id,
            this.MLPlagIndex
         )
         .pipe(first())
         .subscribe(
            (data) => {
               console.log('data', data);
               this.dialog.open(SourcePreviewComponent, {
                  data,
                  width: '60%',
                  height: '80%',
               });
            },
            (error) => {
               console.log('error', error);
            }
         );
   }

   /**
    * Method used to select source from source list
    * @param id
    */
   selectSource(id) {
      if (this.excludedSourceChecked > 0) {
         this.excludedSourceChecked = 0;
         for (let source of this.excludeSources) {
            this.store.dispatch(
               reportActions.unCheckCheckboxExclude({
                  source: source,
                  sourceType: this.crossPlagIndex,
               })
            );
         }
      }
      this.defaultSourceSelect = false;
      this.allSources = false;
      let i = 0;
      this.checkBoxes.forEach((element) => {
         if (element.nativeElement.checked == true) {
            i++;
         }
      });
      // if there are any checkbox selected.
      if (i > 0) {
         this.selectedSources = true;
         this.removeHighlight();
      }

      this.notFound = [];
      for (let source of this.activePlagSources) {
         if (id == source.sourceId) {
            let sourceIndex = this.sources.findIndex(
               (src) => src.sourceId == source.sourceId
            );
            if (sourceIndex === -1) {
               this.store.dispatch(
                  reportActions.checkCheckbox({
                     source: source,
                     sourceType: this.crossPlagIndex,
                  })
               );

               this.sources.push(source);
            } else {
               this.sources.splice(sourceIndex, 1);

               this.store.dispatch(
                  reportActions.unCheckCheckbox({
                     source: source,
                     sourceType: this.crossPlagIndex,
                  })
               );

               if (this.sources.length == 0) {
                  this.defaultSourceSelect = true;
               }
            }
            this.pages = [];
            for (let source of this.sources) {
               for (let sentence of source.sentences) {
                  let pageIndex = this.pages.findIndex(
                     (x) => x == sentence.page
                  );
                  if (pageIndex === -1) {
                     this.pages.push(sentence.page);
                  }
                  let sentenceCopy = { ...sentence };
                  sentenceCopy.page = sentenceCopy.page.toString();
                  if (this.loadedPages.includes(sentenceCopy.page)) {
                     this.doHighlight(
                        sentenceCopy.text,
                        sentenceCopy,
                        this.notFound,
                        source
                     );
                  }
               }
            }
         }
      }

      if (this.notFound.length !== 0) {
         this.getTextFromPages();
      }
      let sourcePage = this.plagiarismLabels.length;
      if (i == 0) {
         this.selectedSources = false;
         sourcePage = 1;
      } else {
         for (let sentence of this.sources[this.sources.length - 1].sentences) {
            if (sentence.page < sourcePage) {
               sourcePage = sentence.page;
            }
         }
      }
      if (this.page !== sourcePage) {
         this.scrollPage(sourcePage);
      }
   }

   highlightExcludedSource(id) {
      if (this.selectedSources == true) {
         this.selectedSources = false;
         for (let source of this.activePlagSources) {
            this.store.dispatch(
               reportActions.unCheckCheckbox({
                  source: source,
                  sourceType: this.crossPlagIndex,
               })
            );
         }
      }
      this.defaultSourceSelect = false;
      this.allSources = false;
      let i = 0;
      this.checkBoxes.forEach((element) => {
         if (element.nativeElement.checked == true) {
            i++;
         }
      });
      // if there are any checkbox selected.
      if (i > 0) {
         this.selectedSources = true;
         this.removeHighlight();
      }

      this.notFound = [];
      for (let source of this.excludeSources) {
         if (id == source.sourceId) {
            let sourceIndex = this.sources.findIndex(
               (src) => src.sourceId == source.sourceId
            );
            if (sourceIndex === -1) {
               this.excludedSourceChecked++;
               this.store.dispatch(
                  reportActions.checkCheckboxExclude({
                     source: source,
                     sourceType: this.crossPlagIndex,
                  })
               );

               this.sources.push(source);
            } else {
               this.sources.splice(sourceIndex, 1);
               this.excludedSourceChecked--;
               this.store.dispatch(
                  reportActions.unCheckCheckboxExclude({
                     source: source,
                     sourceType: this.crossPlagIndex,
                  })
               );

               if (this.sources.length == 0) {
                  this.defaultSourceSelect = true;
               }
            }
            this.pages = [];
            for (let source of this.sources) {
               for (let sentence of source.sentences) {
                  let pageIndex = this.pages.findIndex(
                     (x) => x == sentence.page
                  );
                  if (pageIndex === -1) {
                     this.pages.push(sentence.page);
                  }
                  let sentenceCopy = { ...sentence };
                  sentenceCopy.page = sentenceCopy.page.toString();
                  if (!this.MLPlagIndex) {
                     sentenceCopy.isExcluded = true;
                  } else {
                     sentenceCopy.isTranslationExcluded = true;
                  }
                  for (let page of this.loadedPages) {
                     if (page.id === sentence.page) {
                        this.doHighlight(
                           sentenceCopy.text,
                           sentenceCopy,
                           this.notFound,
                           source
                        );
                     }
                  }
               }
            }
         }
      }

      if (this.notFound.length !== 0) {
         this.getTextFromPages();
      }
      let sourcePage = this.plagiarismLabels.length;
      if (i == 0) {
         this.selectedSources = false;
         sourcePage = 1;
      } else {
         for (let sentence of this.sources[this.sources.length - 1].sentences) {
            if (sentence.page < sourcePage) {
               sourcePage = sentence.page;
            }
         }
      }
      if (this.page !== sourcePage) {
         this.scrollPage(sourcePage);
      }
   }
   /**
    * Method used to exclude source from calculation.
    */
   excludeSource(source) {
      this.spinner.show();
      this.submissionService
         .excludeSource(
            source.sourceId,
            this.currentSubmissionId,
            this.crossPlagIndex,
            true,
            source.sourceId,
            source.percentage
         )
         .pipe(first())
         .subscribe(
            (data) => {
               this.store.dispatch(
                  reportActions.getSubmissionPlag({
                     presignedUrl: data[0],
                  })
               );
            },
            (error) => {
               this.spinner.hide();
               console.log('error', error);
            }
         );

      this.excludedSourcesShow = true;
   }

   /**
    * Method used to include source into calculation.
    * @param source
    */
   includeSource(source) {
      this.spinner.show();
      this.submissionService
         .includeSource(
            source.sourceId,
            this.currentSubmissionId,
            this.crossPlagIndex,
            source.percentage
         )
         .pipe(first())
         .subscribe(
            (data) => {
               this.store.dispatch(
                  reportActions.getSubmissionPlag({
                     presignedUrl: data[1],
                  })
               );
            },
            (error) => {
               this.spinner.hide();
               console.log('error', error);
            }
         );
   }

   /**
    * Method used to download report. Your can download two kinds of report crossplag report and mlplag report
    */
   download() {
      if (this.downloadClicked) {
         this.downloadClicked = false;
      } else {
         this.downloadClicked = true;
      }
   }

   /**
    * Method used to open sentence information component as modal.
    * @param sentence
    */
   openModal(sentence) {
      this.store.dispatch(
         reportActions.setCurrentSentenceId({ currentSentenceId: sentence.id })
      );
      this.dialog.open(SentenceInformationModalComponent, {
         data: {
            sentenceId: sentence.id,
            sourceType: this.crossPlagIndex,
            submissionId: this.currentSubmissionId,
         },
         width: '50%',
      });
   }

   /**
    * Method used to scroll in specif pages in document.
    * @param page
    */
   scrollPage(page) {
      this.selectedPage = page;
      if (this.selectedPage == page && page !== this.page) {
         this.selectedPage = '';
         setTimeout(() => {
            this.selectedPage = page;
         }, 100);
      }
      setTimeout(() => {
         this.callHighlight(this.selectedPage);
      }, 500);
   }
   /**
    * Method used to reset filters.
    */
   resetFilter(): void {
      this.allSources = true;
      this.defaultSourceSelect = true;
      this.internetSources = false;
      this.documentSources = false;
      this.topSources = false;
      this.selectedSources = false;
      this.scrollPage(1);
      this.store.dispatch(
         reportActions.resetFilter({ sources: this.activePlagSources })
      );
      this.getFilteredSources('all');
   }
   /**
    * Method used to expand pdf on mobile.
    */
   expandPdf() {
      if (this.expandedPdf == true) {
         this.expandedPdf = false;
      } else {
         this.expandedPdf = true;
      }
   }
   /**
    * Method used to recall highlight, specialy it used when we change source that means when we click on Inspera AS report or mlPlag report.
    */
   reCallHighlight() {
      this.notFound = [];
      this.removeHighlight();
      let i = 0;
      this.sources = [];

      if (this.excludedSourceChecked > 0) {
         this.excludeSources.forEach((source) => {
            if (source.isChecked == true) {
               i++;
               this.sources.push(source);
            }
         });
      } else {
         this.activePlagSources.forEach((source) => {
            if (source.isChecked == true) {
               i++;
               this.sources.push(source);
            }
         });
      }

      if (i > 0) {
         this.selectedSources = true;
         this.defaultSourceSelect = false;
         this.activePlagSources.forEach((source) => {
            if (source.isChecked == true) {
               this.pages = [];
               for (let source of this.sources) {
                  for (let sentence of source.sentences) {
                     let pageIndex = this.pages.findIndex(
                        (x) => x == sentence.page
                     );
                     if (pageIndex === -1) {
                        this.pages.push(sentence.page);
                     }
                     let sentenceCopy = { ...sentence };
                     if (this.highlighQuotes === true) {
                        if (this.loadedPagesId.includes(sentenceCopy.page)) {
                           this.doHighlight(
                              sentenceCopy.text,
                              sentenceCopy,
                              this.notFound,
                              source
                           );
                        }
                     } else {
                        if (
                           this.loadedPagesId.includes(sentenceCopy.page) &&
                           (sentenceCopy.isCitation === null ||
                              sentenceCopy.isCitation === false)
                        ) {
                           this.doHighlight(
                              sentenceCopy.text,
                              sentenceCopy,
                              this.notFound,
                              source
                           );
                        }
                     }
                  }
               }
            }
         });
      } else {
         this.selectedSources = false;
         this.defaultSourceSelect = true;
         for (let source of this.activePlagSources) {
            for (let sentence of source.sentences) {
               let sentenceCopy = { ...sentence };
               if (this.highlighQuotes === true) {
                  if (this.loadedPagesId.includes(sentenceCopy.page)) {
                     this.doHighlight(
                        sentenceCopy.text,
                        sentenceCopy,
                        this.notFound,
                        source
                     );
                  }
               } else {
                  if (
                     this.loadedPagesId.includes(sentenceCopy.page) &&
                     (sentenceCopy.isCitation === null ||
                        sentenceCopy.isCitation === false)
                  ) {
                     this.doHighlight(
                        sentenceCopy.text,
                        sentenceCopy,
                        this.notFound,
                        source
                     );
                  }
               }
            }
         }
      }
      if (this.notFound.length !== 0) {
         this.getTextFromPages();
      }
   }
   /**
    * Method used to filter low percentage sources.
    * @param filteredSources
    */
   lowPercentage(filteredSources): boolean {
      for (let source of filteredSources) {
         if (source.percentage < 1) {
            return true;
         }
      }
   }

   /**
    * Method used to download submission pdf report
    * @param reportType
    */
   downloadPdfReport(reportType: string): void {
      this.showText = true;
      this.spinner.show();
      this.submissionService
         .downloadReport(this.currentSubmissionId, reportType)
         .pipe()
         .subscribe(
            (data: any) => {
               this.spinner.hide();
               this.showText = false;
               swal
                  .fire({
                     title: 'Your document is ready, download it?',
                     showDenyButton: true,
                     confirmButtonText: `Yes`,
                     denyButtonText: `No`,
                  })
                  .then((result) => {
                     /* Read more about isConfirmed, isDenied below */
                     if (result.isConfirmed) {
                        swal.fire('Document Saved!', '', 'success');
                        saveAs(data.presignedS3UrlDownload, 'report.pdf');
                     } else if (result.isDenied) {
                        swal.fire('Document not saved', '', 'info');
                     }
                  });
            },
            (error) => {
               console.log('error', error);
               this.spinner.hide();
            }
         );
   }
   /**
    * Method used to get document language.
    * @param documentLanguage
    */
   getDocumentLanguage(documentLanguage: string): string {
      for (const language of this.languages) {
         if (language.value == documentLanguage) {
            return language.text;
         }
      }
   }

   /**
    * Method used to download document certificate
    */
   downloadCertificate() {
      this.spinner.show();
      this.submissionService
         .downloadCertificate(this.currentSubmissionId)
         .pipe()
         .subscribe(
            (data: any) => {
               this.spinner.hide();
               swal
                  .fire({
                     title: 'Your document is ready, download it?',
                     showDenyButton: true,
                     confirmButtonText: `Yes`,
                     denyButtonText: `No`,
                  })
                  .then((result) => {
                     if (result.isConfirmed) {
                        swal.fire('Document Saved!', '', 'success');
                        saveAs(data.urlToDownload, 'certificate.pdf');
                     } else if (result.isDenied) {
                        swal.fire('Document not saved', '', 'info');
                     }
                  });
               // saveAs(data.urlToDownload, 'certificate.pdf');
            },
            (error) => {
               console.log('error', error);
               this.spinner.hide();
            }
         );
   }
   /**
    * Method is used to download original document that means the document that is uploaded by user.
    */
   downloadOriginalDocument() {
      this.spinner.show();
      this.submissionService
         .downloadOriginalDocument(this.currentSubmissionId)
         .pipe()
         .subscribe(
            (data: any) => {
               this.spinner.hide();
               swal
                  .fire({
                     title: 'Your document is ready, download it?',
                     showDenyButton: true,
                     confirmButtonText: `Yes`,
                     denyButtonText: `No`,
                  })
                  .then((result) => {
                     if (result.isConfirmed) {
                        saveAs(data.urlToDownload, 'document.pdf');
                     } else if (result.isDenied) {
                        swal.fire('Document not saved', '', 'info');
                     }
                  });
            },
            (error) => {
               console.log('error', error);
               this.spinner.hide();
            }
         );
   }

   openDeleteModal(){
      this.dialog.open(DeleteModalComponent, {
         data: {
            submissionId: this.currentSubmissionId,
            permissions: this.studentDeletionPermission,
            isDocumentInArchive: this.isDocumentInArchive,
            sourcesAreDeleted: this.sourcesAreDeleted
         },
         width: '50%',
      });
   }

   openPermissionsModal() {      
      this.dialog.open(ProfessorDeleteModalComponent, {
         data: {
            submissionId: this.currentSubmissionId,
            permissions: this.studentDeletionPermission,
            isDocumentInArchive: this.isDocumentInArchive,
            sourcesAreDeleted: this.sourcesAreDeleted
         },
         width: '50%',
      });
   }

   changeModel() {
      console.log('change');
      
if(this.ai_model == 'base'){
   this.ai_model = 'performance'
} else {
   this.ai_model = 'base'
}

this.aiHighlights();
   }
}
