// *** Angular
import { Component, OnDestroy, OnInit, Optional } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';

// *** Components
import { EditFacultyComponent } from '../modals/edit-faculty/edit-faculty.component';

// *** Selectors
import { getInstitutionFacultiesStateDetails } from '../state/faculty/selector/faculty.selectors';

// *** Actions
import * as facultyActions from '../state/faculty/action/faculty.actions';
import { FacultyRegisterComponent } from '../faculty-register/faculty-register.component';

@Component({
   selector: 'app-units-faculties',
   templateUrl: './units-faculties.component.html',
   styleUrls: ['./units-faculties.component.scss'],
})
export class UnitsFacultiesComponent implements OnInit, OnDestroy {
   /**
    * Variable is used to store retrieved faculties from store.
    */
   facultiesDetails$;
   facultiesDetails;
   /**
    * Symbolizing the page of pagination
    */
   page: number = 1;
   /**
    * Symbolizing the size of the page
    */
   pageSize: number = 5;
   /**
    * Variable used to store a time for function to trigger.(x time after key is up call y function.)
    */
   timer: any;
   /**
    * Variable used to store a name of faculty that is beeing used to filter faculties.
    */
   name: any;
   constructor(
      private store: Store,
      public dialog: MatDialog,
      @Optional() public dialogRef: MatDialogRef<any>
   ) {}
   ngOnDestroy(): void {
      this.facultiesDetails$.unsubscribe();
   }

   ngOnInit(): void {
      this.store.dispatch(facultyActions.loadFacultiesDetails({ page: 1 }));

      this.facultiesDetails$ = this.store
         .select(getInstitutionFacultiesStateDetails)
         .subscribe((data) => {
            if (data !== null) {
               this.facultiesDetails = data;
            }
            if (data.facultyRegistered !== null) {
               this.resetFilter();
               this.store.dispatch(facultyActions.setRegisterFacultyToNull());
            }
         });
   }

   /**
    * Method used to change pagination page. It dispatches an action with page as param.
    * @param event
    */
   changePage(event): void {
      this.page = event;
      this.store.dispatch(
         facultyActions.loadFacultiesDetails({
            page: event,
         })
      );
   }
   /**
    * Method is used to open a modal that will contain a faculty information to edit or delete.
    * @param id
    */
   openModal(id) {
      const dialogReference: MatDialogRef<any, any> = this.dialog.open(
         EditFacultyComponent,
         {
            data: {
               facultyId: id,
               page: this.page,
               name: this.name,
            },
            width: '50%',
         }
      );
   }

   /**
    * Method is used to open a modal that will contain a faculty information to register faculty.
    */
   registerFacultyModal() {
      this.dialog.open(FacultyRegisterComponent, {
         width: '50%',
         height: '90%',
      });
   }

   titleFilter() {
      let time;
      time = 500;
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
         this.store.dispatch(
            facultyActions.loadFacultiesDetails({
               page: this.page,
               name: this.name,
            })
         );
      }, time);
   }

   resetFilter() {
      this.name = undefined;
      this.page = 1;
      this.store.dispatch(
         facultyActions.loadFacultiesDetails({
            page: this.page,
            name: this.name,
         })
      );
   }
}
