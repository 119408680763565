// *** Angular
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { first } from 'rxjs/operators';

// *** Components
import { EditStudentComponent } from '../modals/edit-student/edit-student.component';

// *** Actions
import * as studentActions from '../state/student/action/student.actions';
import * as facultyActions from '../state/faculty/action/faculty.actions';

// *** Selectors
import { getInstitutionStudentsStateDetails } from '../state/student/selector/student.selectors';
import { getInstitutionFacultiesStateDetails } from '../state/faculty/selector/faculty.selectors';

// *** Services
import { DepartmentService } from 'src/app/services/department.service';
import { StudentRegisterComponent } from '../student-register/student-register.component';

@Component({
   selector: 'app-units-students',
   templateUrl: './units-students.component.html',
   styleUrls: ['./units-students.component.scss'],
})
export class UnitsStudentsComponent implements OnInit, OnDestroy {
   /**
    * Variable is used to store retrieved students from service.
    */
   students: any;
   students$;
   /**
    * Symbolizing the page of pagination
    */
   page = 1;
   /**
    * Variabel used to store total number of students without pagination
    */
   studentsNumber: number;
   /**
    * Variable is used to store facultyId to filter students of specific faculty.
    */
   facultyId = '-1';
   /**
    * Variable is used to store departmentId that will be used to filter students of specific department
    */
   departmentId = '-1';
   /**
    * Variable used to store name of student that used to filter them
    */
   name: any;
   /**
    * Variable used to store a time for function to trigger.(x time after key is up call y function.)
    */
   timer: any;
   /**
    * Variable is used to store departments of selected faculty
    */
   departments: any;
   /**
    * Variabels used to retrieve faculties of insitution from store.
    */
   faculties;
   getInstitutionFacultiesStateDetails$: any;
   constructor(
      public dialog: MatDialog,
      private store: Store,
      private departmentService: DepartmentService
   ) {}
   ngOnDestroy(): void {
      this.getInstitutionFacultiesStateDetails$.unsubscribe();
      this.students$.unsubscribe();
   }
   ngOnInit(): void {
      this.store.dispatch(facultyActions.loadFacultiesDetails({ page: 0 }));

      this.getInstitutionFacultiesStateDetails$ = this.store
         .select(getInstitutionFacultiesStateDetails)
         .subscribe((data) => {
            if (data !== null) {
               this.faculties = data.faculties;
            }
         });

      this.store.dispatch(
         studentActions.loadStudentsDetails({
            page: this.page,
            faculty: this.facultyId == '-1' ? undefined : this.facultyId,
            department:
               this.departmentId == '-1' ? undefined : this.departmentId,
            name: this.name,
         })
      );

      this.students$ = this.store
         .select(getInstitutionStudentsStateDetails)
         .subscribe((data) => {
            if (data !== null) {
               this.students = data.students;
               this.studentsNumber = data.studentsNumber;
            }
            if (data.studentRegistered !== null) {
               this.store.dispatch(studentActions.setStudentRegisterToNull());
               this.resetFilter();
            }
         });
   }

   /**
    * Method is used to open a modal that will contain a student information to edit or delete.
    * @param id
    */
   openModal(id) {
      this.dialog.open(EditStudentComponent, {
         data: {
            studentId: id,
            page: this.page,
            faculty: this.facultyId == '-1' ? undefined : this.facultyId,
            department:
               this.departmentId == '-1' ? undefined : this.departmentId,
            name: this.name,
         },
         width: '50%',
         height: '95%',
      });
   }

   /**
    * Method is used to open a modal that will contain a student information to register students.
    */
   registerStudentModal() {
      this.dialog.open(StudentRegisterComponent, {
         width: '50%',
         height: '95%',
      });
   }

   /**
    * Method used to change pagination page. It dispatches an action with page as param.
    * @param event
    */
   changePage(event): void {
      this.page = event;
      this.store.dispatch(
         studentActions.loadStudentsDetails({
            page: this.page,
         })
      );
   }
   /**
    * Function is used to select a faculty to filter thesis and also get departments of selected faculty
    */
   selectedFaculty() {
      this.page = 1;
      this.departmentId = '-1';
      this.store.dispatch(
         studentActions.loadStudentsDetails({
            page: this.page,
            faculty: this.facultyId == '-1' ? undefined : this.facultyId,
            department:
               this.departmentId == '-1' ? undefined : this.departmentId,
            name: this.name,
         })
      );
      if (this.facultyId !== '') {
         this.departmentService
            .getDepartmentsOfFaculty(this.facultyId)
            .pipe(first())
            .subscribe(
               (data) => {
                  this.departments = data.departments;
               },
               (error) => {
                  console.log(error);
               }
            );
      }
   }
   /**
    * Function used to filter thesis by title
    */
   titleFilter() {
      let time = 500;
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
         this.page = 1;
         this.store.dispatch(
            studentActions.loadStudentsDetails({
               page: this.page,
               faculty: this.facultyId == '-1' ? undefined : this.facultyId,
               department:
                  this.departmentId == '-1' ? undefined : this.departmentId,
               name: this.name,
            })
         );
      }, time);
   }
   /**
    * Function used to filter student by department
    */
   departmentFilter() {
      this.page = 1;
      this.store.dispatch(
         studentActions.loadStudentsDetails({
            page: this.page,
            faculty: this.facultyId == '-1' ? undefined : this.facultyId,
            department:
               this.departmentId == '-1' ? undefined : this.departmentId,
            name: this.name,
         })
      );
   }
   /**
    * Function is used to reset filters
    */
   resetFilter() {
      this.page = 1;
      this.facultyId = '-1';
      this.departmentId = '-1';
      this.name = undefined;
      this.store.dispatch(
         studentActions.loadStudentsDetails({
            page: this.page,
            faculty: this.facultyId == '-1' ? undefined : this.facultyId,
            department:
               this.departmentId == '-1' ? undefined : this.departmentId,
            name: this.name,
         })
      );
   }
}
