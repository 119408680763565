import { createAction, props } from '@ngrx/store';

/**
 * Actions related to Subjects.
 * Each action accepts a set of properties.
 * Each action most of the cases has a success and failure action, that updates the store based on successfully/failure api responses from api.
 */

/**
 * Action used to register a subject.
 */
export const subjectsRegister = createAction(
   '[Subject] Subjects Register',
   props<{
      data: any;
   }>()
);

/**
 * Action is called when there is a success status returned from API.
 */
export const subjectsRegisterSuccess = createAction(
   '[Subject] Subjects Register Success',
   props<{ data: any }>()
);

/**
 * Action is called when there is a failure status returned from API.
 */
export const subjectsRegisterFailure = createAction(
   '[Subject] Subjects Register Failure',
   props<{ error: any }>()
);

/**
 * Action used to load Subjects of institution.
 */
export const loadSubjectsDetails = createAction(
   '[Subjects] Load Subjects Details',
   props<{
      generation;
      page;
      faculty?;
      department?;
      professor?;
      status?;
      subjectType?;
      name?;
   }>()
);

/**
 * Action is called when there is a success status returned from API.
 */
export const loadSubjectsDetailsSuccess = createAction(
   '[Subjects] Load Subjects Details Success',
   props<{
      subjects;
      totalSubjects;
   }>()
);

/**
 * Action is called when there is a failure status returned from API.
 */
export const loadSubjectsDetailsFailure = createAction(
   '[Subjects] Load Subjects Details Failure',
   props<{ error: any }>()
);

/**
 * Action used to edit Subject details.
 */
export const editSubjectDetails = createAction(
   '[Subject] Edit Subject Details',
   props<{
      subjectId;
      subjectTitle;
      subjectProfessors;
      subjectFaculty;
      subjectDepartment;
      subjectType;
      subjectDescription;
      subjectCode;
      subjectGeneration;
      subjectYear;
      subjectDuplicate;
      saveAndDuplicate;
      generation;
      page?;
      faculty?;
      department?;
      professor?;
      status?;
      subjectTypeFilter?;
      name?;
   }>()
);

/**
 * Action is called when there is a success status returned from API.
 */
 export const editSubjectDetailsSuccess = createAction(
   '[Subjects] Edit Subject Details Success',
   props<{
    data
   }>()
);

/**
 * Action is called when there is a failure status returned from API.
 */
export const editSubjectDetailsFailure = createAction(
   '[Subject] Edit Subject Details Failure',
   props<{ error: any }>()
);

/**
 * Action used to edit Subject details.
 */
 export const duplicateSubject = createAction(
   '[Subject] Duplicate Subject Details',
   props<{
      subjectId;
      subjectTitle;
      subjectProfessors;
      subjectFaculty;
      subjectDepartment;
      subjectType;
      subjectDescription;
      subjectCode;
      subjectGeneration;
      subjectYear;
      subjectDuplicate;
      saveAndDuplicate;
      generation;
      page?;
      faculty?;
      department?;
      professor?;
      status?;
      subjectTypeFilter?;
      name?;
   }>()
);

/**
 * Action is called when there is a success status returned from API.
 */
 export const duplicateSubjectSuccess = createAction(
   '[Subjects] Duplicate Subject Details Success',
   props<{
    data
   }>()
);

/**
 * Action is called when there is a failure status returned from API.
 */
export const duplicateSubjectFailure = createAction(
   '[Subject] Duplicate Subject Details Failure',
   props<{ error: any }>()
);


/**
 * Action used to remove subject from institution.
 */
export const removeSubject = createAction(
   '[Subject] Remove Subject',
   props<{
      subjectId;
      generation?;
      page?;
      department?;
      professor?;
      faculty?;
      status?;
      subjectTypeFilter?;
      name?
   }>()
);

/**
 * Action is called when there is a success status returned from API.
 */
 export const removeSubjectSuccess = createAction(
   '[Subjects] Remove Subject Success',
   props<{
    data
   }>()
);


/**
 * Action is called when there is a failure status returned from API.
 */
export const removeSubjectFailure = createAction(
   '[Subject] Remove Subject Failure',
   props<{ error: any }>()
);

/**
 * Action used to Change Subjects Generation.
 */
export const changeSubjectsGeneration = createAction(
   '[Subject] Change Subjects Generation',
   props<{
      subjects;
      generation;
   }>()
);

/**
 * Action used to Change Subjects Generation.
 */
 export const changeSubjectsGenerationSuccess = createAction(
   '[Subject] Change Subjects Generation Success',
   props<{
       data
   }>()
);

/**
 * Action is called when there is a failure status returned from API.
 */
export const changeSubjectsGenerationFailure = createAction(
   '[Subject] Change Subjects Generation Failure',
   props<{ error: any }>()
);

export const setSubjectRegisterToNull = createAction(
   '[Subject] Set Subject Register To Null'
);
