import * as AppState from '../../../../../../../store/app.state';
import * as basicProfessorsActions from '../action/basic-professors-statistics.actions';
import { createReducer } from '@ngrx/store';
import { on } from '@ngrx/store';

/**
 * assignment reducer used to updated store values based on certain action.
 */

/**
 * State interface extends App state State and add user state to the main state.
 */
export interface State extends AppState.State {
   basicProfessorsStatistics: basicProfessorsStatisticsState;
}

/**
 * An SubjectState interface, which represents the blueprint of the User State.
 */
export interface basicProfessorsStatisticsState {
   basicProfessorsStatistics: any;
   professorsNumber: number;
   errorMessage: string;
}

/**
 * SubjectState initial state.
 */
export const initialState: basicProfessorsStatisticsState = {
   basicProfessorsStatistics: null,
   professorsNumber: null,
   errorMessage: null,
};

/**
 * Subject Reducer used to update the state upon Subject actions.
 */
export const basicProfessorsStatisticsReducer = createReducer(
   initialState,
   /**
    * On loadSubjectsDetailsSuccess action updated current state with the following properties.
    */
   on(
      basicProfessorsActions.loadBasicProfessorsStatisticsSuccess,
      (state, action): basicProfessorsStatisticsState => {
         return {
            ...state,
            basicProfessorsStatistics: action.data.professorDetails,
            professorsNumber: action.data.professorsNumber,
         };
      }
   ),

   /**
    * On loadSubjectsDetailsFailure action updated current state with the following properties.
    */
   on(
      basicProfessorsActions.loadBasicProfessorsStatisticsFailure,
      (state, action): basicProfessorsStatisticsState => {
         return {
            ...state,
            errorMessage: action.error,
         };
      }
   )
);
