import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  userRole;
  constructor(private authServiceService: AuthService) {}

  ngOnInit(): void {
    this.userRole = this.authServiceService.isLoggedInRole();
  }
}
