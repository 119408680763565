<!-- <div class="text-input">
   <main>
      <h4>{{ 'individual.upload.text_input' | translate }}</h4>
      <h5>{{ 'individual.upload.document_title' | translate }}</h5>
      <input type="text" />
      <h5>{{ 'individual.upload.insert_text' | translate }}</h5>
      <textarea name="" id="" cols="30" rows="10"></textarea>
      <div class="center">
         <a class="submit" href="">{{ 'general.submit' | translate }}</a>
      </div>
      <h5>Coming soon</h5>
   </main>
</div> -->

<ng-container *ngIf="this.uploaded == 0">
    <div class="upload">
        <main>
            <p>Upload multiple documents at once to check for plagiarism. Click on the "Select Files" button below and choose multiple documents from your computer.</p>
            <div>
          
            <ngx-file-drop class="dropzone" dropZoneLabel="Drop files here" (onFileDrop)="dropped($event)" 
            (onFileOver)="fileOver($event)" (onFileLeave)="fileLeave($event)">
                <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                    <div (click)="openFileSelector()" class="contentOnFileDrop">
                        <img src="../../assets/images/upload.png" alt="" />
                        <h6>Drag and drop your files here</h6>
                     </div>
                     <button class="submit" type="button" (click)="openFileSelector()">Select files</button>
                </ng-template>
            </ngx-file-drop>

        </div>
        </main>
     </div>

</ng-container>

<ng-container *ngIf="this.uploaded == 1">
    <div class="file-uploads">
       <main>
          <div>
             <form [formGroup]="uploadForm">
                <h5 class="bold">
                   {{ 'individual.upload.upload_form' | translate }}
                </h5>
                <div
                   *ngIf="
                      form.documentTitle.invalid && form.documentTitle.touched
                   "
                   class="invalid"
                >
                   <div *ngIf="form.documentTitle.errors.required">
                      Document title is required*
                   </div>
                   <div *ngIf="form.documentTitle.errors.minlength">
                      <div>Document title must be at least 5 characters*</div>
                   </div>
                   <div *ngIf="form.documentTitle.errors.maxlength">
                      <div>
                         Document title can't be more than 190 characters*
                      </div>
                   </div>
                </div>
 
                <h5>Name and Surname</h5>
                <input  type="text" formControlName="fullName" />
                <div
                   *ngIf="form.fullName.invalid && form.fullName.touched"
                   class="invalid"
                >
                   <div *ngIf="form.fullName.errors.required">
                      Full name is required*
                   </div>
                </div>
                <h5>{{ 'individual.upload.date_of_upload' | translate }}</h5>
                <input formControlName="dateOfUpload" disabled type="text" />
                <h5 *ngIf="user.roleId == 2">Amount of credits to be charged:</h5>
                <input *ngIf="user.roleId == 2" formControlName="documentCredits" disabled type="text" />
                <h5
                   *ngIf="this.user.roleId == 5 && thesisId                  "
                >
                   Thesis 
                </h5>
                <h5 *ngIf="this.user.roleId == 5 && assignmentId">
                   Assignment
                </h5>
                <h5
                   *ngIf="
                   this.user.roleId != 5 "
                >
                   {{ 'individual.upload.document_language' | translate }}
                </h5>
                <div *ngIf="this.user.roleId != 5" style="position: relative">
                   <ng-container *ngIf="this.disableField === true">
                      <select
                         (change)="selectLanguage($event.target.value)"
                         formControlName="documentLanguage"
                         disabled
                         class="border"
                         name=""
                         id=""
                      >
                         <option value="-1" selected>Select a language</option>
                         <option
                            *ngFor="let language of languages"
                            value="{{ language.value }}"
                         >
                            {{ language.text }}
                         </option>
                         <span
                            ><img src="../../assets/images/Path 26.png" alt=""
                         /></span>
                      </select>
                   </ng-container>
                   <ng-container *ngIf="this.disableField === false">
                      <select
                         (change)="selectLanguage($event.target.value)"
                         formControlName="documentLanguage"
                         class="border"
                         name=""
                         id=""
                      >
                         <option value="-1" selected>Select a language</option>
                         <option
                            *ngFor="let language of languages"
                            value="{{ language.value }}"
                         >
                            {{ language.text }}
                         </option>
                         <span
                            ><img src="../../assets/images/Path 26.png" alt=""
                         /></span>
                      </select>
                   </ng-container>
                   <span
                      ><img src="../../assets/images/Path 26.png" alt=""
                   /></span>
                </div>
                <div
                   *ngIf="form.documentLanguage.invalid && languageSelected"
                   class="invalid"
                   for
                >
                   <div
                      *ngIf="
                         form.documentLanguage.errors.required ||
                         form.documentLanguage.value == 'undefined'
                      "
                   >
                      Document language is required*
                   </div>
                </div>
 
                <h5
                   *ngIf="user.roleId != 5
                   "
                >
                   {{ 'individual.upload.translate_document' | translate }}
                </h5>
                <input *ngIf="this.user.roleId == 5 && assignmentId" [value]="assignmentName" disabled type="text">
                <input *ngIf="this.user.roleId == 5 && thesisId" [value]="thesisName" disabled type="text">
 
                <div *ngIf="this.user.roleId != 5" style="position: relative">
                   <ng-container *ngIf="this.disableField === true">
                      <select
                         (change)="selectTranslatedLanguage($event.target.value)"
                         formControlName="documentTranslatedLanguage"
                         class="border"
                         disabled
                         name=""
                         id=""
                      >
                         <option value="-1" selected>None</option>
                         <option
                            *ngFor="let language of languages"
                            value="{{ language.value }}"
                         >
                            {{ language.text }}
                         </option>
                         <span
                            ><img src="../../assets/images/Path 26.png" alt=""
                         /></span>
                      </select>
                   </ng-container>
                   <ng-container *ngIf="this.disableField === false">
                      <select
                         (change)="selectTranslatedLanguage($event.target.value)"
                         formControlName="documentTranslatedLanguage"
                         class="border"
                         name=""
                         id=""
                      >
                         <option value="-1" selected>None</option>
                         <option
                            *ngFor="let language of languages"
                            value="{{ language.value }}"
                         >
                            {{ language.text }}
                         </option>
                         <span
                            ><img src="../../assets/images/Path 26.png" alt=""
                         /></span>
                      </select>
                   </ng-container>
                   <span
                      ><img src="../../assets/images/Path 26.png" alt=""
                   /></span>
                </div>
                <h5 *ngIf="this.user.roleId !== 5">Enable AI detection:</h5>
                <div *ngIf="this.user.roleId !== 5"> 
                   <label class="switch">
                      <input
                         formControlName="aiDetection"
                         type="checkbox"
                      />
    
                      <span class="slider round"></span>
                   </label>
                </div>
                <div
                   class="advanced_options"
                   *ngIf="this.user.institutionId === null || user.roleId == 4"
                >

                   
                   <div class="flex add_to_archive">
                      <input
                         type="checkbox"
                         id="add_to_archive"
                         value="{{ archive }}"
                         name="add_to_archive"
                         [(ngModel)]="archive"
                         (click)="archiveDocument()"
                         [ngModelOptions]="{ standalone: true }"
                      />
                      <label for="add_to_archive"></label>
                      <label for="">
                         Add this document to archive<i
                            class="fas fa-info-circle"
                            matTooltipPosition="after"
                            matTooltipClass="tooltipCustomCss"
                            matTooltip="If you add this document to archive,all uploaded
                             documents will search for plagiarism from this
                             document"
                         ></i>
                      </label>
                   </div>
                   <h4 (click)="advancedOptions()">
                     Advanced options<img
                        [ngClass]="{ expand: advancedOptionsIndex == true }"
                        src="../../assets/images/Path 26.png"
                        alt=""
                     />
                  </h4>
                   <div *ngIf="advancedOptionsIndex == true">
                      <div>
                         <label for="">
                            <h4 class="librariesTitle">Choose third party libraries</h4>
                            <i
                               style="margin-left: 1%"
                               matTooltipPosition="after"
                               class="fas fa-info-circle"
                               matTooltipClass="tooltipCustomCss"
                               matTooltip="Cross-checking your document with third-party libraries and archives may delay checking your document for up to several hours. Please be aware!"
                            ></i>
                         </label>
                         <div class="">
                            <div
                               *ngFor="
                                  let library of thirdPartyLib;
                                  let i = index
                               "
                            >
                               <input
                                  type="checkbox"
                                  id="library{{ i }}"
                                  [value]="library.description"
                                  [checked]="library.isChecked"
                                  name="library{{ i }}"
                                  (change)="onCheckboxChange($event)"
                               />
                               <label for="library{{ i }}"
                                  >{{ library.description }}
                                  <i
                                     style="margin-left: 1%"
                                     class="fas fa-info-circle"
                                     matTooltip="{{ library.information }}"
                                     matTooltipPosition="after"
                                     matTooltipClass="tooltipCustomCss"
                                  ></i
                               ></label>
                            </div>
                         </div>
                      </div>
                      <div>
                         <h4>Choose your citation</h4>
                         <div style="position: relative">
                            <select
                               (change)="selectCitationStyle($event)"
                               [(ngModel)]="citationStyle"
                               class="border"
                               name=""
                               id=""
                               [ngModelOptions]="{ standalone: true }"
                            >
                               <option value="" selected>
                                  Select a citation style
                               </option>
                               <optgroup label="popular formats">
                                  <option value="AMA">AMA</option>
                                  <option value="APA(6th edition)">
                                     APA (6th edition)
                                  </option>
                                  <option value="APA(7th edition)">
                                     APA (7th edition)
                                  </option>
                                  <option value="Harvard">Harvard</option>
                                  <option value="IEEE">IEEE</option>
                               </optgroup>
                               <optgroup label="others">
                                  <option value="OSCOLA">OSCOLA</option>
                                  <option value="MHRA(3rd edition)">
                                     MHRA (3rd edition)
                                  </option>
                                  <option value="Vancouver">Vancouver</option>
                               </optgroup>
                            </select>
                            <span class="chevron-icon"
                               ><img
                                  src="../../assets/images/Path 26.png"
                                  alt=""
                            /></span>
                         </div>
                      </div>
                   </div>
                </div>
                <div class="center">
                   <button
                      *ngIf="!uploadForm.invalid && filesPreviewReady"
                      [ngClass]="{ disable: uploadForm.invalid }"
                      class="submit"
                      (click)="createSubmission()"
                   >
                      {{ 'general.submit' | translate }}
                   </button>
 
                   <button
                   style="pointer-events: unset;"
                      *ngIf="uploadForm.invalid || !filesPreviewReady"
                      [ngClass]="{ disable: uploadForm.invalid }"
                      (click)="showFormMessages()"
                      class="submit"
                   >
                      {{ 'general.submit' | translate }}
                   </button>
 
                   <!-- <ng-container *ngIf="allowToSubmitDocument == false">
                       <button
                          *ngIf="!uploadForm.invalid"
                          [ngClass]="{ disable: uploadForm.invalid }"
                          class="submit"
                          (click)="showAlertMessage()"
                       >
                          {{ 'general.submit' | translate }}
                       </button>
  
                       <button
                          *ngIf="uploadForm.invalid"
                          [ngClass]="{ disable: uploadForm.invalid }"
                          (click)="showFormMessages()"
                          class="submit"
                       >
                          {{ 'general.submit' | translate }}
                       </button>
                    </ng-container> -->
                </div>
             </form>
          </div>
      
          <div class="file files-preview" >
            <ng-container *ngIf="filesPreviewReady">
              <div  class="files" *ngFor="let file of filesProperties ; let i = index">
                <i class="far fa-file"></i>
                <div>
                    <h6>{{ file.title }}</h6>
                    <p>{{ file.fileSize }}</p>
                </div>
                <button>
                    <i (click)="previewFile(file.previewSrc)" class="fas fa-eye"></i>
                    <i class="far fa-times-circle" (click)="deleteFile(i)"></i>
                </button>
              </div>
            </ng-container >
              <ng-container *ngIf="!filesPreviewReady">
               <div class="loadingFiles">
                   <div class="processing">
                     Loading files preview
                     
                     <mat-spinner></mat-spinner>
                   </div>
               </div>
             </ng-container>
          </div>


       </main>
    </div>
 </ng-container>
 