import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SubmissionsService } from 'src/app/services/submissions.service';
import { getCurrentUser } from 'src/app/authModule/state/authentication.selectors';
import { Store } from '@ngrx/store';
import { UploadfileService } from '../upload-file/service/uploadfile.service';
import { first } from 'rxjs/operators';
import swal from 'sweetalert2';
import { Router } from '@angular/router';
import { FilePreviewComponent } from '../file-preview/file-preview.component';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-input-text',
  templateUrl: './input-text.component.html',
  styleUrls: ['./input-text.component.scss'],
})
export class InputTextComponent implements OnInit {
  filesProperties = [];
  uploadedFile: any;
  converted: any;
  allowToSubmitDocument: any;
  trial: any;
  allowedProducer: any;
  documentCredits: any;
  uploaded = 0;
  filesPreviewReady = false;
  filesPreviewBar = 0;
   languageSelected: boolean;

  constructor(  private submissionService: SubmissionsService,    private toastrService: ToastrService,     private spinner: NgxSpinnerService,  private fb: FormBuilder,    private store: Store,      private fileUpload: UploadfileService,      private router: Router,    private dialog: MatDialog,private cdr: ChangeDetectorRef) { }

  assignmentId;
  fileToUpload: File = null;
  uploadForm;
  user$;
  user;
  disableField = false;
   /**
    * List of third party libraries to check for document.
    */
   thirdPartyLib: Array<any> = [
      {
         description: 'ScienceDirect',
         isChecked: false,
         information:
            'Searching ScienceDirect content may involve delays on checking your document.',
      },
      {
         description: 'Scopus',
         isChecked: false,
         information:
            'Searching Scopus metadata and abstracts may involve delays on checking your document.',
      },
      {
         description: 'CORE',
         isChecked: false,
         information:
            'Searching CORE content may involve delays on checking your document. ',
      },
      {
         description: 'IEEE',
         isChecked: false,
         information:
            'Searching IEEE metadata and abstracts may involve delays on checking your document.',
      },
      {
         description: 'arXiv.org',
         isChecked: false,
         information:
            'Searching arXiv metadata and abstracts may involve delays on checking your document.',
      },
      {
         description: 'Crossref',
         isChecked: false,
         information:
            'Searching Crossref metadata and abstracts may involve delays on checking your document.',
      },
      {
         description: 'Europeana',
         isChecked: false,
         information:
            'Searching Europeana metadata and abstracts may involve delays on checking your document.',
      },
      {
         description: 'CaseLaw Access Project',
         isChecked: false,
         information:
            'Searching CaseLaw content may involve delays on checking your document.',
      },
   ];
  thesisId;
  originalLanguage = "en";
  translatedLanguage = "es";
  previewKey;
  thirdPartyLibraries: any = this.thirdPartyLib;
     /**
    * Boolean value used to show/hide advanced options of the form.
    */
     advancedOptionsIndex: boolean = false;
     /**
      * Boolean value used to get user consent to archive the file.
      */
     archive: boolean = false;
     /**
      * Document citation style picked by user.
      */
     citationStyle: string = '';

     languages = [
      {
         text: 'English',
         value: 'en',
      },
      {
         text: 'Albanian',
         value: 'sq',
      },
      {
         text: 'German',
         value: 'de',
      },
      {
         text: 'Italian',
         value: 'it',
      },
      {
         text: 'French',
         value: 'fr',
      },
      {
         text: 'Spanish',
         value: 'es',
      },
      {
         text: 'Greek',
         value: 'el',
      },
      {
         text: 'Czech',
         value: 'cs',
      },
      {
         text: 'Turkish',
         value: 'tr',
      },
      {
         text: 'Slovak',
         value: 'sk',
      },
      {
         text: 'Lithuanian',
         value: 'lt',
      },
      {
         text: 'Latvian',
         value: 'lv',
      },
      {
         text: 'Polish',
         value: 'pl',
      },
      {
         text: 'Serbian',
         value: 'sr',
      },
      {
         text: 'Macedonian',
         value: 'mk',
      },
      {
         text: 'Portuguese',
         value: 'pt',
      },
      {
         text: 'Dutch',
         value: 'nl',
      },
      {
         text: 'Russian',
         value: 'ru',
      },
      {
         text: 'Bulgarian',
         value: 'bg',
      },
      {
         text: 'Hungarian',
         value: 'hu',
      },
      {
         text: 'Romanian',
         value: 'ro',
      },
      {
         text: 'Slovenian',
         value: 'sl',
      },
      {
         text: 'Swedish',
         value: 'sv',
      },
      {
         text: 'Finnish',
         value: 'fi',
      },
      {
         text: 'Croatian',
         value: 'hr',
      },
      {
         text: 'Bosnian',
         value: 'bs',
      },
      {
         text: 'Norwegian',
         value: 'no',
      },
   ];
  



  ngOnInit(): void {


    this.user$ = this.store.select(getCurrentUser).subscribe((data) => {
      if (data !== null && data != undefined) {
         this.user = data;

         if (this.user.allowedLanguages !== null) {
            this.disableField = true;
         }
      }
   });
    this.uploadForm = this.fb.group({
      assignmentId: [],
      thesisId: [],
      documentTitle: [
         ''],
      fullName: ['', [Validators.required]],
      dateOfUpload: ['', [Validators.required]],
      documentSize: ['', [Validators.required]],
      documentCredits: ['', [Validators.required]],
      documentLanguage: ['', [Validators.required]],
      documentTranslatedLanguage: [''],
      aiDetection: [false],
      thirdPartyLibs: this.fb.array(this.thirdPartyLib),
   });
  }

  public files: NgxFileDropEntry[] = [];
 
  public dropped(files: NgxFileDropEntry[]) {
    this.files = files;
    this.uploaded = 1;
    this.spinner.hide();

    for (const droppedFile of files) {
      let fileProperties = {
         fileSize : '',
         title: "",
         previewSrc: "",
         converted: "",
         fileKey: "",
         allowedProducer: ""
       }
      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {

         fileProperties.title = file.name; 
         this.fileToUpload = file;
         const firstName = this.user?.name.split(' ')[0];
         const lastName = this.user?.name.split(' ')[1];
         this.form.fullName.setValue(this.user?.name);
         let fileSize = this.formatBytes(this.fileToUpload.size);
         fileProperties.fileSize = fileSize; 
         this.form.documentSize.setValue(fileSize);
         if (this.assignmentId || this.thesisId) {
            this.form.documentLanguage.setValue(this.originalLanguage);
            this.form.documentTranslatedLanguage.setValue(this.translatedLanguage);
         }
         let date = new Date();
         let dateString =
            date.getDate() +
            '/' +
            (date.getMonth() + 1) +
            '/' +
            date.getFullYear();
         this.form.dateOfUpload.setValue(dateString);
   
         // this.spinner.show();
         this.fileUpload
            .generatePresignedURL(file.type, firstName, lastName)
            .pipe(first())
            .subscribe(
               async (data) => {
                  if (data?.showAlert) {
                     this.spinner.hide();
                  } else {
                     this.previewKey = data.key;


                     
                     
                      this.fileUpload
                        .uploadfileAWSS3(data.presignedS3Url, file)
                        .pipe(first())
                        .subscribe(
                           async (data1) => {
                              console.log( fileProperties.fileKey);

                              console.log( file.type," this.fileToUpload.type");
                              

                               this.fileUpload
                                 .generatePresignedURLPreview(
                                    data.key,
                                    file.type,
                                    firstName,
                                    lastName
                                 )
                                 .pipe(first())
                                 .subscribe((data) => {
                                    this.documentCredits = data.documentCredits;
                                    setTimeout(() => {
                                       fileProperties.previewSrc =data.presignedS3UrlPreview; 
                                       fileProperties.converted =data.converted; 
                                       fileProperties.fileKey = data.Key; 
                                       fileProperties.allowedProducer = data.allowedProducer;

                                       this.filesProperties.push(fileProperties);
                                       this.filesPreviewBar = (this.filesProperties.length*100)/files.length;
                                       
                                       this.allowToSubmitDocument =
                                          data.allowSubmission.allowToSubmitDocument;
                                       this.trial = data.allowSubmission.trail;
                              

                                       this.form.documentCredits.setValue(
                                          this.documentCredits
                                       );
                                       if (
                                          this.user.Institution !== null &&
                                          this.user.roleId === 2
                                       ) {
                                          this.form.documentTranslatedLanguage.setValue(
                                             this.user.allowedLanguages?.targetLanguage
                                          );
                                          this.form.documentLanguage.setValue(
                                             this.user.allowedLanguages?.documentLanguage
                                          );
                                       } else {
                                          // this.form.documentTranslatedLanguage.setValue(
                                          //    -1
                                          // );
                                          // this.form.documentLanguage.setValue('');
                                       }
                                       if (this.assignmentId || this.thesisId) {
                                          this.form.documentLanguage.setValue(
                                             this.originalLanguage
                                          );
                                          this.form.documentTranslatedLanguage.setValue(
                                             this.translatedLanguage
                                          );
                                          this.form.documentLanguage.disable();
                                          this.form.documentTranslatedLanguage.disable();
                                       }
                                          
                        
                                    if(files.length == this.filesProperties.length) {
                                       this.filesPreviewReady = true;
                                       this.cdr.detectChanges();
                                    }
                                    }, 500);

                                 }),
                                 (error) => {
                                    this.spinner.hide();
                                    console.log('error3', error);
                                 };
                           },
                           (error) => {
                              this.spinner.hide();
                              console.log('error2', error);
                           }
                        );
                  }
               },
               (error) => {
                  this.spinner.hide();
                  console.log('error1', error);
               }
            );
 
        });
      } else {
        // It was a directory (empty directories are added, otherwise only files)
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
        console.log(droppedFile.relativePath, fileEntry);
      }
    }
    
    console.log(this.filesProperties,"this.filesProperties");
      
  }
 
  get form() {
    return this.uploadForm.controls;
 }

  public fileOver(event){
    console.log(event);
  }
 
  public fileLeave(event){
    console.log(event);
  }

     /**
    * Method used to create submission when uploading a document.
    */


   /**
    * Method used to create submission when uploading a document.
    */
   async createSubmission() {

      this.spinner.show();

      
      for (let index = 0; index <  this.filesProperties.length; index++) {
         const element =  this.filesProperties[index];
         let withCredits = true;
         if (this.user.institutionId !== null && this.user.roleId == 2) {
            if (this.user.documentsToUse > 0 && this.user.wordsToUse > 0) {
               const result = await swal.fire({
                  title: 'Choose how to proceed',
                  text: 'Would you like to use credits or  number of documents?',
                  icon: 'question',
                  cancelButtonText: 'Close',
                  confirmButtonText: 'With credits',
                  denyButtonText: 'With documents',
                  denyButtonColor: '#2455C2',
                  confirmButtonColor: '#F3001E',
                  cancelButtonColor: '#8F00F1',
                  showDenyButton: true,
                  showCancelButton: true,
                  allowOutsideClick: false,
               });
               if (result.isConfirmed) {
                  withCredits = true;
               }
               if (result.isDenied) {
                  withCredits = false;
               }
               if (result.isDismissed) {
                  return;
               }
            } else {
               if (this.user.documentsToUse > 0 && this.user.wordsToUse === 0) {
                  withCredits = false;
               } else if (
                  this.user.documentsToUse === 0 &&
                  this.user.wordsToUse > 0
               ) {
                  withCredits = true;
               } else {
                  await swal.fire({
                     title: `Something is wrong!`,
                     text: `You don't have credits or documents to submit the document`,
                     icon: 'error',
                     confirmButtonText: 'I understand',
                     confirmButtonColor: '#F3001E',
                     allowOutsideClick: false,
                  });
                  return;
               }
            }
         }
   
         if(this.user.roleId == 5) {
            this.archive = true;
         }
   
         if (this.form.documentTranslatedLanguage.value === -1) {
            this.form.documentTranslatedLanguage.setValue(null);
         }
         
                  this.submissionService
            .createSubmission(
               element.title,
               element.fileKey,
               this.form.fullName.value,
               this.form.dateOfUpload.value,
               element.fileSize,
               this.form.documentLanguage.value,
               this.form.documentTranslatedLanguage.value,
               this.archive,
               this.citationStyle,
               this.thirdPartyLibraries,
               element.converted,
               withCredits,
               this.form.assignmentId.value,
               this.form.thesisId.value,
               this.form.aiDetection.value
            )
            .subscribe(
               (data: any) => {
                  if (data.showAlert == true) {
                     this.trial = data.allowSubmission.trial;
                     setTimeout(() => {
                        this.router.navigateByUrl('/individual/dashboard');
                     }, 1500);
                     if(index + 1 == this.filesProperties.length ) {
                        this.spinner.hide();
                        this.toastrService.success(
                           'Document are uploaded successfully.'
                        );
                     }
                  } else {
                     if (this.user.roleId != 4 && this.user.roleId != 5) {
                           this.router.navigateByUrl('/individual/dashboard');
                     } else if (this.user.roleId == 4) {
                           this.router.navigateByUrl('/professor/submissions');
                     } else if (this.user.roleId == 5) {
                           this.router.navigateByUrl('/student/register/upload');
                     }
        
                     if(index + 1 == this.filesProperties.length ) {
                        this.spinner.hide();
                        this.toastrService.success(
                           'Document are uploaded successfully.'
                        );
                     }
     
                  }
               },
               (error) => {
                  console.log('error Submit', error);
                  if (withCredits) {
                     if (
                        error !== 'Document title must be at least 5 characters' &&
                        error !==
                           `Document title can't be more than 190 characters`
                     ) {
                        this.showAlertMessage();
                     }
                  }
                  this.spinner.hide();
               }
            );
         
      }

   }

   formatBytes(bytes) {
    var marker = 1024; // Change to 1000 if required
    var decimal = 2; // Change as required
    var kiloBytes = marker; // One Kilobyte is 1024 bytes
    var megaBytes = marker * marker; // One MB is 1024 KB
    var gigaBytes = marker * marker * marker; // One GB is 1024 MB

    if (bytes < kiloBytes) return bytes + ' Bytes';
    else if (bytes < megaBytes)
       return (bytes / kiloBytes).toFixed(decimal) + ' KB';
    else if (bytes < gigaBytes)
       return (bytes / megaBytes).toFixed(decimal) + ' MB';
 }

    /**
    * Method to display modal that the producer is not allowed
    */
    async showNotAllowedProducerMessage() {
      const result = await swal.fire({
         title: 'This file has some issues',
         text: 'Try converting your file to .docx and then convert it to pdf',
         icon: 'warning',
         confirmButtonColor: '#3085d6',
         cancelButtonColor: '#b5adad',
         confirmButtonText: 'I understand',
         allowOutsideClick: false,
      });
   }

      /**
    * Method used to inform user that he can't upload document.
    */
      async showAlertMessage() {
         if (this.user.roleId != 4 && this.user.roleId !== 5) {
            let title = `Not enough credits!`;
            let message = `To submit your document you need ${Math.abs(
               this.user.wordsToUse - this.documentCredits
            )} more credits.`;
            const result = await swal.fire({
               title: title,
               text: message,
               icon: 'warning',
               showCancelButton: true,
               confirmButtonColor: '#3085d6',
               cancelButtonColor: '#b5adad',
               confirmButtonText: 'Buy more credits',
               allowOutsideClick: false,
            });
            if (result.isConfirmed) {
               this.router.navigateByUrl('/individual/add-credits');
            } else if (result.isDismissed) {
               this.router.navigateByUrl('/individual/dashboard');
            }
         }
      }
  
      previewFile(src) {
         console.log(src,"srcc");
         
         this.dialog.open( FilePreviewComponent, {
            width: '50%',
            height: '90%',
            data: {
               src,
            },
         });
      }

      deleteFile(index) { 
        this.filesProperties.splice(index, 1);
        if(this.filesProperties.length == 0) {
         this.uploaded = 0;
        }
      }

      showFormMessages() {
            if (this.form.documentLanguage.invalid) {
               this.showWarningMessage('Please select a language!');
            } else if(!this.filesPreviewReady) {

               this.showWarningMessage('Please wait for files preview');
            }
      }



      showWarningMessage(message) {
         this.toastrService.warning(message);
      }

      selectLanguage(event) {
         if (event == '-1') {
            this.showWarningMessage('Please select a language!');
            this.form.documentLanguage.setErrors({
               required: true,
               invalid: true,
               touched: true,
            });
            this.languageSelected = true;
         } else {
            this.languageSelected = false;
            this.form.documentLanguage.setValue(event);
         }
      }

      advancedOptions() {
         if (this.advancedOptionsIndex == false) {
            this.advancedOptionsIndex = true;
         } else {
            this.advancedOptionsIndex = false;
         }
      }
      

}
