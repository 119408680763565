//***Angular***
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Routes, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NgxPaginationModule } from 'ngx-pagination';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';


//***Packages***
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { NgApexchartsModule } from 'ng-apexcharts';
import { PdfViewerModule } from 'ng2-pdf-viewer';


//***Components***
import { AdministratorDashboardComponent } from './administrator-dashboard/administrator-dashboard.component';
import { NavbarAdministratorComponent } from '../sharedModule/navbar-administrator/navbar-administrator.component';
import { FacultyRegisterComponent } from './faculty-register/faculty-register.component';
import { DepartmentRegisterComponent } from './department-register/department-register.component';
import { CourseRegisterComponent } from './course-register/course-register.component';
import { ProfessorRegisterComponent } from './professor-register/professor-register.component';
import { StudentRegisterComponent } from './student-register/student-register.component';
import { RegisterModulesComponent } from './register-modules/register-modules.component';
import { UnitsFacultiesComponent } from './units-faculties/units-faculties.component';
import { EditFacultyComponent } from './modals/edit-faculty/edit-faculty.component';
import { UnitsDepartmentsComponent } from './units-departments/units-departments.component';
import { EditDepartmentComponent } from './modals/edit-department/edit-department.component';
import { UnitsCoursesComponent } from './units-courses/units-courses.component';
import { EditCourseComponent } from './modals/edit-course/edit-course.component';
import { UnitsProfessorsComponent } from './units-professors/units-professors.component';
import { EditProfessorComponent } from './modals/edit-professor/edit-professor.component';
import { UnitsStudentsComponent } from './units-students/units-students.component';
import { EditStudentComponent } from './modals/edit-student/edit-student.component';
import { UnitsThesisComponent } from './units-thesis/units-thesis.component';
import { AdministratorStatisticsComponent } from './administrator-statistics/overview/administrator-statistics.component';
import { SubmissionsInstitutionalComponent } from './graphs/submissions-institutional/submissions-institutional.component';
import { UsersInstitutionalComponent } from './graphs/users-institutional/users-institutional.component';
import { ThesisInstitutionalComponent } from './graphs/thesis-institutional/thesis-institutional.component';
import { UsersBasicComponent } from './graphs/users-basic/users-basic.component';
import { SubmissionsBasicComponent } from './graphs/submissions-basic/submissions-basic.component';
import { EditSubjectsGenerationComponent } from './modals/edit-subjects-generation/edit-subjects-generation.component';
import { AcademicUnitsStatisticsComponent } from './administrator-statistics/academic-units-statistics/academic-units-statistics.component';
import { StatisticsDashboardComponent } from './administrator-statistics/dashboard/statistics-dashboard/statistics-dashboard.component';
import { FacultiesStatisticsComponent } from './administrator-statistics/academic-units-statistics/faculties/faculties-statistics/faculties-statistics.component';

import { DepartmentsStatisticsComponent } from './administrator-statistics/academic-units-statistics/departments/departments-statistics/departments-statistics/departments-statistics.component';

import { SubjectsStatisticsComponent } from './administrator-statistics/academic-units-statistics/subjects/subjects-statistics/subjects-statistics.component';
import { AssignmentsStatisticsComponent } from './administrator-statistics/academic-units-statistics/assignments/assignments-statistics/assignments-statistics.component';
import { UsersComponent } from './administrator-statistics/users/users.component';
import { InstitutionalProfessorsStatisticsComponent } from './administrator-statistics/users/professors/institutional/institutional-professors-statistics/institutional-professors-statistics.component';
import { BasicProfessorsStatisticsComponent } from './administrator-statistics/users/professors/basic/basic-professors-statistics/basic-professors-statistics.component';
import { StudentsStatisticsComponent } from './administrator-statistics/users/students/students-statistics/students-statistics.component';
import { ProfessorsOverviewComponent } from './administrator-statistics/users/professors/professors-overview.component';
import { AssignmentSubmissionsComponent } from './administrator-statistics/submissions/assignment-submissions/assignment-submissions/assignment-submissions.component';
import { SubmissionsStatisticsComponent } from './administrator-statistics/submissions/overview/submissions-statistics/submissions-statistics.component';
import { ProfessorsSubmissionsComponent } from './administrator-statistics/submissions/professor-submissions/professors-submissions/professors-submissions.component';
import { AdministratorUsersComponent } from './administrator-users/administrator-users.component';
import { ThesisStatisticsComponent } from './administrator-statistics/submissions/thesis/thesis-statistics/thesis-statistics.component';
import { AdministratorStoreComponent } from './administrator-store/administrator-store.component';
import { ManageComponent } from './administrator-store/licenses/manage/manage.component';
import { OffersComponent } from './administrator-store/licenses/offers/offers.component';
import { CostumeOfferComponent } from './administrator-store/modal/costume-offer/costume-offer.component';
import { ExpandOfferComponent } from './administrator-store/modal/expand-offer/expand-offer.component';
import { ManageAddonsComponent } from './administrator-store/licenses/manage/addons/manage-addons/manage-addons.component';
import { ManageCommitmentComponent } from './administrator-store/licenses/manage/commitment/manage-commitment/manage-commitment.component';
import { ManageUsersComponent } from './administrator-store/licenses/manage/users/manage-users/manage-users.component';
import { ManageRepositoriesComponent } from './administrator-store/licenses/manage/repositories/manage-repositories/manage-repositories.component';

//***Effects***
import { EffectsModule } from '@ngrx/effects';
import { FacultyEffects } from './state/faculty/effect/faculty.effects';
import { DepartmentEffects } from './state/department/effect/department.effects';
import { SubjectEffects } from './state/subject/effect/subject.effects';
import { ProfessorEffects } from './state/professor/effect/professor.effects';
import { StudentEffects } from './state/student/effect/student.effects';
import { ThesisEffects } from './state/thesis/effect/thesis.effects';
import { AcademicUnitsStatisticsEffects } from './administrator-statistics/academic-units-statistics/state/effect/academic-units-statistics.effects';
import { UnitsStatisticsFaculties } from './administrator-statistics/academic-units-statistics/faculties/state/effect/units-statistics-faculties.effects';
import { UnitsStatisticsDepartments } from './administrator-statistics/academic-units-statistics/departments/state/effect/units-statistics-departments.effects';
import { UnitsStatisticsSubjects } from './administrator-statistics/academic-units-statistics/subjects/state/effect/units-statistics-subject.effects';
import { UnitsStatisticsAssignmentsEffects } from './administrator-statistics/academic-units-statistics/assignments/state/effect/units-statistics-assignments.effects';
import { UsersStatisticsEffects } from './administrator-statistics/users/state/effect/users-statistics.effects';
import { insProfessorsStatisticsEffects } from './administrator-statistics/users/professors/institutional/state/effect/institutional-professors-statistics.effects';
import { StudentsStatisticsEffects } from './administrator-statistics/users/students/state/effect/students-statistics.effects';
import { BasicProfessorsStatisticsEffects } from './administrator-statistics/users/professors/basic/state/effect/basic-professors-statistics.effects';
import { AssignmentSubmissionsEffects } from './administrator-statistics/submissions/assignment-submissions/state/effect/assignment-submissions.effects';
import { ProfessorsSubmissionsEffects } from './administrator-statistics/submissions/professor-submissions/state/effect/professors-submissions.effects';
import { ThesisStatisticsEffects } from './administrator-statistics/submissions/thesis/state/effect/thesis-statistics.effects';
import { OffersEffects } from './administrator-store/licenses/offers/state/effect/offers.effects';

//***Reducers***
import { facultyReducer } from '../administrator-components/state/faculty/reducer/faculty.reducer';
import { departmentReducer } from '../administrator-components/state/department/reducer/department.reducer';
import { subjectReducer } from '../administrator-components/state/subject/reducer/subject.reducer';
import { professorReducer } from '../administrator-components/state/professor/reducer/professor.reducer';
import { studentReducer } from '../administrator-components/state/student/reducer/student.reducer';
import { thesisReducer } from '../administrator-components/state/thesis/reducer/thesis.reducer';
import { statisticsOverviewReducer } from './administrator-statistics/academic-units-statistics/state/reducer/academic-units-statistics.reducer';
import { facultiesStatisticsReducer } from './administrator-statistics/academic-units-statistics/faculties/state/reducer/units-statistics-faculties.reducer';
import { departmentsStatisticsReducer } from './administrator-statistics/academic-units-statistics/departments/state/reducer/units-statistics-departments.reducer';
import { subjectsStatisticsReducer } from './administrator-statistics/academic-units-statistics/subjects/state/reducer/units-statistics-subject.reducer';
import { assignmentsStatisticsReducer } from './administrator-statistics/academic-units-statistics/assignments/state/reducer/units-statistics-assignments.reducer';
import { usersStatisticsOverviewReducer } from './administrator-statistics/users/state/reducer/users-statistics.reducer';
import { insProfessorsStatisticsReducer } from './administrator-statistics/users/professors/institutional/state/reducer/institutional-professors-statistics.reducer';
import { studentsStatisticsReducer } from './administrator-statistics/users/students/state/reducer/students-statistics.reducer';
import { basicProfessorsStatisticsReducer } from './administrator-statistics/users/professors/basic/state/reducer/basic-professors-statistics.reducer';
import { assignmentSubmissionsReducer } from './administrator-statistics/submissions/assignment-submissions/state/reducer/assignment-submissions.reducer';
import { professorsSubmissionsReducer } from './administrator-statistics/submissions/professor-submissions/state/reducer/professors-submissions.reducer';
import { thesisSubmissionsStatisticsReducer } from './administrator-statistics/submissions/thesis/state/reducer/thesis-statistics.reducer';
import { FiltersReducer } from './state/filters/reducer/filters.reducer';
import { offersReducer } from './administrator-store/licenses/offers/state/reducer/offers.reducer';
import { ContactUsComponent } from './administrator-store/modal/contact-us/contact-us.component';

const administratorRoutes: Routes = [
   {
      path: '',
      component: NavbarAdministratorComponent,
      data: { roles: '3' },
      children: [
         { path: '', pathMatch: 'full', redirectTo: 'statistics' },
         {
            path: '',
            loadChildren: () =>
               import('../sharedModule/shared.module').then(
                  (m) => m.SharedModule
               ),
         },
         {
            path: 'store/offers',
            component: OffersComponent,
            data: { roles: '3' },
         },
         {
            path: 'statistics',
            component: StatisticsDashboardComponent,
            data: { roles: '3' },
            children: [
               {
                  path: '',
                  pathMatch: 'full',
                  redirectTo: 'overview',
               },
               {
                  path: 'overview',
                  component: AdministratorStatisticsComponent,
                  data: { roles: '3' },
               },
               {
                  path: 'academic-units',
                  component: AcademicUnitsStatisticsComponent,
                  data: { roles: '3' },
                  children: [
                     {
                        path: '',
                        pathMatch: 'full',
                        redirectTo: 'faculties',
                     },
                     {
                        path: 'faculties',
                        component: FacultiesStatisticsComponent,
                        data: { roles: '3' },
                     },
                     {
                        path: 'departments',
                        component: DepartmentsStatisticsComponent,
                        data: { roles: '3' },
                     },
                     {
                        path: 'subjects',
                        component: SubjectsStatisticsComponent,
                        data: { roles: '3' },
                     },
                     {
                        path: 'assignments',
                        component: AssignmentsStatisticsComponent,
                        data: { roles: '3' },
                     },
                  ],
               },
               {
                  path: 'users',
                  component: UsersComponent,
                  data: { roles: '3' },
                  children: [
                     {
                        path: '',
                        pathMatch: 'full',
                        redirectTo: 'professors',
                     },
                     {
                        path: 'professors',
                        data: { roles: '3' },
                        component: InstitutionalProfessorsStatisticsComponent,
                     },
                     {
                        path: 'students',
                        component: StudentsStatisticsComponent,
                        data: { roles: '3' },
                     },
                  ],
               },
               {
                  path: 'submissions',
                  component: SubmissionsStatisticsComponent,
                  data: { roles: '3' },
                  children: [
                     {
                        path: '',
                        pathMatch: 'full',
                        redirectTo: 'assignment-submissions',
                     },
                     {
                        path: 'assignment-submissions',
                        data: { roles: '3' },
                        component: AssignmentSubmissionsComponent,
                     },
                     {
                        path: 'professors-submissions',
                        data: { roles: '3' },
                        component: ProfessorsSubmissionsComponent,
                     },
                     {
                        path: 'thesis',
                        data: { roles: '3' },
                        component: ThesisStatisticsComponent,
                     },
                  ],
               },
            ],
         },
         {
            path: 'dashboard',
            component: AdministratorDashboardComponent,
            data: { roles: '3' },
            children: [
               {
                  path: '',
                  pathMatch: 'full',
                  redirectTo: 'academic-units-faculties',
               },
               {
                  path: 'academic-units-faculties',
                  component: UnitsFacultiesComponent,
                  data: { roles: '3' },
               },
               {
                  path: 'academic-units-departments',
                  component: UnitsDepartmentsComponent,
                  data: { roles: '3' },
               },
               {
                  path: 'academic-units-courses',
                  component: UnitsCoursesComponent,
                  data: { roles: '3' },
               },
               {
                  path: 'academic-units-professors',
                  component: UnitsProfessorsComponent,
                  data: { roles: '3' },
               },
               {
                  path: 'academic-units-students',
                  component: UnitsStudentsComponent,
                  data: { roles: '3' },
               },
               {
                  path: 'academic-units-thesis',
                  component: UnitsThesisComponent,
                  data: { roles: '3' },
               },
            ],
         },
         {
            path: 'users',
            component: AdministratorUsersComponent,
            data: { roles: '3' },
            children: [
               {
                  path: '',
                  pathMatch: 'full',
                  redirectTo: 'academic-units-professors',
               },
               {
                  path: 'academic-units-professors',
                  component: UnitsProfessorsComponent,
                  data: { roles: '3' },
               },
               {
                  path: 'academic-units-students',
                  component: UnitsStudentsComponent,
                  data: { roles: '3' },
               },
            ],
         },
         {
            path: 'register-module',
            component: RegisterModulesComponent,
            data: { roles: '3' },
            children: [
               { path: '', pathMatch: 'full', redirectTo: 'faculty-register' },
               {
                  path: 'faculty-register',
                  component: FacultyRegisterComponent,
                  data: { roles: '3' },
               },
               {
                  path: 'department-register',
                  component: DepartmentRegisterComponent,
                  data: { roles: '3' },
               },
               {
                  path: 'course-register',
                  component: CourseRegisterComponent,
                  data: { roles: '3' },
               },
               {
                  path: 'professor-register',
                  component: ProfessorRegisterComponent,
                  data: { roles: '3' },
               },
               {
                  path: 'student-register',
                  component: StudentRegisterComponent,
                  data: { roles: '3' },
               },
            ],
         },
      ],
   },
];

@NgModule({
   declarations: [
      AdministratorDashboardComponent,
      FacultyRegisterComponent,
      DepartmentRegisterComponent,
      CourseRegisterComponent,
      ProfessorRegisterComponent,
      StudentRegisterComponent,
      RegisterModulesComponent,
      UnitsFacultiesComponent,
      EditFacultyComponent,
      UnitsDepartmentsComponent,
      EditDepartmentComponent,
      UnitsCoursesComponent,
      EditCourseComponent,
      UnitsProfessorsComponent,
      EditProfessorComponent,
      UnitsStudentsComponent,
      EditStudentComponent,
      UnitsThesisComponent,
      AdministratorStatisticsComponent,
      SubmissionsInstitutionalComponent,
      UsersInstitutionalComponent,
      ThesisInstitutionalComponent,
      UsersBasicComponent,
      SubmissionsBasicComponent,
      EditSubjectsGenerationComponent,
      AcademicUnitsStatisticsComponent,
      StatisticsDashboardComponent,
      FacultiesStatisticsComponent,
      DepartmentsStatisticsComponent,
      SubjectsStatisticsComponent,
      AssignmentsStatisticsComponent,
      UsersComponent,
      InstitutionalProfessorsStatisticsComponent,
      BasicProfessorsStatisticsComponent,
      StudentsStatisticsComponent,
      ProfessorsOverviewComponent,
      AssignmentSubmissionsComponent,
      SubmissionsStatisticsComponent,
      ProfessorsSubmissionsComponent,
      ThesisStatisticsComponent,
      AdministratorUsersComponent,
      AdministratorStoreComponent,
      ManageComponent,
      OffersComponent,
      CostumeOfferComponent,
      ExpandOfferComponent,
      ManageAddonsComponent,
      ManageCommitmentComponent,
      ManageUsersComponent,
      ManageRepositoriesComponent,
      ContactUsComponent,
   ],
   imports: [
      MatProgressSpinnerModule,
      FormsModule,
      ReactiveFormsModule,
      TranslateModule,
      NgxPaginationModule,
      NgApexchartsModule,
      RouterModule.forChild(administratorRoutes),
      CommonModule,
      PdfViewerModule,
      MatTooltipModule,
      SelectDropDownModule,
      StoreModule.forFeature('faculties', facultyReducer),
      StoreModule.forFeature('departments', departmentReducer),
      StoreModule.forFeature('subjects', subjectReducer),
      StoreModule.forFeature('professors', professorReducer),
      StoreModule.forFeature('students', studentReducer),
      StoreModule.forFeature('thesis', thesisReducer),
      StoreModule.forFeature('statisticsOverview', statisticsOverviewReducer),
      StoreModule.forFeature('facultiesStatistics', facultiesStatisticsReducer),
      StoreModule.forFeature(
         'departmentsStatistics',
         departmentsStatisticsReducer
      ),
      StoreModule.forFeature('subjectsStatistics', subjectsStatisticsReducer),
      StoreModule.forFeature(
         'assignmentsStatistics',
         assignmentsStatisticsReducer
      ),

      StoreModule.forFeature('usersStatistics', usersStatisticsOverviewReducer),
      StoreModule.forFeature(
         'insProfessorsStatistics',
         insProfessorsStatisticsReducer
      ),
      StoreModule.forFeature('studentsStatistics', studentsStatisticsReducer),

      StoreModule.forFeature(
         'basicProfessorsStatistics',
         basicProfessorsStatisticsReducer
      ),

      StoreModule.forFeature(
         'assignmentSubmissions',
         assignmentSubmissionsReducer
      ),
      StoreModule.forFeature(
         'professorsSubmissions',
         professorsSubmissionsReducer
      ),

      StoreModule.forFeature(
         'thesisSubmissionsStatistics',
         thesisSubmissionsStatisticsReducer
      ),

      StoreModule.forFeature('administratorFilters', FiltersReducer),

      StoreModule.forFeature('offers', offersReducer),

      EffectsModule.forFeature([FacultyEffects]),
      EffectsModule.forFeature([DepartmentEffects]),
      EffectsModule.forFeature([SubjectEffects]),
      EffectsModule.forFeature([ProfessorEffects]),
      EffectsModule.forFeature([StudentEffects]),
      EffectsModule.forFeature([ThesisEffects]),
      EffectsModule.forFeature([AcademicUnitsStatisticsEffects]),
      EffectsModule.forFeature([UnitsStatisticsFaculties]),
      EffectsModule.forFeature([UnitsStatisticsDepartments]),
      EffectsModule.forFeature([UnitsStatisticsSubjects]),
      EffectsModule.forFeature([UnitsStatisticsAssignmentsEffects]),
      EffectsModule.forFeature([UsersStatisticsEffects]),
      EffectsModule.forFeature([insProfessorsStatisticsEffects]),
      EffectsModule.forFeature([StudentsStatisticsEffects]),
      EffectsModule.forFeature([BasicProfessorsStatisticsEffects]),
      EffectsModule.forFeature([AssignmentSubmissionsEffects]),
      EffectsModule.forFeature([ProfessorsSubmissionsEffects]),
      EffectsModule.forFeature([ThesisStatisticsEffects]),
      EffectsModule.forFeature([OffersEffects]),
   ],
   exports: [TranslateModule],
})
export class AdministratorComponentsModule {}
