import * as AppState from '../../../../store/app.state';
import * as departmentActions from '../action/department.actions';
import { createReducer } from '@ngrx/store';
import { on } from '@ngrx/store';
import { Department } from 'src/app/models/department';

/**
 * Department reducer used to updated store values based on certain action.
 */

/**
 * State interface extends App state State and add user state to the main state.
 */
export interface State extends AppState.State {
   departments: DepartmentState;
}

/**
 * An DepartmentState interface, which represents the blueprint of the User State.
 */
export interface DepartmentState {
   departments: Department[];
   totalDepartments: number;
   errorMessage: string;
   departmentRegistered: string;
}

/**
 * DepartmentState initial state.
 */
export const initialState: DepartmentState = {
   departments: null,
   totalDepartments: null,
   errorMessage: null,
   departmentRegistered: null,
};

/**
 * Department Reducer used to update the state upon department actions.
 */
export const departmentReducer = createReducer<DepartmentState>(
   initialState,
   /**
    * On loadDepartmentsDetailsSuccess action updated current state with the following properties.
    */
   on(
      departmentActions.loadDepartmentsDetailsSuccess,
      (state, action): DepartmentState => {
         return {
            ...state,
            departments: action.departments,
            totalDepartments: action.totalDepartments,
         };
      }
   ),

   /**
    * On loadDepartmentsDetailsFailure action updated current state with the following properties.
    */
   on(
      departmentActions.loadDepartmentsDetailsFailure,
      (state, action): DepartmentState => {
         return {
            ...state,
            errorMessage: action.error,
         };
      }
   ),
   on(
      departmentActions.departmentsRegisterSuccess,
      (state, action): DepartmentState => {
         return {
            ...state,
            departmentRegistered: 'Department registered',
         };
      }
   ),
   on(
      departmentActions.setRegisterDepartmentToNull,
      (state, action): DepartmentState => {
         return {
            ...state,
            departmentRegistered: null,
         };
      }
   )
);
