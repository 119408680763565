// *** Angular
import { Component, OnDestroy, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { MatDialog } from '@angular/material/dialog';

// *** Actions
import * as offersActions from './state/action/offers.actions';

// *** Components
import { CostumeOfferComponent } from '../../modal/costume-offer/costume-offer.component';
import { ContactUsComponent } from '../../modal/contact-us/contact-us.component';

// *** Selectors
import { getOffersStateDetails } from './state/selector/offers.selectors';

// *** Services
import { StoreService } from 'src/app/services/store.service';

// *** Packages
import Swal from 'sweetalert2';
@Component({
   selector: 'app-offers',
   templateUrl: './offers.component.html',
   styleUrls: ['./offers.component.scss'],
})
export class OffersComponent implements OnInit, OnDestroy {
   constructor(
      private store: Store,
      private storeService: StoreService,
      private dialog: MatDialog
   ) {}
   ngOnDestroy(): void {
      this.offers$.unsubscribe();
   }
   /**
    * Variables used to store offers that are returned from store
    */
   offers;
   offers$;
   licenses;
   licenseEndDate;
   /**
    * Variable used to store institution current license
    */
   institutionLicenses;
   moreOfferDetailsIndex;
   moreActiveLicenseDetailsIndex;
   moreCustomOfferDetailsIndex;

   editInput = {
      student: false,
      professor: false,
      validity: false,
      teachingAssistants: false,
      academicIntegrityOfficer: false,
      crosslingual: false,
      billingPlan: false,
      support: false,
   };

   ngOnInit(): void {
      this.store.dispatch(offersActions.loadOffers());

      this.storeService
         .institutionLicenses()
         .pipe(first())
         .subscribe(
            (data) => {
               this.licenses = data.licenses;
               let createdAtLicense  = this.licenses[0].createdAt.substring(0, 10).split('-');
               this.licenseEndDate = [createdAtLicense[2],createdAtLicense[1],parseInt(createdAtLicense[0])+parseInt(this.licenses[0].Offer.commitment)].join('/');               
            },
            (error) => {
               console.log('error', error);
            }
         );

      this.offers$ = this.store
         .select(getOffersStateDetails)
         .subscribe((data) => {
            if (data !== null) {
               this.offers = data.offers;
            }
         });

      this.storeService
         .institutionLicenses()
         .pipe(first())
         .subscribe(
            (data) => {
               this.institutionLicenses = data.licenses;
            },
            (error) => {
               console.log('error', error);
            }
         );
   }
   /**
    * Function is used to make order. // !!! this will be deleted in further updates about licenses.
    * @param id
    */
   order(id) {
      this.storeService
         .order(id)
         .pipe(first())
         .subscribe(
            (data) => {
               console.log('data', data);
            },
            (error) => {
               console.log('error', error);
            }
         );
   }
   /**
    * Fucntion is used to open a modal to create custome offer.
    */
   newOfferModal() {
      this.dialog.open(CostumeOfferComponent, {
         width: '50%',
         height: '90%',
      });
   }
   /**
    * Fucntion is used to open a modal to edit custome offer.
    */
   editOfferModal(offer) {
      this.dialog.open(CostumeOfferComponent, {
         width: '50%',
         height: '90%',
         data: {
            offer: offer,
         },
      });
   }
      /**
    * Fucntion is used to open a modal to contact crossplag for a custome offer.
    */

   contactUsModal(offer) {
      this.dialog.open(ContactUsComponent, {
         width: '50%',
         height: '90%',
         data: {
            offer: offer,
         },
      }); 
   }

   /**
    *Method is used to delete custome offer that is created by user.
    */
   async deleteOffer(offerId) {
      const result = await Swal.fire({
         title: 'Are you sure you want to delete your offer',
         icon: 'warning',
         showCancelButton: true,
         confirmButtonColor: '#3085d6',
         cancelButtonColor: '#b5adad',
         confirmButtonText: 'Yes',
         allowOutsideClick: false,
      });
      if (result.isConfirmed) {
         this.store.dispatch(offersActions.deleteOffer({ id: offerId }));
      }
   }

   moreOfferDetails(i, type) {
      if (type == 'activePlan') {
         this.moreOfferDetailsIndex = -1;
         this.moreCustomOfferDetailsIndex = -1;
         if (i == this.moreActiveLicenseDetailsIndex) {
            this.moreActiveLicenseDetailsIndex = -1;
         } else {
            this.moreActiveLicenseDetailsIndex = i;
         }
      } else if (type == 'customOffers') {
         this.moreOfferDetailsIndex = -1;
         this.moreActiveLicenseDetailsIndex = -1;
         if (i == this.moreCustomOfferDetailsIndex) {
            this.moreCustomOfferDetailsIndex = -1;
         } else {
            this.moreCustomOfferDetailsIndex = i;
         }
      } else {
         this.moreActiveLicenseDetailsIndex = -1;
         this.moreCustomOfferDetailsIndex = -1;
         if (i == this.moreOfferDetailsIndex) {
            this.moreOfferDetailsIndex = -1;
         } else {
            this.moreOfferDetailsIndex = i;
         }
      }
   }

   changeEditInput(field) {
      this.editInput[field] = true;
   }
}
