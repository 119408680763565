import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';

@Injectable({
   providedIn: 'root',
})
export class StatisticsService {
   constructor(private http: HttpClient, private authService: AuthService) {}

   /**
    * Method used to call transactions api gets user transactions for statistics graph.
    * @param firstName
    * @param lastName
    * @param email
    */
   transactions(userId: string) {
      return this.http
         .get<any>(`${environment.apiUrl}/users/${userId}/transactions`)
         .pipe(
            map((data) => {
               return data;
            })
         );
   }
   /**
    * Method used to call transactions pagination api returns paginated transactions.
    * @param userId
    * @param page
    * @param limit
    * @param type
    */
   transactionsPagination(
      userId: string,
      page: number,
      type: number,
      limit?: number
   ) {
      return this.http
         .get<any>(
            `${environment.apiUrl}/users/${userId}/transactionsPage/?page=${page}` +
               (limit ? `&limit=${limit}` : '') +
               (type ? `&type=${type}` : '')
         )
         .pipe();
   }
   individualAdministrator() {
      return this.http
         .get<any>(`${environment.apiUrl}/administrator/individuals/statistics`)
         .pipe();
   }
}
