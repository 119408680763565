import { Component, OnInit } from '@angular/core';

@Component({
   selector: 'app-input-text',
   templateUrl: './input-text.component.html',
   styleUrls: ['./input-text.component.scss'],
})
export class InputTextComponent implements OnInit {
   constructor() {}
   /**
    * Method used to be called whenever the component is loaded and all the methods in it, will be triggered.
    */
   ngOnInit(): void {}
}
