import { NgxSpinnerService } from 'ngx-spinner';
import { UserService } from './../../services/user.service';
import { ToastrService } from 'ngx-toastr';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import swal from 'sweetalert2';
import { Store } from '@ngrx/store';
import * as authActions from '../state/actions/authentication.actions';

import { MatDialog } from '@angular/material/dialog';
import { TermsAndConditionsComponent } from '../signup/terms-and-conditions/terms-and-conditions.component';
/**
 * Component used for user account verification.
 */
@Component({
   selector: 'app-account-verification',
   templateUrl: './account-verification.component.html',
   styleUrls: ['./account-verification.component.scss'],
})
export class AccountVerificationComponent implements OnInit {
   /**
    * Form group used to get user input
    */
   setPasswordForm: FormGroup;
   /**
    * Variable used to hide/show password
    */
   showPassword;
   /**
    * Variable used to hide/show password
    */
   showConfirmPassword;
   /**
    * User Token
    */
   userToken: any;
   /**
    * The proivder that user is using to join in the system.
    */
   user;

   lowerCase = false;
   upperCase = false;
   numeric = false;
   specialCharacter = false;
   invited: string;
   /**
    * Component constructor.
    * @param router
    * @param fb
    * @param route
    * @param userService
    * @param toastrService
    * @param spinner
    * @param store
    */
   constructor(
      private router: Router,
      private fb: FormBuilder,
      public route: ActivatedRoute,
      private userService: UserService,
      private toastrService: ToastrService,
      private spinner: NgxSpinnerService,
      private store: Store,
      public dialog: MatDialog
   ) {}
   /**
    * Method used to be called whenever the component is loaded and all the methods in it, will be triggered.
    */
   ngOnInit(): void {
      this.invited = this.route.snapshot.queryParamMap.get('invited');

      this.route.params.subscribe((params) => {
         this.userToken = params.token;
      });

      if(this.invited) {
         this.setPasswordForm = this.fb.group({
            password: ['', [Validators.required]],
            confirmPassword: ['', [Validators.required]],
            agree: ['', [Validators.required]],
            name: ['', [Validators.required]],
            index: [''],
         });
      } else {
         this.setPasswordForm = this.fb.group({
            password: ['', [Validators.required,Validators.pattern(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/)]],
            confirmPassword: ['', [Validators.required]],
            agree: ['', [Validators.required]]
         });
     }

   }
   /**
    * Method use to get form controls.
    */
   get form() {
      return this.setPasswordForm.controls;
   }
   /**
    * Method used to show/hide password.
    */
   password() {
      this.showPassword = !this.showPassword;
   }
   /**
    * Method used to show/hide password.
    */
   confirmPassword() {
      this.showConfirmPassword = !this.showConfirmPassword;
   }
   /**
    * Method used activate account and submit password.
    */
   submit() {
      let pass = this.setPasswordForm.value.password;
      let confirmPass = this.setPasswordForm.value.confirmPassword;

      let isSame = pass === confirmPass ? true : false;
      if (isSame) {
         this.spinner.show();
         this.userService
            .verifyAccount({
               password: pass,
               repeatPassword: confirmPass,
               token: this.userToken,
               name: this.setPasswordForm.value.name,
               index: this.setPasswordForm.value.index
            })
            .pipe(first())
            .subscribe(
               (data) => {
                  this.user = data.user;
                  this.spinner.hide();
                  this.showToasterSuccess('Password set successfully');
                  this.notifyUserAlert();
                  this.store.dispatch(
                     authActions.updateUserActivationState({ user: data.user })
                  );
               },
               (error) => {
                  this.spinner.hide();
                  console.log('Error', error);
               }
            );
      } else {
         this.showToasterWarning('Password and confirm password');
      }
   }
   
   /**
    * Method used to show a toaster with a success message to the user.
    * @param message
    */
   showToasterSuccess(message) {
      this.toastrService.success(message);
   }
   /**
    * Method used to show a toaster with a warning message to the user.
    * @param message
    */
   showToasterWarning(message) {
      this.toastrService.warning(message);
   }
   /**
    * Method use to notify user after account activation for 10 free credits.
    */
   notifyUserAlert() {
      if (this.user?.institutionId !== null) {
         swal.fire({
            title: 'You have successfully activated your account!',
            icon: 'success',
         });
      } else {
         swal.fire({
            title: 'You have successfully activated your account!',
            text: 'Now you have 10 free trial credits for one document.',
            icon: 'success',
         });
      }
      
      this.router.navigateByUrl('/login');
   }

   /**
    * Method used to open terms & conditions component in modal.
    */
   openModal() {
      this.dialog.open(TermsAndConditionsComponent, {
         width: '70%',
      });
   }

   checkPassword() {
      if (this.setPasswordForm.value.password.length !== 0) {
         let lowerCase = 0;
         let upperCase = 0;
         let numeric = 0;
         let specialCharacter = 0;
         for (let char of this.setPasswordForm.value.password) {
            if (!isNaN(char * 1)) {
               numeric++;
            } else {
               if (char == char.toUpperCase() && char == char.toLowerCase()) {
                  specialCharacter++;
               } else {
                  this.specialCharacter = false;
                  if (char == char.toUpperCase()) {
                     upperCase++;
                  }
                  if (char == char.toLowerCase()) {
                     lowerCase++;
                  }
               }
            }
            if (lowerCase !== 0) {
               this.lowerCase = true;
            } else {
               this.lowerCase = false;
            }

            if (upperCase !== 0) {
               this.upperCase = true;
            } else {
               this.upperCase = false;
            }

            if (numeric !== 0) {
               this.numeric = true;
            } else {
               this.numeric = false;
            }

            if (specialCharacter !== 0) {
               this.specialCharacter = true;
            } else {
               this.specialCharacter = false;
            }
         }
      } else {
         this.lowerCase = false;
         this.upperCase = false;
         this.numeric = false;
         this.specialCharacter = false;
      }
   }
}
