import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, catchError, concatMap } from 'rxjs/operators';
import * as institutionalProfessorsActions from '../action/institutional-professors-statistics.actions';
import { AdministratorStatisticsService } from '../../../../../../../services/administrator-statistics.service';

@Injectable()
export class insProfessorsStatisticsEffects {
   constructor(
      private actions$: Actions,
      private administratorStatistics: AdministratorStatisticsService
   ) {}

   /**
    * Effect listening for institutionalProfessorsActionsStatistics Action, when institutionalProfessorsActionsStatistics is dispatched the effect with call create() from courseService.
    * In case of success response the effect it will trigger institutionalProfessorsActionsStatisticsSuccess.
    * In case of Error it will trigger institutionalProfessorsActionsStatisticsFailure with error property.
    */
   institutionalProfessorsActionsStatistics$ = createEffect(() => {
      return this.actions$.pipe(
         ofType(
            institutionalProfessorsActions.loadInstitutionalProfessorsStatistics
         ),
         concatMap((action) => {
            return this.administratorStatistics
               .professorsFullIntegrationStatistics(
                  action.page,
                  action.faculty,
                  action.department,
                  action.name,
                  action.subject
               )
               .pipe(
                  map((data) => {
                     return institutionalProfessorsActions.loadInstitutionalProfessorsStatisticsSuccess(
                        {
                           data: data,
                        }
                     );
                  }),
                  catchError((error) =>
                     of(
                        institutionalProfessorsActions.loadInstitutionalProfessorsStatisticsFailure(
                           {
                              error,
                           }
                        )
                     )
                  )
               );
         })
      );
   });
}
