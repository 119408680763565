import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, catchError, concatMap } from 'rxjs/operators';
import * as usersStatisticsActions from '../action/users-statistics.actions';
import { AdministratorStatisticsService } from '../../../../../services/administrator-statistics.service';

@Injectable()
export class UsersStatisticsEffects {
   constructor(
      private actions$: Actions,
      private administratorStatistics: AdministratorStatisticsService
   ) {}

   /**
    * Effect listening for userStatistics Action, when userStatistics is dispatched the effect with call create() from courseService.
    * In case of success response the effect it will trigger userStatisticsSuccess.
    * In case of Error it will trigger userStatisticsFailure with error property.
    */
   userStatistics$ = createEffect(() => {
      return this.actions$.pipe(
         ofType(usersStatisticsActions.userStatistics),
         concatMap((action) => {
            return this.administratorStatistics.usersStatisticsOverview().pipe(
               map((data) => {
                  return usersStatisticsActions.userStatisticsSuccess({
                     data: data.data,
                  });
               }),
               catchError((error) =>
                  of(
                     usersStatisticsActions.userStatisticsFailure({
                        error,
                     })
                  )
               )
            );
         })
      );
   });
}
