<section>
   <h4 class="bold w-87">Students</h4>

   <div class="shadowBorder">
      <div class="scroll" *ngIf="this.users?.totalStudents !== 0">
         <table class="table-university">
            <tr>
               <th><h5 class="bold left">Full name</h5></th>
               <th><h5 class="bold">Email</h5></th>
               <th><h5 class="bold">Status</h5></th>
               <th><h5 class="bold">Thesis Language</h5></th>
               <th><h5 class="bold">Thesis Translate to</h5></th>
               <th><h5 class="bold">Edit</h5></th>
            </tr>
            <tr
               class="shadow"
               *ngFor="
                  let user of this.users?.users
                     | paginate
                        : {
                             itemsPerPage: 5,
                             currentPage: page,
                             totalItems: this.users?.totalStudents
                          }
               "
            >
               <td>
                  <h5 class="left">{{ user.name }}</h5>
               </td>
               <td>
                  <h5>{{ user.email }}</h5>
               </td>
               <td>
                  <h5 *ngIf="user.status === 1">Active</h5>
                  <h5 *ngIf="user.status === 0">Inactive</h5>
               </td>
               <td>
                  <h5>{{ user.allowedLanguages.documentLanguage }}</h5>
               </td>
               <td>
                  <h5>{{ user.allowedLanguages.targetLanguage }}</h5>
               </td>
               <td>
                  <button
                     class="blue-btn border shadow"
                     (click)="openEditModal(user.id)"
                  >
                     Edit
                  </button>
               </td>
            </tr>
         </table>
         <pagination-controls
            *ngIf="this.users?.totalStudents !== 0"
            class="num"
            (pageChange)="changePage($event)"
            autoHide="true"
            responsive="true"
            previousLabel=""
            nextLabel=""
         ></pagination-controls>
      </div>

      <div class="zero-state" *ngIf="this.users?.totalStudents === 0">
         <!-- <img src="../../assets/images/icona-image.png" alt="" /> -->
         <h5 class="bold">
            You have not invited any student yet! Please invite students by
            clicking the button below.
         </h5>
         <div class="center" style="z-index: 999">
            <button (click)="this.openInviteModal()" class="blue-btn border">
               <i class="fas fa-plus"></i>Invite student
            </button>
         </div>
      </div>

      <div class="center" *ngIf="this.users?.totalStudents != 0">
         <button (click)="this.openInviteModal()" class="blue-btn border">
            <i class="fas fa-plus"></i>Invite student
         </button>
      </div>
   </div>
</section>
