import { AuthService } from './services/auth.service';
import {
   HttpEvent,
   HttpHandler,
   HttpRequest,
   HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry, tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';
/**
 * Interceptor used for error handling
 */
@Injectable()
export class ErrorInterceptorService {
   /**
    * Class Constructor
    * @param toastrService
    */
   constructor(
      private toastrService: ToastrService,
      private authService: AuthService
   ) {}

   intercept(
      request: HttpRequest<any>,
      next: HttpHandler
   ): Observable<HttpEvent<any>> {
      return next.handle(request).pipe(
         catchError((response: HttpErrorResponse) => {
            if (response.status === 401) {
               this.authService.logout();
            }
            let errorMessage = '';
            if (response.error instanceof ErrorEvent) {
               // client-side error
               if (response.error) {
                  if (response.error.message) {
                     errorMessage = response.error.message;
                     this.toastrService.error(errorMessage);
                  } else {
                     this.toastrService.error('Something went wrong!');
                  }
               } else {
                  this.toastrService.error('Something went wrong!');
               }
            } else {
               // server-side error
               console.log('SERVER SIDE ERROR!!!');
               console.log('response', response);
               if (response.error) {
                  if (response.error.message) {
                     errorMessage = response.error.message;
                     console.log(errorMessage,"errorMessage");
                     
                     this.toastrService.error(errorMessage);
                  } else {
                     this.toastrService.error('Something went wrong!');
                  }
               } else if (response.status === 504) {
                  this.toastrService.error('We are experiencing high traffic. Please try again in few minutes or contact us for help.');
               }  else {
                  this.toastrService.error('Something went wrong!');
               }
            }
            return throwError(errorMessage);
         })
      );
   }
}
