<main class="log-in">
   <div class="left-side">
      <img src="../../assets/images/Group (2)-white.png" alt="" />
      <div>
         <h1>Pioneering Originality in the World of AI</h1>
         <p [innerHtml]="'login.crossplag_description' | translate">
         </p>
      </div>
      <h6>© All rights reserved - Inspera AS</h6>
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      viewBox="0 0 1030 1030" style="enable-background:new 0 0 1030 1030;" xml:space="preserve">
  <style type="text/css">
     .st0{fill:#ffffff36;}

  </style>
  <g>
     <g>
        <circle class="st0" cx="137" cy="716.5" r="115.1"/>
        <circle class="st0" cx="410.7" cy="460.1" r="139"/>
        <circle class="st0" cx="834.3" cy="292.9" r="182.5"/>
     </g>
  </g>
  <g>
     <path class="st0" d="M131.9,232.2c-0.2,0-0.4-0.1-0.6-0.3c-0.3-0.3-0.3-0.9,0-1.2l44-44c0.3-0.3,0.9-0.3,1.2,0
        c0.3,0.3,0.3,0.9,0,1.2l-44,44C132.3,232.1,132.1,232.2,131.9,232.2z"/>
  </g>
  <g>
     <path class="st0" d="M27.2,129.2c-0.2,0-0.4-0.1-0.6-0.3c-0.3-0.3-0.3-0.9,0-1.2l98.7-98.7c0.3-0.3,0.9-0.3,1.2,0
        c0.3,0.3,0.3,0.9,0,1.2L27.8,129C27.6,129.1,27.4,129.2,27.2,129.2z"/>
  </g>
  </svg>
   </div>
   <div class="right-side">
      <span class="go-back" routerLink="/sign-up">
         <!-- <i  class="fas fa-arrow-left"></i> -->
         Sign up
      </span>

      <div class="displayOnMobile">
         <h2>Welcome to Crossplag</h2>
         <img src="../../../assets/images/Crossplag_Logo (3).png" alt="">
      </div>
      <div class="form-container">
         <h4>Sign in</h4>
      <form
         action=""
         [formGroup]="loginForm"
         (keydown.enter)="onKeydown($event)"
      >
         <label for="">{{ 'general.email' | translate }}</label>
         <input
            formControlName="email"
            type="email"
            placeholder="{{ 'general.email' | translate }}"
            style="font-size: 1rem; padding: 0 10px; width: 100%"
         />
         <div
            *ngIf="
               loginFormValues.email.touched && loginFormValues.email.invalid
            "
            class="alert alert-danger"
         >
            <div class="invalid" *ngIf="loginFormValues.email.errors.required">
               {{ 'validators.enter_email' | translate }}
            </div>
         </div>
         <label for="">{{ 'general.password' | translate }}</label>
         <div class="password">
            <input
               formControlName="password"
               [type]="showPassword ? 'text' : 'password'"
               placeholder="{{ 'general.password' | translate }}"
               style="font-size: 1rem; padding: 0 10px; width: 100%"
            />
            <span class="input-group-text" (click)="currentPassword()">
               <i
                  class="fa"
                  [ngClass]="{
                     'fa-eye-slash': !showPassword,
                     'fa-eye': showPassword
                  }"
               ></i>
            </span>
         </div>
         <div
            *ngIf="
               loginFormValues.password.touched &&
               loginFormValues.password.invalid
            "
            class="alert alert-danger"
         >
            <div
               class="invalid"
               *ngIf="loginFormValues.password.errors.required"
            >
               {{ 'validators.enter_passsword' | translate }}
            </div>
         </div>
         <!-- <re-captcha
         formControlName="captcha"
         (resolved)="resolved($event)"
         [siteKey]="siteKey"
       ></re-captcha> -->
      </form>
    <div  class="forgot-password">
      <a routerLink="/forgot-password"  class="forgot-password">{{
         'login.forgot_password' | translate
      }}</a>
    </div>
      <div class="center">
         <ng-container *ngIf="loginForm.valid">
            <button
               [disabled]="loginForm.invalid"
               (click)="signIn()"
               class="submit"
            >
               {{ 'login.sign_in' | translate }}
            </button>
         </ng-container>
         <ng-container *ngIf="loginForm.invalid">
            <button
               (click)="showToasterWarning('Please re-check your info')"
               class="submit"
            >
               {{ 'login.sign_in' | translate }}
            </button>
         </ng-container>
      </div>
      <div class="social-container">
         <div class="seperator-line">
            <i class="line-seperator"></i>
            <span>{{ 'login.or' | translate }}</span>
            <i class="line-seperator"></i>
         </div>
         <div>
            <span>{{ 'login.sign_in_with' | translate }}</span>
         </div>
         <div>
            <span
               >By continuing I agree with the
               <a mat-button (click)="openModal()">
                  Privacy Policy, Terms & Conditions</a
               ></span
            >
         </div>
         <div class="social-buttons">
            <a
               class="btn btn-social-icon btn-google"
               (click)="signInWithGoogle()"
            >
               <svg
                  viewBox="0 0 533.5 544.3"
                  xmlns="http://www.w3.org/2000/svg"
               >
                  <path
                     d="M533.5 278.4c0-18.5-1.5-37.1-4.7-55.3H272.1v104.8h147c-6.1 33.8-25.7 63.7-54.4 82.7v68h87.7c51.5-47.4 81.1-117.4 81.1-200.2z"
                     fill="#4285f4"
                  />
                  <path
                     d="M272.1 544.3c73.4 0 135.3-24.1 180.4-65.7l-87.7-68c-24.4 16.6-55.9 26-92.6 26-71 0-131.2-47.9-152.8-112.3H28.9v70.1c46.2 91.9 140.3 149.9 243.2 149.9z"
                     fill="#34a853"
                  />
                  <path
                     d="M119.3 324.3c-11.4-33.8-11.4-70.4 0-104.2V150H28.9c-38.6 76.9-38.6 167.5 0 244.4l90.4-70.1z"
                     fill="#fbbc04"
                  />
                  <path
                     d="M272.1 107.7c38.8-.6 76.3 14 104.4 40.8l77.7-77.7C405 24.6 339.7-.8 272.1 0 169.2 0 75.1 58 28.9 150l90.4 70.1c21.5-64.5 81.8-112.4 152.8-112.4z"
                     fill="#ea4335"
                  />
               </svg>
               <span class="social-button-text">{{
                  'login.google' | translate
               }}</span>
            </a>

            <a
               class="btn btn-social-icon btn-linkedin"
               (click)="loginLinkedin()"
            >
               <svg
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 382 382"
                  style="enable-background: new 0 0 382 382"
                  xml:space="preserve"
               >
                  <path
                     style="fill: #0077b7"
                     d="M347.445,0H34.555C15.471,0,0,15.471,0,34.555v312.889C0,366.529,15.471,382,34.555,382h312.889
    C366.529,382,382,366.529,382,347.444V34.555C382,15.471,366.529,0,347.445,0z M118.207,329.844c0,5.554-4.502,10.056-10.056,10.056
    H65.345c-5.554,0-10.056-4.502-10.056-10.056V150.403c0-5.554,4.502-10.056,10.056-10.056h42.806
    c5.554,0,10.056,4.502,10.056,10.056V329.844z M86.748,123.432c-22.459,0-40.666-18.207-40.666-40.666S64.289,42.1,86.748,42.1
    s40.666,18.207,40.666,40.666S109.208,123.432,86.748,123.432z M341.91,330.654c0,5.106-4.14,9.246-9.246,9.246H286.73
    c-5.106,0-9.246-4.14-9.246-9.246v-84.168c0-12.556,3.683-55.021-32.813-55.021c-28.309,0-34.051,29.066-35.204,42.11v97.079
    c0,5.106-4.139,9.246-9.246,9.246h-44.426c-5.106,0-9.246-4.14-9.246-9.246V149.593c0-5.106,4.14-9.246,9.246-9.246h44.426
    c5.106,0,9.246,4.14,9.246,9.246v15.655c10.497-15.753,26.097-27.912,59.312-27.912c73.552,0,73.131,68.716,73.131,106.472
    L341.91,330.654L341.91,330.654z"
                  />
               </svg>
               <span class="social-button-text">{{
                  'login.linkedin' | translate
               }}</span>
            </a>
         </div>
      </div>
   </div>
   </div>
</main>