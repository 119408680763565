// *** Angular
import { Component, OnInit } from '@angular/core';

@Component({
   selector: 'app-register-modules',
   templateUrl: './register-modules.component.html',
   styleUrls: ['./register-modules.component.scss'],
})
export class RegisterModulesComponent implements OnInit {
   constructor() {}

   ngOnInit(): void {}
}
