<ng-container *ngIf="this.uploaded == 0">
   <div class="upload">
      <main>
         <div (click)="upload()" style="cursor: pointer">
            <img src="../../assets/images/upload.png" alt="" />
            <h6>{{ 'individual.upload.dropzone_text' | translate }}</h6>
         </div>
         <a (click)="upload()" class="submit">{{
            'general.upload' | translate
         }}</a>
      </main>
   </div>
   <input
      class="dropzone"
      type="file"
      name=""
      id=""
      (change)="onFileSelected($event)"
      accept="application/pdf, .docx"
      style="display: none"
   />
</ng-container>

<ng-container *ngIf="this.uploaded == 1">
   <div class="file-uploads">
      <main>
         <div>
            <form [formGroup]="uploadForm">
               <h5 class="bold">
                  {{ 'individual.upload.upload_form' | translate }}
               </h5>
               <h5>{{ 'individual.upload.document_title' | translate }}</h5>
               <input type="text" formControlName="documentTitle" />
               <div
                  *ngIf="
                     form.documentTitle.invalid && form.documentTitle.touched
                  "
                  class="invalid"
               >
                  <div *ngIf="form.documentTitle.errors.required">
                     Document title is required*
                  </div>
               </div>
               <h5>Name and Surname</h5>
               <input type="text" formControlName="fullName" />
               <div
                  *ngIf="form.fullName.invalid && form.fullName.touched"
                  class="invalid"
               >
                  <div *ngIf="form.fullName.errors.required">
                     Full name is required*
                  </div>
               </div>
               <h5>{{ 'individual.upload.date_of_upload' | translate }}</h5>
               <input formControlName="dateOfUpload" disabled type="text" />
               <h5>{{ 'individual.upload.document_size' | translate }}</h5>
               <input formControlName="documentSize" disabled type="text" />
               <h5>Amount of credits to be charged:</h5>
               <input formControlName="documentCredits" disabled type="text" />
               <h5>{{ 'individual.upload.document_language' | translate }}</h5>
               <div style="position: relative">
                  <select
                     (change)="selectLanguage($event)"
                     class="border"
                     name=""
                     id=""
                  >
                     <option value="undefined" selected>
                        Select a language
                     </option>
                     <option
                        *ngFor="let language of languages"
                        value="{{ language.value }}"
                     >
                        {{ language.text }}
                     </option>
                     <span
                        ><img src="../../assets/images/Path 26.png" alt=""
                     /></span>
                  </select>
                  <span
                     ><img src="../../assets/images/Path 26.png" alt=""
                  /></span>
               </div>
               <div
                  *ngIf="form.documentLanguage.invalid && languageSelected"
                  class="invalid"
               >
                  <div
                     *ngIf="
                        form.documentLanguage.errors.required ||
                        form.documentLanguage.value == 'undefined'
                     "
                  >
                     Document language is required*
                  </div>
               </div>
               <h5>{{ 'individual.upload.translate_document' | translate }}</h5>
               <div style="position: relative">
                  <select
                     (change)="selectTranslatedLanguage($event)"
                     class="border"
                     name=""
                     id=""
                  >
                     <option value="">None</option>
                     <option
                        *ngFor="let language of languages"
                        value="{{ language.value }}"
                     >
                        {{ language.text }}
                     </option>
                     <span
                        ><img src="../../assets/images/Path 26.png" alt=""
                     /></span>
                  </select>
                  <span
                     ><img src="../../assets/images/Path 26.png" alt=""
                  /></span>
               </div>

               <h5>Enable AI detection:</h5>
               <div>
                  <label class="switch">
                     <input
                        formControlName="aiDetection"
                        type="checkbox"
                     />
   
                     <span class="slider round"></span>
                  </label>
               </div>
               <input formControlName="documentCredits" disabled type="text" />
               
               <div class="advanced_options">
                  <h4 (click)="advancedOptions()">
                     Advanced options<img
                        [ngClass]="{ expand: advancedOptionsIndex == true }"
                        src="../../assets/images/Path 26.png"
                        alt=""
                     />
                  </h4>
                  <div *ngIf="advancedOptionsIndex == true">
                     <div class="flex add_to_archive">
                        <input
                           type="checkbox"
                           id="add_to_archive"
                           value="{{ archive }}"
                           name="add_to_archive"
                           [(ngModel)]="archive"
                           (click)="archiveDocument()"
                           [ngModelOptions]="{ standalone: true }"
                        />
                        <label for="add_to_archive"></label>
                        <label for="">
                           Add this document to archive<i
                              class="fas fa-info-circle"
                              matTooltipPosition="after"
                              matTooltipClass="tooltipCustomCss"
                              matTooltip="If you add this document to archive,all uploaded
                              documents will search for plagiarism from this
                              document"
                           ></i>
                        </label>
                     </div>
                     <div>
                        <label for="">
                           <h4>Choose third party libraries</h4>
                           <i
                              style="margin-left: 1%"
                              matTooltipPosition="after"
                              class="fas fa-info-circle"
                              matTooltipClass="tooltipCustomCss"
                              matTooltip="Cross-checking your document with third-party libraries and archives may delay checking your document for up to several hours. Please be aware!"
                           ></i>
                        </label>
                        <div class="">
                           <div
                              *ngFor="
                                 let library of thirdPartyLib;
                                 let i = index
                              "
                           >
                              <input
                                 type="checkbox"
                                 id="library{{ i }}"
                                 [value]="library.description"
                                 [checked]="library.isChecked"
                                 name="library{{ i }}"
                                 (change)="onCheckboxChange($event)"
                              />
                              <label for="library{{ i }}"
                                 >{{ library.description }}
                                 <i
                                    style="margin-left: 1%"
                                    class="fas fa-info-circle"
                                    matTooltip="{{ library.information }}"
                                    matTooltipPosition="after"
                                    matTooltipClass="tooltipCustomCss"
                                 ></i
                              ></label>
                           </div>
                        </div>
                     </div>
                     <div>
                        <h4>Choose your citation</h4>
                        <div style="position: relative">
                           <select
                              (change)="selectCitationStyle($event)"
                              [(ngModel)]="citationStyle"
                              class="border"
                              name=""
                              id=""
                              [ngModelOptions]="{ standalone: true }"
                           >
                              <option value="" selected>
                                 Select a citation style
                              </option>
                              <optgroup label="popular formats">
                                 <option value="AMA">AMA</option>
                                 <option value="APA(6th edition)">
                                    APA (6th edition)
                                 </option>
                                 <option value="APA(7th edition)">
                                    APA (7th edition)
                                 </option>
                                 <option value="Harvard">Harvard</option>
                                 <option value="IEEE">IEEE</option>
                              </optgroup>
                              <optgroup label="others">
                                 <option value="OSCOLA">OSCOLA</option>
                                 <option value="MHRA(3rd edition)">
                                    MHRA (3rd edition)
                                 </option>
                                 <option value="Vancouver">Vancouver</option>
                              </optgroup>
                           </select>
                           <span class="chevron-icon"
                              ><img
                                 src="../../assets/images/Path 26.png"
                                 alt=""
                           /></span>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="center">
                  <button
                     *ngIf="!uploadForm.invalid"
                     [ngClass]="{ disable: uploadForm.invalid }"
                     class="submit"
                     (click)="createSubmission()"
                  >
                     {{ 'general.submit' | translate }}
                  </button>

                  <button
                     *ngIf="uploadForm.invalid"
                     [ngClass]="{ disable: uploadForm.invalid }"
                     (click)="showFormMessages()"
                     class="submit"
                  >
                     {{ 'general.submit' | translate }}
                  </button>

                  <!-- <ng-container *ngIf="allowToSubmitDocument == false">
                     <button
                        *ngIf="!uploadForm.invalid"
                        [ngClass]="{ disable: uploadForm.invalid }"
                        class="submit"
                        (click)="showAlertMessage()"
                     >
                        {{ 'general.submit' | translate }}
                     </button>

                     <button
                        *ngIf="uploadForm.invalid"
                        [ngClass]="{ disable: uploadForm.invalid }"
                        (click)="showFormMessages()"
                        class="submit"
                     >
                        {{ 'general.submit' | translate }}
                     </button>
                  </ng-container> -->
               </div>
            </form>
         </div>
         <div class="file">
            <pdf-viewer
               id="viewer"
               [src]="this.previewSrc"
               [fit-to-page]="true"
               style="
                  display: block;
                  overflow: auto;
                  height: 650px;
                  scroll-behavior: smooth;
                  border-radius: 20px;
               "
            ></pdf-viewer>

            <div
               style="
                  background: #eee;
                  padding-bottom: 1%;
                  border-radius: 20px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  flex-direction: column;
               "
            >
               <h6>{{ 'individual.upload.document_preview' | translate }}</h6>
               <p>{{ 'individual.upload.check_your_document' | translate }}</p>
            </div>
         </div>
      </main>
   </div>
</ng-container>
