// *** Angular
import { Component, OnInit, Inject, Optional } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import {
   MatDialog,
   MatDialogRef,
   MAT_DIALOG_DATA,
} from '@angular/material/dialog';

// *** Actions
import * as offersActions from '../../licenses/offers/state/action/offers.actions';
@Component({
   selector: 'app-costume-offer',
   templateUrl: './costume-offer.component.html',
   styleUrls: ['./costume-offer.component.scss'],
})
export class CostumeOfferComponent implements OnInit {
   constructor(
      private fb: FormBuilder,
      private store: Store,
      public dialog: MatDialog,
      @Inject(MAT_DIALOG_DATA) public data: any,
      @Optional() public dialogRef: MatDialogRef<any>
   ) {}
   /**
    * Variable used to store formGroup
    */
   createOffer;
   /**
    * Variable used to store modules selected for corosslingual feature.
    */
   modulesSelected = {
      professorUploads: false,
      assignments: false,
      thesis: false,
   };
   /**
    * Variable used to store a boolean value if its true it shows a message to user that at least one module should be selected if crosslingualy feature is clicked for offer.
    */
   showAlert: boolean = false;

   displaySupportOptions: boolean = false;
   displayBillingPlans: boolean = false;

   ngOnInit(): void {
      this.createOffer = this.fb.group({
         title: ['', [Validators.required, Validators.minLength(3)]],
         commitment: ['', [Validators.required]],
         administratorAccounts: [''],
         academicIntegrityOfficerAccounts: [''],
         professorAccounts: ['',[Validators.required]],
         teachingAssistantAccounts: [''],
         studentAccounts: ['', [Validators.required]],
         professorUploads: [''],
         crossLingual: [''],
         bachelor: [''],
         master: [''],
         phd: [''],
         repositories: [''],
         billingPlan: ['', [Validators.required]],
         subscriptions: [''],
         support: [''],
         sentEmail: false
      });

      if (this.data) {
         // this.displaySupportOptions = true;
         // this.displayBillingPlans = true;
         this.createOffer.controls['title'].setValue(this.data.offer.title);
         this.createOffer.controls['commitment'].setValue(
            this.data.offer.commitment
         );
         this.createOffer.controls['administratorAccounts'].setValue(
            this.data.offer.administrators
         );
         this.createOffer.controls['studentAccounts'].setValue(
            this.data.offer.students
         );
         this.createOffer.controls['academicIntegrityOfficerAccounts'].setValue(
            this.data.offer.academicIntegrityOfficers
         );
         this.createOffer.controls['professorAccounts'].setValue(
            this.data.offer.professors
         );
         this.createOffer.controls['teachingAssistantAccounts'].setValue(
            this.data.offer.teachingAssistants
         );
         this.createOffer.controls['professorUploads'].setValue(
            this.data.offer.professorIndividualUploads
         );
         let j = 0;

         if (this.data.offer.crossLingual) {
            Object.entries(this.data.offer.crossLingual).forEach(
               ([key, value]) => {
                  if (!value) {
                     j++;
                  }
               }
            );
            if (j == 3) {
               this.createOffer.controls['crossLingual'].setValue('');
            } else {
               this.createOffer.controls['crossLingual'].setValue(
                  this.data.offer.crossLingual
               );
            }
         }

         if (this.data.offer.crossLingual) {
            this.modulesSelected.assignments =
               this.data.offer.crossLingual.assignments;
            this.modulesSelected.thesis = this.data.offer.crossLingual.thesis;
            this.modulesSelected.professorUploads =
               this.data.offer.crossLingual.professorUploads;
         }
         this.createOffer.controls['bachelor'].setValue(
            this.data.offer.bachelor
         );
         this.createOffer.controls['master'].setValue(this.data.offer.master);
         this.createOffer.controls['phd'].setValue(this.data.offer.phd);
         this.createOffer.controls['subscriptions'].setValue(
            this.data.offer.subscriptions
         );
         this.createOffer.controls['support'].setValue(this.data.offer.support);
         this.createOffer.controls['billingPlan'].setValue(
            this.data.offer.billingPlan
         );

         this.createOffer.controls['commitment'].setValue(
            this.data.offer.commitment
         );
      }
   }

   selectModule(selected) {
      this.modulesSelected[selected] = !this.modulesSelected[selected];
   }

   /**
    * Method use to get form controls.
    */
   get form() {
      return this.createOffer.controls;
   }

   /**
    * Function is used to submit formGroup values to a action for createing offer.
    */
   submit() {
      let i = 0;
      if (this.form.crossLingual.value) {
         Object.entries(this.modulesSelected).forEach(([key, value]) => {
            console.log(key, value);
            if (!value) {
               i++;
            }
         });
         if (i === 3) {
            this.showAlert = true;
            return;
         }
      }
      this.store.dispatch(
         offersActions.createOffer({
            title: this.form.title.value?this.form.title.value:"",
            commitment: this.form.commitment.value?this.form.commitment.value:0,
            bachelor: this.form.bachelor.value?this.form.bachelor.value:0,
            master: this.form.master.value?this.form.master.value:0,
            phd: this.form.phd.value?this.form.phd.value:0,
            administratorAccounts: this.form.administratorAccounts.value?this.form.administratorAccounts.value:0,
            studentAccounts: this.form.studentAccounts.value?this.form.studentAccounts.value:0,
            crossLingual: this.modulesSelected?this.modulesSelected:"",
            professorUploads: this.form.professorUploads.value?this.form.professorUploads.value:0,
            repositories: this.form.repositories.value?this.form.repositories.value:"",
            billingPlan: this.form.billingPlan.value?this.form.billingPlan.value:"",
            subscriptions: this.form.subscriptions.value?this.form.subscriptions.value:"",
            professorAccounts: this.form.professorAccounts.value?this.form.professorAccounts.value:0,
            teachingAssistantAccounts:
               this.form.teachingAssistantAccounts.value?this.form.teachingAssistantAccounts.value:0,
            academicIntegrityOfficerAccounts:
               this.form.academicIntegrityOfficerAccounts.value?this.form.academicIntegrityOfficerAccounts.value:0,
            support: this.form.support.value?this.form.support.value:"Basic",
            sentEmail: this.form.sentEmail.value?this.form.sentEmail.value:false
         })
      );
      this.dialogRef.close();
   }

   edit() {
      let i = 0;
      if (this.form.crossLingual.value) {
         Object.entries(this.modulesSelected).forEach(([key, value]) => {
            console.log(key, value);
            if (!value) {
               i++;
            }
         });
         if (i === 3) {
            this.showAlert = true;
            return;
         }
      }
      this.store.dispatch(
         offersActions.editOffer({
            id: this.data.offer.id,
            title: this.form.title.value,
            commitment: this.form.commitment.value,
            bachelor: this.form.bachelor.value,
            master: this.form.master.value,
            phd: this.form.phd.value,
            administratorAccounts: this.form.administratorAccounts.value,
            studentAccounts: this.form.studentAccounts.value,
            crossLingual: this.modulesSelected,
            professorUploads: this.form.professorUploads.value,
            repositories: this.form.repositories.value,
            billingPlan: this.form.billingPlan.value,
            subscriptions: this.form.subscriptions.value,
            professorAccounts: this.form.professorAccounts.value,
            teachingAssistantAccounts:
               this.form.teachingAssistantAccounts.value,
            academicIntegrityOfficerAccounts:
               this.form.academicIntegrityOfficerAccounts.value,
            support: this.form.support.value,
            sentEmail: this.form.sentEmail.value
         })
      );
      this.dialogRef.close();
   }

   openSelectOptions(optionsToDisplay) {
      if (optionsToDisplay == 'billing plans') {
         if (this.displayBillingPlans) {
            this.displayBillingPlans = false;
         } else {
            this.displayBillingPlans = true;
         }
      } else {
         if (this.displaySupportOptions) {
            this.displaySupportOptions = false;
         } else {
            this.displaySupportOptions = true;
         }
      }
   }
}
