import * as AppState from '../../../../store/app.state';
import * as subjectActions from '../action/subject.actions';
import { createReducer } from '@ngrx/store';
import { on } from '@ngrx/store';
import { Subject } from 'src/app/models/subject';

/**
 * subject reducer used to updated store values based on certain action.
 */

/**
 * State interface extends App state State and add user state to the main state.
 */
export interface State extends AppState.State {
   subjects: SubjectState;
}

/**
 * An SubjectState interface, which represents the blueprint of the User State.
 */
export interface SubjectState {
   subjects: Subject[];
   totalSubjects: number;
   errorMessage: string;
   subjectRegistered: string;
}

/**
 * SubjectState initial state.
 */
export const initialState: SubjectState = {
   subjects: null,
   totalSubjects: null,
   errorMessage: null,
   subjectRegistered: null,
};

/**
 * Subject Reducer used to update the state upon Subject actions.
 */
export const subjectReducer = createReducer(
   initialState,
   /**
    * On loadSubjectsDetailsSuccess action updated current state with the following properties.
    */
   on(
      subjectActions.loadSubjectsDetailsSuccess,
      (state, action): SubjectState => {
         return {
            ...state,
            subjects: action.subjects,
            totalSubjects: action.totalSubjects,
         };
      }
   ),

   /**
    * On loadSubjectsDetailsFailure action updated current state with the following properties.
    */
   on(
      subjectActions.loadSubjectsDetailsFailure,
      (state, action): SubjectState => {
         return {
            ...state,
            errorMessage: action.error,
         };
      }
   ),
   on(subjectActions.subjectsRegisterSuccess, (state, action): SubjectState => {
      return {
         ...state,
         subjectRegistered: 'subject registered',
      };
   }),
   on(
      subjectActions.setSubjectRegisterToNull,
      (state, action): SubjectState => {
         return {
            ...state,
            subjectRegistered: null,
         };
      }
   ),
   on(subjectActions.duplicateSubjectSuccess, (state, action): SubjectState => {
      return {
         ...state,
         subjectRegistered: 'subject registered',
      };
   }),
);
