import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { first } from 'rxjs/operators';
import {  ChartComponent } from "ng-apexcharts";
import { Store } from '@ngrx/store';
import { TextDetectorModalComponent } from '../text-detector-modal/text-detector-modal.component';
import { getCurrentUser } from 'src/app/authModule/state/authentication.selectors';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';

// export type ChartOptions = {
//   series: ApexNonAxisChartSeries;
//   chart: ApexChart;
//   labels: string[];
//   plotOptions: ApexPlotOptions;
//   fill: ApexFill;
//   stroke: ApexStroke;
// };

@Component({
  selector: 'app-text-detector',
  templateUrl: './text-detector.component.html',
  styleUrls: ['./text-detector.component.scss']
})



export class TextDetectorComponent implements OnInit {
  @ViewChild("chart") chart: ChartComponent;
  public chartOptions;
  processing = false;
  text;
  likedClicked;
  dislikeClicked;
  data;
  fakeProbability = 0;
  description="Please provide some text for detection.";
  hasText = false;
  user$: any;
  dialogIsOpen = false;
  models;
  modelSelected = 'default';

  constructor( private userService: UserService,private store: Store,private dialog: MatDialog,private authService: AuthService) {
    this.chartOptions = {
      series: [44, 55],
      chart: {
        width: 380,
        type: "donut"
      },
      plotOptions: {
        pie: {
          startAngle: -90,
          endAngle: 90,
          offsetY: 10
        }
      },
      labels: ['AI','human'],
      grid: {
        padding: {
          bottom: -80
        }
      },
      responsive: [
        {
          breakpoint: 2500,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }
      ]
    };
   }

  ngOnInit(): void {
    let modalShown = localStorage.getItem('modal');
    this.user$ = this.store.select(getCurrentUser).subscribe((data) => {
      console.log(data);
      
      if (data !== null && data != undefined) {
        
         if(!this.dialogIsOpen && (data?.lastLogin < "2022-12-19" || !data?.lastLogin) && modalShown=='false') {
          this.dialog.open( TextDetectorModalComponent, {
            width: '40%',
            height: 'unset',
         });
            this.dialogIsOpen = true;
            localStorage.setItem('modal', "true");
         }  
      }
   });
  }

  detectText() {    
    this.text = this.text.trim();
    // if(!this.text) {
    //   this.fakeProbability = 0;
    //   this.chartOptions['series'] = [0];
    //   this.description ="Please provide some text for detection."
    //   this.hasText = false;
    //   return
    // }
    this.hasText = true;
    setTimeout(() => {
      this.processing = true;
      this.userService.detectText(
        this.text
      )
      .pipe(first())
      .subscribe(
         (data) => {
             this.data =data.dataToreturn;
             this.fakeProbability = Math.ceil(data.dataToreturn.aiIndex*100);
             this.chartOptions['series'] = [Math.ceil(data.dataToreturn.aiIndex*100)]
             this.processing = false;
             if(this.fakeProbability <= 100/3) {
               this.description = "This text is mainly written by a human.";
               return;
             }else if(this.fakeProbability <= 200/3) {
              this.description = "This text is co-written by both a human and an AI";
             } else {
              this.description = "This text is mainly written by an AI.";
             }
         },
         (error) => {
            console.log('error', error);
            this.processing = false;
         }
      );
    }, 900);

  }

  rate(string){
    if(string=='like') {
       this.likedClicked = true;
       this.dislikeClicked = false;
    } else {
       this.dislikeClicked = true;
       this.likedClicked = false;
    }
  }

   checkModel(model) {
    this.processing = true;
    this.hasText = true;
    
    

    if(!this.models) {
       this.userService.checkAIModels(
        this.text
      )
      .pipe(first())
      .subscribe(
         (data) => {
          this.processing = false;
             this.models = {
              'model_1': data.aiPercentage,
              'model_2': data.percentageModel,
              'model_3': data.percentage_1,
              'model_4': data.percentage_2,
              'model_5': data.percentage_3,
              'model_6': data.percentage_4,
              'model_7': data.percentage_5,
              'model_8': data.percentage_6,
              'model_9': data.percentage_7,
              'model_10': data.percentage_8,
              'model_11': data.percentage_9,
             };
             this.fakeProbability = Math.ceil(this.models[model]);
             this.chartOptions['series'] = [Math.ceil(this.models[model])]
             this.processing = false;
             if(this.fakeProbability <= 100/3) {
               this.description = "This text is mainly written by a human.";
               return;
             }else if(this.fakeProbability <= 200/3) {
              this.description = "This text is co-written by both a human and an AI";
             } else {
              this.description = "This text is mainly written by an AI.";
             }
         },
         (error) => {
            console.log('error', error);
            this.processing = false;
         }
      );
    }

    if(model != 'default' && this.models) {
      this.fakeProbability = Math.ceil(this.models[model]);
      this.chartOptions['series'] = [Math.ceil(this.models[model])]
      this.processing = false;
      if(this.fakeProbability <= 100/3) {
        this.description = "This text is mainly written by a human.";
        return;
      }else if(this.fakeProbability <= 200/3) {
       this.description = "This text is co-written by both a human and an AI";
      } else {
       this.description = "This text is mainly written by an AI.";
      }
      this.processing = false;
    } else if (model == 'default') {
      this.checkDefault();
    }

  }

  checkDefault() {
    this.processing = true;
    this.hasText = true;
    this.modelSelected = 'default';
    this.models = null;
    this.userService.checkAIDefault(
      this.text
    )
    .pipe(first())
    .subscribe(
       (data) => {
           this.fakeProbability = Math.ceil(data.aiPercentage);
           this.chartOptions['series'] = [Math.ceil(data.aiPercentage)]
           this.processing = false;
           if(this.fakeProbability <= 100/3) {
             this.description = "This text is mainly written by a human.";
             return;
           }else if(this.fakeProbability <= 200/3) {
            this.description = "This text is co-written by both a human and an AI";
           } else {
            this.description = "This text is mainly written by an AI.";
           }
           this.processing = false;
       },
       (error) => {
          console.log('error', error);
          this.processing = false;
       }
    );
  }

}
