import { User } from './../../models/user';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ToastrService } from 'ngx-toastr';
import * as authActions from '../../authModule/state/actions/authentication.actions';
import * as assignmentActions from '../../professor-components/state/assignments/action/assignment.actions';
import * as thesisActions from '../../professor-components/state/thesis/action/thesis.actions';
import * as subjectActions from '../../professor-components/state/subjects/action/subject.actions';
import * as studentActions from '../../professor-components/state/students/action/student.actions';
import * as individualAdministratorActions from '../../individual-administrator-components/state/action/individualAdministrator.actions';

import * as requestsActions from '../../professor-components/state/requests/action/request.actions';

import * as studentRequestsActions from '../../student-components/state/request/action/request.actions';

import * as studentAdministrationActions from '../../student-components/state/administration/action/administration.actions';

import * as facultyActions from '../../administrator-components/state/faculty/action/faculty.actions';
import * as departmentActions from '../../administrator-components/state/department/action/department.actions';
import * as administratorSubjectActions from '../../administrator-components/state/subject/action/subject.actions';
import * as professorActions from '../../administrator-components/state/professor/action/professor.actions';
import * as administratorStudentActions from '../../administrator-components/state/student/action/student.actions';
import * as offersActions from '../../administrator-components/administrator-store/licenses/offers/state/action/offers.actions';

import { tap } from 'rxjs/operators';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Store } from '@ngrx/store';

/**
 * Alert Effect responsible to show alert messages based on specific actions
 */
@Injectable()
export class AlertEffects {
   user: any;
   constructor(
      private actions$: Actions,
      private toastrService: ToastrService,
      private store: Store, 
   ) {}
   /**
    * Effect listening for login, googleLogin, linkedInLogin action. When actions are triggered the effect will trigger toastr Service info method to inform user.
    */
   checkingYourInformation$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(
               authActions.login,
               authActions.googleLogin,
               authActions.linkedInLogin,
               authActions.LTILogin
            ),
            tap(() => this.toastrService.info('Checking your information'))
         ),
      { dispatch: false }
   );
   /**
    * Effect listening for successfully login actions (loginSuccess, googleLoginSuccess, linkedInLoginSuccess), when the actions are dispatched effect will trigger toaster Service success() method to welcome user.
    */
   welcomeBack$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(
               authActions.loginSuccess,
               authActions.googleLoginSuccess,
               authActions.linkedInLoginSuccess,
               authActions.LTILoginSuccess
            ),
            tap((action) => {
               this.user = action.user;
               this.toastrService.success('Welcome  ' + this.user.name + ' !');
            })
         ),
      { dispatch: false }
   );

   /**
    * Effect listening for successfully login actions (loginSuccess, googleLoginSuccess, linkedInLoginSuccess), when the actions are dispatched effect will trigger toaster Service success() method to welcome user.
    */
   updateUserInfo$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(authActions.updateUserInfoSuccess),
            tap((action) => {
               this.toastrService.success('Your info updated successfully');
            })
         ),
      { dispatch: false }
   );

   createAssignment$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(assignmentActions.createAssignmentSuccess),
            tap((action) => {
               this.toastrService.success('Assignment created successfully');
            })
         ),
      { dispatch: false }
   );

   createThesis$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(thesisActions.createThesisSuccess),
            tap((action) => {
               this.toastrService.success(action.data.message);
            })
         ),
      { dispatch: false }
   );

   editAssignment$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(assignmentActions.editAssignmentSuccess),
            tap((action) => {
               this.toastrService.success('Assignment edited successfully');
            })
         ),
      { dispatch: false }
   );

   editThesis$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(thesisActions.editThesisSuccess),
            tap((action) => {
               this.toastrService.success(action.data.message);
            })
         ),
      { dispatch: false }
   );

   createSubject$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(subjectActions.registerSubjectsSuccess),
            tap((action) => {
               this.toastrService.success(action.data.message);
            })
         ),
      { dispatch: false }
   );

   inviteStudent$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(studentActions.inviteStudentSuccess),
            tap((action) => {
               this.toastrService.success(action.data.message);
            })
         ),
      { dispatch: false }
   );

   deleteSubject$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(subjectActions.deleteSubjectsSuccess),
            tap((action) => {
               this.toastrService.success(action.data.message);
            })
         ),
      { dispatch: false }
   );

   inviteIndividual$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(individualAdministratorActions.inviteStudentSuccess),
            tap((action) => {
               this.toastrService.success(action.data.message);
            })
         ),
      { dispatch: false }
   );

   deleteAssignment$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(assignmentActions.deleteAssignmentSuccess),
            tap((action) => {
               this.toastrService.success(action.data.message);
            })
         ),
      { dispatch: false }
   );

   EditIndividualDetails$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(individualAdministratorActions.editStudentSuccess),
            tap((action) => {
               this.toastrService.success(action.data.message);
            })
         ),
      { dispatch: false }
   );

   deleteThesis$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(thesisActions.deleteThesisSuccess),
            tap((action) => {
               this.toastrService.success(action.data.message);
            })
         ),
      { dispatch: false }
   );

   editSubject$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(subjectActions.editSubjectsSuccess),
            tap((action) => {
               this.toastrService.success(action.data.message);
            })
         ),
      { dispatch: false }
   );

   duplicateSubject$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(subjectActions.duplicateSubjectsSuccess),
            tap((action) => {
               this.toastrService.success(action.data.message);
            })
         ),
      { dispatch: false }
   );

   toggleSubmissionAccess$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(
               individualAdministratorActions.toggleSubmissionAccessSuccess
            ),
            tap((action) => {
               this.toastrService.success(action.data.message);
            })
         ),
      { dispatch: false }
   );

   studentRegisterSubject$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(studentAdministrationActions.registerSubjectSuccess),
            tap((action) => {
               this.toastrService.success(action.data.message);
            })
         ),
      { dispatch: false }
   );

   studentRequest$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(studentRequestsActions.sentRequestSuccess),
            tap((action) => {
               this.toastrService.success(action.data.message);
            })
         ),
      { dispatch: false }
   );

   studentRequestApprove$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(requestsActions.approveRequestsSuccess),
            tap((action) => {
               this.toastrService.info(action.data.message);
            })
         ),
      { dispatch: false }
   );

   facultyRegistration$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(facultyActions.facultiesRegisterSuccess),
            tap((action) => {
               this.toastrService.success('Faculty created successfully');
            })
         ),
      { dispatch: false }
   );

   facultyEdit$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(facultyActions.editFacultyDetailsSuccess),
            tap((action) => {
               this.toastrService.success('Faculty edited successfully');
            })
         ),
      { dispatch: false }
   );

   facultyRemove$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(facultyActions.removeFacultySuccess),
            tap((action) => {
               this.toastrService.success('Faculty removed successfully');
            })
         ),
      { dispatch: false }
   );

   departmentRegistration$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(departmentActions.departmentsRegisterSuccess),
            tap((action) => {
               this.toastrService.success('Department created successfully');
            })
         ),
      { dispatch: false }
   );

   departmentEdit$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(departmentActions.editDepartmentDetailsSuccess),
            tap((action) => {
               this.toastrService.success('Department edited successfully');
            })
         ),
      { dispatch: false }
   );

   departmentRemove$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(departmentActions.removeDepartmentSuccess),
            tap((action) => {
               this.toastrService.success('Department removed successfully');
            })
         ),
      { dispatch: false }
   );

   subjectRegistration$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(administratorSubjectActions.subjectsRegisterSuccess),
            tap((action) => {
               this.toastrService.success('Subject created successfully');
            })
         ),
      { dispatch: false }
   );

   subjectEdit$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(administratorSubjectActions.editSubjectDetailsSuccess),
            tap((action) => {
               this.toastrService.success('Subject edited successfully');
            })
         ),
      { dispatch: false }
   );

   subjectRemove$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(administratorSubjectActions.removeSubjectSuccess),
            tap((action) => {
               this.toastrService.success('Subject removed successfully');
            })
         ),
      { dispatch: false }
   );

   professorRegistration$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(professorActions.professorRegisterSuccess),
            tap((action) => {
               this.toastrService.success('Professor registered successfully');
            })
         ),
      { dispatch: false }
   );

   professorEdit$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(professorActions.editProfessorDetailsSuccess),
            tap((action) => {
               this.toastrService.success('Professor edited successfully');
            })
         ),
      { dispatch: false }
   );

   professorRemove$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(professorActions.removeProfessorSuccess),
            tap((action) => {
               this.toastrService.success('Professor removed successfully');
            })
         ),
      { dispatch: false }
   );

   studentRegistration$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(administratorStudentActions.studentRegisterSuccess),
            tap((action) => {
               this.toastrService.success(action.message);
            })
         ),
      { dispatch: false }
   );

   studentEdit$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(administratorStudentActions.editStudentDetailsSuccess),
            tap((action) => {
               this.toastrService.success('Professor edited successfully');
            })
         ),
      { dispatch: false }
   );

   studentRemove$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(administratorStudentActions.removeStudentSuccess),
            tap((action) => {
               this.toastrService.success('Professor removed successfully');
            })
         ),
      { dispatch: false }
   );

   professorStudentRegister$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(studentActions.registerStudentSuccess),
            tap((action) => {
               this.toastrService.success(action.data.message);
            })
         ),
      { dispatch: false }
   );
   inviteStudents$ = createEffect(
      () =>
      this.actions$.pipe(
         ofType(studentActions.inviteStudentsSuccess),
         tap(async (action) => {
             if(!action.data.message){
                  this.toastrService.info('list of the students you want to invite');
            } else {
               this.toastrService.success('Students invited successfully');
            }
         })
      ),
   { dispatch: false }
   )

   offerCreated$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(offersActions.createOfferSuccess),
            tap((action) => {
               this.toastrService.success('Your offer created successfully');
            })
         ),
      { dispatch: false }
   );

   offerEdites$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(offersActions.editOfferSuccess),
            tap((action) => {
               this.toastrService.success('Your offer edited successfully');
            })
         ),
      { dispatch: false }
   );

   offerDeleted$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(offersActions.sentEmailForCustomOfferSuccess),
            tap((action) => {
               this.toastrService.success("Email sent");
            })
         ),
      { dispatch: false }
   );

   emailSentForOffer$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(offersActions.deleteOffer),
            tap((action) => {
               this.toastrService.success('Your offer has been deleted');
            })
         ),
      { dispatch: false }
   );


}
