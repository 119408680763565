import { createAction, props } from '@ngrx/store';

/**
 * Actions related to Thesis.
 * Each action accepts a set of properties.
 * Each action most of the cases has a success and failure action, that updates the store based on successfully/failure api responses from api.
 */

/**
 * Action used to load Thesis information of institution.
 */
export const loadThesisDetails = createAction(
   '[Thesis] Load Thesis Details',
   props<{
      page?;
      faculty?;
      department?;
      name?;
      level?;
   }>()
);

/**
 * Action is called when there is a success status returned from API.
 */
export const loadThesisDetailsSuccess = createAction(
   '[Thesis] Load Thesis Details Success',
   props<{
      thesis;
   }>()
);

/**
 * Action is called when there is a failure status returned from API.
 */
export const loadThesisDetailsFailure = createAction(
   '[Thesis] Load Thesis Details Failure',
   props<{ error: any }>()
);

/**
 * Action used to edit Assign thesis to professor.
 */
export const assignThesis = createAction(
   '[Thesis] Assign Thesis',
   props<{
      professorData;
      data;
      assign;
   }>()
);

/**
 * Action is called when there is a failure status returned from API.
 */
export const assignThesisFailure = createAction(
   '[Thesis] Assign Thesis Failure',
   props<{ error: any }>()
);
