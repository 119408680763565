<div class="edit-subject-modal">
   <div class="edit-subject-title">
      <h4>Edit subjects</h4>
   </div>
   <form [formGroup]="subjectEdit">
      <div class="editimi-lendes-inputs">
         <div class="fieldContainer">
            <label for="title">Name</label>
            <input id="title" type="text" formControlName="subjectTitle" />
            <div
               *ngIf="
                  subjectEdit.controls.subjectTitle.touched &&
                  subjectEdit.controls.subjectTitle.invalid
               "
               class="invalid"
            >
               Subject Name is required*
            </div>
            <div
               *ngIf="subjectEdit.controls.subjectTitle.errors?.minlength"
               class="invalid"
            >
               Subject name should contain at least 3 characters!
            </div>
            <label for="prof">Educators</label>
            <ngx-select-dropdown
               [config]="this.config"
               [options]="this.professors"
               [multiple]="true"
               [formControl]="form['subjectProfessors']"
            ></ngx-select-dropdown>
            <div
               *ngIf="
                  subjectEdit.controls.subjectProfessors.touched &&
                  subjectEdit.controls.subjectProfessors.invalid
               "
               class="invalid"
            >
               Subject Educators are required*
            </div>

            <label for="title">School</label>
            <select
               formControlName="subjectFaculty"
               (change)="facultyChanged()"
            >
               <ng-container *ngFor="let faculty of this.faculties">
                  <option value="{{ faculty.id }}" selected>
                     {{ faculty.name }}
                  </option>
               </ng-container>
            </select>
            <div
               *ngIf="
                  subjectEdit.controls.subjectFaculty.touched &&
                  subjectEdit.controls.subjectFaculty.invalid
               "
               class="invalid"
            >
               Subject School is required*
            </div>
            <label for="title">Department</label>
            <select formControlName="subjectDepartment">
               <ng-container *ngFor="let department of this.departments">
                  <option value="{{ department.id }}" selected>
                     {{ department.name }}
                  </option>
               </ng-container>
            </select>
            <div
               *ngIf="
                  subjectEdit.controls.subjectDepartment.touched &&
                  subjectEdit.controls.subjectDepartment.invalid
               "
               class="invalid"
            >
               Subject Department is required*
            </div>
            <label for="title">Type</label>
            <select formControlName="subjectType">
               <option value="1">Obligatory</option>
               <option value="2">Elective</option>
            </select>
            <div
               *ngIf="
                  subjectEdit.controls.subjectType.touched &&
                  subjectEdit.controls.subjectType.invalid
               "
               class="invalid"
            >
               Subject type is required*
            </div>
            <label for="prof">Description</label>
            <input id="prof" type="text" formControlName="subjectDescription" />
            <div
               *ngIf="
                  subjectEdit.controls.subjectDescription.touched &&
                  subjectEdit.controls.subjectDescription.invalid
               "
               class="invalid"
            >
               Subject Description is required*
            </div>
            <label for="title">Study year</label>
            <select formControlName="subjectYear">
               <option value="-1" selected disabled>Choose year!</option>
               <option value="1">1</option>
               <option value="2">2</option>
               <option value="3">3</option>
               <option value="4">4</option>
               <option value="5">5</option>
            </select>
            <div
               *ngIf="
                  subjectEdit.controls.subjectYear.touched &&
                  subjectEdit.controls.subjectYear.invalid
               "
               class="invalid"
            >
               Subject Study year is required*
            </div>
            <label for="title">Academic year</label>
            <select
               class="border white-bg select-adm"
               formControlName="subjectGeneration"
               [ngClass]="{
                  changeGeneration: this.generationAlert === true
               }"
            >
               <option value="-1" selected disabled>Choose Generation!</option>
               <option value="g1819">2018/2019</option>
               <option value="g1920">2019/2020</option>
               <option value="g2021">2020/2021</option>
               <option value="g2122">2021/2022</option>
               <option value="g2223">2022/2023</option>
               <option value="g2324">2023/2024</option>
               <option value="g2425">2024/2025</option>
               <option value="g2526">2025/2026</option>
            </select>
            <ng-container *ngIf="this.generationAlert">
               <span style="color: red !important">
                  To duplicate the subject please change the generation.
               </span>
            </ng-container>
            <div
               *ngIf="
                  subjectEdit.controls.subjectGeneration.touched &&
                  subjectEdit.controls.subjectGeneration.invalid
               "
               class="invalid"
            >
               Subject Generation is required*
            </div>

            <label for="title">Status</label>
            <select
               class="border white-bg select-adm"
               formControlName="subjectStatus"
            >
               <option value="0">Deactive</option>
               <option value="1">Active</option>
            </select>
         </div>
         <div class="editimi-lendes-links">
            <a class="fshij" (click)="delete()">Delete</a>
            <button
               class="perfundo-lendet"
               [disabled]="subjectEdit.invalid"
               [ngClass]="{
                  disable: subjectEdit.invalid
               }"
               type="submit"
               (click)="update()"
            >
               Save
            </button>
            <a class="dupliko-lendet" (click)="duplicate()">Duplicate</a>
         </div>
      </div>
   </form>
</div>
<div id="overlay"></div>
