import { createAction, props } from '@ngrx/store';

export const loadStudents = createAction(
   '[Student] Load Students',
   props<{ user?: any; page: any; name?: any;invitedStudents? }>()
);

export const loadStudentsSuccess = createAction(
   '[Student] Load Students Success',
   props<{ data: any }>()
);

export const loadStudentsFailure = createAction(
   '[Student] Load Students Failure',
   props<{ error: any }>()
);

export const inviteStudent = createAction(
   '[Student] Invite Student',
   props<{ studentId: any; subjectId: any; email? }>()
);

export const inviteStudentSuccess = createAction(
   '[Student] Invite Student Success',
   props<{ data: any }>()
);

export const inviteStudentFailure = createAction(
   '[Student] Invite Student Failure',
   props<{ error: any }>()
);

export const inviteStudents = createAction(
   '[Student] Invite Students',
   props<{ emails ; subjectId ; checkEmails?; }>()
);

export const inviteStudentsSuccess = createAction(
   '[Student] Invite Students Success',
   props<{ data: any }>()
);

export const inviteStudentsFailure = createAction(
   '[Student] Invite Students Failure',
   props<{ error: any }>()
);

export const verifyStudent = createAction(
   '[Student] Verify Student',
   props<{ indexId: any }>()
);

export const verifyStudentSuccess = createAction(
   '[Student] Verify Student Success',
   props<{ data: any }>()
);

export const verifyStudentFailure = createAction(
   '[Student] Verify Student Failure',
   props<{ error: any }>()
);

export const registerStudent = createAction(
   '[Student] Professor Register Student',
   props<{
      studentName: any;
      studentFaculty: any;
      studentDepartment: any;
      studentIndexID: any;
      studentEmail: any;
   }>()
);

export const registerStudentSuccess = createAction(
   '[Student] Professor Register Students Success',
   props<{ data: any }>()
);

export const registerStudentFailure = createAction(
   '[Student] Professor Register Students Failure',
   props<{ error: any }>()
);

export const deleteStudent = createAction(
   '[Student] Professor Delete Student',
   props<{studentId,page? ,name?,invitedStudents?}>()
);

export const deleteStudentSuccess = createAction(
   '[Student] Professor Delete Student Success',
   props<{ data: any }>()
);

export const deleteStudentFailure = createAction(
   '[Student] Professor Delete Student Failure',
   props<{ error: any }>()
);

export const setStudentRegisterToNull = createAction(
   '[Student] Set Professor Student Register To Null'
);

export const deleteStudentDataFromStore = createAction(
   '[Student] Delete Student data From Store'
);
