<!-- 
   // TODO: Fix it on next version inshaAllah
   <select #langselect (change)="translate.use(langselect.value)">
   <option *ngFor="let lang of translate.getLangs()" [value]="lang">
      {{ lang }}
   </option>
</select> -->
<ngx-spinner
   bdColor="rgba(0, 0, 0, 0.8)"
   size="medium"
   color="#1b76de"
   type="ball-beat"
   [fullScreen]="true"
></ngx-spinner>

<router-outlet></router-outlet>
