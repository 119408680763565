import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';

@Injectable({
   providedIn: 'root',
})
export class UploadfileService {
   constructor(protected http: HttpClient) {}
   /**
    * Method used to upload file
    * @param base64
    * @param type
    * @param fileName
    * @param size
    */
   uploadFile(base64: any, type: string, fileName: string, size: number) {
      return this.http
         .post<any>(`${environment.apiUrl}/fileUpload`, {
            base64,
            type,
            fileName,
            size,
         })
         .pipe();
   }

   generatePresignedURL(type: string, firstName: string, lastName: string) {
      return this.http
         .post<any>(`${environment.apiUrl}/getPutPresignedURL`, {
            type,
            firstName,
            lastName,
         })
         .pipe();
   }

   uploadfileAWSS3(fileUploadUrl, file) {
      return this.http.put<any>(fileUploadUrl, file).pipe();
   }

   generatePresignedURLPreview(
      key: string,
      type: string,
      firstName: string,
      lastName: string
   ) {
      return this.http
         .post<any>(`${environment.apiUrl}/getDisplayPresignedURLPreview`, {
            key,
            type,
            firstName,
            lastName,
         })
         .pipe();
   }
}
