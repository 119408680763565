// *** Angular
import { Component, OnInit } from '@angular/core';

@Component({
   selector: 'app-manage-commitment',
   templateUrl: './manage-commitment.component.html',
   styleUrls: ['./manage-commitment.component.scss'],
})
export class ManageCommitmentComponent implements OnInit {
   constructor() {}

   ngOnInit(): void {}
}
