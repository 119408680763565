<section>
   <h4 class="bold w-87">Onboarding panel</h4>
   <main class="flex register-profesori">
      <div class="links">
         <a routerLink="faculty-register" routerLinkActive="active"
            >Schools</a
         >
         <a routerLink="department-register" routerLinkActive="active"
            >Departments</a
         >
         <a routerLink="course-register" routerLinkActive="active"
            >Subjects</a
         >
         <a routerLink="professor-register" routerLinkActive="active"
            >Educators</a
         >
         <a routerLink="student-register" routerLinkActive="active"
            >Students</a
         >
      </div>
      <div class="shadowBorder">
         <router-outlet></router-outlet>
      </div>
   </main>
</section>
