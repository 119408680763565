<div class="documents-2 shadow white-bg">
   <div
      *ngIf="
         !(
            this.totalAssignments == 0 &&
            assignmentName == undefined &&
            facultyId == '-1' &&
            departmentId == '-1' &&
            subjectName == undefined &&
            professor == '-1'
         )
      "
      class="filtersContainer"
   >
      <h4>Overview</h4>
      <div class="selectors">
         <div>
            <select [(ngModel)]="facultyId" (change)="selectedFaculty()">
               <option value="-1" selected disabled>
                  Filter by school
               </option>
               <ng-container *ngFor="let faculty of this.faculties">
                  <option value="{{ faculty.id }}">{{ faculty.name }}</option>
               </ng-container>
               <option value="">All</option>
            </select>
         </div>
         <div>
            <select
               disabled
               *ngIf="facultyId == '-1' || facultyId == ''"
               name=""
               id=""
            >
               <option value="">Select school first</option>
            </select>
            <select
               *ngIf="facultyId !== '-1' && facultyId !== ''"
               [(ngModel)]="departmentId"
               (change)="selectedDepartment()"
            >
               <option value="-1" selected disabled>
                  Filter by department
               </option>
               <ng-container *ngFor="let department of this.departments">
                  <option value="{{ department.id }}">
                     {{ department.name }}
                  </option>
               </ng-container>
               <option value="">All</option>
            </select>
         </div>
         <div>
            <select [(ngModel)]="professor" (change)="filterByProfessor()">
               <option value="-1" selected disabled>
                  Filter by educator
               </option>
               <ng-container *ngFor="let professor of this.professors">
                  <option value="{{ professor.id }}">
                     {{ professor.name }}
                  </option>
               </ng-container>

               <option value="">All</option>
            </select>
         </div>
         <div>
            <input
               placeholder="Search by subject"
               [(ngModel)]="subjectName"
               (ngModelChange)="filterWithTimeout()"
               type="text"
               name="searchByName"
            />
         </div>
         <div>
            <input
               placeholder="Search by name"
               [(ngModel)]="assignmentName"
               (ngModelChange)="filterWithTimeout()"
               type="text"
               name="searchByName"
            />
         </div>
         <button class="reportBtn" (click)="resetFilter()">Reset</button>
      </div>
   </div>

   <div
      *ngIf="this.totalAssignments != 0"
      class="tableWithExpandedInfoAssignments tablewithRatios"
   >
      <ng-container
         *ngFor="
            let assignment of this.assignments
               | paginate
                  : {
                       itemsPerPage: 5,
                       currentPage: this.page,
                       totalItems: this.totalAssignments
                    };
            let i = index
         "
      >
         <div>
            <div>
               <h4 class="blueText bold">{{ assignment.assignment.name }}</h4>
               <div class="container_data">
                  <div>
                     <span>Subject</span>
                     <span>Educator</span>
                     <span>School</span>
                     <span>Department</span>
                     <span>Start date</span>
                     <span>End<br> date</span>
                     <span>Submissions</span>
                     <span>Document<br> language<br/> similarity</span>
                     <span>Translation<br> language<br/> similarity</span>
                  </div>
                  <div>
                     <span class="bold">{{
                        assignment.assignment.Course.title
                     }}</span>
                     <span class="bold">{{
                        assignment.assignment.user.name
                     }}</span>
                     <span class="bold">{{
                        assignment.assignment.user.Department.Faculty.name
                     }}</span>
                     <span class="bold">{{
                        assignment.assignment.user.Department.name
                     }}</span>
                     <span class="bold">{{
                        assignment.assignment.startDate.split('T')[0]
                     }}</span>
                     <span class="bold">{{
                        assignment.assignment.endDate.split('T')[0]
                     }}</span>
                     <span
                        (click)="filterAssignments(assignment.assignment.name)"
                        class="bold clickable"
                        >{{ assignment.submissions._count }}</span
                     >
                     <span class="bold">{{
                        assignment.submissions._avg.originalPercentage?.toFixed(
                           2
                        ) || 0
                     }}%</span>
                     <span class="bold">{{
                        assignment.submissions._avg.translatedPercentage?.toFixed(
                           2
                        ) || 0
                     }}%</span>
                  </div>
               </div>
               <a
                  *ngIf="expandInfo !== i"
                  class="blueText underline vold"
                  (click)="expand(i)"
                  >Expand</a
               >
               <a
                  *ngIf="expandInfo === i"
                  class="blueText underline vold"
                  (click)="expand(i)"
                  >Collapse</a
               >
            </div>
            <div
               [ngClass]="{ expandedInfo: expandInfo == i }"
               class="moreInfo"
               style="grid-template: none !important"
            >
              <div class="details">
               <span>
                  Most used language:
                  <b>{{ setLanguageName(assignment.language?assignment.language[0]:'-') }}</b>
                  <!-- <b>{{ assignment.language === undefined
                     ? '-'
                     : assignment.language[0] }}</b> -->
                     </span>
               <span>
                  Students:
                  <b>{{ assignment.students }}</b>
               </span>
               <span>
                  High similarity submissions:
                  <b>{{ assignment.highSimilaritySubmissions }}</b></span
               >
               <span>
                  Medium similarity submissions:
                  <b>{{
                     assignment.mediumSimilaritySubmissions
                  }}</b></span
               >
               <span>
                  Low similarity submissions:
                  <b>{{ assignment.lowSimilaritySubmissions }}</b></span
               >
              </div>
              <div class="ratios">
               Ratios
              </div>
              <div class="details">
               <span>
                  Student-to-Submission:
                  <b>
                     {{ assignment.students }} : {{
                        assignment.submissions._count
                     }}</b
                  ></span
               >
              </div>
            </div>
         </div>
      </ng-container>
   </div>
   <div
      class="zeroState"
      *ngIf="
         this.totalAssignments == 0 &&
         (assignmentName != undefined ||
            facultyId != '-1' ||
            departmentId != '-1' ||
            subjectName != undefined ||
            professor != '-1')
      "
   >
      No assignments found
   </div>
   <pagination-controls
      *ngIf="this.totalAssignments != 0"
      class="num"
      (pageChange)="changePage($event)"
      responsive="true"
      previousLabel=""
      nextLabel=""
   ></pagination-controls>
   <div
      class="zero-state"
      *ngIf="
         this.totalAssignments == 0 &&
         assignmentName == undefined &&
         facultyId == '-1' &&
         departmentId == '-1' &&
         subjectName == undefined &&
         professor == '-1'
      "
   >
      <!-- <img src="../../assets/images/icona-image.png" alt="" /> -->
      <h5 class="bold">There are no assignments created by your institution educators.</h5>
   </div>
</div>
