import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../services/auth.service';
@Component({
   selector: 'app-help-center',
   templateUrl: './help-center.component.html',
   styleUrls: ['./help-center.component.scss'],
})
export class HelpCenterComponent implements OnInit {
   userRole;
   /**
    * Component constructor
    * @param router
    * @param route
    * @param authServiceService
    */
   constructor(
      private router: Router,
      public route: ActivatedRoute,
      private authServiceService: AuthService
   ) {}
   /**
    * Method used to be called whenever the component is loaded and all the methods in it, will be triggered.
    */
   ngOnInit(): void {
      this.userRole = this.authServiceService.isLoggedInRole();
   }
}
