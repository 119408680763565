import * as AppState from '../../../../../../../store/app.state';
import * as institutionalProfessorsActions from '../action/institutional-professors-statistics.actions';
import { createReducer } from '@ngrx/store';
import { on } from '@ngrx/store';

/**
 * assignment reducer used to updated store values based on certain action.
 */

/**
 * State interface extends App state State and add user state to the main state.
 */
export interface State extends AppState.State {
   insProfessorsStatistics: insProfessorsStatisticsState;
}

/**
 * An SubjectState interface, which represents the blueprint of the User State.
 */
export interface insProfessorsStatisticsState {
   insProfessorsStatistics: any;
   professorsNumber: number;
   errorMessage: string;
}

/**
 * SubjectState initial state.
 */
export const initialState: insProfessorsStatisticsState = {
   insProfessorsStatistics: null,
   professorsNumber: null,
   errorMessage: null,
};

/**
 * Subject Reducer used to update the state upon Subject actions.
 */
export const insProfessorsStatisticsReducer = createReducer(
   initialState,
   /**
    * On loadSubjectsDetailsSuccess action updated current state with the following properties.
    */
   on(
      institutionalProfessorsActions.loadInstitutionalProfessorsStatisticsSuccess,
      (state, action): insProfessorsStatisticsState => {
         return {
            ...state,
            insProfessorsStatistics: action.data.professorDetails,
            professorsNumber: action.data.professorsNumber,
         };
      }
   ),

   /**
    * On loadSubjectsDetailsFailure action updated current state with the following properties.
    */
   on(
      institutionalProfessorsActions.loadInstitutionalProfessorsStatisticsFailure,
      (state, action): insProfessorsStatisticsState => {
         return {
            ...state,
            errorMessage: action.error,
         };
      }
   )
);
