import * as AppState from '../../../../store/app.state';
import { Faculty } from '../../../../models/faculty';
import * as facultyActions from '../action/faculty.actions';
import { createReducer } from '@ngrx/store';
import { on } from '@ngrx/store';
/**
 * Faculty reducer used to updated store values based on certain action.
 */

/**
 * State interface extends App state State and add user state to the main state.
 */
export interface State extends AppState.State {
   faculties: FacultyState;
}

/**
 * An FacultyState interface, which represents the blueprint of the User State.
 */
export interface FacultyState {
   faculties: Faculty[];
   errorMessage: string;
   totalFaculties: number;
   facultyRegistered: string;
}
/**
 * FacultyState initial state.
 */
export const initialState: FacultyState = {
   faculties: null,
   totalFaculties: null,
   errorMessage: null,
   facultyRegistered: null,
};
/**
 * Faculty Reducer used to update the state upon faculty actions.
 */
export const facultyReducer = createReducer<FacultyState>(
   initialState,
   /**
    * On loadFacultiesDetailsSuccess action updated current state with the following properties.
    */
   on(
      facultyActions.loadFacultiesDetailsSuccess,
      (state, action): FacultyState => {
         return {
            ...state,
            faculties: action.faculties,
            totalFaculties: action.totalFaculties,
         };
      }
   ),

   /**
    * On loadFacultiesDetailsFailure action updated current state with the following properties.
    */
   on(
      facultyActions.loadFacultiesDetailsFailure,
      (state, action): FacultyState => {
         return {
            ...state,
            errorMessage: action.error,
         };
      }
   ),
   /**
    * On loadFacultiesDetailsSuccess action updated current state with the following properties.
    */
   on(
      facultyActions.facultiesRegisterSuccess,
      (state, action): FacultyState => {
         return {
            ...state,
            facultyRegistered: 'Faculty registered',
         };
      }
   ),
   /**
    * On loadFacultiesDetailsSuccess action updated current state with the following properties.
    */
   on(
      facultyActions.setRegisterFacultyToNull,
      (state, action): FacultyState => {
         return {
            ...state,
            facultyRegistered: null,
         };
      }
   )
);
