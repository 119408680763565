import { createAction, props } from '@ngrx/store';
/**
 * Actions related to Faculty.
 * Each action accepts a set of properties.
 * Each action most of the cases has a success and failure action, that updates the store based on successfully/failure api responses from api.
 */

/**
 * Action used to recall faculties details.
 */
export const recallFaculties = createAction('[Faculty] recallFaculties');

/**
 * Action used to register a faculties.
 */
export const facultiesRegister = createAction(
   '[Faculty] Faculties Register',
   props<{
      data: any;
      page?;
      name?;
   }>()
);

/**
 * Action is called when there is a success status returned from API.
 */
export const facultiesRegisterSuccess = createAction(
   '[Faculty] Faculties Register Success',
   props<{ data: any }>()
);

/**
 * Action is called when there is a failure status returned from API.
 */
export const facultiesRegisterFailure = createAction(
   '[Faculty] Faculties Register Failure',
   props<{ error: any }>()
);

/**
 * Action used to load faculties of institution.
 */
export const loadFacultiesDetails = createAction(
   '[Faculty] Load Faculties Details',
   props<{
      page;
      name?;
   }>()
);

/**
 * Action is called when there is a success status returned from API.
 */
export const loadFacultiesDetailsSuccess = createAction(
   '[Faculty] Load Faculties Details Success',
   props<{
      faculties;
      totalFaculties;
   }>()
);

/**
 * Action is called when there is a failure status returned from API.
 */
export const loadFacultiesDetailsFailure = createAction(
   '[Faculty] Load Faculties Details Failure',
   props<{ error: any }>()
);

/**
 * Action used to edit Faculty details.
 */
export const editFacultyDetails = createAction(
   '[Faculty] Edit Faculty Details',
   props<{
      facultyId;
      facultyName;
      facultyDescription;
      page?;
      name?
   }>()
);

/**
 * Action is called when there is a success status returned from API.
 */
 export const editFacultyDetailsSuccess = createAction(
   '[Faculty] Edit Faculty Details Success',
   props<{
       data
   }>()
);

/**
 * Action is called when there is a failure status returned from API.
 */
export const editFacultyDetailsFailure = createAction(
   '[Faculty] Edit Faculty Details Failure',
   props<{ error: any }>()
);

/**
 * Action used to remove Faculty from institution.
 */
export const removeFaculty = createAction(
   '[Faculty] Remove Faculty',
   props<{
      facultyId;
      page?;
      name?
   }>()
);

/**
 * Action is called when there is a success status returned from API.
 */
 export const removeFacultySuccess = createAction(
   '[Faculty] Remove Faculty Success',
   props<{
       data
   }>()
);


/**
 * Action is called when there is a failure status returned from API.
 */
export const removeFacultyFailure = createAction(
   '[Faculty] Remove Faculty Failure',
   props<{ error: any }>()
);

export const setRegisterFacultyToNull = createAction(
   '[Faculty] Set Register Faculty To Null'
);
