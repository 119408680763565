import { createAction, props } from '@ngrx/store';

/**
 * Actions related to Subjects.
 * Each action accepts a set of properties.
 * Each action most of the cases has a success and failure action, that updates the store based on successfully/failure api responses from api.
 */

/**
 * Action used to register a subject.
 */
export const userStatistics = createAction('[User Statistics] User Statistics');

/**
 * Action is called when there is a success status returned from API.
 */
export const userStatisticsSuccess = createAction(
   '[User Statistics] User Statistics Success',
   props<{ data: any }>()
);

/**
 * Action is called when there is a failure status returned from API.
 */
export const userStatisticsFailure = createAction(
   '[User Statistics] User Statistics Failure',
   props<{ error: any }>()
);
