import { createFeatureSelector, createSelector } from '@ngrx/store';
import { OffersState } from '../reducer/offers.reducer';

/**
 * Selectors are pure functions used for obtaining slices of store state.
 */

/**
 * The createFeatureSelector is a convenience method for returning a top level feature state.
 */
const getOffersState = createFeatureSelector<OffersState>('offers');

/**
 * Selector used to get faculties details state.
 */
export const getOffersStateDetails = createSelector(
   getOffersState,
   (state) => state
);
