import { createAction, props } from '@ngrx/store';

export const loadProfessorSubmissions = createAction(
   '[ProfessorSubmissions] Load Professor Submissions',
   props<{ user: any; page: any; title: any; percentageFilter? }>()
);

export const loadProfessorSubmissionsSuccess = createAction(
   '[ProfessorSubmissions] Load Professor Submissions Success',
   props<{ data: any }>()
);

export const loadProfessorSubmissionsFailure = createAction(
   '[ProfessorSubmissions] Load Professor Submissions Failure',
   props<{ error: any }>()
);
