<div class="faqs">
   <main>
      <div class="accordion-item" *ngFor="let number of numbers; let i = index">
         <div class="flex" (click)="expandRow(i)">
            <div>
               <i class="fas fa-question"></i>
               <h5 class="bold">
                  {{ 'help_center.FAQS.question_' + number | translate }}
               </h5>
            </div>
            <i *ngIf="i !== expandedIndex" class="fas fa-plus"></i>
            <i *ngIf="i == expandedIndex" class="fas fa-minus"></i>
         </div>
         <p *ngIf="i === expandedIndex">
            {{ 'help_center.FAQS.answer_' + number | translate }}
         </p>
      </div>
   </main>
</div>
