import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, catchError, concatMap } from 'rxjs/operators';
import * as assignmentsActions from '../action/units-statistics-assignments.actions';
import { AdministratorStatisticsService } from '../../../../../../services/administrator-statistics.service';

@Injectable()
export class UnitsStatisticsAssignmentsEffects {
   constructor(
      private actions$: Actions,
      private administratorStatistics: AdministratorStatisticsService
   ) {}

   /**
    * Effect listening for assignemntsStatistics Action, when assignemntsStatistics is dispatched the effect with call create() from courseService.
    * In case of success response the effect it will trigger assignemntsStatisticsSuccess.
    * In case of Error it will trigger assignemntsStatisticsFailure with error property.
    */
   assignemntsStatistics$ = createEffect(() => {
      return this.actions$.pipe(
         ofType(assignmentsActions.loadUnitsStatisticsAssignments),
         concatMap((action) => {
            return this.administratorStatistics
               .academicUnitsAssignmentsStatistics(
                  action.page,
                  action.title,
                  action.faculty,
                  action.department,
                  action.subject,
                  action.professor
               )
               .pipe(
                  map((data) => {
                     return assignmentsActions.loadUnitsStatisticsAssignmentsSuccess(
                        {
                           data: data,
                        }
                     );
                  }),
                  catchError((error) =>
                     of(
                        assignmentsActions.loadUnitsStatisticsAssignmentsFailure(
                           {
                              error,
                           }
                        )
                     )
                  )
               );
         })
      );
   });
}
