<div class="contact-us-2">
   <main>
      <ng-container [formGroup]="contactUsForm">
         <div class="flex column">
            <div class="input">
               <h5>{{ 'general.full_name' | translate }}</h5>
               <input
                  [readonly]="true"
                  formControlName="fullName"
                  type="text"
               />
            </div>
            <div
               *ngIf="
                  formControls.fullName.touched && formControls.fullName.invalid
               "
               class="alert alert-danger"
            >
               <div
                  class="invalid"
                  *ngIf="formControls.fullName.errors.required"
               >
                  {{ 'validators.enter_email' | translate }}
               </div>
            </div>
            <div class="input">
               <h5>{{ 'general.email' | translate }}</h5>
               <input [readonly]="true" formControlName="email" type="text" />
            </div>
            <div
               *ngIf="formControls.email.touched && formControls.email.invalid"
               class="alert alert-danger"
            >
               <div class="invalid" *ngIf="formControls.email.errors.required">
                  {{ 'validators.enter_email' | translate }}
               </div>
            </div>
            <div class="input">
               <h5>{{ 'general.phone' | translate }}</h5>
               <input formControlName="phoneNumber" type="text" />
            </div>
         </div>
         <div
            *ngIf="
               formControls.phoneNumber.touched &&
               formControls.phoneNumber.invalid
            "
            class="alert alert-danger"
         >
            <div
               class="invalid"
               *ngIf="formControls.phoneNumber.errors.required"
            >
               Your phone number is required.
            </div>
         </div>
         <h5>{{ 'general.message' | translate }}</h5>
         <textarea
            formControlName="message"
            name=""
            id=""
            cols="30"
            rows="7"
            placeholder="Enter your message here ..."
         >
         </textarea>
         <div
            *ngIf="formControls.message.touched && formControls.message.invalid"
            class="alert alert-danger"
         >
            <div class="invalid" *ngIf="formControls.message.errors.required">
               Message can't be empty
            </div>
         </div>
         <div class="center">
            <button
               *ngIf="contactUsForm.valid"
               (click)="sendMessage()"
               class="submit"
            >
               Send Message
            </button>
            <button
               *ngIf="contactUsForm.invalid"
               (click)="showToasterWarning('Please re-check your info!')"
               class="submit"
            >
               Send Message
            </button>
         </div>
      </ng-container>
   </main>
</div>
