import { createAction, props } from '@ngrx/store';

export const loadProfessorThesis = createAction(
   '[ProfessorThesis] Load Professor Thesis',
   props<{ data?: any; status?: any; name?: any; page: any }>()
);

export const loadProfessorThesisSuccess = createAction(
   '[ProfessorThesis] Load Professor Thesis Success',
   props<{ data: any }>()
);

export const loadProfessorThesisFailure = createAction(
   '[ProfessorThesis] Load Professor Thesis Failure',
   props<{ error: any }>()
);

/**
 * Action used to register a department.
 */
export const createThesis = createAction(
   '[ProfessorThesis] Create Thesis',
   props<{
      data: any;
   }>()
);

/**
 * Action is called when there is a success status returned from API.
 */
export const createThesisSuccess = createAction(
   '[ProfessorThesis] Create Thesis Success',
   props<{ data: any }>()
);

/**
 * Action is called when there is a failure status returned from API.
 */
export const createThesisFailure = createAction(
   '[ProfessorThesis] Create Thesis Failure',
   props<{ error: any }>()
);

/**
 * Action used to register a department.
 */
export const editThesis = createAction(
   '[ProfessorThesis] Edit Thesis',
   props<{
      data: any;
      id: any;
      status?: any; name?: any; page: any
   }>()
);

/**
 * Action is called when there is a success status returned from API.
 */
export const editThesisSuccess = createAction(
   '[ProfessorThesis] Edit Thesis Success',
   props<{ data: any }>()
);

/**
 * Action is called when there is a failure status returned from API.
 */
export const editThesisFailure = createAction(
   '[ProfessorThesis] Edit Thesis Failure',
   props<{ error: any }>()
);

/**
 * Action used to register a department.
 */
export const deleteThesis = createAction(
   '[ProfessorThesis] Delete Thesis',
   props<{
      data: any;
      status?: any; name?: any; page?: any 
   }>()
);

/**
 * Action is called when there is a success status returned from API.
 */
export const deleteThesisSuccess = createAction(
   '[ProfessorThesis] Delete Thesis Success',
   props<{ data: any }>()
);

/**
 * Action is called when there is a failure status returned from API.
 */
export const deleteThesisFailure = createAction(
   '[ProfessorThesis] Delete Thesis Failure',
   props<{ error: any }>()
);

/**
 * Action used to register a department.
 */
 export const changeThesisAccess = createAction(
   '[ProfessorThesis] Change Thesis Submission Access',
   props<{
      data: any;
      status?: any; name?: any; page?: any 
   }>()
);

/**
 * Action is called when there is a success status returned from API.
 */
export const changeThesisAccessSuccess = createAction(
   '[ProfessorThesis] Change Thesis Submission Access Success',
   props<{ data: any }>()
);

/**
 * Action is called when there is a failure status returned from API.
 */
export const changeThesisAccessFailure = createAction(
   '[ProfessorThesis] Change Thesis Submission Access Failure',
   props<{ error: any }>()
);


export const setCreatedThesisToNull = createAction(
   '[ProfessorThesis] Set Created Thesis To Null'
);
