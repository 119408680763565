// *** Angular
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
   MatDialog,
   MatDialogRef,
   MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { first } from 'rxjs/operators';
import {
   FormBuilder,
   FormControl,
   FormGroup,
   Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';

// *** Packages
import swal from 'sweetalert2';

// *** Actions
import * as departmentActions from '../../state/department/action/department.actions';
import * as facultyActions from '../../state/faculty/action/faculty.actions';

// *** Selectors
import { getInstitutionFacultiesStateDetails } from '../../state/faculty/selector/faculty.selectors';

// *** Services
import { DepartmentService } from 'src/app/services/department.service';

@Component({
   selector: 'app-edit-department',
   templateUrl: './edit-department.component.html',
   styleUrls: ['./edit-department.component.scss'],
})
export class EditDepartmentComponent implements OnInit, OnDestroy {
   /**
    * Variable used to store departmentId that we passe from units-departments
    */
   departmentId;

   /**
    * Variable used to store department object that we retrieve from API.
    */
   department;

   /**
    * Variable used to store FormGroup values that are being edited on modal.
    */
   departmentEdit: FormGroup;

   /**
    * Variable used to store faculties of institution
    */
   faculties;
   faculties$;

   constructor(
      public dialogRef: MatDialogRef<EditDepartmentComponent>,
      public dialog: MatDialog,
      private departmentService: DepartmentService,
      private fb: FormBuilder,
      /**
       * Used to pass data in component through dialog
       */
      @Inject(MAT_DIALOG_DATA) public data: any,
      private store: Store
   ) {}

   ngOnDestroy(): void {
      this.faculties$.unsubscribe();
   }

   ngOnInit(): void {
      this.departmentEdit = this.fb.group({
         departmentName: new FormControl('', Validators.required),
         departmentFaculty: new FormControl('', Validators.required),
         departmentDescription: new FormControl('', Validators.required),
         departmentYear: new FormControl('', Validators.required),
         departmentLevel: new FormControl('', Validators.required),
      });
      this.departmentId = this.data.departmentId;
      this.store.dispatch(facultyActions.loadFacultiesDetails({ page: 0 }));

      this.faculties$ = this.store
         .select(getInstitutionFacultiesStateDetails)
         .subscribe((data) => {
            if (data !== null) {
               this.faculties = data.faculties;
            }
         });
      this.departmentService
         .department(this.departmentId)
         .pipe(first())
         .subscribe(
            (data) => {
               this.department = data.department;

               this.form.departmentName.setValue(this.department.name);
               this.form.departmentFaculty.setValue(this.department.Faculty.id);
               this.form.departmentDescription.setValue(
                  this.department.description
               );
               this.form.departmentYear.setValue(this.department.year);
               this.form.departmentLevel.setValue(this.department.level);
            },
            (error) => {
               console.log('error', error);
            }
         );
   }

   /**
    * Method use to get form controls.
    */
   get form() {
      return this.departmentEdit.controls;
   }

   /**
    * Method is used to call edit Department service that will send updated fields of department to API.
    */
   async update() {
      const result = await swal.fire({
         title: 'Confirm update of department',
         icon: 'warning',
         showCancelButton: true,
         confirmButtonColor: '#3085d6',
         cancelButtonColor: '#b5adad',
         confirmButtonText: 'Confirm',
         allowOutsideClick: false,
      });
      if (result.isConfirmed) {
         this.store.dispatch(
            departmentActions.editDepartmentDetails({
               departmentId: this.departmentId,
               departmentName: this.form.departmentName.value,
               departmentFaculty: this.form.departmentFaculty.value,
               departmentDescription: this.form.departmentDescription.value,
               departmentYear: this.form.departmentYear.value,
               departmentLevel: this.form.departmentLevel.value,
               page: this.data.page,
               faculty: this.data.faculty,
               name: this.data.name,
            })
         );
         this.dialogRef.close();
      } else if (result.isDismissed) {
         this.dialogRef.close();
      }
   }

   /**
    * Method is used to call remove department service.
    */
   async delete() {
      const result = await swal.fire({
         title: 'You are about to DELETE a department. Please confirm your action by clicking “Confirm” below.',
         text: 'Your action is recoverable only by contacting the technical support team at support@crossplag.com',
         icon: 'warning',
         showCancelButton: true,
         confirmButtonColor: '#3085d6',
         cancelButtonColor: '#b5adad',
         confirmButtonText: 'Confirm',
         allowOutsideClick: false,
      });
      if (result.isConfirmed) {
         this.store.dispatch(
            departmentActions.removeDepartment({
               departmentId: this.departmentId,
               page: this.data.page,
               faculty: this.data.faculty,
               name: this.data.name,
            })
         );
         this.dialogRef.close();
      } else if (result.isDismissed) {
         this.dialogRef.close();
      }
   }
}
