import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from './services/auth.service';
import { ActivatedRoute, NavigationEnd } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { filter, map } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
   selector: 'app-root',
   templateUrl: './app.component.html',
   styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
   constructor(
      public translate: TranslateService,
      private authService: AuthService,
      private titleService: Title,
      public router: Router,
      private activatedRoute: ActivatedRoute
   ) {
      translate.addLangs([
         'english',
         'albanian',
         'german',
         'serbian',
         'polish',
         'french',
         'turkish',
         'italian',
         'czech',
         'greek',
         'portuguese',
         'spanish',
      ]);
      translate.setDefaultLang('english');
      const browserLang = translate.getBrowserLang();
      translate.use(
         browserLang.match(/english|shqip|german/) ? browserLang : 'english'
      );
   }
   ngOnInit(): void {
      // window.addEventListener('storage', (event) => {
      //    if (event.storageArea == localStorage) {
      //       let token = this.authService.getUserToken();
      //       if (
      //          token == undefined &&
      //          event.url.indexOf('individual') === -1 &&
      //          event.url.indexOf('admin') === -1
      //       ) {
      //          console.log('agneseeesaaa');
               
      //          this.authService.logout();
      //       }
      //    }
      // });

      const appTitle = this.titleService.getTitle();
      
      this.router
        .events.pipe(
          filter(event => event instanceof NavigationEnd),
          map(() => {
            const child = this.activatedRoute.firstChild;            

            if(child?.snapshot['_routerState']?.url?.split('individual/')[1]?.split('/')) {
               let componentNAme =  child.snapshot['_routerState'].url.split('individual/')[1].split('/');

               console.log(componentNAme,"componentNAme");
               
    
               componentNAme = componentNAme.map(element => {
                  return element.charAt(0).toUpperCase() + element.slice(1);            
               });
                return componentNAme.join(' ');

            } 

            return appTitle


          })
        ).subscribe((ttl: string) => {
          this.titleService.setTitle(ttl);
        });
    }
    
   }