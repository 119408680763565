import { first } from 'rxjs/operators';
import { getCurrentUser } from './../../../authModule/state/authentication.selectors';
import { UserState } from './../../../authModule/state/authentication.reducer';
import { Observable } from 'rxjs';
import { User } from './../../../models/user';
import {
   FormBuilder,
   FormGroup,
   Validators,
   FormControl,
} from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Component, OnInit } from '@angular/core';
import * as authActions from '../../../authModule/state/actions/authentication.actions';
import { AuthService } from '../../../services/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import Swal from 'sweetalert2';

@Component({
   selector: 'app-personal-information',
   templateUrl: './personal-information.component.html',
   styleUrls: ['./personal-information.component.scss'],
})
export class PersonalInformationComponent implements OnInit {
   userInfoForm: FormGroup;
   user_details;
   userFirst_name;
   userLast_name;
   userEmail;
   user;
   getState: Observable<any>;
   isAuthenticated: boolean = false;
   name;
   authState$;
   userState;

   /**
    * Component Constructor
    */
   constructor(
      private authServiceService: AuthService,
      private store: Store<UserState>,
      private fb: FormBuilder,
      private authService: AuthService,
      private spinner: NgxSpinnerService,
      private toastrService: ToastrService,
      private router: Router,
      private userService: UserService
   ) {
      // this.getState = this.store.select(selectAuthenticationState);
   }
   /**
    * Method used to be called whenever the component is loaded and all the methods in it, will be triggered.
    */
   ngOnInit(): void {
      this.userInfoForm = this.fb.group({
         firstName: [
            '',
            [
               Validators.required,
               Validators.pattern('^[a-zA-Z ]*$'),
               Validators.minLength(3),
            ],
         ],
         lastName: [
            '',
            [
               Validators.required,
               Validators.pattern('^[a-zA-Z ]*$'),
               Validators.minLength(3),
            ],
         ],
         email: ['', [Validators.required, Validators.email]],
      });

      /**
       * Used to select currentUser from the store
       */
      this.user = this.store
         .select(getCurrentUser)
         .pipe(first())
         .subscribe((currentUser) => this.displayUser(currentUser));
   }

   /**
    * Method used to display current User info
    * @param user
    */
   displayUser(user: User | null) {
      this.name = user.name.split(' ');
      this.userInfoForm.patchValue({
         firstName: this.name[0],
         lastName: this.name[1],
         email: user.email,
      });
   }
   /**
    * Method used ot get from group controls
    */
   get form() {
      return this.userInfoForm.controls;
   }

   async updateUserInfo() {
      const result = await Swal.fire({
         title: 'Are you sure ?',
         icon: 'warning',
         showCancelButton: true,
         confirmButtonColor: '#3085d6',
         cancelButtonColor: '#b5adad',
         confirmButtonText: 'Yes, update info!',
      });
      if (result.isConfirmed) {
         this.store.dispatch(
            authActions.updateUserInfo({
               firstName: this.userInfoForm.value.firstName,
               lastName: this.userInfoForm.value.lastName,
            })
         );
      }
   }
}
