<section class="flex register-profesori container">
   <main>
      <h4 class="bold">Register departments</h4>
      <div class="nav">
         <a (click)="toggleForm('manual')" [ngClass]="{ active: this.excel === false }"
            >Manual form</a
         >
         <a (click)="toggleForm('bulk')" [ngClass]="{ active: this.excel === true }" class="disable"
            >Bulk form</a
         >
      </div>
      <ng-container *ngIf="this.excel === false">
         <form action="" [formGroup]="departmentRegister">
            <ng-container formArrayName="departments">
               <ng-container
                  *ngFor="
                     let faculty of departmentsForm.controls;
                     let i = index
                  "
                  [formGroupName]="i"
               >
                  <div class="departmentInfo">
                     <div style="width: 100%">
                        <h5>Department name</h5>
                        <input formControlName="departmentName" type="text" />
                        <div
                           *ngIf="
                              departmentsForm.controls[i].controls
                                 .departmentName.touched &&
                              departmentsForm.controls[i].controls
                                 .departmentName.invalid
                           "
                           class="invalid"
                        >
                           Department Name is required*
                        </div>
                        <div
                           *ngIf="
                              departmentsForm.controls[i].controls
                                 .departmentName.errors?.minlength
                           "
                           class="invalid"
                        >
                           Department name should contain at least 3 characters!
                        </div>
                        <h5>Duration (in years)</h5>
                        <select formControlName="departmentYear">
                           <!-- <option value="-1" selected disabled>
                              Choose years
                           </option> -->
                           <option value="1">1</option>
                           <option value="2">2</option>
                           <option value="3">3</option>
                           <option value="4">4</option>
                           <option value="5">5</option>
                        </select>
                        <div
                           *ngIf="
                              departmentsForm.controls[i].controls
                                 .departmentYear.touched &&
                              departmentsForm.controls[i].controls
                                 .departmentYear.invalid
                           "
                           class="invalid"
                        >
                           Department duration is required*
                        </div>
                        <h5>School</h5>
                        <select formControlName="departmentFaculty">
                           <option selected disabled value="">
                              Select school
                           </option>

                           <ng-container
                              *ngFor="
                                 let faculty of this.facultiesDetails?.faculties
                              "
                           >
                              <option value="{{ faculty.id }}">
                                 {{ faculty.name }}
                              </option>
                           </ng-container>
                        </select>
                        <div
                           *ngIf="
                              departmentsForm.controls[i].controls
                                 .departmentFaculty.touched &&
                              departmentsForm.controls[i].controls
                                 .departmentFaculty.invalid
                           "
                           class="invalid"
                        >
                           Department School is required*
                        </div>
                        <h5>Level of studies</h5>
                        <select formControlName="departmentLevel">
                           <!-- <option value="-1" selected disabled>
                              Choose level
                           </option> -->
                           <option value="1">Bachelor</option>
                           <option value="2">Master</option>
                           <option value="3">Ph.D.</option>
                        </select>
                        <div
                           *ngIf="
                              departmentsForm.controls[i].controls
                                 .departmentLevel.touched &&
                              departmentsForm.controls[i].controls
                                 .departmentLevel.invalid
                           "
                           class="invalid"
                        >
                           Department Level is required*
                        </div>
                        <h5>Description</h5>
                        <textarea
                           formControlName="departmentDescription"
                           name=""
                           id=""
                           cols="30"
                           rows="2"
                        ></textarea>
                        <div
                           *ngIf="
                              departmentsForm.controls[i].controls
                                 .departmentDescription.touched &&
                              departmentsForm.controls[i].controls
                                 .departmentDescription.invalid
                           "
                           class="invalid"
                        >
                           Department Description is required*
                        </div>
                        <div
                           *ngIf="
                              departmentsForm.controls[i].controls
                                 .departmentDescription.errors?.minlength
                           "
                           class="invalid"
                        >
                           Department description should contain at least 3 characters!
                        </div>
                     </div>
                     <div>
                        <ng-container
                           *ngIf="departmentsForm.controls.length > 1"
                        >
                           <button
                              class="removeFaculty"
                              (click)="this.removeDepartment(i)"
                           >
                              - Remove
                           </button>
                        </ng-container>
                     </div>
                  </div>
               </ng-container>
            </ng-container>
            <div>
               <button (click)="this.addDepartment()" class="addMore">
                  + Add a new department
               </button>
            </div>
            <div class="center" [ngClass]="{ flex: dialogRef }">
               <button
                  class="submit cancelButton"
                  *ngIf="dialogRef"
                  (click)="closeModal()"
               >
                  Cancel
               </button>
               <button
                  [disabled]="departmentRegister.invalid"
                  [ngClass]="{
                     disable: departmentRegister.invalid
                  }"
                  type="submit"
                  (click)="submit()"
                  class="submit"
               >
                  Register
               </button>
            </div>
         </form>
      </ng-container>
      <ng-container *ngIf="this.excel === true">
         <div class="content">
            <div>
               <select class="border" name="" id="">
                  <option value="">1</option>
                  <option value="">2</option>
               </select>
               <div class="border">
                  <h6>Download Template</h6>
                  <div>
                     <i class="fas fa-download"></i>
                     <p>
                        Download and fill the relevant information within the
                        template
                     </p>
                  </div>
               </div>
            </div>
            <div>
               <select class="border" name="" id="">
                  <option value=""></option>
                  <option value=""></option>
               </select>
               <div class="border">
                  <div>
                     <h6>Upload Template</h6>
                     <i class="fas fa-upload"></i>
                  </div>
                  <p>
                     Upload the filled template to migrate your data
                     automatically
                  </p>
               </div>
            </div>
         </div>
      </ng-container>
   </main>
</section>
