// *** Angular
import { Component, OnInit } from '@angular/core';

@Component({
   selector: 'app-administrator-users',
   templateUrl: './administrator-users.component.html',
   styleUrls: ['./administrator-users.component.scss'],
})
export class AdministratorUsersComponent implements OnInit {
   constructor() {}

   ngOnInit(): void {}
}
