import { Action, createReducer, on } from '@ngrx/store';

import * as filtersActions from '../action/filters.actions';

export const filtersFeatureKey = 'filters';

export interface FiltersState {
   departmentsFilters: {
      faculty: any;
   };
   subjectsFilters: {
      faculty?: any;
      department?: any;
      professor?: any;
      student?: any;
   };
   assignmentFilters: {
      faculty?: any;
      department?: any;
      subject?: any;
      professor?: any;
   };
   professorsFilters: {
      faculty?: any;
      department?: any;
      subject?: any;
   };
   studentsFilters: {
      faculty?: any;
      department?: any;
      subject?: any;
   };
   assignmentSubmissionsFilters: {
      faculty?: any;
      department?: any;
      professor?: any;
      subject?: any;
      student?: any;
      assignment?: any;
   };
   professorSubmissionsFilters: {
      faculty?: any;
      department?: any;
      professor?: any;
   };
   thesisSubmissionsFilters: {
      faculty?: any;
      department?: any;
      professor?: any;
      student?: any;
   };
}

export const initialState: FiltersState = {
   departmentsFilters: {
      faculty: null,
   },
   subjectsFilters: {
      faculty: null,
      department: null,
      professor: null,
      student: null,
   },
   assignmentFilters: {
      faculty: null,
      department: null,
      subject: null,
      professor: null,
   },
   professorsFilters: {
      faculty: null,
      department: null,
      subject: null,
   },
   studentsFilters: {
      faculty: null,
      department: null,
      subject: null,
   },
   assignmentSubmissionsFilters: {
      faculty: null,
      department: null,
      professor: null,
      subject: null,
   },
   thesisSubmissionsFilters: {
      faculty: null,
      department: null,
      professor: null,
      student: null,
   },
   professorSubmissionsFilters: {
      faculty: null,
      department: null,
   },
};

export const FiltersReducer = createReducer(
   initialState,
   on(filtersActions.departmentsFilters, (state, action): FiltersState => {
      return {
         ...state,
         departmentsFilters: {
            faculty: action.faculty,
         },
      };
   }),
   on(filtersActions.subjectsFilters, (state, action): FiltersState => {
      return {
         ...state,
         subjectsFilters: {
            faculty: action.faculty,
            department: action.department,
            professor: action.professor,
            student: action.student,
         },
      };
   }),
   on(filtersActions.assignmentFilters, (state, action): FiltersState => {
      return {
         ...state,
         assignmentFilters: {
            faculty: action.faculty,
            department: action.department,
            subject: action.subject,
            professor: action.professor,
         },
      };
   }),
   on(filtersActions.professorsFilters, (state, action): FiltersState => {
      return {
         ...state,
         professorsFilters: {
            faculty: action.faculty,
            department: action.department,
            subject: action.subject,
         },
      };
   }),
   on(filtersActions.studentsFilters, (state, action): FiltersState => {
      return {
         ...state,
         studentsFilters: {
            faculty: action.faculty,
            department: action.department,
            subject: action.subject,
         },
      };
   }),
   on(
      filtersActions.assignmentSubmissionsFilters,
      (state, action): FiltersState => {
         return {
            ...state,
            assignmentSubmissionsFilters: {
               faculty: action.faculty,
               department: action.department,
               professor: action.professor,
               subject: action.subject,
               student: action.student,
               assignment: action.assignment,
            },
         };
      }
   ),
   on(
      filtersActions.thesisSubmissionsFilters,
      (state, action): FiltersState => {
         return {
            ...state,
            thesisSubmissionsFilters: {
               faculty: action.faculty,
               department: action.department,
               professor: action.professor,
               student: action.student,
            },
         };
      }
   ),
   on(
      filtersActions.professorSubmissionsFilters,
      (state, action): FiltersState => {
         return {
            ...state,
            professorSubmissionsFilters: {
               faculty: action.faculty,
               department: action.department,
               professor: action.professor
            },
         };
      }
   )
);
