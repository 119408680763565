import { Injectable } from '@angular/core';
import * as iso6391 from 'iso-639-1';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  findLanguageName(languageCode: string): string {
    try {
      return iso6391.default.getName(languageCode) || 'Language not found';
    } catch (error) {
      console.error('Error while finding language name:', error);
      return 'Language not found';
    }
  }
}
