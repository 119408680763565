import * as AppState from '../../../../../../store/app.state';
import * as thesisSubmissionsActions from '../action/thesis-statistics.actions';
import { createReducer } from '@ngrx/store';
import { on } from '@ngrx/store';

/**
 * assignment reducer used to updated store values based on certain action.
 */

/**
 * State interface extends App state State and add user state to the main state.
 */
export interface State extends AppState.State {
   thesisSubmissionsStatistics: thesisSubmissionsStatisticsState;
}

/**
 * An SubjectState interface, which represents the blueprint of the User State.
 */
export interface thesisSubmissionsStatisticsState {
   thesisSubmissionsStatistics: any;
   thesisSubmissionsDetails: any;
   thesisNumber: number;
   errorMessage: string;
}

/**
 * SubjectState initial state.
 */
export const initialState: thesisSubmissionsStatisticsState = {
   thesisSubmissionsStatistics: null,
   thesisSubmissionsDetails: null,
   thesisNumber: null,
   errorMessage: null,
};

/**
 * Subject Reducer used to update the state upon Subject actions.
 */
export const thesisSubmissionsStatisticsReducer = createReducer(
   initialState,
   /**
    * On loadThesisSubmissionStatisticsSuccess action updated current state with the following properties.
    */
   on(
      thesisSubmissionsActions.loadThesisSubmissionStatisticsSuccess,
      (state, action): thesisSubmissionsStatisticsState => {
         return {
            ...state,
            thesisSubmissionsStatistics: action.data.thesisDetails,
            thesisNumber: action.data.thesisNumber,
         };
      }
   ),

   /**
    * On loadThesisSubmissionStatisticsFailure action updated current state with the following properties.
    */
   on(
      thesisSubmissionsActions.loadThesisSubmissionStatisticsFailure,
      (state, action): thesisSubmissionsStatisticsState => {
         return {
            ...state,
            errorMessage: action.error,
         };
      }
   ),

   /**
    * On loadThesisSubmissionStatisticsSuccess action updated current state with the following properties.
    */
   on(
      thesisSubmissionsActions.loadThesisSubmissionDetailsSuccess,
      (state, action): thesisSubmissionsStatisticsState => {
         return {
            ...state,
            thesisSubmissionsDetails: action.data.submissions,
         };
      }
   ),

   /**
    * On loadThesisSubmissionStatisticsFailure action updated current state with the following properties.
    */
   on(
      thesisSubmissionsActions.loadThesisSubmissionDetailsFailure,
      (state, action): thesisSubmissionsStatisticsState => {
         return {
            ...state,
            errorMessage: action.error,
         };
      }
   )
);
