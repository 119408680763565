import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ReportState } from '../reducer/report.reducer';
/**
 * Selectors are pure functions used for obtaining slices of store state.
 */

/**
 * getReportFeatureState represent top level of the report state.
 * The createFeatureSelector is a convenience method for returning a top level feature state.
 * It is used by other selectors to get slices of state from report state.
 */
const reportState = createFeatureSelector<ReportState>('currentSubmission');

/**
 * Selector used to get current submission state slice from report state.
 * */
export const getCurrentSubmission = createSelector(
   reportState,
   (state) => state.submission
);
/**
 * Selector used to get current active sources in report.
 * @param sourceType
 */
export const getActiveSource = (sourceType: boolean) =>
   createSelector(reportState, (state) => {
      let arrayToReturn;
      if (sourceType == true) {
         arrayToReturn = [state.sources, state.excludedSourcesMlPlag];
         return arrayToReturn;
      } else {
         arrayToReturn = [state.mlSources, state.excludedSourcesCrossPlag];
         return arrayToReturn;
      }
   });
/**
 * Selector used to get navigation data of current active sources in report.
 * @param sourceType
 */
export const getActiveSourceNav = (sourceType: boolean) =>
   createSelector(reportState, (state) => {
      if (sourceType == true) {
         return state.originalNavigationArray;
      } else {
         return state.translatedNavigationArray;
      }
   });

/**
 * Selector used to get current submission state slice from report state.
 * */
export const getSources = createSelector(reportState, (state) => state.sources);

/**
 * Selector used to get current submission state slice from report state.
 * */
export const getMLSources = createSelector(
   reportState,
   (state) => state.mlSources
);

/**
 * Selector used to get current submission state slice from report state.
 * */
export const getCurrentSource = (
   sourceType: boolean,
   currentSentenceId: number
) =>
   createSelector(reportState, (state) => {
      if (sourceType == true) {
         return state.sources.find((source) => {
            for (const sentence of source.sentences) {
               if (sentence.id == currentSentenceId) {
                  return sentence;
               }
            }
         });
      } else {
         return state.mlSources.find((source) => {
            for (const sentence of source.sentences) {
               if (sentence.id == currentSentenceId) {
                  return sentence;
               }
            }
         });
      }
   });

/**
 * Selector used to get current submission state slice from report state.
 * */
export const selectedSourceToUpdate = createSelector(
   reportState,
   (state, props) => {
      return state.sources.filter((source) => source.id == props.id);
   }
);
