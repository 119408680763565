import * as AppState from '../../../store/app.state';
import * as individualAdministratorActions from '../action/individualAdministrator.actions';
import { createReducer } from '@ngrx/store';
import { on } from '@ngrx/store';
import { User } from 'src/app/models/user';
import { Submissions } from 'src/app/models/submissions';

/**
 * individualAdministrator reducer used to updated store values based on certain action.
 */

/**
 * An individualAdministratorState interface, which represents the blueprint of the User State.
 */
export interface individualAdministratorState {
   students: {
      users: User[];
      page: number;
      totalStudents: number;
   };
   dashboard: {
      totalDocuments: number;
      highSimilarity: number;
      mediumSimilarity: number;
      lowSimilarity: number;
      totalResubmissions: number;
      resubmissionsHighSimilarity: number;
      resubmissionsMediumSimilarity: number;
      resubmissionsLowSimilarity: number;
      Submissions: {
         submissions: Submissions[];
         page: number;
      };
   };
   statistics: {
      Submissions: {
         submissions: Submissions[];
         page: number;
         totalSubmissions: number;
      };
      avgWordsPerDoc: number;
      avgMlPlagSimilarity: number;
      avgCrossPlagSimilarity: number;
      graph: {
         documentsPerMonth: [];
         wordsPerMonth: [];
         chartDataReSubmissions: [];
      };
      averageResubmissionsPlag: {
         originalPercentage: number;
         translatedPercentage: number;
         wordCount: number;
      };
      totalStudents: number;
      activeStudents: number;
      inActiveStudents: number;
   };
   documentSubmissions: any;
   errorMessage: string;
}

/**
 * individualAdministratorState initial state.
 */
export const initialState: individualAdministratorState = {
   students: null,
   dashboard: null,
   statistics: null,
   errorMessage: null,
   documentSubmissions: null,
};

/**
 * Subject Reducer used to update the state upon Subject actions.
 */
export const IndividualAdministratorReducer = createReducer(
   initialState,

   /**
    * On loadSubjectsDetailsSuccess action updated current state with the following properties.
    */
   on(
      individualAdministratorActions.loadStudentsListsSuccess,
      (state, action): individualAdministratorState => {
         return {
            ...state,
            students: {
               users: action.students,
               page: 1,
               totalStudents: action.totalStudents,
            },
         };
      }
   ),

   /**
    * On loadSubjectsDetailsFailure action updated current state with the following properties.
    */
   on(
      individualAdministratorActions.loadStudentsListsFailure,
      (state, action): individualAdministratorState => {
         return {
            ...state,
            errorMessage: action.error,
         };
      }
   ),

   /**
    * On loadSubjectsDetailsSuccess action updated current state with the following properties.
    */
   on(
      individualAdministratorActions.administratorIndividualDashboardDetailsSuccess,
      (state, action): individualAdministratorState => {
         return {
            ...state,
            dashboard: {
               totalDocuments: action.dashboard.submissionsNumber,
               highSimilarity: action.dashboard.highSimilarity,
               mediumSimilarity: action.dashboard.mediumSimilarity,
               lowSimilarity: action.dashboard.lowSimilarity,
               resubmissionsHighSimilarity:
                  action.dashboard.resubmissionsHighSimilarity,
               resubmissionsMediumSimilarity:
                  action.dashboard.resubmissionsMediumSimilarity,
               resubmissionsLowSimilarity:
                  action.dashboard.resubmissionsLowSimilarity,
               totalResubmissions: action.dashboard.totalResubmissions,

               Submissions: {
                  submissions: action.dashboard.submissions,
                  page: action.dashboard.pages,
               },
            },
            statistics: {
               averageResubmissionsPlag: {
                  originalPercentage:
                     state.statistics?.averageResubmissionsPlag
                        .originalPercentage,
                  translatedPercentage:
                     state.statistics?.averageResubmissionsPlag
                        .translatedPercentage,
                  wordCount:
                     state.statistics?.averageResubmissionsPlag.wordCount,
               },
               Submissions: {
                  submissions: action.dashboard.submissions,
                  page: action.dashboard.pages,
                  totalSubmissions: action.dashboard.submissionsNumber,
               },
               avgWordsPerDoc: state.statistics?.avgWordsPerDoc,
               avgMlPlagSimilarity: state.statistics?.avgMlPlagSimilarity,
               avgCrossPlagSimilarity: state.statistics?.avgCrossPlagSimilarity,
               graph: {
                  documentsPerMonth: state.statistics?.graph.documentsPerMonth,
                  wordsPerMonth: state.statistics?.graph.wordsPerMonth,
                  chartDataReSubmissions:
                     state.statistics?.graph.chartDataReSubmissions,
               },
               totalStudents: state.statistics?.totalStudents,
               activeStudents: state.statistics?.activeStudents,
               inActiveStudents: state.statistics?.inActiveStudents,
            },
         };
      }
   ),

   /**
    * On loadSubjectsDetailsFailure action updated current state with the following properties.
    */
   on(
      individualAdministratorActions.administratorIndividualDashboardDetailsFailure,
      (state, action): individualAdministratorState => {
         return {
            ...state,
            errorMessage: action.error,
         };
      }
   ),

   /**
    * On loadSubjectsDetailsSuccess action updated current state with the following properties.
    */
   on(
      individualAdministratorActions.SubmissionsStatisticsDetailsSuccess,
      (state, action): individualAdministratorState => {
         return {
            ...state,
            statistics: {
               averageResubmissionsPlag: {
                  originalPercentage:
                     state.statistics?.averageResubmissionsPlag
                        .originalPercentage,
                  translatedPercentage:
                     state.statistics?.averageResubmissionsPlag
                        .translatedPercentage,
                  wordCount:
                     state.statistics?.averageResubmissionsPlag.wordCount,
               },
               Submissions: {
                  submissions: action.statistics.submissions,
                  page: action.statistics.pages,
                  totalSubmissions: action.statistics.submissionsNumber,
               },
               avgWordsPerDoc: state.statistics?.avgWordsPerDoc,
               avgMlPlagSimilarity: state.statistics?.avgMlPlagSimilarity,
               avgCrossPlagSimilarity: state.statistics?.avgCrossPlagSimilarity,
               graph: {
                  documentsPerMonth: state.statistics?.graph.documentsPerMonth,
                  wordsPerMonth: state.statistics?.graph.wordsPerMonth,
                  chartDataReSubmissions:
                     state.statistics?.graph.chartDataReSubmissions,
               },
               totalStudents: state.statistics?.totalStudents,
               activeStudents: state.statistics?.activeStudents,
               inActiveStudents: state.statistics?.inActiveStudents,
            },
         };
      }
   ),

   /**
    * On loadSubjectsDetailsSuccess action updated current state with the following properties.
    */
   on(
      individualAdministratorActions.GeneralStatisticsDetailsSuccess,
      (state, action): individualAdministratorState => {
         return {
            ...state,
            statistics: {
               averageResubmissionsPlag: {
                  originalPercentage:
                     action.statistics?.averageResubmissionsPlag
                        .originalPercentage,
                  translatedPercentage:
                     action.statistics?.averageResubmissionsPlag
                        .translatedPercentage,
                  wordCount:
                     action.statistics?.averageResubmissionsPlag.wordCount,
               },
               Submissions: state.statistics?.Submissions,
               avgWordsPerDoc: action.statistics.avgWords,
               avgMlPlagSimilarity:
                  action.statistics.avgMlPagSubmissionsPercentage,
               avgCrossPlagSimilarity:
                  action.statistics.avgCrossPlagSubmissionsPercentage,
               graph: {
                  documentsPerMonth: action.statistics.documentsPerMonth,
                  wordsPerMonth: action.statistics.wordsPerMonth,
                  chartDataReSubmissions:
                     action.statistics.chartDataReSubmissions,
               },
               totalStudents:
                  action.statistics.activeStudents +
                  action.statistics.unActiveStudents,
               activeStudents: action.statistics.activeStudents,
               inActiveStudents: action.statistics.unActiveStudents,
            },
         };
      }
   ),

   /**
    * On loadSubjectsDetailsFailure action updated current state with the following properties.
    */
   on(
      individualAdministratorActions.getDocumentSubmissionsFailure,
      (state, action): individualAdministratorState => {
         return {
            ...state,
            errorMessage: action.error,
         };
      }
   ),
   on(
      individualAdministratorActions.getDocumentSubmissionsSuccess,
      (state, action): individualAdministratorState => {
         return {
            ...state,
            documentSubmissions: action.documentSubmissions,
         };
      }
   )
);
