import { createAction, props } from '@ngrx/store';

export const loadRequests = createAction(
   '[Request] Load Professor Requests',
   props<{
      requestType?: any;
      isChecked?: any;
      startDate?: any;
      endDate?: any;
      page: any;
   }>()
);

export const loadRequestsSuccess = createAction(
   '[Request] Load Professor Requests Success',
   props<{ data: any }>()
);

export const loadRequestsFailure = createAction(
   '[Request] Load Professor Requests Failure',
   props<{ error: any }>()
);

export const approveRequests = createAction(
   '[Request] Approve Requests',
   props<{
      requestId: any;
      isApproved: any;
      isChecked: any;
   }>()
);

export const approveRequestsSuccess = createAction(
   '[Request] Approve Requests Success',
   props<{ data: any }>()
);

export const approveRequestsFailure = createAction(
   '[Request] Approve Requests Failure',
   props<{ error: any }>()
);
