import { createFeatureSelector, createSelector } from '@ngrx/store';
import { assignmentsStatisticsState } from '../reducer/units-statistics-assignments.reducer';

/**
 * Selectors are pure functions used for obtaining slices of store state.
 */

/**
 * getAssignmentsStatistics represent top level of the assignment state.
 * The createFeatureSelector is a convenience method for returning a top level feature state.
 */
const getAssignmentsStatistics =
   createFeatureSelector<assignmentsStatisticsState>('assignmentsStatistics');

/**
 * Selector used to get statisticsOverview details state.
 */
export const getAssignmentsStatisticsDetails = createSelector(
   getAssignmentsStatistics,
   (state) => state
);
