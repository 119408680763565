import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-email-confirmation-send',
  templateUrl: './email-confirmation-send.component.html',
  styleUrls: ['./email-confirmation-send.component.scss']
})
export class EmailConfirmationSendComponent implements OnInit {

  constructor( public route: ActivatedRoute,private spinner: NgxSpinnerService,  private userService: UserService,private toastrService: ToastrService) { }
  email;

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.email = params.email;
   });
  }

  resendEmail() {
    
    this.spinner.show();

    this.userService
       .signUp(
          '',
          '',
          this.email,
          '',
          false,
          '',
          '',
          '',
          '',
          '',
       )
       .pipe(first())
       .subscribe(
          (data) => {
            this.spinner.hide();
            this.toastrService.success('Email is successfully resend');
          },
          (error) => {
             this.spinner.hide();
             console.log('error', error);
          }
       );
 }
}
