import { createAction, props } from '@ngrx/store';

export const loadStudentsStatistics = createAction(
   '[ Students Statistics] Load  Students Statistics',
   props<{
      page?: any;
      facultyId?: string;
      departmentId?: string;
      studentName?: string;
      subject?: string;
   }>()
);

export const loadStudentsStatisticsSuccess = createAction(
   '[ Students Statistics] Load  Students Statistics Success',
   props<{ data: any }>()
);

export const loadStudentsStatisticsFailure = createAction(
   '[ Students Statistics] Load  Students Statistics Failure',
   props<{ error: any }>()
);
