import { AuthService } from './auth.service';
import { HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
/**
 * Service used to add token to api request.
 */
@Injectable({
   providedIn: 'root',
})
export class JwtInterceptorService {
   /**
    * Service constructor
    * @param authService
    */
   constructor(private authService: AuthService) {}
   intercept(
      request: HttpRequest<any>,
      next: HttpHandler
   ): Observable<HttpEvent<any>> {
      // add auth header with jwt if user is logged in and request is to api url
      const currentUser = this.authService.getCurrentUser();
      const isUserLoggedIn = this.authService.isLoggedIn();
      const userToken = this.authService.getUserToken();
      const isLoggedIn = currentUser && isUserLoggedIn == true;
      const isApiUrl = request.url.startsWith(environment.apiUrl);
      if (isLoggedIn && isApiUrl) {
         request = request.clone({
            setHeaders: {
               Authorization: `Bearer ${userToken}`,
            },
         });
      }

      return next.handle(request);
   }
}
