import { createFeatureSelector, createSelector } from '@ngrx/store';
import { facultiesStatisticsState } from '../reducer/units-statistics-faculties.reducer';

/**
 * Selectors are pure functions used for obtaining slices of store state.
 */

/**
 * getFacultiesStatistics represent top level of the subject state.
 * The createFeatureSelector is a convenience method for returning a top level feature state.
 */
const getFacultiesStatistics = createFeatureSelector<facultiesStatisticsState>(
   'facultiesStatistics'
);

/**
 * Selector used to get statisticsOverview details state.
 */
export const getFacultiesStatisticsDetails = createSelector(
   getFacultiesStatistics,
   (state) => state
);
