<h4>Edit student</h4>
<form [formGroup]="studentEdit">
   <div class="editimi-fakultetit-inputs">
      <div class="inside-inputs">
         <label for="studentName">Name</label>
         <input formControlName="studentName" type="text" />
      </div>
      <div
         *ngIf="
            studentEdit.controls.studentName.touched &&
            studentEdit.controls.studentName.invalid
         "
         class="invalid"
      >
         Student name is required*
      </div>
      <div
         *ngIf="studentEdit.controls.studentName.errors?.minlength"
         class="invalid"
      >
         Student name should contain at least 3 characters!
      </div>

      <div class="inside-inputs">
         <label for="studentEmail">Email</label>
         <input
            formControlName="studentEmail"
            type="email"
            [readonly]="this.selectedUser.status === 1 ? true : false"
         />
      </div>
      <div
         *ngIf="
            studentEdit.controls.studentEmail.touched &&
            studentEdit.controls.studentEmail.invalid
         "
         class="invalid"
      >
         Email is required*
      </div>

      <div
         *ngIf="studentEdit.controls.studentEmail.errors?.minlength"
         class="invalid"
      >
         Student email should contain at least 3 characters!
      </div>
      <div class="inside-inputs">
         <label for="documentLanguage">Document Language</label>
         <select
            name=""
            id=""
            formControlName="documentLanguage"
            (change)="this.languageSelected(1)"
         >
            <option value="-1">Choose Language</option>
            <ng-container *ngFor="let language of this.languages">
               <option
                  value="{{ language.value }}"
                  *ngIf="language.selectedTranslateLanguage === false"
               >
                  {{ language.text }}
               </option>
            </ng-container>
         </select>
      </div>

      <div class="inside-inputs">
         <label for="translateTo">Translate to</label>
         <select
            name=""
            id=""
            formControlName="translateTo"
            (change)="this.languageSelected(2)"
         >
            <option value="-1">Choose Language</option>
            <ng-container *ngFor="let language of this.languages">
               <option
                  value="{{ language.value }}"
                  *ngIf="language.selectedDocLanguage === false"
               >
                  {{ language.text }}
               </option>
            </ng-container>
         </select>
      </div>
   </div>
   <div
      class="editimi-fakultetit-fshij-perfundo"
      style="display: flex; justify-content: space-evenly"
   >
      <button
         [disabled]="studentEdit.invalid"
         [ngClass]="{
            disable: studentEdit.invalid
         }"
         type="submit"
         class="submit"
         (click)="update()"
      >
         Confirm
      </button>
      <button
         [disabled]="this.selectedUser.status === 1 ? true : false"
         [ngClass]="{
            disable: this.selectedUser.status === 1 ? true : false
         }"
         class="submit"
         (click)="resendActivationEmail()"
      >
         Ri dergo email'in konfirmues
      </button>
   </div>
</form>
