<div class="cards">
   <div routerLink="professors" routerLinkActive="active" class="shadowBorder">
      <span
         ><img src="../../../../assets/images/newIcons/20.Professor_Color.png" alt="" />
         <img src="../../../../assets/images/newIcons/20.Professor_White.png" alt="" />
      </span>
      <div>
         Educators
         <span> {{ this.overview?.professors || 0 }} </span>
      </div>
   </div>
   <div routerLink="students" routerLinkActive="active" class="shadowBorder">
      <span
         ><img src="../../../../assets/images/newIcons/22.Student_Color.png" alt="" />
         <img src="../../../../assets/images/newIcons/22.Student_White.png" alt="" />
      </span>
      <div>
         Students
         <span>{{ this.overview?.students || 0 }}</span>
      </div>
   </div>
</div>
<router-outlet></router-outlet>
