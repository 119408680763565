<h3
   *ngIf="!(this.facultiesDetails?.totalFaculties == 0 && name == undefined)"
   class="filtersContainer"
>
   <h4>Schools</h4>
   <div class="selectors">
      <div>
         <input
            placeholder="Search by name"
            [(ngModel)]="name"
            (ngModelChange)="titleFilter()"
            type="text"
            name="searchByName"
         />
      </div>
      <button class="reportBtn" (click)="resetFilter()">Reset</button>
   </div>
</h3>
<div *ngIf="this.facultiesDetails?.totalFaculties != 0">
   <table class="table-university">
      <tr>
         <th class="bold">Name</th>
         <th class="bold">Description</th>
         <th class="bold">Departments</th>
         <th class="bold"> Submissions</th>
         <th class="bold">Edit</th>
      </tr>
      <tr
         class="shadow"
         *ngFor="
            let faculty of this.facultiesDetails?.faculties
               | paginate
                  : {
                       itemsPerPage: pageSize,
                       currentPage: page,
                       totalItems: this.facultiesDetails?.totalFaculties
                    }
         "
      >
         <td>
            {{ faculty.name }}
         </td>
         <td [title]="faculty.description" class="addEllipsis">
            {{ faculty.description }}
         </td>
         <td>
            {{ faculty.Department.length }}
         </td>
         <td>
            {{ faculty.submissions || 0 }}
         </td>
         <td>
            <i (click)="openModal(faculty.id)" class="far fa-edit editButton"></i>
         </td>
      </tr>
   </table>
</div>
<div class="paginationContainer">
   <div></div>
   <pagination-controls
   *ngIf="this.facultiesDetails?.totalFaculties != 0"
   class="num"
   (pageChange)="changePage($event)"
   responsive="true"
   previousLabel=""
   nextLabel=""
></pagination-controls>
   <div class="center"
   *ngIf="!(this.facultiesDetails?.totalFaculties == 0 && name == undefined)"
   >
      <button class="reportBtn" (click)="registerFacultyModal()" >
         <i class="fas fa-plus"></i>Register school
      </button>
   </div>
</div>


<div
   class="zeroState"
   *ngIf="this.facultiesDetails?.totalFaculties == 0 && name != undefined"
>
   No schools found
</div>
<div
   class="zero-state"
   *ngIf="this.facultiesDetails?.totalFaculties == 0 && name == undefined"
>
   <!-- <img src="../../assets/images/icona-image.png" alt="" /> -->
   <h5 class="bold">There are no schools registered in your institution yet.<br/><span class="clickable" routerLink="/administrator/register-module/faculty-register">Click here</span> to begin registering your first school.</h5>
</div>
