import { createFeatureSelector, createSelector } from '@ngrx/store';
import { usersStatisticsOverviewState } from '../reducer/users-statistics.reducer';

/**
 * Selectors are pure functions used for obtaining slices of store state.
 */

/**
 * getUsersStatisticsOverview represent top level of the subject state.
 * The createFeatureSelector is a convenience method for returning a top level feature state.
 */
const getUsersStatisticsOverview =
   createFeatureSelector<usersStatisticsOverviewState>('usersStatistics');

/**
 * Selector used to get statisticsOverview details state.
 */
export const getUsersStatisticsOverviewDetails = createSelector(
   getUsersStatisticsOverview,
   (state) => state
);
