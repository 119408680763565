import { Injectable } from '@angular/core';
import {
   CanActivate,
   ActivatedRouteSnapshot,
   UrlTree,
   Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
/**
 * Guard is used to secure routes on client side so if a student tries to have access on professor route the auth guard will not activate requested guard
 */
@Injectable({
   providedIn: 'root',
})
export class AuthGuard implements CanActivate {
   /**
    * Class constructor
    * @param authServiceService
    * @param router
    */
   constructor(
      private authServiceService: AuthService,
      private router: Router
   ) {}
   /**
    * Method used to check if the path can be activated upon specific conditions.
    */
   canActivate(
      route: ActivatedRouteSnapshot
   ):
      | Observable<boolean | UrlTree>
      | Promise<boolean | UrlTree>
      | boolean
      | UrlTree {         
      if (this.authServiceService.isLoggedInRole()) {
         if (this.authServiceService.isLoggedInRole() == route.data.roles) {
            return true;
         } else {
            return this.router.parseUrl('/notauthorized');
         }
      } else {
            return this.router.parseUrl('/sign-up');
      }
   }
}
