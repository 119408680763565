import { Submissions } from 'src/app/models/submissions';
import { createAction, props } from '@ngrx/store';
/**
 * Actions related to Submissions.
 * Each action accepts a set of properties.
 * Each action most of the cases has a success and failure action, that updates the store based on successfully/failure api responses from api.
 */

/**
 * Action used to load submission details.
 */
export const loadSubmissionsDetails = createAction(
   '[Submissions] Load Submissions Details'
);
export const loadSubmissionsDetailsSuccess = createAction(
   '[Submissions] Load Submissions Details Success',
   props<{
      totalSubmissions: number;
      userSuspiciousSubmissions: number;
      userTotalCredits: number;
      userUsedCredits: number;
      totalUserDocuments: number;
      totalUserDocumentsUsed: number;
      totalUserDrafts: number;
      totalUserDraftsUsed: number;
      totalUserSubmissions: number;
      totalUserSubmissionsAll: number;
      userReSubmissions: number;
   }>()
);
export const loadSubmissionsDetailsFailure = createAction(
   '[Submissions] Load Submissions Details Failure',
   props<{ error: any }>()
);
/**
 * Action used to load submissions.
 */
export const loadSubmissions = createAction(
   '[Submissions] Load Submissions',
   props<{
      page: number;
      title?: string;
      filter?: number;
      pageSize?: number;
      reSubmissions?: number;
   }>()
);

export const loadSubmissionsSuccess = createAction(
   '[Submissions] Load Submissions Success',
   props<{
      submissions: Submissions[];
      pages: any;
      submissionsNumber: number;
   }>()
);

export const loadSubmissionsFailure = createAction(
   '[Submissions] Load Submissions Failure',
   props<{ error: any }>()
);

export const loadSubmissionsGraphIndividual = createAction(
   '[Submissions] Load Submissions Graph Individual',
   props<{ year: number }>()
);

export const loadSubmissionsGraphIndividualSuccess = createAction(
   '[Submissions] Load Submissions Graph Individual Success',
   props<{
      graph: [];
   }>()
);

export const loadSubmissionsGraphIndividualFailure = createAction(
   '[Submissions] Load Submissions Graph Individual Failure',
   props<{ error: any }>()
);

/**
 * Action used to load submission graph
 */
export const loadSubmissionsGraph = createAction(
   '[Submissions] Load Submissions Graph',
   props<{ year: number }>()
);

export const loadSubmissionsGraphSuccess = createAction(
   '[Submissions] Load Submissions Graph Success',
   props<{
      submissionsGraph: any;
   }>()
);

export const loadSubmissionsGraphFailure = createAction(
   '[Submissions] Load Submissions Graph Failure',
   props<{ error: any }>()
);
/**
 * Delete submission action used to delete a submission.
 */
export const deleteSubmission = createAction(
   '[Individual Dashboard & Statistics] Delete Submission',
   props<{ id: string; showSubmission: boolean }>()
);

export const deleteSubmissionSuccess = createAction(
   '[Individual Dashboard & Statistics] Delete Submission Success',
   props<{
      submission: Submissions;
   }>()
);

export const deleteSubmissionFailure = createAction(
   '[Individual Dashboard & Statistics] Delete Submission Failure',
   props<{ error: any }>()
);
