import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, catchError, concatMap, switchMap } from 'rxjs/operators';
import { StatisticsService } from 'src/app/services/statistics.service';
import { SubmissionsService } from 'src/app/services/submissions.service';
import { UserService } from 'src/app/services/user.service';
import * as individualAdministratorActions from '../../state/action/individualAdministrator.actions';

@Injectable()
export class StudentsListEffects {
   constructor(
      private actions$: Actions,
      private userService: UserService,
      private submissionService: SubmissionsService,
      private statisticsService: StatisticsService
   ) {}

   /**
    * Effect listening for loadStudentsLists Action, when loadStudentsLists is dispatched the effect with call courses() from courseService.
    * In case of success response the effect it will trigger loadStudentsListsSuccess.
    * In case of Error it will trigger loadStudentsListsFailure with error property.
    */
   getStudentsListDetails$ = createEffect(() => {
      return this.actions$.pipe(
         ofType(individualAdministratorActions.loadStudentsLists),
         concatMap((action) => {
            return this.userService.administratorIndividuals(action.page).pipe(
               map((data) => {
                  return individualAdministratorActions.loadStudentsListsSuccess(
                     {
                        students: data.invitedStudents,
                        totalStudents: data.totalStudents,
                     }
                  );
               }),
               catchError((error) =>
                  of(
                     individualAdministratorActions.loadStudentsListsFailure({
                        error,
                     })
                  )
               )
            );
         })
      );
   });

   /**
    * Effect listening for editStudent Action, when editStudent is dispatched the effect with call courses() from courseService.
    * In case of success response the effect it will trigger editStudentSuccess.
    * In case of Error it will trigger editStudentFailure with error property.
    */
   editStudentDetails$ = createEffect(() => {
      return this.actions$.pipe(
         ofType(individualAdministratorActions.editStudent),
         concatMap((action) => {
            return this.userService
               .editAdministratorIndividual(
                  action.id,
                  action.studentName,
                  action.studentEmail,
                  action.documentLanguage,
                  action.translateTo
               )
               .pipe(
                  switchMap((data) => [
                     individualAdministratorActions.loadStudentsLists({
                        page: action.page,
                     }),
                     individualAdministratorActions.editStudentSuccess({
                        data: data,
                     }),
                  ]),
                  catchError((error) =>
                     of(
                        individualAdministratorActions.inviteStudentFailure({
                           error,
                        })
                     )
                  )
               );
         })
      );
   });

   /**
    * Effect listening for inviteStudent Action, when inviteStudent is dispatched the effect with call courses() from courseService.
    * In case of success response the effect it will trigger inviteStudentSuccess.
    * In case of Error it will trigger inviteStudentFailure with error property.
    */
   inviteStudent$ = createEffect(() => {
      return this.actions$.pipe(
         ofType(individualAdministratorActions.inviteStudent),
         concatMap((action) => {
            return this.userService
               .inviteStudent(
                  action.studentName,
                  action.studentEmail,
                  action.documentLanguage,
                  action.translateTo
               )
               .pipe(
                  switchMap((data) => [
                     individualAdministratorActions.loadStudentsLists({
                        page: 1,
                     }),
                     individualAdministratorActions.inviteStudentSuccess({
                        data: data,
                     }),
                  ]),
                  catchError((error) =>
                     of(
                        individualAdministratorActions.inviteStudentFailure({
                           error,
                        })
                     )
                  )
               );
         })
      );
   });

   /**
    * Effect listening for administratorIndividualDashboardDetails Action, when administratorIndividualDashboardDetails is dispatched the effect with call courses() from courseService.
    * In case of success response the effect it will trigger administratorIndividualDashboardDetailsSuccess.
    * In case of Error it will trigger administratorIndividualDashboardDetailsFailure with error property.
    */
   getAdminDashboardDetails$ = createEffect(() => {
      return this.actions$.pipe(
         ofType(
            individualAdministratorActions.administratorIndividualDashboardDetails
         ),
         concatMap((action) => {
            return this.submissionService
               .individualAdministratorSubmissions(
                  action.page,
                  action.filter,
                  action.title
               )
               .pipe(
                  map((data) => {
                     return individualAdministratorActions.administratorIndividualDashboardDetailsSuccess(
                        {
                           dashboard: data,
                        }
                     );
                  }),
                  catchError((error) =>
                     of(
                        individualAdministratorActions.administratorIndividualDashboardDetailsFailure(
                           {
                              error,
                           }
                        )
                     )
                  )
               );
         })
      );
   });

   /**
    * Effect listening for toggleSubmissionAccess Action, when toggleSubmissionAccess is dispatched the effect with call courses() from courseService.
    * In case of success response the effect it will trigger administratorIndividualDashboardDetails.
    * In case of Error it will trigger toggleSubmissionAccessFailure with error property.
    */
   toggleSubmissionAccess$ = createEffect(() => {
      return this.actions$.pipe(
         ofType(individualAdministratorActions.toggleSubmissionAccess),
         concatMap((action) => {
            return this.submissionService.toggleAccess(action.id).pipe(
               switchMap((data) => [
                  individualAdministratorActions.administratorIndividualDashboardDetails(
                     {
                        page: action.page,
                        title: action.title,
                        filter: action.filter,
                     }
                  ),
                  individualAdministratorActions.getDocumentSubmissions({
                     submissionId: action.id,
                     page: 1,
                  }),
                  individualAdministratorActions.toggleSubmissionAccessSuccess({
                     data: data,
                  }),
               ]),
               catchError((error) =>
                  of(
                     individualAdministratorActions.toggleSubmissionAccessFailure(
                        {
                           error,
                        }
                     )
                  )
               )
            );
         })
      );
   });

   /**
    * Effect listening for toggleDraft Action, when toggleDraft is dispatched the effect with call courses() from courseService.
    * In case of success response the effect it will trigger administratorIndividualDashboardDetails.
    * In case of Error it will trigger toggleDraftFailure with error property.
    */
   toggleDraft$ = createEffect(() => {
      return this.actions$.pipe(
         ofType(individualAdministratorActions.toggleDraft),
         concatMap((action) => {
            return this.submissionService
               .toggleDraft(action.id, action.message)
               .pipe(
                  switchMap((data) => [
                     individualAdministratorActions.administratorIndividualDashboardDetails(
                        {
                           page: 1,
                        }
                     ),
                     individualAdministratorActions.getDocumentSubmissions({
                        submissionId: action.id,
                        page: 1,
                     }),
                     individualAdministratorActions.toggleDraftSuccess({
                        data: data,
                     }),
                  ]),
                  catchError((error) =>
                     of(
                        individualAdministratorActions.toggleSubmissionAccessFailure(
                           {
                              error,
                           }
                        )
                     )
                  )
               );
         })
      );
   });

   /**
    * Effect listening for SubmissionsStatisticsDetails Action, when SubmissionsStatisticsDetails is dispatched the effect with call courses() from courseService.
    * In case of success response the effect it will trigger SubmissionsStatisticsDetailsSuccess.
    * In case of Error it will trigger SubmissionsStatisticsDetailsFailure with error property.
    */
   getAdminStatisticsDetails$ = createEffect(() => {
      return this.actions$.pipe(
         ofType(individualAdministratorActions.SubmissionsStatisticsDetails),
         concatMap((action) => {
            return this.submissionService
               .individualAdministratorSubmissions(
                  action.page,
                  action.filter,
                  action.title
               )
               .pipe(
                  map((data) => {
                     return individualAdministratorActions.SubmissionsStatisticsDetailsSuccess(
                        {
                           statistics: data,
                        }
                     );
                  }),
                  catchError((error) =>
                     of(
                        individualAdministratorActions.SubmissionsStatisticsDetailsFailure(
                           {
                              error,
                           }
                        )
                     )
                  )
               );
         })
      );
   });

   /**
    * Effect listening for GeneralStatisticsDetails Action, when GeneralStatisticsDetails is dispatched the effect with call courses() from courseService.
    * In case of success response the effect it will trigger GeneralStatisticsDetailsSuccess.
    * In case of Error it will trigger GeneralStatisticsDetailsFailure with error property.
    */
   getAdminGeneralStatisticsDetails$ = createEffect(() => {
      return this.actions$.pipe(
         ofType(individualAdministratorActions.GeneralStatisticsDetails),
         concatMap((action) => {
            return this.statisticsService.individualAdministrator().pipe(
               map((data) => {
                  return individualAdministratorActions.GeneralStatisticsDetailsSuccess(
                     {
                        statistics: data,
                     }
                  );
               }),
               catchError((error) =>
                  of(
                     individualAdministratorActions.GeneralStatisticsDetailsFailure(
                        {
                           error,
                        }
                     )
                  )
               )
            );
         })
      );
   });

   /**
    * Effect listening for GeneralStatisticsDetails Action, when GeneralStatisticsDetails is dispatched the effect with call courses() from courseService.
    * In case of success response the effect it will trigger GeneralStatisticsDetailsSuccess.
    * In case of Error it will trigger GeneralStatisticsDetailsFailure with error property.
    */
   getDocumentSubmissions$ = createEffect(() => {
      return this.actions$.pipe(
         ofType(individualAdministratorActions.getDocumentSubmissions),
         concatMap((action) => {
            return this.submissionService
               .getAllDocumentSubmissions(action.submissionId, action.page)
               .pipe(
                  map((data) => {
                     return individualAdministratorActions.getDocumentSubmissionsSuccess(
                        {
                           documentSubmissions: data,
                        }
                     );
                  }),
                  catchError((error) =>
                     of(
                        individualAdministratorActions.getDocumentSubmissionsFailure(
                           {
                              error,
                           }
                        )
                     )
                  )
               );
         })
      );
   });
}
