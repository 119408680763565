<div class="filtersContainer">
   <select name="" id="" (change)="this.filterByYear($event.target.value)">
    <option *ngFor="let currYear of availableYears" [value]="currYear" [selected]="currYear === year">{{ currYear }}</option>
   </select>
</div>
<apx-chart
   [series]="chartOptions.series"
   [chart]="chartOptions.chart"
   [xaxis]="chartOptions.xaxis"
   [dataLabels]="chartOptions.dataLabels"
   [colors]="chartOptions.colors"
></apx-chart>
