import { createAction, props } from '@ngrx/store';

export const overviewStatistics = createAction(
   '[Statistics] Load Overview Statistics',
   props<{ data: any }>()
);

export const overviewStatisticsSuccess = createAction(
   '[Statistics] Load Overview Statistics Success',
   props<{ data: any }>()
);

export const overviewStatisticsFailure = createAction(
   '[Statistics] Load Overview Statistics Failure',
   props<{ error: any }>()
);

export const graphStatistics = createAction(
   '[Statistics] Graph Statistics',
   props<{ year: any; submissionsToShow: any }>()
);

export const graphStatisticsSuccess = createAction(
   '[Statistics] Graph Statistics Success',
   props<{ data: any }>()
);

export const graphStatisticsFailure = createAction(
   '[Statistics] Graph Statistics Failure',
   props<{ error: any }>()
);

export const subjectsStatistics = createAction(
   '[Statistics] Subjects Statistics',
   props<{ title: any; page: any }>()
);

export const subjectsStatisticsSuccess = createAction(
   '[Statistics] Professor Subjects Statistics Success',
   props<{ data: any }>()
);

export const subjectsStatisticsFailure = createAction(
   '[Statistics] Professor Subjects Statistics Failure',
   props<{ error: any }>()
);

export const assignmentsStatistics = createAction(
   '[Statistics] Professor Assignments Statistics',
   props<{ title: any; subject: any; page: any }>()
);

export const assignmentsStatisticsSuccess = createAction(
   '[Statistics] Professor Assignments Statistics Success',
   props<{ data: any }>()
);

export const assignmentsStatisticsFailure = createAction(
   '[Statistics] Professor Assignments Statistics Failure',
   props<{ error: any }>()
);

export const assignmentsSubmissionsStatistics = createAction(
   '[Statistics] Professor Assignments Submissions Statistics',
   props<{ title?: any; subject?: any; assignment?: any; page?: any }>()
);

export const assignmentsSubmissionsStatisticsSuccess = createAction(
   '[Statistics] Professor Assignments Submissions Statistics Success',
   props<{ data: any }>()
);

export const assignmentsSubmissionsStatisticsFailure = createAction(
   '[Statistics] Professor Assignments Submissions Statistics Failure',
   props<{ error: any }>()
);

export const studentsStatistics = createAction(
   '[Statistics] Professor Students Statistics',
   props<{
      name: any;
      department: any;
      subject: any;
      assignment: any;
      page: any;
   }>()
);

export const studentsStatisticsSuccess = createAction(
   '[Statistics] Professor Students Statistics Success',
   props<{ data: any }>()
);

export const studentsStatisticsFailure = createAction(
   '[Statistics] Professor Students Statistics Failure',
   props<{ error: any }>()
);

export const thesisSubmissions = createAction(
   '[Statistics] Professor Thesis Submissions',
   props<{ name: any; status: any; page: any }>()
);

export const thesisSubmissionsSuccess = createAction(
   '[Statistics] Professor Thesis Submissions Success',
   props<{ data: any }>()
);

export const thesisSubmissionsFailure = createAction(
   '[Statistics] Professor Thesis Submissions Failure',
   props<{ error: any }>()
);

export const getAcademicUnits = createAction(
   '[Statistics] Academic Units Submissions'
);

export const getAcademicUnitsSuccess = createAction(
   '[Statistics] Academic Units Success',
   props<{ data: any }>()
);

export const getAcademicUnitsFailure = createAction(
   '[Statistics] Academic Units Failure',
   props<{ error: any }>()
);

export const getTotalSubmissions = createAction(
   '[Statistics]  Total Submissions'
);

export const getTotalSubmissionsSuccess = createAction(
   '[Statistics] Total Submissions Success',
   props<{ data: any }>()
);

export const getTotalSubmissionsFailure = createAction(
   '[Statistics] Total Submissions Failure',
   props<{ error: any }>()
);

export const changeDocumentAccess = createAction(
   '[Statistics]  Change Document Access',
   props<{ submission: any; title?; subject?; assignment?; page? }>()
);

export const changeDocumentAccessSuccess = createAction(
   '[Statistics] Change Document Access Success',
   props<{ data: any }>()
);

export const changeDocumentAccessFailure = createAction(
   '[Statistics] Change Document Access Failure',
   props<{ error: any }>()
);

export const changeAccessForAssignment = createAction(
   '[Statistics]  Change Access For All Assignments',
   props<{ assignment;access }>()
);

export const changeAccessForAssignmentSuccess = createAction(
   '[Statistics] Change Access For All Assignments Success',
   props<{ data: any }>()
);

export const changeAccessForAssignmentFailure = createAction(
   '[Statistics] Change Access For All Assignments Failure',
   props<{ error: any }>()
);
