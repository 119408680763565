// *** Angular
import { Component, OnInit } from '@angular/core';

@Component({
   selector: 'app-professors-overview',
   templateUrl: './professors-overview.component.html',
   styleUrls: ['./professors-overview.component.scss'],
})
export class ProfessorsOverviewComponent implements OnInit {
   constructor() {}

   ngOnInit(): void {}
}
