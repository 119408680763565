import { Component, OnInit, Optional } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-text-detector-modal',
  templateUrl: './text-detector-modal.component.html',
  styleUrls: ['./text-detector-modal.component.scss']
})
export class TextDetectorModalComponent implements OnInit {

  constructor(@Optional() public dialogRef: MatDialogRef<any>) { }

  ngOnInit(): void {
  }

  closeModal() {
    this.dialogRef.close();
 }

}
