import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProfessorState } from '../../professor/reducer/professor.reducer';

/**
 * Selectors are pure functions used for obtaining slices of store state.
 */

/**
 * getInstitutionProfessorsState represent top level of the professor state.
 * The createFeatureSelector is a convenience method for returning a top level feature state.
 */
const getInstitutionProfessorsState = createFeatureSelector<ProfessorState>(
   'professors'
);

/**
 * Selector used to get educators details state.
 */
export const getInstitutionProfessorsStateDetails = createSelector(
   getInstitutionProfessorsState,
   (state) => state
);
