import { createFeatureSelector, createSelector } from '@ngrx/store';
import { basicProfessorsStatisticsState } from '../reducer/basic-professors-statistics.reducer';

/**
 * Selectors are pure functions used for obtaining slices of store state.
 */

/**
 * getBasicProfessorsStatistics represent top level of the assignment state.
 * The createFeatureSelector is a convenience method for returning a top level feature state.
 */
const getBasicProfessorsStatistics =
   createFeatureSelector<basicProfessorsStatisticsState>(
      'basicProfessorsStatistics'
   );

/**
 * Selector used to get statisticsOverview details state.
 */
export const getBasicProfessorsStatisticsDetails = createSelector(
   getBasicProfessorsStatistics,
   (state) => state
);
