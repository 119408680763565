// *** Angular
import { Component, OnInit } from '@angular/core';

@Component({
   selector: 'app-manage-addons',
   templateUrl: './manage-addons.component.html',
   styleUrls: ['./manage-addons.component.scss'],
})
export class ManageAddonsComponent implements OnInit {
   constructor() {}

   ngOnInit(): void {}
}
