<main>
   <h3
      *ngIf="
         !(
            this.studentsNumber == 0 &&
            facultyId == '-1' &&
            departmentId == '-1' &&
            name == undefined
         )
      "
      class="filtersContainer"
   >
      <h4>Students</h4>
      <div class="selectors">
         <div>
            <select [(ngModel)]="facultyId" (change)="selectedFaculty()">
               <option value="-1" selected disabled>
                  Filter by school
               </option>
               <ng-container *ngFor="let faculty of this.faculties">
                  <option value="{{ faculty.id }}">
                     {{ faculty.name }}
                  </option>
               </ng-container>
               <option value="">All</option>
            </select>
         </div>
         <div>
            <select
               disabled
               *ngIf="facultyId == '-1' || facultyId == ''"
               name=""
               id=""
            >
               <option value="">Select school first</option>
            </select>
            <select
               *ngIf="facultyId !== '-1' && facultyId !== ''"
               [(ngModel)]="departmentId"
               (change)="departmentFilter()"
            >
               <option value="-1" selected disabled>
                  Filter by department
               </option>
               <ng-container *ngFor="let department of this.departments">
                  <option value="{{ department.id }}">
                     {{ department.name }}
                  </option>
               </ng-container>
               <option value="">All</option>
            </select>
         </div>
         <div>
            <input
               placeholder="search by students name ... "
               [(ngModel)]="name"
               (ngModelChange)="titleFilter()"
               type="text"
               name="searchByName"
            />
         </div>
         <button class="reportBtn" (click)="resetFilter()">Reset</button>
      </div>
   </h3>

   <div *ngIf="this.studentsNumber != 0" class="scroll white-bg">
      <table>
         <tr>
            <th class="bold">Name</th>
            <th class="bold">Email</th>
            <!-- <th class="bold">Phone Number</th> -->
            <th class="bold">Student ID</th>
            <th class="bold">School</th>
            <th class="bold">Department</th>
            <th class="bold">Status</th>
            <th class="bold">Edit</th>
         </tr>
         <ng-container
            *ngFor="
               let student of this.students
                  | paginate
                     : {
                          itemsPerPage: 5,
                          currentPage: page,
                          totalItems: this.studentsNumber
                       }
            "
         >
            <tr class="shadow">
               <td>
                  {{ student.name }}
               </td>
               <td>
                  {{ student.email }}
               </td>
               <!-- <td>
                  {{ student.phoneNumber }}
               </td> -->
               <td>
                  {{ student.indexId }}
               </td>
               <td>
                  {{ student.Faculty.name }}
               </td>
               <td>
                  {{ student.Department.name }}
               </td>
               <td>
                  <ng-container *ngIf="student.status === 1">
                     Active
                  </ng-container>
                  <ng-container *ngIf="student.status === 0">
                     Inactive
                  </ng-container>
               </td>
               <td>
                  <i (click)="this.openModal(student.id)" class="far fa-edit editButton"></i>
               </td>
            </tr>
         </ng-container>
      </table>
   </div>
   <div
      class="zero-state"
      *ngIf="
         this.studentsNumber == 0 &&
         facultyId == '-1' &&
         departmentId == '-1' &&
         name == undefined
      "
   >
      <!-- <img src="../../assets/images/icona-image.png" alt="" /> -->
      <h5 class="bold">There are no students registered in your institution.<br/><span class="clickable" routerLink="/administrator/register-module/student-register">Click here</span> to begin registering students.</h5>
   </div>
   <div
      class="zeroState"
      *ngIf="
         this.studentsNumber == 0 &&
         (name != undefined || facultyId != '-1' || departmentId != '-1')
      "
   >
      No thesis found
   </div>
   <div class="paginationContainer">
      <div>

      </div>
      <pagination-controls
      *ngIf="this.studentsNumber != 0"
      class="num"
      (pageChange)="changePage($event)"
      autoHide="false"
      responsive="true"
      previousLabel=""
      nextLabel=""
   ></pagination-controls>
   <div>
      <button class="reportBtn" (click)="registerStudentModal()">
         <i class="fas fa-plus"></i> Register student
      </button>
   </div>
   </div>
</main>
