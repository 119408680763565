<section>
   <div style="padding: 1%">
      Total Users
      <b>
         {{
            this.users[0]?.administrators +
               this.users[0]?.professors +
               this.users[0]?.teachingAssistants +
               this.users[0]?.academicIntegrityOfficers +
               this.users[0]?.students
         }}
      </b>
   </div>
</section>

<section>
   <div style="padding: 1%">
      <span> Administrators </span>
      <ng-container *ngIf="this.managingStarted === false">
         <span>
            <b>{{ this.users[0]?.administrators }}</b>
         </span>
      </ng-container>
      <ng-container *ngIf="this.managingStarted === true">
         <span>
            <input type="number" [(ngModel)]="administratorsNumber" />
         </span>
      </ng-container>
   </div>
</section>

<section>
   <div style="padding: 1%">
      <span> Academic Integrity Officers </span>
      <ng-container *ngIf="this.managingStarted === false">
         <span>
            <b>{{ this.users[0]?.academicIntegrityOfficers }}</b>
         </span>
      </ng-container>
      <ng-container *ngIf="this.managingStarted === true">
         <span>
            <input
               type="number"
               [(ngModel)]="academicIntegrityOfficersNumber"
            /> </span
      ></ng-container>
   </div>
</section>

<section>
   <div style="padding: 1%">
      <span> Professors </span>
      <ng-container *ngIf="this.managingStarted === false">
         <span>
            <b>{{ this.users[0]?.professors }}</b>
         </span>
      </ng-container>

      <ng-container *ngIf="this.managingStarted === true">
         <span>
            <input type="number" [(ngModel)]="professorsNumber" />
         </span>
      </ng-container>
   </div>
</section>

<section>
   <div style="padding: 1%">
      <span> Teaching Assistants </span>
      <ng-container *ngIf="this.managingStarted === false">
         <span>
            <b>{{ this.users[0]?.teachingAssistants }}</b>
         </span>
      </ng-container>
      <ng-container *ngIf="this.managingStarted === true">
         <span>
            <input type="number" [(ngModel)]="teachingAssistantsNumber" />
         </span>
      </ng-container>
   </div>
</section>

<section>
   <div style="padding: 1%">
      <span> Students </span>
      <ng-container *ngIf="this.managingStarted === false">
         <span>
            <b>{{ this.users[0]?.students }}</b>
         </span>
      </ng-container>
      <ng-container *ngIf="this.managingStarted === true">
         <span>
            <input type="number" [(ngModel)]="studentsNumber" />
         </span>
      </ng-container>
   </div>
</section>

<ng-container *ngIf="this.managingStarted === false">
   <div (click)="this.startManage()">Manage</div>
</ng-container>

<ng-container *ngIf="this.managingStarted === true">
   <div (click)="this.startManage()">Back</div>
   <div (click)="this.calculatePrice()">Calculate price</div>
</ng-container>

<ng-container *ngIf="this.managingStarted === true">
   <div>
      <span> Calculated price : {{ this.calculatedPrice }} </span>
      <ng-container *ngIf="this.calculatedPrice !== 0">
         <h3>Save {{ this.calculatedPrice }}</h3>
      </ng-container>
   </div>
</ng-container>
