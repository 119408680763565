import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SubjectState } from '../../subject/reducer/subject.reducer';

/**
 * Selectors are pure functions used for obtaining slices of store state.
 */

/**
 * getInstitutionSubjectsState represent top level of the subject state.
 * The createFeatureSelector is a convenience method for returning a top level feature state.
 */
const getInstitutionSubjectsState = createFeatureSelector<SubjectState>(
   'subjects'
);

/**
 * Selector used to get subjects details state.
 */
export const getInstitutionSubjectsStateDetails = createSelector(
   getInstitutionSubjectsState,
   (state) => state
);
