import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, catchError, concatMap, switchMap } from 'rxjs/operators';
import * as facultyActions from '../../faculty/action/faculty.actions';
import { FacultyService } from '../../../../services/faculty.service';
/**
 * Faculties Effects responsible to listen for dispatched actions that call service methods related to faculties
 */
@Injectable()
export class FacultyEffects {
   constructor(
      private actions$: Actions,
      private facultyService: FacultyService
   ) {}

   /**
    * Effect listening for registerFaculty Action, when registerFaculty is dispatched the effect with call create() from facultyService.
    * In case of success response the effect it will trigger registerFacultySuccess.
    * In case of Error it will trigger registerFacultyFailure with error property.
    */
   registerFaculty$ = createEffect(() => {
      return this.actions$.pipe(
         ofType(facultyActions.facultiesRegister),
         concatMap((action) => {
            return this.facultyService.create(action.data).pipe(
               switchMap((data) => [
                  facultyActions.facultiesRegisterSuccess({
                     data: data.facultyDetails,
                  })
               ]),
               catchError((error) =>
                  of(facultyActions.facultiesRegisterFailure({ error }))
               )
            );
         })
      );
   });

   /**
    * Effect listening for recallFaculties Action, when recallFaculties is dispatched the effect with call getFacultiesOfInstitution() from facultyService.
    * In case of success response the effect it will trigger loadFacultiesDetailsSuccess.
    * In case of Error it will trigger loadFacultiesDetailsFailure with error property.
    */
   recallFaculties$ = createEffect(() => {
      return this.actions$.pipe(
         ofType(facultyActions.recallFaculties),
         concatMap((action) => {
            return this.facultyService.getFacultiesOfInstitution(1).pipe(
               map((data) => {
                  return facultyActions.loadFacultiesDetailsSuccess({
                     faculties: data.faculties,
                     totalFaculties: data.totalFaculties,
                  });
               }),
               catchError((error) =>
                  of(facultyActions.loadFacultiesDetailsFailure({ error }))
               )
            );
         })
      );
   });

   /**
    * Effect listening for loadFacultiesDetails Action, when loadFacultiesDetails is dispatched the effect with call getFacultiesOfInstitution() from facultyService.
    * In case of success response the effect it will trigger loadFacultiesDetailsSuccess.
    * In case of Error it will trigger loadFacultiesDetailsFailure with error property.
    */
   getFacultiesDetails$ = createEffect(() => {
      return this.actions$.pipe(
         ofType(facultyActions.loadFacultiesDetails),
         concatMap((action) => {
            return this.facultyService
               .getFacultiesOfInstitution(action.page, action.name)
               .pipe(
                  map((data) => {
                     return facultyActions.loadFacultiesDetailsSuccess({
                        faculties: data.faculties,
                        totalFaculties: data.totalFaculties,
                     });
                  }),
                  catchError((error) =>
                     of(facultyActions.loadFacultiesDetailsFailure({ error }))
                  )
               );
         })
      );
   });

   /**
    * Effect listening for editFacultyDetails Action, when editFacultyDetails is dispatched the effect with call getFacultiesOfInstitution() from facultyService.
    * In case of success response the effect it will trigger recallFaculties.
    * In case of Error it will trigger editFacultyDetailsFailure with error property.
    */
   editFacultyDetails$ = createEffect(() => {
      return this.actions$.pipe(
         ofType(facultyActions.editFacultyDetails),
         concatMap((action) => {
            return this.facultyService
               .editFaculty(
                  action.facultyId,
                  action.facultyName,
                  action.facultyDescription
               )
               .pipe(
                  switchMap((data) => [
                     facultyActions.editFacultyDetailsSuccess({
                        data:data
                     }),
                     facultyActions.loadFacultiesDetails({
                        page: action.page,
                        name: action.name
                     }),
                  ]),
                  catchError((error) =>
                     of(facultyActions.editFacultyDetailsFailure({ error }))
                  )
               );
         })
      );
   });

   /**
    * Effect listening for removeFaculty Action, when removeFaculty is dispatched the effect with call getFacultiesOfInstitution() from facultyService.
    * In case of success response the effect it will trigger recallFaculties.
    * In case of Error it will trigger removeFacultyFailure with error property.
    */
   removeFaculty$ = createEffect(() => {
      return this.actions$.pipe(
         ofType(facultyActions.removeFaculty),
         concatMap((action) => {
            return this.facultyService.remove(action.facultyId).pipe(
               switchMap((data) => [
                  facultyActions.removeFacultySuccess({
                     data:data
                  }),
                  facultyActions.loadFacultiesDetails({
                     page: action.page,
                     name: action.name
                  }),
               ]),
               catchError((error) =>
                  of(facultyActions.removeFacultyFailure({ error }))
               )
            );
         })
      );
   });
}
