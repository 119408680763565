import { createAction, props } from '@ngrx/store';

export const loadUnitsStatisticsSubjects = createAction(
   '[Units Statistics Subjects] Load Units Statistics Subjects',
   props<{
      page?: any;
      title?: string;
      faculty?: string;
      department?: string;
      professor?: string;
      studentName?: string;
   }>()
);

export const loadUnitsStatisticsSubjectsSuccess = createAction(
   '[Units Statistics Subjects] Load Units Statistics Subjects Success',
   props<{ data: any }>()
);

export const loadUnitsStatisticsSubjectsFailure = createAction(
   '[Units Statistics Subjects] Load Units Statistics Subjects Failure',
   props<{ error: any }>()
);
