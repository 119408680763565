import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect, Effect } from '@ngrx/effects';
import { of } from 'rxjs';
import {
   map,
   catchError,
   concatMap,
   switchMap,
   mergeMap,
   tap,
} from 'rxjs/operators';
import * as reportActions from '../actions/report.actions';
import { SubmissionsService } from '../../../services/submissions.service';

/**
 * Report Effect responsible to listen for dispatching action that call any service methods related to report.
 */

@Injectable()
export class ReportEffects {
   /**
    * Report Effects Constructor
    * @param actions$
    * @param submissionService
    */
   constructor(
      private actions$: Actions,
      private submissionService: SubmissionsService
   ) {}
   /**
    * Effect listening for load Current Submission Actions to call api.
    */
   getCurrentCurrentSubmission$ = createEffect(() => {
      return this.actions$.pipe(
         ofType(reportActions.loadCurrentSubmission),
         concatMap((action) => {
            return this.submissionService
               .getUserCurrentSubmissionDetails(action.submissionId)
               .pipe(
                  map((data) => {
                     return reportActions.loadCurrentSubmissionSuccess({
                        submission: data.currentSubmissionDetails,
                     });
                  }),
                  catchError((error) =>
                     of(reportActions.loadCurrentSubmissionFailure({ error }))
                  )
               );
         })
      );
   });
   /**
    * Effect used to get current source id.
    */
   getCurrentSourceId$ = createEffect(
      (): any => {
         return this.actions$.pipe(
            ofType(reportActions.setCurrentSentenceId),
            tap((action) =>
               reportActions.setCurrentSentenceIdSuccess({
                  currentSentenceId: action.currentSentenceId,
               })
            )
         );
      },
      { dispatch: false }
   );

   /**
    * Listening for excludeSentence action to trigger excludeSentenceSuccess in case of success
    * and excludeSentenceFailure action in case of failure.
    */
   excludeSentence$ = createEffect(() => {
      return this.actions$.pipe(
         ofType(reportActions.excludeSentence),
         concatMap((action) => {
            return this.submissionService
               .excludeSentence(
                  action.submissionId,
                  `${action.sentence.id}`,
                  action.sourceType,
                  action.comment
               )
               .pipe(
                  map((data: any) => {
                     return reportActions.getSubmissionPlag({
                        presignedUrl: data.infoToSend[0],
                     });
                  }),
                  catchError((error) =>
                     of(reportActions.excludeSentenceFailure({ error }))
                  )
               );
         })
      );
   });

   /**
    * Listening for excludeSource action to trigger excludeSourceSuccess in case of success
    * and excludeSourceFailure action in case of failure.
    */

   excludeSource$ = createEffect(() =>
      this.actions$.pipe(
         ofType(reportActions.excludeSource),
         concatMap((action) => {
            return this.submissionService
               .excludeSource(
                  action.source.sourceId,
                  action.submissionId,
                  action.originalLanguage,
                  action.exclude,
                  action.excludeUrlId,
                  action.excludeUrlPrecentage
               )
               .pipe(
                  map(
                     (data) =>
                        reportActions.excludeSourceSuccess({
                           source: data,
                           submissionId: action.submissionId,
                           sourceType: action.originalLanguage,
                           exclude: action.exclude,
                           excludeUrlId: action.excludeUrlId,
                           excludeUrlPrecentage: action.excludeUrlPrecentage,
                        }),
                     catchError((error) =>
                        of(reportActions.excludeSourceFailure({ error }))
                     )
                  )
               );
         })
      )
   );

   /**
    * Listening for includeSentence action to trigger includeSentenceSuccess in case of success.
    * And includeSentenceFailure action in case of failure
    */
   includeSentence$ = createEffect(() => {
      return this.actions$.pipe(
         ofType(reportActions.includeSentence),
         concatMap((action) => {
            return this.submissionService
               .includeSentence(
                  action.submissionId,
                  action.sentence.id,
                  action.sourceType
               )
               .pipe(
                  map((data: any) => {
                     return reportActions.getSubmissionPlag({
                        presignedUrl: data.infoToSend[0],
                     });
                  }),
                  catchError((error) =>
                     of(reportActions.includeSentenceFailure({ error }))
                  )
               );
         })
      );
   });
   /**
    * Listening for checkCheckbox action an then triggers checkCheckboxSuccess action
    * in case of failure checkCheckboxFailure action is triggered
    */
   checkCheckbox$ = createEffect(
      () => {
         return this.actions$.pipe(
            ofType(reportActions.checkCheckbox),
            tap((action) => {
               return reportActions.checkCheckboxSuccess({
                  source: action.source,
                  sourceType: action.sourceType,
               });
            }),
            catchError((error) =>
               of(reportActions.checkCheckboxFailure({ error }))
            )
         );
      },
      { dispatch: false }
   );

   /**
    * Listening for checkCheckbox action an then triggers checkCheckboxSuccess action
    * in case of failure checkCheckboxFailure action is triggered
    */
   checkCheckboxExclude$ = createEffect(
      () => {
         return this.actions$.pipe(
            ofType(reportActions.checkCheckboxExclude),
            tap((action) => {
               return reportActions.checkCheckboxExcludeSuccess({
                  source: action.source,
                  sourceType: action.sourceType,
               });
            }),
            catchError((error) =>
               of(reportActions.checkCheckboxExcludeFailure({ error }))
            )
         );
      },
      { dispatch: false }
   );
   /**
    * Effect listening for un check checkbox action.
    */
   unCheckCheckbox$ = createEffect(
      () => {
         return this.actions$.pipe(
            ofType(reportActions.unCheckCheckbox),
            tap((action) => {
               return reportActions.unCheckCheckboxSuccess({
                  source: action.source,
                  sourceType: action.sourceType,
               });
            })
         );
      },
      { dispatch: false }
   );
   /**
    * Effect listening for un check checkbox action.
    */
   unCheckCheckboxExclude$ = createEffect(
      () => {
         return this.actions$.pipe(
            ofType(reportActions.unCheckCheckboxExclude),
            tap((action) => {
               return reportActions.unCheckCheckboxExcludeSuccess({
                  source: action.source,
                  sourceType: action.sourceType,
               });
            })
         );
      },
      { dispatch: false }
   );
   /**
    * Effect listening to getSubmissionPlag action, to trigger api call to get submission plagiarism.
    */
   getReportData$ = createEffect((): any => {
      return this.actions$.pipe(
         ofType(reportActions.getSubmissionPlag),
         mergeMap((action) => {
            return this.submissionService.getS3Json(action.presignedUrl).pipe(
               switchMap((data: any) => {
                  return [
                     reportActions.getSubmissionPlagSuccess({
                        submission: data.submission,
                        sources: data.originalSources,
                        mlSources: data.translatedSources,
                        excludedSourcesMlPlag: data.excludedSourcesMlPlag,
                        excludedSourcesCrossPlag: data.excludedSourcesCrossPlag,
                        originalNavigationArray: data.originalNavigationArray,
                        translatedNavigationArray:
                           data.translatedNavigationArray,
                     }),
                     reportActions.addIsCheckedProp(),
                  ];
               })
            );
         }),
         catchError((error) =>
            of(reportActions.getSubmissionPlagFailure({ error }))
         )
      );
   });
   /**
    * Effect listening for addIsCheckedProp action and then triggers addIsCheckedPropSuccess action.
    */
   addCheckedProp$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(reportActions.addIsCheckedProp),
            tap(() => reportActions.addIsCheckedPropSuccess),
            catchError((error) =>
               of(reportActions.addIsCheckedPropFailure({ error }))
            )
         ),
      /**
       * Whenever ] dispatching any actions we use dispatch false
       */
      { dispatch: false }
   );
   /**
    * Effect listening for reset filter action.
    */
   resetFilter$ = createEffect(() =>
      this.actions$.pipe(
         ofType(reportActions.resetFilter),
         map((action: any) => {
            return reportActions.resetFilterSuccess({
               sources: action.sources,
            });
         })
      )
   );
   // TODO: Remove this since we don't update comment. Ask ibo & aurel.
   updateComment$ = createEffect((): any => {
      return this.actions$.pipe(
         ofType(reportActions.updateComment),
         concatMap((action) => {
            return this.submissionService
               .updateComment(
                  action.comment,
                  action.submissionId,
                  action.sentence.id,
                  action.sourceType
               )
               .pipe(
                  map((data) => {
                     return reportActions.updateCommentSuccess({
                        source: action.source,
                        sentence: action.sentence,
                        sourceType: action.sourceType,
                        comment: action.comment,
                     });
                  }),
                  catchError((error) =>
                     of(reportActions.updateCommentFailure({ error }))
                  )
               );
         })
      );
   });
   // TODO: Remove this since we don't delete comment. Ask ibo & aurel.
   deleteComment$ = createEffect((): any => {
      return this.actions$.pipe(
         ofType(reportActions.deleteComment),
         concatMap((action) => {
            return this.submissionService
               .updateComment(
                  '',
                  action.submissionId,
                  action.sentence.id,
                  action.sourceType
               )
               .pipe(
                  map((data) => {
                     return reportActions.deleteCommentSuccess({
                        source: action.source,
                        sentence: action.sentence,
                        sourceType: action.sourceType,
                     });
                  }),
                  catchError((error) =>
                     of(reportActions.deleteCommentFailure({ error }))
                  )
               );
         })
      );
   });
   // !!! WIP
   /**
    * Effect listening for load Current Submission Actions to call api.
    */
   changeSource$ = createEffect(() => {
      return this.actions$.pipe(
         ofType(reportActions.changeSource),
         concatMap((action) => {
            return this.submissionService
               .changeSentenceSource(
                  action.sourceId,
                  action.sentenceId,
                  action.submissionId,
                  action.originalLanguage
               )
               .pipe(
                  map((data) => {
                     return reportActions.changeSourceSuccess({
                        sourceId: data.sourceId,
                        sentenceId: data.sentenceId,
                        originalLanguage: data.originalLanguage,
                     });
                  }),
                  catchError((error) =>
                     of(reportActions.changeSourceFailure({ error }))
                  )
               );
         })
      );
   });
}
