import * as AppState from '../../../../../../store/app.state';
import * as departmentsActions from '../action/units-statistics-departments.actions';
import { createReducer } from '@ngrx/store';
import { on } from '@ngrx/store';

/**
 * subject reducer used to updated store values based on certain action.
 */

/**
 * State interface extends App state State and add user state to the main state.
 */
export interface State extends AppState.State {
   departmentsStatistics: departmentsStatisticsState;
}

/**
 * An SubjectState interface, which represents the blueprint of the User State.
 */
export interface departmentsStatisticsState {
   departmentsStatistics: any;
   departmentsNumber: number;
   errorMessage: string;
}

/**
 * SubjectState initial state.
 */
export const initialState: departmentsStatisticsState = {
   departmentsStatistics: null,
   departmentsNumber: null,
   errorMessage: null,
};

/**
 * Subject Reducer used to update the state upon Subject actions.
 */
export const departmentsStatisticsReducer = createReducer(
   initialState,
   /**
    * On loadSubjectsDetailsSuccess action updated current state with the following properties.
    */
   on(
      departmentsActions.loadUnitsStatisticsDepartmentsSuccess,
      (state, action): departmentsStatisticsState => {
         return {
            ...state,
            departmentsStatistics: action.data.departmentDetails,
            departmentsNumber: action.data.departmentsNumber,
         };
      }
   ),

   /**
    * On loadSubjectsDetailsFailure action updated current state with the following properties.
    */
   on(
      departmentsActions.loadUnitsStatisticsDepartmentsFailure,
      (state, action): departmentsStatisticsState => {
         return {
            ...state,
            errorMessage: action.error,
         };
      }
   )
);
