import { Store } from '@ngrx/store';
import {
   Component,
   ElementRef,
   OnDestroy,
   OnInit,
   Renderer2,
   ViewChild,
} from '@angular/core';
import { AuthService } from '../../services/auth.service';
import * as authActions from '../../authModule/state/actions/authentication.actions';
import { getCurrentUser } from 'src/app/authModule/state/authentication.selectors';
import { TawkService } from 'src/app/services/tawk.service';
import * as userActions from '../../authModule/state/actions/authentication.actions';
/**
 * Header component
 */
@Component({
   selector: 'app-header',
   templateUrl: './header.component.html',
   styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
   /**
    * Method used to store user role.
    */
   userRole;
   /**
    * Used to store
    */
   user$;
   user;
   expandedMenu: boolean;
   @ViewChild('name') name: ElementRef;
   @ViewChild('chevron') chevron: ElementRef;
   removeInfoIndex: boolean;

   /**
    * Component Constructor
    * @param authService
    * @param store
    */
   constructor(
      private renderer: Renderer2,
      private authService: AuthService,
      private store: Store,
      private tawkService: TawkService
   ) {
      this.renderer.listen('window', 'click', (e: Event) => {
         if (
            e.target !== this.name.nativeElement &&
            e.target !== this.chevron.nativeElement
         ) {
            this.expandedMenu = false;
         }
      });
   }
   ngOnDestroy(): void {
      this.user$.unsubscribe();
   }
   /**
    * Method used to be called whenever the component is loaded and all the methods in it, will be triggered.
    */
   ngOnInit(): void {
      this.userRole = this.authService.isLoggedInRole();
      this.user$ = this.store.select(getCurrentUser).subscribe((data) => {
         if (data !== null) {
            this.user = data;            
         }
      });
      this.expandedMenu = false;
   }

   /**
    * A lifecycle hook that is called after Angular has fully initialized a component's view.
    */
   ngAfterViewInit(): void {
      this.user$ = this.store.select(getCurrentUser).subscribe((data) => {
         if (data !== null) {
            this.user = data;
         }
      });
   }
   /**
    * Method used to sign out and then redirect to login
    */
   signOut() {
      this.tawkService.SetChatVisibility(false);
      this.store.dispatch(authActions.logout());
   }
   expandMenu() {
      if (this.expandedMenu) {
         this.expandedMenu = false;
      } else {
         this.expandedMenu = true;
      }
   }

   removeInfo(){
      this.removeInfoIndex=true;
   }
}
