import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Store } from '@ngrx/store';
import { getCurrentUser } from 'src/app/authModule/state/authentication.selectors';
import { User } from 'src/app/models/user';
import { UserService } from 'src/app/services/user.service';
import { first } from 'rxjs/operators';

@Component({
   selector: 'app-add-credits',
   templateUrl: './add-credits.component.html',
   styleUrls: ['./add-credits.component.scss'],
})
export class AddCreditsComponent implements OnInit {
   /**
    * Role of the current user.
    */
   userRole;
   /**
    * Used to subscribe to current user.
    */
   currentUserDetailsSubscriber$: any;
   /**
    * Used to store current user details.
    */
   currentUserDetails: User;
   reSubmissions: any;

   /**
    * Component Constructor
    * @param authServiceService
    * @param router
    * @param route
    * @param store
    */
   constructor(
      private authServiceService: AuthService,
      private router: Router,
      public route: ActivatedRoute,
      private store: Store,
      private userService: UserService
   ) {}
   /**
    * Method used to be called whenever the component is loaded and all the methods in it, will be triggered.
    */
   ngOnInit(): void {
      this.userRole = this.authServiceService.isLoggedInRole();
      this.currentUserDetailsSubscriber$ = this.store
         .select(getCurrentUser)
         .subscribe((data: User) => {
            this.currentUserDetails = data;
         });

      if (this.currentUserDetails?.Institution !== null) {
         this.userService
            .checkReSubmissionsNumber()
            .pipe(first())
            .subscribe(
               (data) => {
                  this.reSubmissions = data.reSubmissions;
               },
               (error) => {
                  console.log('error', error);
               }
            );
      }
   }

   /**
    *
    * Check if the router url contains the specified route
    * @param {string} route
    * @returns
    */
   hasRoute(route: string) {
      return this.router.url.includes(route);
   }

   order() {
      let url = `https://secure.2checkout.com/order/checkout.php?PRODS=34406144&ADDITIONAL_userId=${this.currentUserDetails.id}&CARD=1&CART=1`;
      let a = document.createElement('a');
      a.target = '_blank';
      a.href = url;
      a.click();
   }

   orderBundle() {
      let url = `https://secure.2checkout.com/order/checkout.php?PRODS=35092708&ADDITIONAL_userId=${this.currentUserDetails.id}&CARD=1&CART=1`;
      let a = document.createElement('a');
      a.target = '_blank';
      a.href = url;
      a.click();
   }

   orderInstitutional() {
      let url = `https://secure.2checkout.com/order/checkout.php?PRODS=36433968&ADDITIONAL_userId=${this.currentUserDetails.id}&CARD=1&CART=1`;
      let a = document.createElement('a');
      a.target = '_blank';
      a.href = url;
      a.click();
   }

   orderReSubmission() {
      let url = `https://secure.2checkout.com/order/checkout.php?PRODS=36616064&ADDITIONAL_userId=${this.currentUserDetails.id}&CARD=1&CART=1`;
      let a = document.createElement('a');
      a.target = '_blank';
      a.href = url;
      a.click();
   }

   orderThesisInstitutional() {
      let url = `https://secure.2checkout.com/order/checkout.php?PRODS=36433949&ADDITIONAL_userId=${this.currentUserDetails.id}&CARD=1&CART=1`;
      let a = document.createElement('a');
      a.target = '_blank';
      a.href = url;
      a.click();
   }
}
