import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, catchError, concatMap } from 'rxjs/operators';
import * as basicProfessorsActions from '../action/basic-professors-statistics.actions';
import { AdministratorStatisticsService } from '../../../../../../../services/administrator-statistics.service';

@Injectable()
export class BasicProfessorsStatisticsEffects {
   constructor(
      private actions$: Actions,
      private administratorStatistics: AdministratorStatisticsService
   ) {}

   /**
    * Effect listening for basicProfessorsActionsStatistics Action, when basicProfessorsActionsStatistics is dispatched the effect with call create() from courseService.
    * In case of success response the effect it will trigger basicProfessorsActionsStatisticsSuccess.
    * In case of Error it will trigger basicProfessorsActionsStatisticsFailure with error property.
    */
   basicProfessorsActionsStatistics$ = createEffect(() => {
      return this.actions$.pipe(
         ofType(basicProfessorsActions.loadBasicProfessorsStatistics),
         concatMap((action) => {
            return this.administratorStatistics
               .professorsBasicStatistics(
                  action.page,
                  action.faculty,
                  action.department,
                  action.name,
                  action.subject
               )
               .pipe(
                  map((data) => {
                     return basicProfessorsActions.loadBasicProfessorsStatisticsSuccess(
                        {
                           data: data,
                        }
                     );
                  }),
                  catchError((error) =>
                     of(
                        basicProfessorsActions.loadBasicProfessorsStatisticsFailure(
                           {
                              error,
                           }
                        )
                     )
                  )
               );
         })
      );
   });
}
