import * as AppState from '../../../../../../store/app.state';
import * as subjectsActions from '../action/units-statistics-subject.actions';
import { createReducer } from '@ngrx/store';
import { on } from '@ngrx/store';

/**
 * subject reducer used to updated store values based on certain action.
 */

/**
 * State interface extends App state State and add user state to the main state.
 */
export interface State extends AppState.State {
   subjectsStatistics: subjectsStatisticsState;
}

/**
 * An SubjectState interface, which represents the blueprint of the User State.
 */
export interface subjectsStatisticsState {
   subjectsStatistics: any;
   subjectsNumber: number;
   errorMessage: string;
}

/**
 * SubjectState initial state.
 */
export const initialState: subjectsStatisticsState = {
   subjectsStatistics: null,
   subjectsNumber: null,
   errorMessage: null,
};

/**
 * Subject Reducer used to update the state upon Subject actions.
 */
export const subjectsStatisticsReducer = createReducer(
   initialState,
   /**
    * On loadSubjectsDetailsSuccess action updated current state with the following properties.
    */
   on(
      subjectsActions.loadUnitsStatisticsSubjectsSuccess,
      (state, action): subjectsStatisticsState => {
         return {
            ...state,
            subjectsStatistics: action.data.subjectsDetails,
            subjectsNumber: action.data.subjectsNumber,
         };
      }
   ),

   /**
    * On loadSubjectsDetailsFailure action updated current state with the following properties.
    */
   on(
      subjectsActions.loadUnitsStatisticsSubjectsFailure,
      (state, action): subjectsStatisticsState => {
         return {
            ...state,
            errorMessage: action.error,
         };
      }
   )
);
