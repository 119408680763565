// *** Angular
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
   MatDialog,
   MatDialogRef,
   MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { first } from 'rxjs/operators';
import {
   FormBuilder,
   FormControl,
   FormGroup,
   Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';

// *** Packages
import swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';

// *** Actions
import * as studentActions from '../../state/student/action/student.actions';
import * as facultyActions from '../../state/faculty/action/faculty.actions';

// *** Selectors
import { getInstitutionFacultiesStateDetails } from '../../state/faculty/selector/faculty.selectors';

// *** Services
import { DepartmentService } from 'src/app/services/department.service';
import { UserService } from 'src/app/services/user.service';
import { CourseService } from '../../../services/course.service';

@Component({
   selector: 'app-edit-student',
   templateUrl: './edit-student.component.html',
   styleUrls: ['./edit-student.component.scss'],
})
export class EditStudentComponent implements OnInit, OnDestroy {
   /**
    * Variable used to store FormGroup values that are being edited on modal.
    */
   studentEdit: FormGroup;
   studentId;

   /**
    * Variable used to store faculties of institution.
    */
   faculties;
   faculties$;

   /**
    * Variable used to store departments of institution.
    */
   departments;
   departments$;

   /**
    * Variable used to store subjects of institution.
    */
   subjects;
   subjects$;

   /**
    * Variable stores configuration of ngx-select-dropdown.
    */
   config;
   /**
    * Variabel used to store selected student information
    */
   student;
   /**
    * Boolean used to store a value after email is writen to check if there is same email or not on database
    */
   emailExists: boolean = false;
   /**
    * Variable used to store a time for function to trigger.(x time after key is up call y function.)
    */
   timer;
   /**
    * Boolean used to store a value after index is writen to check if there is same index or not on database
    */
   indexExists: boolean = false;
   constructor(
      public dialogRef: MatDialogRef<EditStudentComponent>,
      public dialog: MatDialog,
      private departmentService: DepartmentService,
      private fb: FormBuilder,
      private userService: UserService,
      private store: Store,
      private courseService: CourseService,
      private spinner: NgxSpinnerService,
      /**
       * Used to pass data in component through dialog
       */
      @Inject(MAT_DIALOG_DATA) public data: any
   ) {}

   ngOnDestroy(): void {
      this.faculties$.unsubscribe();
   }

   ngOnInit(): void {
      this.config = {
         displayKey: 'title', //if objects array passed which key to be displayed defaults to description
         search: true, //true/false for the search functionality defaults to false,
         height: 'auto', //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
         placeholder: 'Select', // text to be displayed when no item is selected defaults to Select,
         limitTo: 0, // number thats limits the no of options displayed in the UI (if zero, options will not be limited)
         moreText: 'more', // text to be displayed when more than one items are selected like Option 1 + 5 more
         noResultsFound: 'No results found!', // text to be displayed when no items are found while searching
         searchPlaceholder: 'Search', // label thats displayed in search input,
         searchOnKey: 'title', // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
      };
      this.store.dispatch(facultyActions.loadFacultiesDetails({ page: 0 }));

      this.faculties$ = this.store
         .select(getInstitutionFacultiesStateDetails)
         .subscribe((data) => {
            if (data !== null) {
               this.faculties = data.faculties;
            }
         });

      this.studentEdit = this.fb.group({
         studentName: new FormControl('', [
            Validators.required,
            Validators.minLength(3),
         ]),
         studentEmail: new FormControl('', [
            Validators.required,
            Validators.email,
         ]),
         // studentPhoneNumber: new FormControl('', [
         //    Validators.required,
         //    Validators.minLength(6),
         // ]),
         studentIndexID: [''],
         studentFaculty: new FormControl('', Validators.required),
         studentDepartment: new FormControl('', Validators.required),
         studentStatus: new FormControl('', Validators.required),
      });

      this.studentId = this.data.studentId;
      this.userService
         .userInfo(this.studentId)
         .pipe(first())
         .subscribe(
            (data) => {
               this.student = data.user;
               this.studentEdit = this.fb.group({
                  studentName: new FormControl(this.student.name, [
                     Validators.required,
                     Validators.minLength(3),
                  ]),
                  studentEmail: new FormControl(this.student.email, [
                     Validators.required,
                     Validators.email,
                  ]),
                  // studentPhoneNumber: new FormControl(
                  //    this.student.phoneNumber || 'No contact number',
                  //    [Validators.required, Validators.minLength(6)]
                  // ),
                  studentIndexID: [
                     this.student.indexId || ''
                  ],
                  studentFaculty: new FormControl(
                     this.student.Faculty.id,
                     Validators.required
                  ),
                  studentDepartment: new FormControl(
                     this.student.Department.id,
                     Validators.required
                  ),
                  studentStatus: new FormControl(
                     this.student.status,
                     Validators.required
                  ),
               });
               this.facultyChanged();
            },
            (error) => {
               console.log('error', error);
            }
         );
   }
   /**
    * Method use to get form controls.
    */
   get form() {
      return this.studentEdit.controls;
   }

   /**
    * Method is used to get faculty id from dropdown and then to retrieve departments of that faculty.
    */
   facultyChanged() {
      let facultyId = this.form.studentFaculty.value;
      this.departmentService
         .getDepartmentsOfFaculty(facultyId)
         .pipe(first())
         .subscribe(
            (data) => {
               this.departments = data.departments;
               if (this.departments.length !== 0) {
                  this.form.studentDepartment.setValue(this.departments[0].id);
               } else {
                  this.form.studentDepartment.setValue('');
               }
            },
            (error) => {
               console.log('error', error);
            }
         );
   }

   /**
    * Method is used to call editCourse service that will send updated fields of department to API.
    */
   async update() {
      const result = await swal.fire({
         title: 'Confirm update of Student',
         icon: 'warning',
         showCancelButton: true,
         confirmButtonColor: '#3085d6',
         cancelButtonColor: '#b5adad',
         confirmButtonText: 'Confirm',
         allowOutsideClick: false,
      });
      if (result.isConfirmed) {
         this.store.dispatch(
            studentActions.editStudentDetails({
               studentId: this.studentId,
               studentName: this.form.studentName.value,
               studentEmail: this.form.studentEmail.value,
               studentPhoneNumber: 1,
               studentIndexID: this.form.studentIndexID.value,
               studentFaculty: this.form.studentFaculty.value,
               studentDepartment: this.form.studentDepartment.value,
               studentStatus: this.form.studentStatus.value,
               page: this.data.page,
               faculty: this.data.faculty,
               department:
                  this.data.department,
               name: this.data.name,
            })
         );
         this.dialogRef.close();
      } else if (result.isDismissed) {
         this.dialogRef.close();
      }
   }

   /**
    * Method is used to call remove student dispatcher.
    */
   async delete() {
      const result = await swal.fire({
         title: 'You are about to DELETE a student. Please confirm your action by clicking “Confirm” below.',
         text: 'Your action is recoverable only by contacting the technical support team at support@crossplag.com',
         icon: 'warning',
         showCancelButton: true,
         confirmButtonColor: '#3085d6',
         cancelButtonColor: '#b5adad',
         confirmButtonText: 'Confirm',
         allowOutsideClick: false,
      });
      if (result.isConfirmed) {
         this.store.dispatch(
            studentActions.removeStudent({
               studentId: this.studentId,
               page: this.data.page,
               faculty: this.data.faculty,
               department:
                  this.data.department,
               name: this.data.name,
            })
         );
         this.dialogRef.close();
      } else if (result.isDismissed) {
         this.dialogRef.close();
      }
   }

   /**
    * Method is used to call a api to check if emails exist on database or not.
    */
   // validateEmail() {
   //    let time;
   //    time = 300;
   //    clearTimeout(this.timer);
   //    if (
   //       this.form.studentEmail.value.length !== 0 &&
   //       this.student.email !== this.form.studentEmail.value
   //    ) {
   //       this.timer = setTimeout(() => {
   //          this.spinner.show();
   //          this.userService
   //             .checkEmail(this.form.studentEmail.value)
   //             .pipe(first())
   //             .subscribe(
   //                (data) => {
   //                   if (data.sameEmails !== 0) {
   //                      this.emailExists = true;
   //                   } else {
   //                      this.emailExists = false;
   //                   }
   //                   this.spinner.hide();
   //                },
   //                (error) => {
   //                   this.spinner.hide();
   //                   console.log('error', error);
   //                }
   //             );
   //       }, time);
   //    } else {
   //       this.emailExists = false;
   //    }
   // }

   /**
    * Method is used to call a api to check if emails exist on database or not.
    */
   validateIndex() {
      let time;
      time = 300;
      clearTimeout(this.timer);

      if (
         this.form.studentIndexID.value.length !== 0 &&
         this.student.indexId !== this.form.studentIndexID.value
      ) {
         this.timer = setTimeout(() => {
            this.spinner.show();
            this.userService
               .checkIndex(this.form.studentIndexID.value)
               .pipe(first())
               .subscribe(
                  (data) => {
                     if (data.sameIndex !== 0) {
                        this.indexExists = true;
                     } else {
                        this.indexExists = false;
                     }
                     this.spinner.hide();
                  },
                  (error) => {
                     this.spinner.hide();
                     console.log('error', error);
                  }
               );
         }, time);
      } else {
         this.indexExists = false;
      }
   }
}
