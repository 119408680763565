import { createAction, props } from '@ngrx/store';

export const loadRequests = createAction(
   '[Request] Load Requests',
   props<{ page: any; assignmentId: any; checked: any }>()
);

export const loadRequestsSuccess = createAction(
   '[Request] Load Requests Success',
   props<{ data: any }>()
);

export const loadRequestsFailure = createAction(
   '[Request] Load Requests Failure',
   props<{ error: any }>()
);

export const sentRequest = createAction(
   '[Administration] Student Request',
   props<{ assignmentId: any; requestType: any; request: any ; requestFor}>()
);

export const sentRequestSuccess = createAction(
   '[Administration] Student Request Success',
   props<{ data: any }>()
);

export const sentRequestFailure = createAction(
   '[Administration] Student Request Failure',
   props<{ error: any }>()
);

export const requestsAllowed = createAction(
   '[Administration] Requests Type Allowed',
   props<{ id: any, requestFor  }>()
);

export const requestsAllowedSuccess = createAction(
   '[Administration] Requests Type Allowed Success',
   props<{ data: any }>()
);

export const requestsAllowedFailure = createAction(
   '[Administration] Requests Type Allowed Failure',
   props<{ error: any }>()
);
