<section style="position: relative;" class="flex register-student container">
   <main [ngClass]="{blurryBackground:user?.Institution?.InstitutionLicense?.length == 0 && user.roleId == 3}">
      <h4 class="bold">Register students</h4>
      <div class="nav">
         <a (click)="toggleForm('manual')" [ngClass]="{ active: this.excel === false }"
            >Manual form</a
         >
         <a (click)="toggleForm('bulk')" [ngClass]="{ active: this.excel === true }" class="disable"
            >Bulk form</a
         >
      </div>
      <ng-container *ngIf="this.excel === false">
         <form action="" [formGroup]="studentRegister">
            <h5>Full name</h5>
            <input formControlName="studentName" type="text" />
            <div
               *ngIf="
                  studentRegister.controls.studentName.touched &&
                  studentRegister.controls.studentName.invalid
               "
               class="invalid"
            >
               Full name is required
            </div>
            <div
               *ngIf="studentRegister.controls.studentName.errors?.minlength"
               class="invalid"
            >
               Full name should contain at least 3 characters.
            </div>
            <h5>School</h5>
            <select
               formControlName="studentFaculty"
               (change)="facultyChanged()"
            >
               <option selected disabled value="">Select school</option>
               <ng-container *ngFor="let faculty of this.faculties">
                  <option value="{{ faculty.id }}">
                     {{ faculty.name }}
                  </option>
               </ng-container>
            </select>
            <div
               *ngIf="
                  studentRegister.controls.studentFaculty.touched &&
                  studentRegister.controls.studentFaculty.invalid
               "
               class="invalid"
            >
               Student School is required*
            </div>
            <h5>Department</h5>
            <select
               disabled
               *ngIf="
                  studentRegister.controls.studentFaculty.value == '' ||
                  studentRegister.controls.studentFaculty == undefined
               "
               name=""
               id=""
            >
               <option value="">Select school first</option>
            </select>
            <select
               *ngIf="
                  studentRegister.controls.studentFaculty.value !== '' &&
                  studentRegister.controls.studentFaculty !== undefined
               "
               formControlName="studentDepartment"
            >
               <option selected disabled value="">Select department</option>
               <ng-container *ngFor="let department of this.departments">
                  <option value="{{ department.id }}">
                     {{ department.name }}
                  </option>
               </ng-container>
            </select>
            <div
               *ngIf="
                  studentRegister.controls.studentDepartment.touched &&
                  studentRegister.controls.studentDepartment.invalid
               "
               class="invalid"
            >
               Student Department is required*
            </div>
            <h5>Student ID (Optional)</h5>
            <input
               formControlName="studentIndexID"
               (ngModelChange)="validateIndex()"
               type="text"
            />
            <div *ngIf="this.indexExists" class="invalid">
               Student ID already exists*
            </div>
            <!-- <h5>Phone Number</h5>
            <input formControlName="studentPhoneNumber" type="text" />
            <div
               *ngIf="
                  studentRegister.controls.studentPhoneNumber.touched &&
                  studentRegister.controls.studentPhoneNumber.invalid
               "
               class="invalid"
            >
               Student Contact number is required*
            </div>
            <div
               *ngIf="
                  studentRegister.controls.studentPhoneNumber.errors?.minlength
               "
               class="invalid"
            >
               Student Contact number should contain at least 6 characters!
            </div> -->
            <h5>Email</h5>
            <input
               formControlName="studentEmail"
               type="email"
            />
            <!-- <div *ngIf="this.emailExists" class="invalid">
               Email already exists, please try another one.*
            </div> -->
            <div
               *ngIf="
                  studentRegister.controls.studentEmail.touched &&
                  studentRegister.controls.studentEmail.invalid
               "
               class="invalid"
            >
               Student Email is required*
            </div>
            <div
               *ngIf="studentRegister.controls.studentEmail.errors?.email"
               class="invalid"
            >
               Student Email is not valid!
            </div>
            <div class="center">
               <button
                  [disabled]="
                     studentRegister.invalid ||
                     this.emailExists ||
                     this.indexExists
                  "
                  [ngClass]="{
                     disable:
                        studentRegister.invalid ||
                        this.emailExists ||
                        this.indexExists
                  }"
                  class="submit"
                  type="submit"
                  (click)="submit()"
               >
                  Confirm
               </button>
            </div>
         </form>
      </ng-container>
      <ng-container *ngIf="this.excel === true">
         <div class="content">
            <div>
               <select
                  (change)="facultyChanged()"
                  [(ngModel)]="this.selectedFaculty"
               >
                  <option selected disabled value="">Select school</option>
                  <ng-container *ngFor="let faculty of this.faculties">
                     <option value="{{ faculty.id }}">
                        {{ faculty.name }}
                     </option>
                  </ng-container>
               </select>
               <div
                  class="border"
                  (click)="downloadTemplate()"
                  style="cursor: pointer"
               >
                  <h6>Download Template</h6>
                  <div>
                     <i class="fas fa-download"></i>
                     <p>
                        Download and fill the relevant information within the
                        template
                     </p>
                  </div>
               </div>
            </div>
            <div>
               <select
                  disabled
                  *ngIf="
                     this.selectedFaculty == '' ||
                     this.selectedFaculty == undefined
                  "
               >
                  <option value="">Select school first</option>
               </select>
               <select
                  *ngIf="
                     this.selectedFaculty !== '' &&
                     this.selectedFaculty !== undefined
                  "
                  [(ngModel)]="this.selectedDepartment"
               >
                  <option selected disabled value="">Select department</option>
                  <ng-container *ngFor="let department of this.departments">
                     <option value="{{ department.id }}">
                        {{ department.name }}
                     </option>
                  </ng-container>
               </select>
               <div
                  class="border"
                  (click)="
                     this.selectedFaculty !== undefined &&
                     this.selectedDepartment !== undefined
                        ? upload()
                        : showAlert()
                  "
                  style="cursor: pointer"
               >
                  <div>
                     <h6>Upload Template</h6>
                     <i class="fas fa-upload"></i>
                  </div>
                  <p>
                     Upload the filled template to migrate your data
                     automatically
                  </p>
               </div>
               <input
                  class="dropzone"
                  type="file"
                  name=""
                  id=""
                  (change)="onFileSelected($event)"
                  accept=".xlsx"
                  style="display: none"
               />
            </div>
         </div>
      </ng-container>
      <ng-container *ngIf="this.excel === true && this.showErrors">
         <div class="content">
            <table>
               <tr>
                  <th>
                     <h5 class="bold">#</h5>
                  </th>
                  <th>
                     <h5 class="bold">Error</h5>
                  </th>
                  <th>
                     <h5 class="bold">Mark as fixed</h5>
                  </th>
               </tr>
               <tr *ngFor="let error of this.errorFromExcel; let i = index">
                  <td>
                     <h5>
                        {{ i + 1 }}
                     </h5>
                  </td>
                  <td>
                     <h5 id="{{ i + 1 }}">
                        {{ error }}
                     </h5>
                  </td>
                  <td>
                     <h5 (click)="changeStyle(i + 1)" style="cursor: pointer">
                        Mark
                     </h5>
                  </td>
               </tr>
            </table>
         </div>
      </ng-container>
   </main>
   <div class="noActiveLicense" *ngIf="user?.Institution?.InstitutionLicense?.length == 0 && user.roleId == 3">
      You should have an active license
    </div>
</section>
