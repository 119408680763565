import { Action, createReducer, on } from '@ngrx/store';
import * as AppState from '../../../../../../store/app.state';
import * as offersActions from '../action/offers.actions';

export const offersFeatureKey = 'offers';

export interface State extends AppState.State {
   offers: OffersState;
}

export interface OffersState {
   offers: any;
   errorMessage: string;
}

export const initialState: OffersState = {
   offers: null,
   errorMessage: null,
};

export const offersReducer = createReducer(
   initialState,
   on(offersActions.loadOffersSuccess, (state, action): OffersState => {
      return {
         ...state,
         offers: action.data,
      };
   }),

   on(offersActions.loadOffersFailure, (state, action): OffersState => {
      return {
         ...state,
         errorMessage: action.error,
      };
   })
);
