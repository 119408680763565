<div class="modalContainer">
    <div class="modalHeader">
        <h3>Permissions</h3>
        <i class="far fa-edit"></i>
    </div>
    
    <div class="btnContainer">
        <div *ngIf="!(data.isDocumentInArchive == undefined)">
            <div>
                <label [ngClass]="{ disabled: data.isDocumentInArchive == false }" for="">Give student permission to delete document in our archives <div class="tooltipDelete"><i class="fa fa-info-circle"></i><span>Enabling this option allows the student to delete the document stored in our archives.</span></div></label>
                <input name="deleteInArchive" id="deleteInArchive" [disabled]="data.isDocumentInArchive == false" [(ngModel)]="permissions.deleteInArchive" type="checkbox">
                <label for="deleteInArchive"></label>
            </div>
            <span *ngIf="data.isDocumentInArchive == false">
                Student has deleted this document from our archives
            </span>
        </div>
        
        <div>
            <div>
                <label [ngClass]="{ disabled: data.sourcesAreDeleted }" for="">Give student permission to delete document sources <div class="tooltipDelete"><i class="fa fa-info-circle"></i><span>Enabling this option allows the student to delete the sources we found that have similarities with this document.</span></div></label>
    
                <input  name="deleteSources" id="deleteSources" [disabled]="data.sourcesAreDeleted" [(ngModel)]="permissions.deleteSources"  type="checkbox">
                <label for="deleteSources"></label>
            </div>
            <span *ngIf="data.sourcesAreDeleted">
                Student has deleted sources for this document
            </span>
        </div>
        <div>
            <div>
                <label for="">Give student permission to delete everything about this document <div class="tooltipDelete"><i class="fa fa-info-circle"></i><span>Enabling this option allows the student to delete everything about this document, meaning the student has full ownership!</span></div></label>
                <input name="deleteEverything" id="deleteEverything" [(ngModel)]="permissions.deleteEverything" (change)="deleteEverything()" type="checkbox">
                <label for="deleteEverything"></label>
            </div>
        </div>
    </div>
    
    <div class="submitnBtn">
        <button (click)="submit()">Submit</button>
    </div>
</div>