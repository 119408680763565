<div class="web-import">
  <main>
    <h4>{{ "individual.upload.web_import" | translate }}</h4>
    <h5>{{ "individual.upload.enter_url" | translate }}</h5>
    <input type="text" />
    <div class="center">
      <a class="submit" href="">{{ "general.submit" | translate }}</a>
    </div>
  </main>
</div>
