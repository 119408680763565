import { createAction, props } from '@ngrx/store';

export const loadUnitsStatisticsAssignments = createAction(
   '[Units Statistics Assignments] Load Units Statistics Assignments',
   props<{
      page?: any;
      title?: string;
      faculty?: string;
      department?: string;
      subject?: string;
      professor?: string;
   }>()
);

export const loadUnitsStatisticsAssignmentsSuccess = createAction(
   '[Units Statistics Assignments] Load Units Statistics Assignments Success',
   props<{ data: any }>()
);

export const loadUnitsStatisticsAssignmentsFailure = createAction(
   '[Units Statistics Assignments] Load Units Statistics Assignments Failure',
   props<{ error: any }>()
);
