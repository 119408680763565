import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FacultyState } from '../../faculty/reducer/faculty.reducer';

/**
 * Selectors are pure functions used for obtaining slices of store state.
 */

/**
 * getInstitutionFacultiesState represent top level of the department state.
 * The createFeatureSelector is a convenience method for returning a top level feature state.
 */
const getInstitutionFacultiesState = createFeatureSelector<FacultyState>(
   'faculties'
);

/**
 * Selector used to get faculties details state.
 */
export const getInstitutionFacultiesStateDetails = createSelector(
   getInstitutionFacultiesState,
   (state) => state
);
