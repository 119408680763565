import { createFeatureSelector, createSelector } from '@ngrx/store';
import { departmentsStatisticsState } from '../reducer/units-statistics-departments.reducer';

/**
 * Selectors are pure functions used for obtaining slices of store state.
 */

/**
 * getDepartmentsStatistics represent top level of the subject state.
 * The createFeatureSelector is a convenience method for returning a top level feature state.
 */
const getDepartmentsStatistics =
   createFeatureSelector<departmentsStatisticsState>('departmentsStatistics');

/**
 * Selector used to get statisticsOverview details state.
 */
export const getDepartmentsStatisticsDetails = createSelector(
   getDepartmentsStatistics,
   (state) => state
);
