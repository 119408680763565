<h4 class="bold w-87">AI Content Prediction</h4>
<main class="shadowBorder textDetectorContainer">
    <div class="header">
        <h2>AI Content Prediction</h2>
        <p>Use our free AI Content Prediction to check if your text was written with AI.</p>
    </div>
    
    <div class="content">
        <div class="textContainer">
            <select class="selectModels"  [(ngModel)]="modelSelected" (change)="checkModel($event.target.value)">
                <option value="default">Default</option>
                <option value="model_1">Model 1</option>
                <option value="model_2">Model 2</option>
                <option value="model_3">Model 3</option>
                <option value="model_4">Model 4</option>
                <option value="model_5">Model 5</option>
                <option value="model_6">Model 6</option>
                <option value="model_7">Model 7</option>
                <option value="model_8">Model 8</option>
                <option value="model_9">Model 9</option>
                <option value="model_10">Model 10</option>
                <option value="model_11">Model 11</option>
            </select>
            <h4>Text box</h4>
            <textarea placeholder="Paste or write some text here" [(ngModel)]="text" (ngModelChange)="checkDefault()"  name="" id="" cols="30" rows="10"></textarea>
        </div>
    <div class="dataConatiner">
        <h4>Results</h4>
        <div class="border">
        <div class="resultsConatiner">
            <h4 class="contentIndex">AI Content Index<i
             class="fas fa-info-circle"
             matTooltipPosition="after"
             matTooltipClass="tooltipCustomCss"
             matTooltip="AI Content Index is in the beta phase, and results may vary and may not always be accurate. Results are provided for informational purposes only and are not intended to be relied upon for any specific purpose."
          ></i></h4>
            <div class="chartContainer">
                <div [ngStyle]="{'left.%': fakeProbability}" class="pointer">
                    <span>{{fakeProbability}}%</span>
                    <img  src="../../../assets/images/Asset_2.png" alt="">
                </div>
  
                <img src="../../../assets/images/Results-Graph.png" alt="">
            </div>
            <!-- <div id="chart">
                <apx-chart
                  [series]="chartOptions.series"
                  [chart]="chartOptions.chart"
                  [plotOptions]="chartOptions.plotOptions"
                  [labels]="chartOptions.labels"
                  [fill]="chartOptions.fill"
                ></apx-chart>
              </div> -->
              <div    [ngClass]="{
                'redBorder':
                fakeProbability > 200/3 && hasText,
                'orangeBorder':
                fakeProbability > 100/3 && fakeProbability <= 200/3 && hasText,
                'greenBorder':
                fakeProbability <= 100/3 && hasText
             }"  class="description">
                {{description}}</div>
        </div>
        <div class="rates">
            <span>Are you satisfied with the results?</span> <i [ngClass]="{ likedClicked: likedClicked }" (click)="rate('like')" class="far fa-thumbs-up"></i><i [ngClass]="{ dislikeClicked: dislikeClicked }"  (click)="rate('dislike')" class="far fa-thumbs-down"></i>
        </div>
        <h4 class="disclaimer">Disclaimer</h4>
        <div >
            <ul>
                <li>AI Content Prediction is based on a fine-tuned model of <a href="https://ai.facebook.com/blog/roberta-an-optimized-method-for-pretraining-self-supervised-nlp-systems/">RoBERTa</a> using the <a href="https://openai.com/blog/gpt-2-1-5b-release/">Open AI dataset</a></li>
                <li>AI Content Prediction is in testing phase and is not yet available for use by institutions.</li>
                <li>AI Content Prediction generates results by analyzing up to 1,000 words at a time. </li>
                <li>AI Content Prediction at this point works for English text only.</li>
            </ul>

        </div>
        </div>
    </div>
</div>
<div class="processing" *ngIf="processing">
    Hang tight while we analyze your text.<br/> This shouldn't take too long.
    <mat-spinner></mat-spinner>
</div>
</main>

