import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SubmissionsState } from '../reducer/submissions.reducer';
/**
 * Selectors are pure functions used for obtaining slices of store state.
 */

/**
 * getSubmissionsFeatureState represent top level of the submissions state.
 * The createFeatureSelector is a convenience method for returning a top level feature state.
 * It is used by other selectors to get slices of state from submissions state.
 */
const getSubmissionsFeatureState = createFeatureSelector<SubmissionsState>(
   'submissions'
);

/**
 * Selector used to get submissions details state.
 */
export const getCurrentUserSubmissionsDetails = createSelector(
   getSubmissionsFeatureState,
   (state) => state
);
/**
 * Selector used to get current user submissions graph data
 */
export const getCurrentUserSubmissionsGraphData = createSelector(
   getSubmissionsFeatureState,
   (state) => state.submissionsStatistics
);
