import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { SubmissionsService } from '../../../services/submissions.service';
import swal from 'sweetalert2';

@Component({
  selector: 'app-professor-delete-modal',
  templateUrl: './professor-delete-modal.component.html',
  styleUrls: ['./professor-delete-modal.component.scss']
})
export class ProfessorDeleteModalComponent implements OnInit {

  constructor(private spinner: NgxSpinnerService,private SubmissionsService: SubmissionsService,
    public dialog: MatDialog,
    /**
     * Used to pass data in component through dialog
     */
    @Inject(MAT_DIALOG_DATA) public data: any) { }

    permissions = {
      deleteEverything: false,
      deleteSources: false,
      deleteInArchive: false
    }

  ngOnInit(): void {
    this.permissions = this.data.permissions;
  }

  submit(){
    if(this.permissions.deleteEverything) {
      this.permissions.deleteInArchive = true,
      this.permissions.deleteSources = true
    }
    let deleteInArchiveText;
    let deleteEverythingText;
    let deleteSourcesText;
    let title="Are you sure you want to give student permissions to:";
    if(this.permissions.deleteInArchive && !(this.data.isDocumentInArchive == undefined)){
      deleteInArchiveText = '<li style="text-align: left;margin-bottom: 20px;"><b>Delete the document from our archive</b><br/> Disables the ability to detect plagiarism in this document from other students.<span style="color:red; display: block; font-weight: bold;font-size: 1rem;margin-left:20px">If student deletes it:</span><span style="color:red; display: block; font-weight: bold;font-size: 0.8rem;margin-left:20px">You will still be able to see everything</span></li>'
    }else {
      deleteInArchiveText=''
    }
     if(this.permissions.deleteEverything) {
      deleteEverythingText = '<li style="text-align: left;margin-bottom: 20px;"><b>Delete everything</b></br>This document will be permanently deleted without leaving any records behind.<span style="color:red; display: block; font-weight: bold;font-size: 1rem;;margin-left:20px">If student deletes it:</span><span style="color:red; display: block; font-weight: bold;font-size: 0.8rem;margin-left:20px">THIS DOCUMENT WILL BE DELETED PERMANENTL</span></li>'
    } else {
      deleteEverythingText=''
    }
    if(this.permissions.deleteSources) {
      deleteSourcesText = '<li style="text-align: left;margin-bottom: 20px;"><b>Delete the sources</b></br>Removes any source that we found that has similarities with this document<span style="color:red; display: block; font-weight: bold;font-size: 1rem;margin-left:20px">If student deletes it:</span><span style="color:red; display: block; font-weight: bold;font-size: 0.8rem;margin-left:20px">You will still be able to see the percentage of similarity</span></li>'
    }else {
      deleteSourcesText=''
    }
    if(!deleteInArchiveText && !deleteSourcesText && !deleteEverythingText) {
      title = "Student won't be able to delete anything"
    }
    swal.fire({
      title: title,
      html:
      deleteInArchiveText +
      deleteSourcesText +
      deleteEverythingText,
      icon: 'warning',
      showDenyButton: true,
      confirmButtonText: `Yes`,
      denyButtonText: `No`,
   })
   .then((result) => {
      if (result.isConfirmed) {
         this.spinner.show('loading');
         this.SubmissionsService.deletionPermissions(this.data.submissionId,this.permissions)
         .pipe()
         .subscribe(
            (data: any) => {
               this.spinner.hide('loading');
               swal.fire('', 'Permissions changed successfully', 'info');
            },
            (error) => {
               console.log('error', error);
               this.spinner.hide('loading');
            }
         );
      }
   });
  }

  deleteEverything() {
    
    if(this.permissions.deleteEverything) {
      this.permissions.deleteInArchive = true,
      this.permissions.deleteSources = true
    }
      }

}
