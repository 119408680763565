import { createAction, props } from '@ngrx/store';

/**
 * Actions related to Subjects.
 * Each action accepts a set of properties.
 * Each action most of the cases has a success and failure action, that updates the store based on successfully/failure api responses from api.
 */

/**
 * Action used to register a subject.
 */
export const overviewStatistics = createAction(
   '[Overview Statistics] Overview Statistics'
);

/**
 * Action is called when there is a success status returned from API.
 */
export const overviewStatisticsSuccess = createAction(
   '[Overview Statistics] Overview Statistics Success',
   props<{ data: any }>()
);

/**
 * Action is called when there is a failure status returned from API.
 */
export const overviewStatisticsFailure = createAction(
   '[Overview Statistics] Overview Statistics Failure',
   props<{ error: any }>()
);
