import * as AppState from '../../../../../../store/app.state';
import * as facultiesActions from '../action/units-statistics-faculties.actions';
import { createReducer } from '@ngrx/store';
import { on } from '@ngrx/store';

/**
 * subject reducer used to updated store values based on certain action.
 */

/**
 * State interface extends App state State and add user state to the main state.
 */
export interface State extends AppState.State {
   facultiesStatistics: facultiesStatisticsState;
}

/**
 * An SubjectState interface, which represents the blueprint of the User State.
 */
export interface facultiesStatisticsState {
   facultiesStatistics: any;
   facultiesNumber: number;
   errorMessage: string;
}

/**
 * SubjectState initial state.
 */
export const initialState: facultiesStatisticsState = {
   facultiesStatistics: null,
   facultiesNumber: null,
   errorMessage: null,
};

/**
 * Subject Reducer used to update the state upon Subject actions.
 */
export const facultiesStatisticsReducer = createReducer(
   initialState,
   /**
    * On loadSubjectsDetailsSuccess action updated current state with the following properties.
    */
   on(
      facultiesActions.loadUnitsStatisticsFacultiesSuccess,
      (state, action): facultiesStatisticsState => {
         return {
            ...state,
            facultiesStatistics: action.data.facultyDetails,
            facultiesNumber: action.data.facultiesNumber,
         };
      }
   ),

   /**
    * On loadSubjectsDetailsFailure action updated current state with the following properties.
    */
   on(
      facultiesActions.loadUnitsStatisticsFacultiesFailure,
      (state, action): facultiesStatisticsState => {
         return {
            ...state,
            errorMessage: action.error,
         };
      }
   )
);
