import * as AppState from '../../../../store/app.state';
import * as thesisActions from '../action/thesis.actions';
import { createReducer } from '@ngrx/store';
import { on } from '@ngrx/store';

/**
 * thesis reducer used to updated store values based on certain action.
 */

/**
 * State interface extends App state State and add user state to the main state.
 */
export interface State extends AppState.State {
   thesis: ThesisState;
}

/**
 * An ThesisState interface, which represents the blueprint of the User State.
 */
export interface ThesisState {
   thesis: [];
   errorMessage: string;
}

/**
 * ThesisState initial state.
 */
export const initialState: ThesisState = {
   thesis: null,
   errorMessage: null,
};

/**
 * Thesis Reducer used to update the state upon Thesis actions.
 */
export const thesisReducer = createReducer(
   initialState,
   /**
    * On loadThesisDetailsSuccess action updated current state with the following properties.
    */ on(
      thesisActions.loadThesisDetailsSuccess,
      (state, action): ThesisState => {
         return {
            ...state,
            thesis: action.thesis,
         };
      }
   )
);
