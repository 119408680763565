import { Component, Inject, OnInit } from '@angular/core';
import {
   MatDialog,
   MatDialogRef,
   MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import {
   FormBuilder,
   FormControl,
   FormGroup,
   Validators,
} from '@angular/forms';
import * as individualAdministratorActions from '../../state/action/individualAdministrator.actions';

@Component({
   selector: 'app-draft-message-request',
   templateUrl: './draft-message-request.component.html',
   styleUrls: ['./draft-message-request.component.scss'],
})
export class DraftMessageRequestComponent implements OnInit {
   draftMessage: FormGroup;
   submissionId: string;
   page: number;
   title: string;
   constructor(
      public dialogRef: MatDialogRef<DraftMessageRequestComponent>,
      public dialog: MatDialog,
      private store: Store,
      private fb: FormBuilder,
      /**
       * Used to pass data in component through dialog
       */
      @Inject(MAT_DIALOG_DATA) public data: any
   ) {}

   ngOnInit(): void {
      this.submissionId = this.data.submissionId;
      this.page = this.data.page;
      this.title = this.data.title;

      this.draftMessage = this.fb.group({
         message: new FormControl(''),
      });
   }

   /**
    * Method use to get form controls.
    */
   get form() {
      return this.draftMessage.controls;
   }

   submit() {
      this.store.dispatch(
         individualAdministratorActions.toggleDraft({
            id: this.submissionId,
            message: this.form.message.value,
            page: this.page,
            title: this.title,
         })
      );
      this.store.dispatch(
         individualAdministratorActions.administratorIndividualDashboardDetails(
            { page: this.page, title: this.title }
         )
      );
      this.dialogRef.close();
   }
}
