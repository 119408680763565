import { createFeatureSelector, createSelector } from '@ngrx/store';
import { StudentState } from '../../student/reducer/student.reducer';

/**
 * Selectors are pure functions used for obtaining slices of store state.
 */

/**
 * getInstitutionStudentsState represent top level of the student state.
 * The createFeatureSelector is a convenience method for returning a top level feature state.
 */
const getInstitutionStudentsState = createFeatureSelector<StudentState>(
   'students'
);

/**
 * Selector used to get students details state.
 */
export const getInstitutionStudentsStateDetails = createSelector(
   getInstitutionStudentsState,
   (state) => state
);
