import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';
import { getCurrentUser } from 'src/app/authModule/state/authentication.selectors';
import { UserService } from 'src/app/services/user.service';

@Component({
   selector: 'app-contact-us-bank',
   templateUrl: './contact-us-bank.component.html',
   styleUrls: ['./contact-us-bank.component.scss'],
})
export class ContactUsBankComponent implements OnInit, OnDestroy {
   /**
    * Form group to contact us form values
    */
   contactUsForm: FormGroup;
   /**
    * User Subscriber
    */
   user$;
   /**
    * Storing current user data from the store.
    */
   user;
   /**
    * Component Constructor
    * @param fb
    * @param userService
    * @param store
    * @param spinner
    * @param toastrService
    */
   constructor(
      private fb: FormBuilder,
      private userService: UserService,
      private store: Store,
      private spinner: NgxSpinnerService,
      private toastrService: ToastrService
   ) {}
   /**
    * Method used to be called whenever the component is loaded and all the methods in it, will be triggered.
    */
   ngOnInit(): void {
      this.user$ = this.store.select(getCurrentUser).subscribe((data) => {
         if (data !== null) {
            this.user = data;
         }
      });
      this.contactUsForm = this.fb.group({
         fullName: [this.user.name, [Validators.required]],
         email: [this.user.email, [Validators.email, Validators.required]],
         phoneNumber: ['', [Validators.required]],
         message: ['', Validators.required],
      });
   }
   /**
    * Method is called when a directive, pipe, or service is destroyed. Use for any custom cleanup that needs to occur when the instance is destroyed. In our case we will use to unsubscribe.
    */
   ngOnDestroy() {
      this.user$.unsubscribe();
   }

   /**
    * Method used to send email from contact us form.
    */
   sendMessage() {
      this.spinner.show();
      this.userService
         .sendMessage(
            this.formControls.fullName.value,
            this.formControls.email.value,
            this.formControls.phoneNumber.value,
            this.formControls.message.value,
            this.user.id,
            'bank'
         )
         .pipe(first())
         .subscribe((data) => {
            this.spinner.hide();
            this.contactUsForm.controls['phoneNumber'].reset();
            this.contactUsForm.controls['message'].reset();
            this.toastrService.success(data.message);
         }),
         (error) => {
            console.log('error', error);
         };
   }
   /**
    * Method used to get from controls from the contactUsForm.
    */
   get formControls() {
      return this.contactUsForm.controls;
   }
   /**
    * Method used to show a toaster with a warning message to the user.
    * @param message
    */
   showToasterWarning(message) {
      this.toastrService.warning(message);
   }
}
