import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, map, switchMap } from 'rxjs/operators';
import { StoreService } from 'src/app/services/store.service';

import * as offersActions from '../action/offers.actions';

@Injectable()
export class OffersEffects {
   constructor(private actions$: Actions, private storeService: StoreService) {}

   getOffers$ = createEffect(() => {
      return this.actions$.pipe(
         ofType(offersActions.loadOffers),
         concatMap((action) => {
            return this.storeService.offers().pipe(
               map((data) => {
                  return offersActions.loadOffersSuccess({
                     data: data,
                  });
               }),
               catchError((error) =>
                  of(offersActions.loadOffersFailure({ error }))
               )
            );
         })
      );
   });

   createOffer$ = createEffect(() => {
      return this.actions$.pipe(
         ofType(offersActions.createOffer),
         concatMap((action) => {
            console.log('action', action);
            return this.storeService
               .createOffer(
                  action.title,
                  action.commitment,
                  action.bachelor,
                  action.master,
                  action.phd,
                  action.administratorAccounts,
                  action.studentAccounts,
                  action.crossLingual,
                  action.professorUploads,
                  action.repositories,
                  action.billingPlan,
                  action.subscriptions,
                  action.support,
                  action.professorAccounts,
                  action.teachingAssistantAccounts,
                  action.academicIntegrityOfficerAccounts,
                  action.sentEmail
               )
               .pipe(
                  switchMap((data) => [
                     offersActions.loadOffers(),
                     offersActions.createOfferSuccess(),
                  ]),
                  catchError((error) =>
                     of(offersActions.createOfferFailure({ error }))
                  )
               );
         })
      );
   });

   editOffer$ = createEffect(() => {
      return this.actions$.pipe(
         ofType(offersActions.editOffer),
         concatMap((action) => {
            return this.storeService
               .editOffer(
                  action.id,
                  action.title,
                  action.commitment,
                  action.bachelor,
                  action.master,
                  action.phd,
                  action.administratorAccounts,
                  action.studentAccounts,
                  action.crossLingual,
                  action.professorUploads,
                  action.repositories,
                  action.billingPlan,
                  action.subscriptions,
                  action.support,
                  action.professorAccounts,
                  action.teachingAssistantAccounts,
                  action.academicIntegrityOfficerAccounts,   
                     action.sentEmail            )
               .pipe(
                  switchMap((data) => [
                     offersActions.loadOffers(),
                     offersActions.editOfferSuccess(),
                  ]),
                  catchError((error) =>
                     of(offersActions.editOfferFailure({ error }))
                  )
               );
         })
      );
   });

   sentEmailForCustomOffer$ = createEffect(() => {
      return this.actions$.pipe(
         ofType(offersActions.sentEmailForCustomOffer),
         concatMap((action) => {
            return this.storeService
               .sentEmailForCustomOffer(
                  action.name,
                  action.email,
                  action.subject,
                  action.message,
                  action.offerId
                  )
               .pipe(
                  switchMap((data) => [
                     offersActions.sentEmailForCustomOfferSuccess(),
                  ]),
                  catchError((error) =>
                     of(offersActions.sentEmailForCustomOfferFailure({ error }))
                  )
               );
         })
      );
   });

   deleteOffer$ = createEffect(() => {
      return this.actions$.pipe(
         ofType(offersActions.deleteOffer),
         concatMap((action) => {
            return this.storeService.deleteOffer(action.id).pipe(
               map((data) => {
                  return offersActions.loadOffers();
               }),
               catchError((error) =>
                  of(offersActions.createOfferFailure({ error }))
               )
            );
         })
      );
   });
}
