import { createAction, props } from '@ngrx/store';

export const loadOffers = createAction('[Offers] Load Offers');

export const loadOffersSuccess = createAction(
   '[Offers] Load Offers Success',
   props<{ data: any }>()
);

export const loadOffersFailure = createAction(
   '[Offers] Load Offers Failure',
   props<{ error: any }>()
);

export const createOffer = createAction(
   '[Offers] Create Offers',
   props<{
      title: string;
      commitment: number;
      bachelor: number;
      master: number;
      phd: number;
      administratorAccounts: number;
      studentAccounts: number;
      crossLingual: Object;
      professorUploads: number;
      repositories: any;
      billingPlan: string;
      subscriptions: any;
      professorAccounts: number;
      teachingAssistantAccounts: number;
      academicIntegrityOfficerAccounts: number;
      support: string;
      sentEmail: boolean;
   }>()
);

export const createOfferFailure = createAction(
   '[Offers] Create Offers Failure',
   props<{ error: any }>()
);

export const createOfferSuccess = createAction(
   '[Offers] Create Offers Success'
);

export const editOffer = createAction(
   '[Offers] Edit Offers',
   props<{
      id: string;
      title: string;
      commitment: number;
      bachelor: number;
      master: number;
      phd: number;
      administratorAccounts: number;
      studentAccounts: number;
      crossLingual: Object;
      professorUploads: number;
      repositories: any;
      billingPlan: string;
      subscriptions: any;
      professorAccounts: number;
      teachingAssistantAccounts: number;
      academicIntegrityOfficerAccounts: number;
      support: string;
      sentEmail: boolean;
   }>()
);

export const editOfferFailure = createAction(
   '[Offers] Edit Offers Failure',
   props<{ error: any }>()
);

export const editOfferSuccess = createAction('[Offers] Edit Offers Success');

export const sentEmailForCustomOffer = createAction(
   '[Offers] Sent Email For Custom Offers',
   props<{
      name: string;
      email: string;
      subject: number;
      message: number;
      offerId: number;
   }>()
);

export const sentEmailForCustomOfferFailure = createAction(
   '[Offers] Sent Email For Custom Offers Failure',
   props<{ error: any }>()
);

export const sentEmailForCustomOfferSuccess = createAction('[Offers] Sent Email For Custom Offers Success');

export const deleteOffer = createAction(
   '[Offers] Delete Offers',
   props<{
      id: string;
   }>()
);

export const deleteOfferFailure = createAction(
   '[Offers] Delete Offers Failure',
   props<{ error: any }>()
);
