import { Component, OnInit } from '@angular/core';

@Component({
   selector: 'app-faqs',
   templateUrl: './faqs.component.html',
   styleUrls: ['./faqs.component.scss'],
})
export class FaqsComponent implements OnInit {
   expandedIndex;
   i;
   numbers;
   faqs;

   constructor() {
      this.numbers = Array(14)
         .fill(1)
         .map((x, i) => i);
   }

   ngOnInit(): void {
      this.expandedIndex = -1;
   }
   expandRow(index: number): void {
      this.expandedIndex = index === this.expandedIndex ? -1 : index;
   }
}
