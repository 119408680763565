import { Component, Inject, OnInit } from '@angular/core';
import {
   FormControl,
   FormBuilder,
   FormGroup,
   Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { first } from 'rxjs/operators';
import { UserService } from 'src/app/services/user.service';
import { ToastrService } from 'ngx-toastr';
import * as individualAdministratorActions from '../../state/action/individualAdministrator.actions';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
   selector: 'app-edit-user',
   templateUrl: './edit-user.component.html',
   styleUrls: ['./edit-user.component.scss'],
})
export class EditUserComponent implements OnInit {
   /**
    * Form group used to get user input
    */
   studentEdit: FormGroup;
   languages = [
      {
         text: 'English',
         value: 'en',
         selectedDocLanguage: false,
         selectedTranslateLanguage: false,
      },
      {
         text: 'Albanian',
         value: 'sq',
         selectedDocLanguage: false,
         selectedTranslateLanguage: false,
      },
      {
         text: 'German',
         value: 'de',
         selectedDocLanguage: false,
         selectedTranslateLanguage: false,
      },
      {
         text: 'Italian',
         value: 'it',
         selectedDocLanguage: false,
         selectedTranslateLanguage: false,
      },
      {
         text: 'French',
         value: 'fr',
         selectedDocLanguage: false,
         selectedTranslateLanguage: false,
      },
      {
         text: 'Spanish',
         value: 'es',
         selectedDocLanguage: false,
         selectedTranslateLanguage: false,
      },
      {
         text: 'Greek',
         value: 'el',
         selectedDocLanguage: false,
         selectedTranslateLanguage: false,
      },
      {
         text: 'Czech',
         value: 'cs',
         selectedDocLanguage: false,
         selectedTranslateLanguage: false,
      },
      {
         text: 'Turkish',
         value: 'tr',
         selectedDocLanguage: false,
         selectedTranslateLanguage: false,
      },
      {
         text: 'Slovak',
         value: 'sk',
         selectedDocLanguage: false,
         selectedTranslateLanguage: false,
      },
      {
         text: 'Lithuanian',
         value: 'lt',
         selectedDocLanguage: false,
         selectedTranslateLanguage: false,
      },
      {
         text: 'Latvian',
         value: 'lv',
         selectedDocLanguage: false,
         selectedTranslateLanguage: false,
      },
      {
         text: 'Polish',
         value: 'pl',
         selectedDocLanguage: false,
         selectedTranslateLanguage: false,
      },
      {
         text: 'Serbian',
         value: 'sr',
         selectedDocLanguage: false,
         selectedTranslateLanguage: false,
      },
      {
         text: 'Macedonian',
         value: 'mk',
         selectedDocLanguage: false,
         selectedTranslateLanguage: false,
      },
      {
         text: 'Portuguese',
         value: 'pt',
         selectedDocLanguage: false,
         selectedTranslateLanguage: false,
      },
      {
         text: 'Dutch',
         value: 'nl',
         selectedDocLanguage: false,
         selectedTranslateLanguage: false,
      },
      {
         text: 'Russian',
         value: 'ru',
         selectedDocLanguage: false,
         selectedTranslateLanguage: false,
      },
      {
         text: 'Bulgarian',
         value: 'bg',
         selectedDocLanguage: false,
         selectedTranslateLanguage: false,
      },
      {
         text: 'Hungarian',
         value: 'hu',
         selectedDocLanguage: false,
         selectedTranslateLanguage: false,
      },
      {
         text: 'Romanian',
         value: 'ro',
         selectedDocLanguage: false,
         selectedTranslateLanguage: false,
      },
      {
         text: 'Slovenian',
         value: 'sl',
         selectedDocLanguage: false,
         selectedTranslateLanguage: false,
      },
      {
         text: 'Swedish',
         value: 'sv',
         selectedDocLanguage: false,
         selectedTranslateLanguage: false,
      },
      {
         text: 'Finnish',
         value: 'fi',
         selectedDocLanguage: false,
         selectedTranslateLanguage: false,
      },
      {
         text: 'Croatian',
         value: 'hr',
         selectedDocLanguage: false,
         selectedTranslateLanguage: false,
      },
      {
         text: 'Bosnian',
         value: 'bs',
         selectedDocLanguage: false,
         selectedTranslateLanguage: false,
      },
      {
         text: 'Norwegian',
         value: 'no',
         selectedDocLanguage: false,
         selectedTranslateLanguage: false,
      },
   ];
   selectedUser;
   constructor(
      private fb: FormBuilder,
      private userService: UserService,
      public dialogRef: MatDialogRef<EditUserComponent>,
      /**
       * Used to pass data in component through dialog
       */
      @Inject(MAT_DIALOG_DATA) public data: any,
      private store: Store,
      private toastrService: ToastrService,
      private spinner: NgxSpinnerService
   ) {}

   ngOnInit(): void {
      this.selectedUser = this.data.user;

      this.studentEdit = this.fb.group({
         studentName: new FormControl(
            this.selectedUser.name,
            Validators.required
         ),
         studentEmail: new FormControl(
            this.selectedUser.email,
            Validators.required
         ),
         documentLanguage: new FormControl(
            this.selectedUser.allowedLanguages.documentLanguage,
            Validators.required
         ),
         translateTo: new FormControl(
            this.selectedUser.allowedLanguages.targetLanguage,
            Validators.required
         ),
      });

      for (let language of this.languages) {
         if (
            this.selectedUser.allowedLanguages.documentLanguage ===
            language.text
         ) {
            this.form.documentLanguage.setValue(language.value);
            language.selectedDocLanguage = true;
         }

         if (
            this.selectedUser.allowedLanguages.targetLanguage === language.text
         ) {
            this.form.translateTo.setValue(language.value);
            language.selectedTranslateLanguage = true;
         }
      }
   }

   resendActivationEmail() {
      this.spinner.show();
      this.userService
         .resendConfirmationEmail(this.selectedUser.id)
         .pipe(first())
         .subscribe(
            (data) => {
               this.spinner.hide();
               this.toastrService.success(data.message);
            },
            (error) => {
               this.spinner.hide();
               console.log('error', error);
            }
         );
   }

   /**
    * Method use to get form controls.
    */
   get form() {
      return this.studentEdit.controls;
   }

   languageSelected(type) {
      if (type === 1) {
         for (let language of this.languages) {
            language.selectedDocLanguage = false;
            if (language.value === this.form.documentLanguage.value) {
               language.selectedDocLanguage = true;
            }
         }
      } else {
         for (let language of this.languages) {
            language.selectedTranslateLanguage = false;
            if (language.value === this.form.translateTo.value) {
               language.selectedTranslateLanguage = true;
            }
         }
      }
   }

   update() {
      this.store.dispatch(
         individualAdministratorActions.editStudent({
            id: this.selectedUser.id,
            studentName: this.form.studentName.value,
            studentEmail: this.form.studentEmail.value,
            documentLanguage: this.form.documentLanguage.value,
            translateTo: this.form.translateTo.value,
            page: this.data.page,
         })
      );
      this.dialogRef.close();
   }
}
