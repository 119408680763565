import { Sentence } from './../../../models/sentence';
import { Source } from './../../../models/source';
import { Submissions } from 'src/app/models/submissions';
import { createAction, props } from '@ngrx/store';

/**
 * Actions related to report.
 * Each action accepts a set of properties.
 * Each action most of the cases has a success and failure action, that updates the store based on successfully/failure api responses from api.
 */

/**
 * Action used to call api load current submission/document opened in report.
 * It accepts id of submission, so we can know which submission we are loading.
 */
export const loadCurrentSubmission = createAction(
   '[Report Component] Load Current Submission Details',
   props<{ submissionId: string }>()
);
/**
 * Upon successful api response we get current submission details. And then we use it to update the state. Used in report component.
 */
export const loadCurrentSubmissionSuccess = createAction(
   '[Report Component] Load Current Submission Details Success',
   props<{
      submission: Submissions;
   }>()
);
/**
 * Upon unsuccessful api response we get error message. And then we use it to update the state. Used in report component.
 */
export const loadCurrentSubmissionFailure = createAction(
   '[Report Component] Load Current Submission Details Failure',
   props<{ error: any }>()
);

/**
 * Action used to get selected sentence source id. And assign it to the store through setCurrentSourceIdSuccess action.
 */
export const setCurrentSentenceId = createAction(
   '[Report Component] Load Sentence Id',
   props<{ currentSentenceId: number }>()
);

/**
 * Upon successfully api response we save current source id to the store.
 */
export const setCurrentSentenceIdSuccess = createAction(
   '[Report Component] Load Sentence Id Success',
   props<{ currentSentenceId: number }>()
);
/**
 * Upon unsuccessful api response we get error message. And then we use it to update the state.
 */
export const setCurrentSentenceIdFailure = createAction(
   '[Report Component] Load Sentence Id Failure',
   props<{ error: any }>()
);

/**
 * Action used to trigger api call to exclude source sentence plagiarism from calculation.
 */
export const excludeSentence = createAction(
   '[Sentence Info Modal] Exclude Sentence',
   props<{
      source: Source;
      sentence: Sentence;
      sourceType: boolean;
      submissionId: string;
      comment?: string;
   }>()
);

/**
 * Upon successful api response we get updated source, then we use it to update the state. Used in report component.
 */
export const excludeSentenceSuccess = createAction(
   '[Sentence Info Modal] Exclude Sentence Success',
   props<{
      source: Source;
      updatedSentence: Sentence;
      sourceType: boolean;
      comment?: string;
   }>()
);
/**
 * Upon unsuccessful api response we get error message, then use it to update the state. Used in report component.
 */
export const excludeSentenceFailure = createAction(
   '[Sentence Info Modal]  Exclude Sentence Failure',
   props<{ error: string }>()
);

/**
 * Action used to trigger api call to exclude source from calculation.
 */
export const excludeSource = createAction(
   '[Report Component] Exclude Source',
   props<{
      source: Source;
      submissionId: string;
      originalLanguage: boolean;
      exclude: boolean;
      excludeUrlId: string;
      excludeUrlPrecentage: string;
   }>()
);

/**
 * Upon successful api response we get updated source, then we use it to update the state. Used in report component.
 */
export const excludeSourceSuccess = createAction(
   '[Report Component] Exclude Source Success',
   props<{
      source;
      submissionId: string;
      sourceType: boolean;
      exclude: boolean;
      excludeUrlId: string;
      excludeUrlPrecentage: string;
   }>()
);

/**
 * Upon unsuccessful api response we get error message, then use it to update the state. Used in report component.
 */
export const excludeSourceFailure = createAction(
   '[Report Component]  Exclude Source Failure',
   props<{ error: string }>()
);

/**
 * Upon unsuccessful api response we get error message, then use it to update the state. Used in report component.
 */
export const includeSourceFailure = createAction(
   '[Report Component]  Include Source Failure',
   props<{ error: string }>()
);

/**
 * Action used to trigger api call to include source sentence plagiarism from calculation.
 */
export const includeSentence = createAction(
   '[Sentence Info Modal] Include Sentence',
   props<{
      source: Source;
      sentence: Sentence;
      sourceType: boolean;
      submissionId: string;
   }>()
);

/**
 * Upon successful api response we get updated source, then we use it to update the state. Used in report component.
 */
export const includeSentenceSuccess = createAction(
   '[Sentence Info Modal] Include Sentence Success',
   props<{
      source: Source;
      updatedSentence: Sentence;
      sourceType: boolean;
      comment?: string;
   }>()
);
/**
 * Upon unsuccessful api response we get error message, then use it to update the state. Used in report component.
 */
export const includeSentenceFailure = createAction(
   '[Sentence Info Modal]  Include Sentence Failure',
   props<{ error: string }>()
);
/**
 * Action used to trigger source checkbox check. Used in Sentence Information Modal
 */
export const checkCheckbox = createAction(
   '[Report Component] Check Checkbox',
   props<{ source: Source; sourceType: boolean }>()
);
/**
 * Action used to update, source check status upon in state. Used in Sentence Information Modal
 */
export const checkCheckboxSuccess = createAction(
   '[Report Component] Check Checkbox Success',
   props<{ source: Source; sourceType: boolean }>()
);
/**
 * Used in case of error to show error message. Used in Sentence Information Modal
 */
export const checkCheckboxFailure = createAction(
   '[Report Component] Check Checkbox Failure',
   props<{ error: string }>()
);

/**
 * Action used to trigger source checkbox check. Used in Sentence Information Modal
 */
export const checkCheckboxExclude = createAction(
   '[Report Component] Check Checkbox Excluded',
   props<{ source: Source; sourceType: boolean }>()
);
/**
 * Action used to update, source check status upon in state. Used in Sentence Information Modal
 */
export const checkCheckboxExcludeSuccess = createAction(
   '[Report Component] Check Checkbox Excluded Success',
   props<{ source: Source; sourceType: boolean }>()
);
/**
 * Used in case of error to show error message. Used in Sentence Information Modal
 */
export const checkCheckboxExcludeFailure = createAction(
   '[Report Component] Check Checkbox Excluded Failure',
   props<{ error: string }>()
);
/**
 * Action used to trigger source uncheck checkbox. Used in Sentence Information Modal
 */
export const unCheckCheckbox = createAction(
   '[Report Component] UnCheck Checkbox',
   props<{ source: Source; sourceType: boolean }>()
);
/**
 * Action used to update, source check status upon in state. Used in Sentence Information Modal
 */
export const unCheckCheckboxSuccess = createAction(
   '[Report Component] UnCheck Checkbox Success',
   props<{ source: Source; sourceType: boolean }>()
);
/**
 * Used in case of error to show error message. Used in Sentence Information Modal
 */
export const unCheckCheckboxFailure = createAction(
   '[Report Component] UnCheck Checkbox Failure',
   props<{ error: string }>()
);

/**
 * Action used to trigger source uncheck checkbox. Used in Sentence Information Modal
 */
export const unCheckCheckboxExclude = createAction(
   '[Report Component] UnCheck Excluded Checkbox',
   props<{ source: Source; sourceType: boolean }>()
);
/**
 * Action used to update, source check status upon in state. Used in Sentence Information Modal
 */
export const unCheckCheckboxExcludeSuccess = createAction(
   '[Report Component] UnCheck Checkbox Excluded Success',
   props<{ source: Source; sourceType: boolean }>()
);
/**
 * Used in case of error to show error message. Used in Sentence Information Modal
 */
export const unCheckCheckboxExcludeFailure = createAction(
   '[Report Component] UnCheck Checkbox Excluded Failure',
   props<{ error: string }>()
);
/**
 * Action used to get submission plagiarism.
 */
export const getSubmissionPlag = createAction(
   '[Report Component] Get Submission Plag',
   props<{ presignedUrl }>()
);
/**
 * Action used in case of successful response update store state with it's properties.
 */
export const getSubmissionPlagSuccess = createAction(
   '[Report Component] Get Submission Plag Success',
   props<{
      submission: Submissions;
      sources: Source[];
      mlSources: Source[];
      excludedSourcesMlPlag: Source[];
      excludedSourcesCrossPlag: Source[];
      originalNavigationArray: [];
      translatedNavigationArray: [];
   }>()
);
/**
 * In case of error response show error message.
 */
export const getSubmissionPlagFailure = createAction(
   '[Report Component] Get Submission Plag Failure',
   props<{ error: string }>()
);

/**
 * Action used to trigger reset filter for sources in report component.
 */
export const resetFilter = createAction(
   '[Report Component] Reset Filter',
   props<{ sources: Source[] }>()
);
/**
 * In case of success reset filters of sources in report component.
 */
export const resetFilterSuccess = createAction(
   '[Report Component] Reset Filter Success',
   props<{ sources: Source[] }>()
);
/**
 * In case of failure show error message.
 */
export const resetFilterFailure = createAction(
   '[Report Component] Reset Filter Failure',
   props<{ error: string }>()
);
/**
 * Action used to trigger add IsChecked property to source. This action doesn't trigger api call.
 */
export const addIsCheckedProp = createAction(
   '[Report Component] Add Is Checked Prop'
);
/**
 * In case of success add is checked property to source.
 */
export const addIsCheckedPropSuccess = createAction(
   '[Report Component] Add Is Checked Prop Success'
);
/**
 * In case of error update comment failure action, update state with error message.
 */
export const addIsCheckedPropFailure = createAction(
   '[Report Component] Add Is Checked Prop Failure',
   props<{ error: string }>()
);
/**
 * Action used to trigger update sentence comment
 */
export const updateComment = createAction(
   '[Sentence Info Modal Component] Updated Comment',
   props<{
      source: Source;
      sourceType: boolean;
      sentence: Sentence;
      comment: string;
      submissionId: string;
   }>()
);
/**
 * Called upon successful update comment success action, update the store with action properties.
 */
export const updateCommentSuccess = createAction(
   '[Sentence Info Modal Component] Updated Comment Success',
   props<{
      source: Source;
      sourceType: boolean;
      sentence: Sentence;
      comment: string;
   }>()
);
/**
 * Called upon unsuccessful update comment failure action, update state with error message.
 */
export const updateCommentFailure = createAction(
   '[Sentence Info Modal Component] Updated Comment Failure',
   props<{ error: string }>()
);
/**
 * Used to trigger delete a sentence comment.
 */
export const deleteComment = createAction(
   '[Sentence Info Modal Component] Delete Comment',
   props<{
      source: Source;
      sourceType: boolean;
      sentence: Sentence;
      submissionId: string;
   }>()
);
/**
 * Called upon successful deleteComment action, update the store with action properties.
 */
export const deleteCommentSuccess = createAction(
   '[Sentence Info Modal Component] Delete Comment Success',
   props<{
      source: Source;
      sourceType: boolean;
      sentence: Sentence;
   }>()
);
/**
 * Called upon unsuccessful deleteComment action, update state with error message.
 */
export const deleteCommentFailure = createAction(
   '[Sentence Info Modal Component] Delete Comment Failure',
   props<{ error: string }>()
);

/**
 * Action used to call API to change sentence source.
 */
export const changeSource = createAction(
   '[Sentence Info Modal] Change sentence source',
   props<{
      sourceId: string;
      sentenceId: number;
      submissionId: string;
      originalLanguage: boolean;
   }>()
);

// !!! WIP
/**
 * Upon successful api response we get activeSource details. And then we use it to update the state. Used in report component.
 */
export const changeSourceSuccess = createAction(
   '[Sentence Info Modal] Change sentence source Success',
   props<{
      sourceId: string;
      sentenceId: number;
      originalLanguage: boolean;
   }>()
);
// !!! WIP
/**
 * Upon unsuccessful api response we get error message. And then we use it to update the state. Used in report component.
 */
export const changeSourceFailure = createAction(
   '[Sentence Info Modal] Change sentence source Failure',
   props<{ error: any }>()
);

/**
 * Upon successful api response we toggle similarity percentage between with or without quotes.
 */
export const toggleQuotesSuccess = createAction(
   '[Report Component] Toggle quotes Success'
);
/**
 * Upon unsuccessful api response we get error message. And then we use it to update the state. Used in report component.
 */
export const toggleQuotesFailure = createAction(
   '[Report Component] Toggle quotes Failure',
   props<{ error: any }>()
);
