<section class="upload">
   <h4 class="bold w-87">{{ 'general.upload' | translate }}</h4>
   <div>
      <div class="links">
         <a routerLink="upload-file" routerLinkActive="active">{{
            'individual.upload.file_upload' | translate
         }}</a>
         <!-- routerLink="input-text" routerLinkActive="active" -->
         <a  *ngIf="userRole == 4" routerLink="text-input"  routerLinkActive="active">Bulk upload</a>
         <!-- routerLink="web-import" routerLinkActive="active" -->
         <!-- <a style="opacity: 0.5; pointer-events: none">{{
            'individual.upload.web_import' | translate
         }}</a> -->
      </div>
      <router-outlet></router-outlet>
   </div>
</section>
