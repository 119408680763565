import { createFeatureSelector, createSelector } from '@ngrx/store';
import { insProfessorsStatisticsState } from '../reducer/institutional-professors-statistics.reducer';

/**
 * Selectors are pure functions used for obtaining slices of store state.
 */

/**
 * getInstitutionalProfessorsStatistics represent top level of the assignment state.
 * The createFeatureSelector is a convenience method for returning a top level feature state.
 */
const getInstitutionalProfessorsStatistics =
   createFeatureSelector<insProfessorsStatisticsState>(
      'insProfessorsStatistics'
   );

/**
 * Selector used to get statisticsOverview details state.
 */
export const getInstitutionalProfessorsStatisticsDetails = createSelector(
   getInstitutionalProfessorsStatistics,
   (state) => state
);
