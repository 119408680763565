// *** Angular
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

// *** Actions
import * as userStatisticsActions from './state/action/users-statistics.actions';

// *** Selectors
import { getUsersStatisticsOverviewDetails } from './state/selector/users-statistics.selectors';
@Component({
   selector: 'app-users',
   templateUrl: './users.component.html',
   styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit, OnDestroy {
   constructor(private store: Store) {}
   ngOnDestroy(): void {
      this.overview$.unsubscribe();
   }
   overview;
   overview$;
   ngOnInit(): void {
      this.store.dispatch(userStatisticsActions.userStatistics());

      this.overview$ = this.store
         .select(getUsersStatisticsOverviewDetails)
         .subscribe((data) => {
            if (data !== null) {
               this.overview = data.usersStatisticsOverview;
            }
         });
   }
}
