<div style="padding-bottom: 2%">
   <div>
      <span class="documentInformation"> Source Information </span>
   </div>
   <div style="display: flex">
      <div style="width: 50%">
         <span class="documentInformation"> Document Information </span>
         <div class="center">
            <span style="font-weight: 600">Document Title: </span>
            <span> {{ this.data.submission.title }}</span>
         </div>
         <div class="center">
            <span style="font-weight: 600">Date of upload: </span>
            <span> {{ this.data.submission.createdAt.split('T')[0] }}</span>
         </div>
      </div>
      <div style="width: 50%">
         <span class="documentInformation"> User Information </span>
         <div class="center">
            <span style="font-weight: 600">User Name: </span>
            <span> {{ this.data.submissionUserDetails.name }}</span>
         </div>
         <div class="center">
            <span style="font-weight: 600">User Institution: </span>
            <span>
               {{
                  this.data.submissionUserDetails.Institution?.name || '//'
               }}</span
            >
         </div>
      </div>
   </div>
</div>
<div class="content_wrapper">
   <pdf-viewer
      id="viewer"
      [src]="pdfSrc"
      [render-text]="true"
      [fit-to-page]="true"
      (after-load-complete)="callBackFn($event)"
      style="
         display: block;
         overflow: auto;
         height: 100%;
         scroll-behavior: smooth;
         border-radius: 20px;
      "
   ></pdf-viewer>
</div>
