import { createAction, props } from '@ngrx/store';

export const loadStudentsLists = createAction(
   '[StudentsList] Load StudentsLists',
   props<{
      page;
   }>()
);

export const loadStudentsListsSuccess = createAction(
   '[StudentsList] Load StudentsLists Success',
   props<{
      students;
      totalStudents;
   }>()
);

export const loadStudentsListsFailure = createAction(
   '[StudentsList] Load StudentsLists Failure',
   props<{ error: any }>()
);

export const inviteStudent = createAction(
   '[StudentsList] Invite Student',
   props<{
      studentName: string;
      studentEmail: string;
      documentLanguage: string;
      translateTo: string;
   }>()
);

export const inviteStudentSuccess = createAction(
   '[StudentsList] Invite Student Success',
   props<{
      data;
   }>()
);

export const inviteStudentFailure = createAction(
   '[StudentsList] Invites Student Failure',
   props<{ error: any }>()
);

export const editStudent = createAction(
   '[StudentsList] Edit Student',
   props<{
      id: string;
      studentName: string;
      studentEmail: string;
      documentLanguage: string;
      translateTo: string;
      page?: any;
   }>()
);

export const editStudentSuccess = createAction(
   '[StudentsList] Edit Student Success',
   props<{
      data;
   }>()
);

export const editStudentFailure = createAction(
   '[StudentsList] Edit Student Failure',
   props<{ error: any }>()
);

export const administratorIndividualDashboardDetails = createAction(
   '[StudentsList] Administrator Individual Dashboard Details',
   props<{ page; filter?; title? }>()
);

export const administratorIndividualDashboardDetailsSuccess = createAction(
   '[StudentsList] Administrator Individual Dashboard Success',
   props<{
      dashboard;
   }>()
);

export const administratorIndividualDashboardDetailsFailure = createAction(
   '[StudentsList] Administrator Individual Dashboard Details Failure',
   props<{ error: any }>()
);

export const toggleSubmissionAccess = createAction(
   '[StudentsList] Toggle Submission Access Details',
   props<{ id; page: any; title: any; filter?: any }>()
);

export const toggleSubmissionAccessSuccess = createAction(
   '[StudentsList] Toggle Submission Access Details Success',
   props<{ data }>()
);

export const toggleSubmissionAccessFailure = createAction(
   '[StudentsList] Toggle Submission Access Details Failure',
   props<{ error: any }>()
);

export const toggleDraft = createAction(
   '[StudentsList] Toggle Draft Details',
   props<{ id; message; page: any; title: any }>()
);

export const toggleDraftSuccess = createAction(
   '[StudentsList] Toggle Draft Details Success',
   props<{ data }>()
);

export const toggleDraftFailure = createAction(
   '[StudentsList] Toggle Submission Details Failure',
   props<{ error: any }>()
);

export const SubmissionsStatisticsDetails = createAction(
   '[StudentsList] Administrator Individual Statistics Details',
   props<{ page; filter?; title? }>()
);

export const SubmissionsStatisticsDetailsSuccess = createAction(
   '[StudentsList] Administrator Individual Statistics Success',
   props<{
      statistics;
   }>()
);

export const SubmissionsStatisticsDetailsFailure = createAction(
   '[StudentsList] Administrator Individual Statistics Details Failure',
   props<{ error: any }>()
);

export const GeneralStatisticsDetails = createAction(
   '[StudentsList] Administrator Individual General Statistics Details'
);

export const GeneralStatisticsDetailsSuccess = createAction(
   '[StudentsList] Administrator Individual General Statistics Success',
   props<{
      statistics;
   }>()
);

export const GeneralStatisticsDetailsFailure = createAction(
   '[StudentsList] Administrator Individual General Statistics Details Failure',
   props<{ error: any }>()
);

export const getDocumentSubmissions = createAction(
   '[DocumentSubmissions] Administrator Individual Document Submissions',
   props<{
      submissionId: any;
      page: any;
   }>()
);

export const getDocumentSubmissionsSuccess = createAction(
   '[DocumentSubmissions] Administrator Individual Document Submissions Success',
   props<{
      documentSubmissions;
   }>()
);

export const getDocumentSubmissionsFailure = createAction(
   '[DocumentSubmissions] Administrator Individual General Document Submissions Failure',
   props<{ error: any }>()
);
