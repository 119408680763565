<div class="documents-2 shadow white-bg">
   <div
      *ngIf="!(this.totalFaculties == 0 && facultyName == undefined)"
      class="filtersContainer"
   >
      <h4>Overview</h4>
      <div class="selectors">
         <div>
            <input
               placeholder="Search by name"
               [(ngModel)]="facultyName"
               (ngModelChange)="titleFilter()"
               type="text"
               name="searchByName"
            />
         </div>
         <button class="reportBtn" (click)="resetFilter()">Reset</button>
      </div>
   </div>

   <div *ngIf="this.totalFaculties != 0" class="tableWithExpandedInfoFaculties tablewithRatios">
      <ng-container
         *ngFor="
            let faculty of this.faculties
               | paginate
                  : {
                       itemsPerPage: 5,
                       currentPage: this.page,
                       totalItems: this.totalFaculties
                    };
            let i = index
         "
      >
         <div>
            <div>
               <h4 class="blueText bold">{{ faculty.name }}</h4>
               <div class="container_data">
                  <div>
                     <span>Departments</span>
                     <span>Educators</span>
                     <span>Students</span>
                     <span>Subjects</span>
                     <span>Assignments</span>
                     <span>Submissions</span>
                     <span>Theses</span>
                     <span>Document<br> language<br/> similarity</span>
                     <span>Translation<br> language<br/> similarity</span>
                  </div>
                  <div>
                     <span
                        (click)="departmentsFilter(faculty.id)"
                        class="bold clickable"
                        >{{ faculty.departments }}</span
                     >
                     <span
                        (click)="professorFilter(faculty.id)"
                        class="bold clickable"
                        >{{ faculty.professors }}</span
                     >
                     <span
                        (click)="studentsFilter(faculty.id)"
                        class="bold clickable"
                        >{{ faculty.students }}</span
                     >
                     <span
                        (click)="subjectsFilter(faculty.id)"
                        class="bold clickable"
                        >{{ faculty.subjects }}</span
                     >
                     <span
                        (click)="assignmentFilter(faculty.id)"
                        class="bold clickable"
                        >{{ faculty.assignments }}</span
                     >
                     <span
                        class="bold"
                        >{{ faculty.assignmentsSubmissions + faculty.professorsSubmissions }}</span
                     >

                     <span
                        (click)="thesisFilter(faculty.id)"
                        class="bold clickable"
                        >{{ faculty.thesis }}</span
                     >
                     <span class="bold">{{
                        faculty.submissions._avg.originalPercentage === null
                           ? 0
                           : faculty.submissions._avg.originalPercentage.toFixed(
                                2
                             )
                     }}%</span>
                     <span class="bold">{{
                        faculty.submissions._avg.translatedPercentage === null
                           ? 0
                           : faculty.submissions._avg.translatedPercentage.toFixed(
                                2
                             )
                     }}%</span>
                  </div>
               </div>
               <a
                  *ngIf="expandInfo !== i"
                  class="blueText underline vold"
                  (click)="expand(i)"
                  >Expand</a
               >
               <a
                  *ngIf="expandInfo === i"
                  class="blueText underline vold"
                  (click)="expand(i)"
                  >Collapse</a
               >
            </div>
            <div
               [ngClass]="{ expandedInfo: expandInfo == i }" class="moreInfo"
            >
            <div class="details">
                     <span> Most used language:
                      <b>{{ setLanguageName(faculty.language?faculty.language[0]:'-') }}</b>
                       <!-- <b>{{
                          faculty.language === undefined ? '-'
                             : faculty.language[0]
                       }}</b> -->
                     </span>
                     <span>Students submissions <b class="clickable" (click)="assignmentSubmissionsFilter(faculty.id)">{{ faculty.assignmentsSubmissions }}</b></span>
                     <span>Educators submissions <b class="clickable" (click)="professorSubmissionsFilter(faculty.id)" >{{ faculty.professorsSubmissions }}</b></span>
                     <span>
                           Bachelor theses: <b>{{ faculty.bachelor }}</b></span
                        >
                        <span>
                           Master theses: <b>{{ faculty.master }}</b></span
                        >
                        <span>
                           Ph.D. theses: <b>{{ faculty.phd }}</b></span
                        >
                        <span>
                           High similarity submissions:
                           <b>{{ faculty.highSimilaritySubmissions  }}</b></span
                        >
                        <span>
                           Medium similarity submissions:
                           <b>{{ faculty.mediumSimilaritySubmissions }}</b></span
                        >
                        <span>
                           Low similarity submissions:
                           <b>{{ faculty.lowSimilaritySubmissions }}</b></span
                        >

            </div>
            <div class="ratios">
              Ratios
            </div>
            <div class="details">
               <span> Educator-to-Subject:
                 <b>{{ faculty.professors }} : {{ faculty.subjects }}</b>
               </span>
               <span>
                  Educator-to-Student: <b>{{ faculty.professors }} : {{ faculty.students }}</b></span
                  >
                  <span>
                     Educator-to-Submission: <b>{{ faculty.professors }} : {{ faculty.professorsSubmissions }} </b></span
                  >
                  <span>
                     Student-to-Subject: <b>{{ faculty.students }} : {{ faculty.subjects }}</b></span
                  >
                  <span>
                     Student-to-Submission:
                     <b>{{ faculty.students }} : {{ faculty.assignmentsSubmissions }}</b></span
                  >
      </div>
            </div>
         </div>
      </ng-container>
   </div>
   <div
      class="zeroState"
      *ngIf="this.totalFaculties == 0 && facultyName != undefined"
   >
      No schools found
   </div>
   <pagination-controls
      *ngIf="this.totalFaculties != 0"
      class="num"
      (pageChange)="changePage($event)"
      responsive="true"
      previousLabel=""
      nextLabel=""
   ></pagination-controls>
   <div
      class="zero-state"
      *ngIf="this.totalFaculties == 0 && facultyName == undefined"
   >
      <!-- <img src="../../assets/images/icona-image.png" alt="" /> -->
      <h5 class="bold" style="z-index: 1;">There are no schools registered in your institution.
          <a
           routerLink ="/administrator/register-module/faculty-register"
           routerLinkAction="active">Click here</a>
           to begin registering your first school.</h5>
   </div>
</div>
