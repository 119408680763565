import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
   providedIn: 'root',
})
export class ExcelService {
   constructor(private http: HttpClient) {}

   /**
    * Method used to call sign up user api
    * @param firstName
    * @param lastName
    * @param email
    */
   transactions(userId: string) {
      return this.http
         .get<any>(`${environment.apiUrl}/users/${userId}/transactions/excel`)
         .pipe();
   }

   generateGetPresignedUrl(file) {
      return this.http
         .get<any>(`${environment.apiUrl}/generate/presignedUrl/${file}`)
         .pipe();
   }

   generatePutPresignedUrl(folder) {
      return this.http
         .get<any>(`${environment.apiUrl}/generate/putPresignedUrl/${folder}`)
         .pipe();
   }

   registerProfessors(key, facultyId, departmentId) {
      return this.http
         .post<any>(`${environment.apiUrl}/excel/register/professors`, {
            key,
            facultyId,
            departmentId,
         })
         .pipe();
   }

   registerCourses(key, facultyId, departmentId) {
      return this.http
         .post<any>(`${environment.apiUrl}/excel/register/courses`, {
            key,
            facultyId,
            departmentId,
         })
         .pipe();
   }

   registerStudents(key, facultyId, departmentId) {
      return this.http
         .post<any>(`${environment.apiUrl}/excel/register/students`, {
            key,
            facultyId,
            departmentId,
         })
         .pipe();
   }
}
