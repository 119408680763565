<section style="justify-content: flex-start">
   <h4 class="bold w-87">Administration panel</h4>
   <main style="width: 100%">
      <div class="cards">
         <div
            routerLink="academic-units-faculties"
            routerLinkActive="active"
            class="shadowBorder"
         >
            <div>Schools</div>
         </div>
         <div
            routerLink="academic-units-departments"
            routerLinkActive="active"
            class="shadowBorder"
         >
            <div>Departments</div>
         </div>
         <div
            routerLink="academic-units-courses"
            routerLinkActive="active"
            class="shadowBorder"
         >
            <div>Subjects</div>
         </div>
         <div
            routerLink="academic-units-thesis"
            routerLinkActive="active"
            class="shadowBorder"
         >
            <div>Theses</div>
         </div>
      </div>

      <div class="shadowBorder">
         <router-outlet></router-outlet>
      </div>
   </main>
</section>
