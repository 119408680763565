import { createAction, props } from '@ngrx/store';

export const loadUnitsStatisticsFaculties = createAction(
   '[UnitsStatisticsFaculties] Load UnitsStatisticsFaculties',
   props<{ page?: any; title?: string }>()
);

export const loadUnitsStatisticsFacultiesSuccess = createAction(
   '[UnitsStatisticsFaculties] Load UnitsStatisticsFaculties Success',
   props<{ data: any }>()
);

export const loadUnitsStatisticsFacultiesFailure = createAction(
   '[UnitsStatisticsFaculties] Load UnitsStatisticsFaculties Failure',
   props<{ error: any }>()
);
