<section class="marketPlace">
   <h4 class="bold w-87">Billing and Licenses</h4>
   <div class="shadowBorder nav">
      <h3>Offers</h3>
      <img src="../../../../../assets/images/icons/plans.png" alt="" />
   </div>

   <div
      *ngIf="offers?.customOffers?.length == 0 && offers?.offers?.length == 0 && licenses?.length == 0"
      class="shadowBorder zero-state"
   >
      <p>
         You don`t have any plan active yet! <br />
         Please click on <b>“Get a quote”</b> to create your plan.
      </p>
      <button (click)="newOfferModal()" class="submit">Get a quote</button>
   </div>
   <div
      *ngIf="offers?.customOffers?.length != 0 || offers?.offers?.length != 0 || licenses?.length != 0"
      class="shadowBorder content"
   >
      <h2 *ngIf="licenses?.length > 0">Your active plan</h2>
      <div
         class="offersTable"
         *ngFor="let offer of this.licenses; let i = index"
      >
         <div class="header">
            <div>
               <span><b>Active plan</b></span>
               <h2>{{ offer.Offer.title }}</h2>
            </div>
            <a (click)="moreOfferDetails(i, 'activePlan')">Expand</a>
         </div>
         <div class="offerDetails" *ngIf="i !== moreActiveLicenseDetailsIndex">
            <span
               >Students: <b>{{ offer.Offer.students }}</b></span
            >
            <span
               >Educators: <b>{{ offer.Offer.professors }}</b></span
            >
            <!-- <span
               >Crosslingual:
               <b>
                  {{
                     offer.Offer.crossLingual['assignments'] === true ||
                     offer.Offer.crossLingual['professorUploads'] === true ||
                     offer.Offer.crossLingual['thesis'] === true
                        ? 'Yes'
                        : 'No'
                  }}</b
               ></span
            > -->
            <span
               >License duration: <b>{{ offer.paidPeriod }}</b></span
            >
            <!-- <span
               >Remaining days: <b>{{ offer.remainingDays }}</b></span
            > -->
            <span
               >Contract value: <b>{{ offer.paidPrice }}$</b></span
            >
         </div>
         <div
            class="offerExpandedDetails"
            *ngIf="i == moreActiveLicenseDetailsIndex"
         >
            <div>
               <h5>Number of students:</h5>
               <b>{{ offer.Offer.students }}</b>
               <!-- <a (click)="changeEditInput('student')">Add user</a> -->
            </div>
            <div>
               <h5>Number of educators:</h5>
               <b>{{ offer.Offer.professors }}</b>
               <!-- <a>Add user</a> -->
            </div>
            <div>
               <h5>License duration:</h5>
               <b>{{ offer.Offer.commitment }}</b>
               <!-- <a>Add user</a> -->
            </div>
            <!-- <div>
               <h5>Teaching Assistants:</h5>
               <b>{{ offer.Offer.teachingAssistants }}</b> -->
               <!-- <a>Add user</a> -->
            <!-- </div> -->
            <!-- <div>
               <h5>Academic Integrity Officer:</h5>
               <b>{{ offer.Offer.academicIntegrityOfficers }}</b> -->
               <!-- <a>Add user</a> -->
            <!-- </div> -->
            <!-- <div>
               <h5>Subscriptions:</h5>
               <b>{{ offer.Offer.subscriptions == 1 ? true : false }}</b> -->
               <!-- <a>Add user</a> -->
            <!-- </div> -->
            <!-- <div>
               <h5>Thesis estimations:</h5>
               <div>
                  <div>
                     <b>Bachelor: </b>
                     <span> {{ offer.Offer.bachelor }}</span>
                  </div>
                  <div>
                     <b>Master: </b>
                     <span> {{ offer.Offer.master }}</span>
                  </div>
                  <div>
                     <b>Phd: </b>
                     <span> {{ offer.Offer.phd }}</span>
                  </div>
               </div> -->
               <!-- <a>Add user</a> -->
            <!-- </div> -->
            <!-- <div>
               <h5>Document upload:</h5>
               <b>{{ offer.Offer.professorIndividualUploads }}</b> -->
               <!-- <a>Add user</a> -->
            <!-- </div> -->
            <!--<div>
               <h5>Crosslingual:</h5>
                <div>
                  <span
                     ><input
                        [disabled]="!editInput.crossLingual"
                        type="checkbox"
                        id="assignment"
                        [checked]="offer.Offer.crossLingual['assignments']"
                     /><label for="assignment">Assignment Level</label></span
                  >
                  <span
                     ><input
                        [disabled]="!editInput.crossLingual"
                        type="checkbox"
                        id="thesis"
                        [checked]="offer.Offer.crossLingual['thesis']"
                     /><label for="thesis">Thesis</label></span
                  >
                  <span
                     ><input
                        [disabled]="!editInput.crossLingual"
                        type="checkbox"
                        id="professorUpload"
                        [checked]="offer.Offer.crossLingual['professorUploads']"
                     /><label for="professorUpload"
                        >Document Uploads</label
                     ></span
                  >
               </div> -->
               <!-- <a (click)="changeEditInput('crossLingual')">Change</a> -->
            <!-- </div>  -->
            <div>
               <h5>Payment preference:</h5>
               <div>
                  <span
                     ><input
                        name="billingPlan"
                        [disabled]="!editInput.billingPlan"
                        type="radio"
                        id="Monthly"
                        [checked]="offer.Offer.billingPlan == 'Monthly'"
                     /><label for="Monthly">Monthly</label></span
                  >
                  <span
                     ><input
                        name="billingPlan"
                        [disabled]="!editInput.billingPlan"
                        type="radio"
                        id="Annual"
                        [checked]="offer.Offer.billingPlan == 'Annual'"
                     /><label for="Annual">Annual</label></span
                  >
                  <span
                     ><input
                        name="billingPlan"
                        [disabled]="!editInput.billingPlan"
                        type="radio"
                        id="Full"
                        [checked]="offer.Offer.billingPlan == 'OnePayment'"
                     /><label for="Full">Pre-paid</label></span
                  >
               </div>
               <!-- <a (click)="changeEditInput('billingPlan')">Change</a> -->
            </div>
            <!-- <div>
               <h5>Support:</h5>
               <div>
                  <span
                     ><input
                        name="support"
                        [disabled]="!editInput.support"
                        type="radio"
                        id="Basic"
                        [checked]="offer.Offer.support == 'Basic'"
                     /><label for="Basic">Basic</label></span
                  >
                  <span
                     ><input
                        name="support"
                        [disabled]="!editInput.support"
                        type="radio"
                        id="Standard"
                        [checked]="offer.Offer.support == 'Standard'"
                     /><label for="Standard">Standard</label></span
                  >
                  <span
                     ><input
                        name="support"
                        [disabled]="!editInput.support"
                        type="radio"
                        id="Premium"
                        [checked]="offer.Offer.support == 'Premium'"
                     /><label for="Premium">Premium</label></span
                  >
               </div> -->
               <!-- <a (click)="changeEditInput('support')">Change</a> -->
            <!-- </div> -->
            <div>
               <h5>Contract value:</h5>
               <b>{{ offer.paidPrice }} $</b>
               <!-- <a>Add user</a> -->
            </div>
            <!-- <div>
               <h5>Remaining days:</h5>
               <b>{{ offer.remainingDays }}</b>
            </div> -->
            <!-- <div>
               <h5>License duration:</h5>
               <b>{{ offer.commitment }} year</b>
            </div> -->
            <div>
               <h5>License expiry date:</h5>
               <b >{{ licenseEndDate }}</b>
            </div>
         </div>
      </div>
      <h2 *ngIf="offers?.offers?.length != 0">Our offers</h2>
      <div
         class="offersTable"
         *ngFor="let offer of this.offers?.offers; let i = index"
      >
         <div class="header">
            <div>
               <span>Plan name</span>
               <h2>{{ offer.title }}</h2>
            </div>
            <a (click)="moreOfferDetails(i, 'offer')">Expand</a>
         </div>
         <div class="offerDetails" *ngIf="i !== moreOfferDetailsIndex">
            <span
               >License duration: <b>{{ offer.commitment }}</b></span
            >
            <span
               >Students: <b>{{ offer.students }}</b></span
            >
            <span
               >Educators: <b>{{ offer.professors }}</b></span
            >
            <!-- <span
               >Crosslingual:
               <b>
                  {{
                     offer.crossLingual['assignments'] === true ||
                     offer.crossLingual['professorUploads'] === true ||
                     offer.crossLingual['thesis'] === true
                        ? 'Yes'
                        : 'No'
                  }}</b
               ></span
            > -->
            <span
               >Payment preference: <b>{{ offer.billingPlan }}</b></span
            >
            <span
               >Contract value: <b>{{ offer.price }}$</b></span
            >
         </div>
         <div class="offerExpandedDetails" *ngIf="i == moreOfferDetailsIndex">
            <div>
               <h5>Number of students:</h5>
               <b>{{ offer.students }}</b>
               <!-- <a>Add user</a> -->
            </div>
            <div>
               <h5>Number of educators:</h5>
               <b>{{ offer.professors }}</b>
               <!-- <a>Add user</a> -->
            </div>
            <div>
               <h5>License duration:</h5>
               <b>{{ offer.commitment }}</b>
               <!-- <a>Add user</a> -->
            </div>
            <!-- <div>
               <h5>Teaching Assistants:</h5>
               <b>{{ offer.teachingAssistants }}</b> -->
               <!-- <a>Add user</a> -->
            <!-- </div> -->
            <!-- <div>
               <h5>Academic Integrity Officer:</h5>
               <b>{{ offer.academicIntegrityOfficers }}</b> -->
               <!-- <a>Add user</a> -->
            <!-- </div> -->
            <!-- <div>
               <h5>Thesis estimations:</h5>
               <div>
                  <div>
                     <b>Bachelor</b>
                     <span>{{ offer.bachelor }}</span>
                  </div>
                  <div>
                     <b>Master</b>
                     <span>{{ offer.master }}</span>
                  </div>
                  <div>
                     <b>Phd</b>
                     <span>{{ offer.phd }}</span>
                  </div>
               </div> -->
               <!-- <a>Add user</a> -->
            <!-- </div> -->
            <!-- <div>
               <h5>Document upload:</h5>
               <b>{{ offer.professorIndividualUploads }}</b> -->
               <!-- <a>Add user</a> -->
            <!-- </div> -->
            <!-- <div>
               <h5>Crosslingual:</h5>
               <div>
                  <span
                     ><input
                        [disabled]="!editInput.crossLingual"
                        type="checkbox"
                        id="assignment"
                        [checked]="offer.crossLingual['assignments']"
                     /><label for="assignment">Assignment Level</label></span
                  >
                  <span
                     ><input
                        [disabled]="!editInput.crossLingual"
                        type="checkbox"
                        id="thesis"
                        [checked]="offer.crossLingual['thesis']"
                     /><label for="thesis">Thesis</label></span
                  >
                  <span
                     ><input
                        [disabled]="!editInput.crossLingual"
                        type="checkbox"
                        id="professorUpload"
                        [checked]="offer.crossLingual['professorUploads']"
                     /><label for="professorUpload"
                        >Document Uploads</label
                     ></span
                  >
               </div>-->
               <!-- <a (click)="changeEditInput('crossLingual')">Change</a> -->
            <!-- </div>  -->
            <div>
               <h5>Payment preference:</h5>
               <div>
                  <span
                     ><input
                        name="billingPlan"
                        [disabled]="!editInput.billingPlan"
                        type="radio"
                        id="Monthly"
                        [checked]="offer.billingPlan == 'Monthly'"
                     /><label for="Monthly">Monthly</label></span
                  >
                  <span
                     ><input
                        name="billingPlan"
                        [disabled]="!editInput.billingPlan"
                        type="radio"
                        id="Annual"
                        [checked]="offer.billingPlan == 'Annually'"
                     /><label for="Annual">Annual</label></span
                  >
                  <span
                     ><input
                        name="billingPlan"
                        [disabled]="!editInput.billingPlan"
                        type="radio"
                        id="full"
                        [checked]="offer.billingPlan == 'OnePayment'"
                     /><label for="full">Pre-paid</label></span
                  >
               </div>
               <!-- <a (click)="changeEditInput('billingPlan')">Change</a> -->
            </div>
            <!-- <div>
               <h5>Support:</h5>
               <div>
                  <span
                     ><input
                        name="support"
                        [disabled]="!editInput.support"
                        type="radio"
                        id="Basic"
                        [checked]="offer.support == 'Basic'"
                     /><label for="Basic">Basic</label></span
                  >
                  <span
                     ><input
                        name="support"
                        [disabled]="!editInput.support"
                        type="radio"
                        id="Standard"
                        [checked]="offer.support == 'Standard'"
                     /><label for="Standard">Standard</label></span
                  >
                  <span
                     ><input
                        name="support"
                        [disabled]="!editInput.support"
                        type="radio"
                        id="Premium"
                        [checked]="offer.support == 'Premium'"
                     /><label for="Premium">Premium</label></span
                  >
               </div> -->
               <!-- <a (click)="changeEditInput('support')">Change</a> -->
            <!-- </div> -->
            <div>
               <h5>Contract value:</h5>
               <b>{{ offer.price }}$</b>
               <!-- <a>Add user</a> -->
            </div>
         </div>
      </div>
      <h2 *ngIf="offers?.customOffers?.length != 0">Your offers</h2>
      <div
         class="offersTable"
         *ngFor="let offer of this.offers?.customOffers; let i = index"
      >
         <div class="header">
            <div>
               <span>Plan name</span>
               <h2>{{ offer.title }}</h2>
            </div>
            <a (click)="moreOfferDetails(i, 'customOffers')">Expand</a>
         </div>
         <div class="offerDetails" *ngIf="i !== moreCustomOfferDetailsIndex">
            <span
               >License duration: <b>{{ offer.commitment }}</b></span
            >
            <span
               >Students: <b>{{ offer.students }}</b></span
            >
            <span
               >Educators: <b>{{ offer.professors }}</b></span
            >
            <!-- <span
               >Crosslingual:
               <b>
                  {{
                     offer.crossLingual['assignments'] === true ||
                     offer.crossLingual['professorUploads'] === true ||
                     offer.crossLingual['thesis'] === true
                        ? 'Yes'
                        : 'No'
                  }}</b
               ></span
            > -->
            <span
               >Payment preference: <b>{{ offer.billingPlan }}</b></span
            >
            <a class="contact_us" (click)="contactUsModal(offer)"
               >Contact us</a
            >
         </div>
         <div
            class="offerExpandedDetails"
            *ngIf="i == moreCustomOfferDetailsIndex"
         >
            <div>
               <h5>Number of students:</h5>
               <b>{{ offer.students }}</b>
               <!-- <a (click)="changeEditInput('student')">Add user</a> -->
            </div>
            <div>
               <h5>Number of educators:</h5>
               <b>{{ offer.professors }}</b>
               <!-- <a>Add user</a> -->
            </div>
            <div>
               <h5>License duration:</h5>
               <b>{{ offer.commitment }}</b>
               <!-- <a>Add user</a> -->
            </div>
            <!-- <div>
               <h5>Teaching Assistants:</h5>
               <b>{{ offer.teachingAssistants }}</b> -->
               <!-- <a>Add user</a> -->
            <!-- </div> -->
            <!-- <div>
               <h5>Academic Integrity Officer:</h5>
               <b>{{ offer.academicIntegrityOfficers }}</b> -->
               <!-- <a>Add user</a> -->
            <!-- </div> -->
            <!-- <div>
               <h5>Subscriptions:</h5>
               <b>{{ offer.subscriptions == 1 ? true : false }}</b> -->
               <!-- <a>Add user</a> -->
            <!-- </div> -->
            <!-- <div>
               <h5>Thesis estimations:</h5>
               <div>
                  <div>
                     <b>Bachelor: </b>
                     <span> {{ offer.bachelor }}</span>
                  </div>
                  <div>
                     <b>Master: </b>
                     <span> {{ offer.master }}</span>
                  </div>
                  <div>
                     <b>Phd: </b>
                     <span> {{ offer.phd }}</span>
                  </div>
               </div> -->
               <!-- <a>Add user</a> -->
            <!-- </div> -->
            <!-- <div>
               <h5>Document upload:</h5>
               <b>{{ offer.professorIndividualUploads }}</b> -->
               <!-- <a>Add user</a> -->
            <!-- </div> -->
            <!-- <div>
               <h5>Crosslingual:</h5>
               <div>
                  <span
                     ><input
                        [disabled]="!editInput.crossLingual"
                        type="checkbox"
                        id="assignment"
                        [checked]="offer.crossLingual['assignments']"
                     /><label for="assignment">Assignment Level</label></span
                  >
                  <span
                     ><input
                        [disabled]="!editInput.crossLingual"
                        type="checkbox"
                        id="thesis"
                        [checked]="offer.crossLingual['thesis']"
                     /><label for="thesis">Thesis</label></span
                  >
                  <span
                     ><input
                        [disabled]="!editInput.crossLingual"
                        type="checkbox"
                        id="professorUpload"
                        [checked]="offer.crossLingual['professorUploads']"
                     /><label for="professorUpload"
                        >Document Uploads</label
                     ></span
                  >
               </div> -->
               <!-- <a (click)="changeEditInput('crossLingual')">Change</a> -->
            <!-- </div>  -->
            <div>
               <h5>Payment preference:</h5>
               <div>
                  <span
                     ><input
                        name="billingPlan"
                        [disabled]="!editInput.billingPlan"
                        type="radio"
                        id="Monthly"
                        [checked]="offer.billingPlan == 'Monthly'"
                     /><label for="Monthly">Monthly</label></span
                  >
                  <span
                     ><input
                        name="billingPlan"
                        [disabled]="!editInput.billingPlan"
                        type="radio"
                        id="Annual"
                        [checked]="offer.billingPlan == 'Annually'"
                     /><label for="Annual">Annual</label></span
                  >
                  <span
                     ><input
                        name="billingPlan"
                        [disabled]="!editInput.billingPlan"
                        type="radio"
                        id="Full"
                        [checked]="offer.billingPlan == 'OnePayment'"
                     /><label for="Full">Pre-paid</label></span
                  >
               </div>
               <!-- <a (click)="changeEditInput('billingPlan')">Change</a> -->
            </div>
            <!-- <div>
               <h5>Support:</h5>
               <div>
                  <span
                     ><input
                        name="support"
                        [disabled]="!editInput.support"
                        type="radio"
                        id="Basic"
                        [checked]="offer.support == 'Basic'"
                     /><label for="Basic">Basic</label></span
                  >
                  <span
                     ><input
                        name="support"
                        [disabled]="!editInput.support"
                        type="radio"
                        id="Standard"
                        [checked]="offer.support == 'Standard'"
                     /><label for="Standard">Standard</label></span
                  >
                  <span
                     ><input
                        name="support"
                        [disabled]="!editInput.support"
                        type="radio"
                        id="Premium"
                        [checked]="offer.support == 'Premium'"
                     /><label for="Premium">Premium</label></span
                  >
               </div> -->
               <!-- <a (click)="changeEditInput('support')">Change</a> -->
            <!-- </div> -->
            <!-- <div>
               <h5>Contract value:</h5>
               <b>{{ offer.price }}$</b>
            </div> -->
            <h1 class="center">
               <button class="submit m-auto" (click)="editOfferModal(offer)">
                  <i class="fa-solid fa-pen-to-square"></i>Manage
               </button>
            </h1>
         </div>
      </div>


      <div class="center">
         <button (click)="newOfferModal()" class="submit">Get a quote</button>
      </div>
   </div>
</section>
