import { createAction, props } from '@ngrx/store';

export const loadAssignmentSubmissionsStatistics = createAction(
   '[ AssignmentSubmissions Statistics] Load  AssignmentSubmissions Statistics',
   props<{
      page?: any;
      facultyId?: string;
      departmentId?: string;
      subjectName?: string;
      assignmentType?: string;
      submissionName?: string;
      assignmentName?: string;
      studentName?: string;
   }>()
);

export const loadAssignmentSubmissionsStatisticsSuccess = createAction(
   '[ AssignmentSubmissions Statistics] Load  AssignmentSubmissions Statistics Success',
   props<{ data: any }>()
);

export const loadAssignmentSubmissionsStatisticsFailure = createAction(
   '[ AssignmentSubmissions Statistics] Load  AssignmentSubmissions Statistics Failure',
   props<{ error: any }>()
);
