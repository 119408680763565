// *** Angular
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
   MatDialog,
   MatDialogRef,
   MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { first } from 'rxjs/operators';
import {
   FormBuilder,
   FormControl,
   FormGroup,
   Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';

// *** Actions
import * as facultyActions from '../../state/faculty/action/faculty.actions';
import * as subjectActions from '../../state/subject/action/subject.actions';

// *** Selectors
import { getInstitutionFacultiesStateDetails } from '../../state/faculty/selector/faculty.selectors';

// *** Packages
import swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';

// *** Services
import { DepartmentService } from 'src/app/services/department.service';
import { CourseService } from 'src/app/services/course.service';
import { UserService } from 'src/app/services/user.service';

@Component({
   selector: 'app-edit-course',
   templateUrl: './edit-course.component.html',
   styleUrls: ['./edit-course.component.scss'],
})
export class EditCourseComponent implements OnInit, OnDestroy {
   /**
    * Variable used to store subjectId that we passe from units-departments
    */
   subjectId;

   /**
    * Variable used to store subject object that we retrieve from API.
    */
   subject;

   /**
    * Variable used to store FormGroup values that are being edited on modal.
    */
   subjectEdit: FormGroup;

   /**
    * Variable used to store faculties of institution.
    */
   faculties;
   faculties$;

   /**
    * Variable used to store departments of institution.
    */
   departments;
   departments$;

   /**
    * Variable stores configuration of ngx-select-dropdown.
    */
   config;

   /**
    * Variable used to store professors of institution.
    */
   professors;
   /**
    * Variable used to store boolean value that used to check if duplication is started or not of course
    */
   duplicateStarted: boolean = false;
   /**
    * Variable used to store boolean value if there is an error while changing generation of course and that course hase that generation from earlier.
    */
   generationAlert: boolean = false;
   /**
    * Variabele used to store boolean value that his value changed to true if any value within form is changed
    */
   cleanForm: boolean = true;
   /**
    * Variable used to store user decision if they want to save course with changes and duplicate it
    */
   saveAndDuplicate: boolean = false;
   /**
    * Variable used to store a boolean value for validating course code.
    */
   codeExists: boolean = false;
   /**
    * Variable used to store a time for function to trigger.(x time after key is up call y function.)
    */
   timer;

   constructor(
      public dialogRef: MatDialogRef<EditCourseComponent>,
      public dialog: MatDialog,
      private courseService: CourseService,
      private departmentService: DepartmentService,
      private fb: FormBuilder,
      private userService: UserService,
      private store: Store,
      private spinner: NgxSpinnerService,

      /**
       * Used to pass data in component through dialog
       */
      @Inject(MAT_DIALOG_DATA) public data: any
   ) {}
   ngOnDestroy(): void {
      this.faculties$.unsubscribe();
   }
   ngOnInit(): void {
      this.store.dispatch(facultyActions.loadFacultiesDetails({ page: 0 }));

      this.faculties$ = this.store
         .select(getInstitutionFacultiesStateDetails)
         .subscribe((data) => {
            if (data !== null) {
               this.faculties = data.faculties;
            }
         });

      this.config = {
         displayKey: 'name', //if objects array passed which key to be displayed defaults to description
         search: true, //true/false for the search functionality defaults to false,
         height: 'auto', //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
         placeholder: 'Select', // text to be displayed when no item is selected defaults to Select,
         limitTo: 0, // number thats limits the no of options displayed in the UI (if zero, options will not be limited)
         moreText: 'more', // text to be displayed when more than one items are selected like Option 1 + 5 more
         noResultsFound: 'No results found!', // text to be displayed when no items are found while searching
         searchPlaceholder: 'Search', // label thats displayed in search input,
         searchOnKey: 'name', // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
      };
      this.subjectEdit = this.fb.group({
         subjectTitle: new FormControl('', Validators.required),
         subjectProfessors: new FormControl(''),
         subjectFaculty: new FormControl('', Validators.required),
         subjectDepartment: new FormControl('', Validators.required),
         subjectType: new FormControl('', Validators.required),
         subjectDescription: new FormControl('', Validators.required),
         subjectCode: new FormControl('', Validators.required),
         subjectGeneration: new FormControl('', Validators.required),
         subjectYear: new FormControl('', Validators.required),
         subjectStatus: new FormControl('', Validators.required),
      });
      this.subjectId = this.data.subjectId;

      this.courseService
         .course(this.subjectId)
         .pipe(first())
         .subscribe(
            (data) => {
               this.subject = data.course;
               this.subject.professors = [];
               this.userService
                  .institutionProfessors(0)
                  .pipe(first())
                  .subscribe(
                     (data) => {
                        this.professors = data.professors;
                        for (let x in this.professors) {
                           for (let professorCourse of this.subject
                              .professorCourses) {
                              if (
                                 professorCourse.professorId ===
                                 this.professors[x].id
                              ) {
                                 this.professors.splice(x, 1);
                              }
                           }
                        }
                        for (let professor of this.subject.professorCourses) {
                           this.subject.professors.push(professor.User);
                        }
                     },
                     (error) => {
                        console.log('error', error);
                     }
                  );

               this.departmentService
                  .getDepartmentsOfFaculty(this.subject.Department.Faculty.id)
                  .pipe(first())
                  .subscribe(
                     (data) => {
                        this.departments = data.departments;
                     },
                     (error) => {
                        console.log(error);
                     }
                  );

               this.subjectEdit = this.fb.group({
                  subjectTitle: new FormControl(this.subject.title, [
                     Validators.required,
                     Validators.minLength(3),
                  ]),
                  subjectProfessors: new FormControl(this.subject.professors),
                  subjectFaculty: new FormControl(
                     this.subject.Department.Faculty.id,
                     Validators.required
                  ),
                  subjectDepartment: new FormControl(
                     this.subject.Department.id,
                     Validators.required
                  ),
                  subjectType: new FormControl(
                     this.subject.type,
                     Validators.required
                  ),
                  subjectDescription: new FormControl(
                     this.subject.description,
                     [Validators.required, Validators.minLength(3)]
                  ),
                  subjectCode: new FormControl(this.subject.code, [
                     Validators.required,
                     Validators.minLength(3),
                  ]),
                  subjectGeneration: new FormControl(
                     this.subject.generation,
                     Validators.required
                  ),
                  subjectYear: new FormControl(
                     this.subject.year,
                     Validators.required
                  ),
                  subjectStatus: new FormControl(
                     this.subject.status == true ? 1 : 0,
                     Validators.required
                  ),
               });
            },
            (error) => {
               console.log(error);
            }
         );
   }

   /**
    * Method use to get form controls.
    */
   get form() {
      return this.subjectEdit.controls;
   }

   /**
    * Method is used to call editCourse service that will send updated fields of department to API.
    */
   async update() {
      const result = await swal.fire({
         title: 'Confirm update of Course',
         icon: 'warning',
         showCancelButton: true,
         confirmButtonColor: '#3085d6',
         cancelButtonColor: '#b5adad',
         confirmButtonText: 'Confirm',
         allowOutsideClick: false,
      });
      if (result.isConfirmed) {
         this.store.dispatch(
            subjectActions.editSubjectDetails({
               subjectId: this.subjectId,
               subjectTitle: this.form.subjectTitle.value,
               subjectProfessors: this.form.subjectProfessors.value,
               subjectFaculty: this.form.subjectFaculty.value,
               subjectDepartment: this.form.subjectDepartment.value,
               subjectType: this.form.subjectType.value,
               subjectDescription: this.form.subjectDescription.value,
               subjectCode: this.form.subjectCode.value,
               subjectGeneration: this.form.subjectGeneration.value,
               subjectYear: this.form.subjectYear.value,
               subjectDuplicate: this.duplicateStarted,
               saveAndDuplicate: this.saveAndDuplicate,
               generation: this.data.generation,
               page: this.data.page,
               faculty: this.data.faculty,
               department:
                  this.data.department,
               professor: this.data.professor,
               status: this.form.subjectStatus.value,
               subjectTypeFilter: this.data.subjectType,
               name: this.data.name,
            })
         );

         this.dialogRef.close();
      } else if (result.isDismissed) {
         this.dialogRef.close();
      }
   }

   /**
    * Method is used to get faculty id from dropdown and then to retrieve departments of that faculty.
    */
   facultyChanged() {
      let facultyId = this.form.subjectFaculty.value;
      this.departmentService
         .getDepartmentsOfFaculty(facultyId)
         .pipe(first())
         .subscribe(
            (data) => {
               this.departments = data.departments;
               this.form.subjectDepartment.setValue(this.departments[0].id);
            },
            (error) => {
               console.log('error', error);
            }
         );
   }
   /**
    * Function is used to duplicat course
    */
   async duplicate() {
      const result = await swal.fire({
         title: 'Confirm course duplication',
         icon: 'warning',
         showCancelButton: true,
         confirmButtonColor: '#3085d6',
         cancelButtonColor: '#b5adad',
         confirmButtonText: 'Confirm',
         allowOutsideClick: false,
      });
      if (result.isConfirmed) {
         this.duplicateStarted = true;
         if (this.subject.generation === this.form.subjectGeneration.value) {
            this.generationAlert = true;
         } else {
            this.generationAlert = false;
            for (const key of Object.keys(this.form)) {
               if (key !== 'subjectGeneration') {
                  if (this.form[key].pristine == false) {
                     this.cleanForm = false;
                  }
               }
            }
            if (!this.cleanForm) {
               this.userNotifications();
            } else {
         const result = await swal.fire({
         title: 'Confirm update of Course',
         icon: 'warning',
         showCancelButton: true,
         confirmButtonColor: '#3085d6',
         cancelButtonColor: '#b5adad',
         confirmButtonText: 'Confirm',
         allowOutsideClick: false,
      });
      if (result.isConfirmed) {
         this.store.dispatch(
            subjectActions.duplicateSubject({
               subjectId: this.subjectId,
               subjectTitle: this.form.subjectTitle.value,
               subjectProfessors: this.form.subjectProfessors.value,
               subjectFaculty: this.form.subjectFaculty.value,
               subjectDepartment: this.form.subjectDepartment.value,
               subjectType: this.form.subjectType.value,
               subjectDescription: this.form.subjectDescription.value,
               subjectCode: this.form.subjectCode.value,
               subjectGeneration: this.form.subjectGeneration.value,
               subjectYear: this.form.subjectYear.value,
               subjectDuplicate: this.duplicateStarted,
               saveAndDuplicate: this.saveAndDuplicate,
               generation: this.data.generation,
               page: this.data.page,
               faculty: this.data.faculty,
               department:
                  this.data.department,
               professor: this.data.professor,
               status: this.data.status,
               subjectTypeFilter: this.data.subjectType,
               name: this.data.name,
            })
         );
         
         this.dialogRef.close();
      }
            }
         }
      }
   }
   /**
    * Function is called when there are changes detected within the form
    */
   async userNotifications() {
      const result = await swal.fire({
         title: 'Changes detected!',
         denyButtonText: 'Save and Duplicate',
         confirmButtonText: 'Just Duplicate',
         denyButtonColor: '#2455C2',
         confirmButtonColor: '#03a84e',
         showDenyButton: true,
         allowOutsideClick: false,
         html: 'Would you like to save changes and to duplicate or just to duplicate?',
         icon: 'info',
      });
      if (result.isConfirmed) {
         this.saveAndDuplicate = false;
         const result = await swal.fire({
            title: 'Confirm update of Course',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#b5adad',
            confirmButtonText: 'Confirm',
            allowOutsideClick: false,
         });
         if (result.isConfirmed) {
            this.store.dispatch(
               subjectActions.duplicateSubject({
                  subjectId: this.subjectId,
                  subjectTitle: this.form.subjectTitle.value,
                  subjectProfessors: this.form.subjectProfessors.value,
                  subjectFaculty: this.form.subjectFaculty.value,
                  subjectDepartment: this.form.subjectDepartment.value,
                  subjectType: this.form.subjectType.value,
                  subjectDescription: this.form.subjectDescription.value,
                  subjectCode: this.form.subjectCode.value,
                  subjectGeneration: this.form.subjectGeneration.value,
                  subjectYear: this.form.subjectYear.value,
                  subjectDuplicate: this.duplicateStarted,
                  saveAndDuplicate: this.saveAndDuplicate,
                  generation: this.data.generation,
                  page: this.data.page,
                  faculty: this.data.faculty,
                  department:
                     this.data.department,
                  professor: this.data.professor,
                  status: this.data.status,
                  subjectTypeFilter: this.data.subjectType,
                  name: this.data.name,
               })
            );
            
            this.dialogRef.close();
         }
      }
      if (result.isDenied) {
         this.saveAndDuplicate = true;
         this.update();
      }
   }

   /**
    * Method is used to call remove course service.
    */
   async delete() {
      const result = await swal.fire({
         title: 'You are about to DELETE a subject. Please confirm your action by clicking “Confirm” below.',
         text: 'Your action is recoverable only by contacting the technical support team at support@crossplag.com',
         icon: 'warning',
         showCancelButton: true,
         confirmButtonColor: '#3085d6',
         cancelButtonColor: '#b5adad',
         confirmButtonText: 'Confirm',
         allowOutsideClick: false,
      });
      if (result.isConfirmed) {
         this.store.dispatch(
            subjectActions.removeSubject({
               subjectId: this.subjectId,
               generation: this.data.generation,
               page: this.data.page,
               faculty: this.data.faculty,
               department:
                  this.data.department,
               professor: this.data.professor,
               status: this.data.status,
               subjectTypeFilter: this.data.subjectType,
               name: this.data.name,
            })
         );
         this.dialogRef.close();
      } else if (result.isDismissed) {
         this.dialogRef.close();
      }
   }

   /**
    * Method is used to call a api to check if subject coude exist on database or not.
    */
   validateCode() {
      let time;
      time = 500;

      clearTimeout(this.timer);
      console.log('this.form.subjectCode.value', this.form.subjectCode.value);
      console.log('this.subject.code', this.subject.code);

      if (
         this.form.subjectCode.value.length !== 0 &&
         this.form.subjectCode.value !== this.subject.code
      ) {
         this.timer = setTimeout(() => {
            this.spinner.show();
            this.courseService
               .checkCode(this.form.subjectCode.value)
               .pipe(first())
               .subscribe(
                  (data) => {
                     if (data.sameCourseCode !== 0) {
                        this.codeExists = true;
                     } else {
                        this.codeExists = false;
                     }
                     this.spinner.hide();
                  },
                  (error) => {
                     this.spinner.hide();
                     console.log('error', error);
                  }
               );
         }, time);
      } else {
         this.codeExists = false;
      }
   }
}
