import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
   selector: 'app-upload',
   templateUrl: './upload.component.html',
   styleUrls: ['./upload.component.scss'],
})
export class UploadComponent implements OnInit {
   userRole;
   constructor(private router: Router, public route: ActivatedRoute, private authService: AuthService,) {}

   ngOnInit(): void {
      this.userRole = this.authService.isLoggedInRole();
   }
}
