// *** Angular
import { Component, OnInit, Inject, Optional } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { getCurrentUser } from 'src/app/authModule/state/authentication.selectors';
import { Store } from '@ngrx/store';

// *** Actions
import * as offersActions from '../../licenses/offers/state/action/offers.actions';

import {
   MatDialog,
   MatDialogRef,
   MAT_DIALOG_DATA,
} from '@angular/material/dialog';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

  constructor( @Inject(MAT_DIALOG_DATA) public data: any, @Optional() public dialogRef: MatDialogRef<any>, public dialog: MatDialog,private fb: FormBuilder, private store: Store,) { }

     /**
    * Variable used to store formGroup
    */
  contactUs;

  showOfferDetails = false;
  message;

  /**
    * User Subscriber
  */
    user$;
    user

  ngOnInit(): void {
    this.user$ = this.store.select(getCurrentUser).subscribe((data) => {
      if (data !== null) {
         this.user = data;
      }
   });
    this.contactUs = this.fb.group({
      name: [this.user.name, [Validators.required]],
      email: [this.user.email, [Validators.required]],
      message: ['', [Validators.required]],
      subject: ['', [Validators.required]],
      sentEmail: ['', [Validators.required]]
   });
  }
  moreOfferDetails(){
    if(this.showOfferDetails){
      this.showOfferDetails = false
    } else {
      this.showOfferDetails = true
    }
  }

     /**
    * Method use to get form controls.
    */
  get form() {
    return this.contactUs.controls;
  }

  sent(){
    this.store.dispatch(
      offersActions.sentEmailForCustomOffer({
         name: this.form.name.value,
         email: this.form.email.value,
         subject: this.form.subject.value,
         message: this.form.message.value,
         offerId: this.data.offer.id,
      })
   );
   this.dialogRef.close();
  }
}
