import { createAction, props } from '@ngrx/store';

/**
 * Action used to register a professor.
 */
export const professorRegister = createAction(
   '[Professor] Professors Register',
   props<{
      professorName;
      professorFaculty;
      professorDepartment;
      professorSubjects;
      professorEmail;
      professorWords;
   }>()
);

/**
 * Action is called when there is a success status returned from API.
 */
export const professorRegisterSuccess = createAction(
   '[Professor] Professors Register Success'
);

/**
 * Action is called when there is a failure status returned from API.
 */
export const professorRegisterFailure = createAction(
   '[Professor] Professors Register Failure',
   props<{ error: any }>()
);

/**
 * Action used to load Professors of institution.
 */
export const loadProfessorsDetails = createAction(
   '[Professor] Load Professors Details',
   props<{
      page;
      faculty?;
      department?;
      professorName?;
   }>()
);

/**
 * Action is called when there is a success status returned from API.
 */
export const loadProfessorsDetailsSuccess = createAction(
   '[Professor] Load Professors Details Success',
   props<{
      professors;
      professorsNumber;
   }>()
);

/**
 * Action is called when there is a failure status returned from API.
 */
export const loadProfessorsDetailsFailure = createAction(
   '[Professor] Load Professors Details Failure',
   props<{ error: any }>()
);

/**
 * Action used to edit Professor details.
 */
export const editProfessorDetails = createAction(
   '[Professor] Edit Professor Details',
   props<{
      professorId;
      professorName;
      professorEmail;
      professorFaculty;
      professorDepartment;
      professorSubjects;
      professorStatus;
      increaseWords;
      professorWordsToAllocate;
      page?;
      faculty?;
      department?;
      professorNameFilter?;
   }>()
);

/**
 * Action is called when there is a success status returned from API.
 */
 export const editProfessorDetailsSuccess = createAction(
   '[Professor] Edit Professor Details Success',
   props<{
     data
   }>()
);

/**
 * Action is called when there is a failure status returned from API.
 */
export const editProfessorDetailsFailure = createAction(
   '[Professor] Edit Professor Details Failure',
   props<{ error: any }>()
);

/**
 * Action used to remove Professor from institution.
 */
export const removeProfessor = createAction(
   '[Professor] Remove Professor',
   props<{
      professorId;
      page?;
      faculty?;
      department?;
      professorNameFilter?;
   }>()
);

/**
 * Action is called when there is a success status returned from API.
 */
 export const removeProfessorSuccess = createAction(
   '[Professor] Remove Professor Success',
   props<{
     data
   }>()
);

/**
 * Action is called when there is a failure status returned from API.
 */
export const removeProfessorFailure = createAction(
   '[Professor] Remove Professor Failure',
   props<{ error: any }>()
);

export const setProfessorRegisterToNull = createAction(
   '[Professor] Set Professor Register To Null'
);
