<div class="text-input">
   <main>
      <h4>{{ 'individual.upload.text_input' | translate }}</h4>
      <h5>{{ 'individual.upload.document_title' | translate }}</h5>
      <input type="text" />
      <h5>{{ 'individual.upload.insert_text' | translate }}</h5>
      <textarea name="" id="" cols="30" rows="10"></textarea>
      <div class="center">
         <a class="submit" href="">{{ 'general.submit' | translate }}</a>
      </div>
   </main>
</div>
