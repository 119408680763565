// *** Angular
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { first } from 'rxjs/operators';
import { On, Store } from '@ngrx/store';

// *** Actions
import * as thesisActions from '../state/thesis/action/thesis.actions';
import * as professorActions from '../state/professor/action/professor.actions';
import * as facultyActions from '../state/faculty/action/faculty.actions';

// *** Selectors
import { getInstitutionThesisStateDetails } from '../state/thesis/selector/thesis.selectors';
import { getInstitutionProfessorsStateDetails } from '../state/professor/selector/professor.selectors';
import { getInstitutionFacultiesStateDetails } from '../state/faculty/selector/faculty.selectors';

// *** Services
import { DepartmentService } from 'src/app/services/department.service';

@Component({
   selector: 'app-units-thesis',
   templateUrl: './units-thesis.component.html',
   styleUrls: ['./units-thesis.component.scss'],
})
export class UnitsThesisComponent implements OnInit, OnDestroy {
   /**
    * Variable is used to store institution faculties
    */
   faculties;
   /**
    * Variable used to store faculty id that used to filter thesis by faculty
    */
   facultyId = '-1';
   /**
    * Variable used to store department id that used to filter thesis by department
    */
   departmentId = '-1';
   /**
    * Variable is used to store departments of selected faculty
    */
   departments: any;
   /**
    * Variable used to store professor name that used to filter thesis by professor
    */
   name: any;
   /**
    * Variable used to store a time for function to trigger.(x time after key is up call y function.)
    */
   timer: any;
   /**
    * Variable used to store thesis information of institution.
    */
   thesisInformation;
   thesisInformation$;

   /**
    * Variable used to store all professors of institution with thesis details.
    */
   professors;
   professors$;
   /**
    * Symbolizing the page of pagination
    */
   page = 1;
   /**
    * Variable stores a boolean value that determines if checkbox to check all professors is clicked or not.
    */
   allChecked = false;
   getInstitutionFacultiesStateDetails$: any;
   level = '-1';

   constructor(
      public dialog: MatDialog,
      private store: Store,
      private departmentService: DepartmentService
   ) {}
   ngOnDestroy(): void {
      this.getInstitutionFacultiesStateDetails$.unsubscribe();
      this.thesisInformation$.unsubscribe();
   }

   ngOnInit(): void {
      this.store.dispatch(facultyActions.loadFacultiesDetails({ page: 0 }));

      this.getInstitutionFacultiesStateDetails$ = this.store
         .select(getInstitutionFacultiesStateDetails)
         .subscribe((data) => {
            if (data !== null) {
               this.faculties = data.faculties;
            }
         });

      this.store.dispatch(
         thesisActions.loadThesisDetails({
            page: this.page - 1,
            faculty: this.facultyId == '-1' ? undefined : this.facultyId,
            department:
               this.departmentId == '-1' ? undefined : this.departmentId,
            name: this.name,
            level: this.level == '-1' ? undefined : this.level,
         })
      );

      this.thesisInformation$ = this.store
         .select(getInstitutionThesisStateDetails)
         .subscribe((data) => {
            if (data !== null) {
               this.thesisInformation = data.thesis;
            }
         });
   }
   /**
    * Function is used to select a faculty to filter thesis and also get departments of selected faculty
    */
   selectedFaculty() {
      this.page = 1;
      this.departmentId = '-1';
      this.store.dispatch(
         thesisActions.loadThesisDetails({
            page: this.page - 1,
            faculty: this.facultyId == '-1' ? undefined : this.facultyId,
            department:
               this.departmentId == '-1' ? undefined : this.departmentId,
            name: this.name,
            level: this.level == '-1' ? undefined : this.level,
         })
      );
      if (this.facultyId !== '') {
         this.departmentService
            .getDepartmentsOfFaculty(this.facultyId)
            .pipe(first())
            .subscribe(
               (data) => {
                  this.departments = data.departments;
               },
               (error) => {
                  console.log(error);
               }
            );
      }
   }
   /**
    * Function used to filter thesis by title
    */
   titleFilter() {
      let time = 500;
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
         this.page = 1;
         this.store.dispatch(
            thesisActions.loadThesisDetails({
               page: this.page - 1,
               faculty: this.facultyId == '-1' ? undefined : this.facultyId,
               department:
                  this.departmentId == '-1' ? undefined : this.departmentId,
               name: this.name,
               level: this.level == '-1' ? undefined : this.level,
            })
         );
      }, time);
   }

   /**
    * Method used to change pagination page. It dispatches an action with page as param.
    * @param event
    */
   changePage(event): void {
      this.page = event;
      this.store.dispatch(
         thesisActions.loadThesisDetails({
            page: this.page - 1,
            faculty: this.facultyId == '-1' ? undefined : this.facultyId,
            department:
               this.departmentId == '-1' ? undefined : this.departmentId,
            name: this.name,
            level: this.level == '-1' ? undefined : this.level,
         })
      );
   }

   /**
    * Function is used to filter thesis by given values
    */
   filterThesis() {
      this.page = 1;
      this.store.dispatch(
         thesisActions.loadThesisDetails({
            page: this.page - 1,
            faculty: this.facultyId == '-1' ? undefined : this.facultyId,
            department:
               this.departmentId == '-1' ? undefined : this.departmentId,
            name: this.name,
            level: this.level == '-1' ? undefined : this.level,
         })
      );
   }
   /**
    * Function is used to reset filters
    */
   resetFilter() {
      this.facultyId = '-1';
      this.departmentId = '-1';
      this.name = undefined;
      this.page = 1;
      this.level = '-1';
      this.store.dispatch(
         thesisActions.loadThesisDetails({
            page: this.page - 1,
            faculty: this.facultyId == '-1' ? undefined : this.facultyId,
            department:
               this.departmentId == '-1' ? undefined : this.departmentId,
            name: this.name,
            level: this.level == '-1' ? undefined : this.level,
         })
      );
   }
}
