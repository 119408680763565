import {
   MatDialogRef,
   MAT_DIALOG_DATA,
   MatDialog,
} from '@angular/material/dialog';
import { getCurrentSource } from './../../state/selectors/report.selectors';
import * as reportActions from '../../state/actions/report.actions';

import { Store } from '@ngrx/store';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { User } from 'src/app/models/user';
import { getCurrentUser } from '../../../authModule/state/authentication.selectors';
import { SubmissionsService } from 'src/app/services/submissions.service';
import { first } from 'rxjs/operators';
import { SourcePreviewComponent } from '../sourcePreview/source-preview/source-preview.component';
/**
 * Component used to show information about clicked sentence.
 */
@Component({
   selector: 'app-sentence-information-modal',
   templateUrl: './sentence-information-modal.component.html',
   styleUrls: ['./sentence-information-modal.component.scss'],
})
export class SentenceInformationModalComponent implements OnInit, OnDestroy {
   editCommentStarted = false;
   /**
    * Id of the current submission.
    */
   currentSubmissionId: any;
   changeSourceView = false;
   SelectedSentenceSources: any;
   selectedSentenceId: any;
   /**
    * Constructor Component
    * @param store
    * @param dialogRef
    * @param data
    */
   constructor(
      private store: Store,
      public dialogRef: MatDialogRef<SentenceInformationModalComponent>,
      public dialog: MatDialog,
      private submissionService: SubmissionsService,
      /**
       * Used to pass data in component through dialog
       */
      @Inject(MAT_DIALOG_DATA) public data: any
   ) {}

   /**
    * Variable used to store source data, that we get from state.
    */
   sourceId;
   /**
    * Used to store state selector subscriber of sources.
    */
   sourceSub$;
   /**
    * The source that includes the sentence that is clicked
    */
   currentSource;
   /**
    * Clicked sentence by user.
    */
   currentSentence;
   /**
    * Used to show comment section when excluding a sentence
    */
   excludeButtonClicked = false;
   /**
    * Used to store the comment of the section that we are excluding/including or editing.
    */
   comment;
   canEdit: boolean;
   currentUser$;
   currentUserDetailsSubscriber$;
   currentUserDetails;
   /**
    * Method used to be called whenever the component is loaded and all the methods in it, will be triggered.
    */
   ngOnInit(): void {
      this.currentUser$ = this.store.select(getCurrentUser);
      this.currentUserDetailsSubscriber$ = this.store
         .select(getCurrentUser)
         .subscribe((data: User) => {
            this.currentUserDetails = data;
            this.canEdit = true;
            if (this.currentUserDetails.roleId === 2) {
               this.canEdit = !!(
                  this.currentUserDetails.institutionId === null
               );
            }
            if (this.currentUserDetails.roleId === 5) {
               this.canEdit = false;
            }
         });
      this.currentSubmissionId = this.data.submissionId;
      this.comment = '';
      this.sourceSub$ = this.store
         .select(getCurrentSource(this.data.sourceType, this.data.sentenceId))
         .pipe()
         .subscribe((data) => {
            if (data !== null) {
               this.currentSource = data;
               this.currentSource.sentences.forEach((sentence) => {
                  if (this.data.sentenceId === sentence.id) {
                     this.currentSentence = sentence;
                     if (this.data.sourceType) {
                        if (this.currentSentence.comment) {
                           this.comment = this.currentSentence.comment;
                        }
                     } else {
                        if (this.currentSentence.translateComment) {
                           this.comment = this.currentSentence.translateComment;
                        }
                     }
                  }
               });
            }
         });
   }

   /**
    * Method used to start exclude process, and shows the comment section.
    */
   startExclude() {
      this.excludeButtonClicked = !this.excludeButtonClicked;
      if (this.comment === 'undefined') {
         this.comment = '';
      }
   }

   /**
    * Method is called when a directive, pipe, or service is destroyed.
    * Use for any custom cleanup that needs to occur when the instance is destroyed. In our case we will use to unsubscribe.
    */
   ngOnDestroy(): void {
      this.sourceSub$.unsubscribe();
   }

   /**
    * Method used to open source link in new tab
    */
   openLink() {
      window.open(this.currentSource.sourceUrl, '_blank');
   }

   /**
    * Method used to exclude sentence from calculation.
    * @param sentence
    */
   excludeSentence(sentence) {
      let updatedSentence = { ...sentence };
      updatedSentence.isExcluded = true;
      updatedSentence.comment = this.comment;
      this.store.dispatch(
         reportActions.excludeSentence({
            source: this.currentSource,
            sentence: updatedSentence,
            sourceType: this.data.sourceType,
            submissionId: this.currentSubmissionId,
            comment: this.comment,
         })
      );
      this.dialogRef.close();
   }

   /**
    * Method used to include sentence to calculation.
    * @param sentence
    */
   includeSentence(sentence) {
      let updatedSentence = { ...sentence };
      updatedSentence.isExcluded = false;
      delete updatedSentence.comment;
      this.store.dispatch(
         reportActions.includeSentence({
            source: this.currentSource,
            sentence: updatedSentence,
            sourceType: this.data.sourceType,
            submissionId: this.currentSubmissionId,
         })
      );
      this.dialogRef.close();
   }

   /**
    * Method used to start edit comment section. And shows comment section.
    */
   startEdit() {
      this.editCommentStarted = !this.editCommentStarted;
   }

   /**
    * Method used to update sentence exclude comment.
    */
   updateComment() {
      this.store.dispatch(
         reportActions.updateComment({
            source: this.currentSource,
            sourceType: this.data.sourceType,
            sentence: this.currentSentence,
            comment: this.comment,
            submissionId: this.currentSubmissionId,
         })
      );

      this.dialogRef.close();
   }

   /**
    * Method used to delete source comment.
    */
   deleteComment() {
      this.store.dispatch(
         reportActions.deleteComment({
            source: this.currentSource,
            sourceType: this.data.sourceType,
            sentence: this.currentSentence,
            submissionId: this.currentSubmissionId,
         })
      );
      this.dialogRef.close();
   }
   changeSource(currentSentence?) {
      if (currentSentence) {
         this.selectedSentenceId = currentSentence.id;
         if (this.data.sourceType) {
            this.SelectedSentenceSources = currentSentence.secondarySources;
         } else {
            this.SelectedSentenceSources =
               currentSentence.secondaryTranslatedSources;
         }
      }
      this.changeSourceView = !this.changeSourceView;
   }

   async clickedSource(sourceId) {
      let title = 'Are you sure?';
      let message =
         'By pressing confirm the source of this sentence will be changed!';
      const result = await Swal.fire({
         title: title,
         text: message,
         icon: 'question',
         showCancelButton: true,
         confirmButtonColor: '#3085d6',
         cancelButtonColor: '#b5adad',
         confirmButtonText: 'Confirm',
         allowOutsideClick: false,
      });
      if (result.isConfirmed) {
         this.store.dispatch(
            reportActions.changeSource({
               sourceId: sourceId,
               sentenceId: this.selectedSentenceId,
               submissionId: this.currentSubmissionId,
               originalLanguage: this.data.sourceType,
            })
         );
      } else if (result.isDismissed) {
         console.log('Canceled');
      }
   }

   /**
    * Method used to close all modals within the component
    */
   closeModal() {
      this.dialog.closeAll();
   }

   startDocumentPreview() {
      
      this.submissionService
         .submissionPreview(
            this.currentSource.sourceId,
            this.currentSubmissionId,
            false
         )
         .pipe(first())
         .subscribe(
            (data) => {
               console.log('data', data);
               this.dialog.open(SourcePreviewComponent, {
                  data,
                  width: '60%',
                  height: '80%',
               });
            },
            (error) => {
               console.log('error', error);
            }
         );
   }

   
}
