// *** Angular
import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';

// *** Services
import { StoreService } from 'src/app/services/store.service';

@Component({
   selector: 'app-administrator-store',
   templateUrl: './administrator-store.component.html',
   styleUrls: ['./administrator-store.component.scss'],
})
export class AdministratorStoreComponent implements OnInit {
   constructor(private storeService: StoreService, public router: Router) {}
   /**
    * Variable used to store licenses purchased by institution if there is 0 licenses purchased it will be redirected to offer.
    */
   licenses;
   ngOnInit(): void {
      this.storeService
         .institutionLicenses()
         .pipe(first())
         .subscribe(
            (data) => {
               this.licenses = data.licenses;
               if (this.licenses.length === 0) {
                  this.router.navigateByUrl('administrator/store/offers');
               }
            },
            (error) => {
               console.log('error', error);
            }
         );
   }

   /**
    * Method checks if the route contains a specific route url. Used to hide app header & nav component at report url.
    * @param route
    */
   public hasRoute(route: string) {
      return this.router.url.includes(route);
   }
}
