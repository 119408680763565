import { createAction, props } from '@ngrx/store';

export const studentOverview = createAction('[Statistics] Student Overview');

export const studentOverviewSuccess = createAction(
   '[Statistics] Student Overview Success',
   props<{ data: any }>()
);

export const studentOverviewFailure = createAction(
   '[Statistics] Student Overview Failure',
   props<{ error: any }>()
);

export const studentGraph = createAction(
   '[Statistics] Student Graph',
   props<{ year: any }>()
);

export const studentGraphSuccess = createAction(
   '[Statistics] Student Graph Success',
   props<{ data: any }>()
);

export const studentGraphFailure = createAction(
   '[Statistics] Student Graph Failure',
   props<{ error: any }>()
);

export const subjectsStatistics = createAction(
   '[Statistics] Student Subjects Statistics',
   props<{ page: any; title?: any }>()
);

export const subjectsStatisticsSuccess = createAction(
   '[Statistics] Student Subjects Statistics Success',
   props<{ data: any }>()
);

export const subjectsStatisticsFailure = createAction(
   '[Statistics] Student Subjects Statistics Failure',
   props<{ error: any }>()
);

export const assignmentsStatistics = createAction(
   '[Statistics] Student Assignments Statistics',
   props<{ page: any; subjectId: any; title: any }>()
);

export const assignmentsStatisticsSuccess = createAction(
   '[Statistics] Student Assignments Statistics Success',
   props<{ data: any }>()
);

export const assignmentsStatisticsFailure = createAction(
   '[Statistics] Student Assignments Statistics Failure',
   props<{ error: any }>()
);

export const assignmentsSubmissionsStatistics = createAction(
   '[Statistics] Student Assignments Submissions Statistics',
   props<{ page: any; subjectId: any; title: any; assignmentId: any }>()
);

export const assignmentsSubmissionsStatisticsSuccess = createAction(
   '[Statistics] Student Assignments Submissions Statistics Success',
   props<{ data: any }>()
);

export const assignmentsSubmissionsStatisticsFailure = createAction(
   '[Statistics] Student Assignments Submissions Statistics Failure',
   props<{ error: any }>()
);

export const thesisStatistics = createAction(
   '[Statistics] Student Thesis Statistics',
   props<{ page: any; title?: any }>()
);

export const thesisStatisticsSuccess = createAction(
   '[Statistics] Student Thesis Statistics Success',
   props<{ data: any }>()
);

export const thesisStatisticsFailure = createAction(
   '[Statistics] Student Thesis Statistics Failure',
   props<{ error: any }>()
);

export const countSubjectsAndAssignments = createAction(
   '[Statistics] Count Subjects And Assignments'
);

export const countSubjectsAndAssignmentsSuccess = createAction(
   '[Statistics] Count Subjects And Assignments Success',
   props<{ data: any }>()
);

export const countSubjectsAndAssignmentsFailure = createAction(
   '[Statistics] Count Subjects And Assignments Failure',
   props<{ error: any }>()
);

export const countSubmissionsAndThesis = createAction(
   '[Statistics] Count Submissions And Thesis'
);

export const countSubmissionsAndThesisSuccess = createAction(
   '[Statistics] Count Submissions And Thesis Success',
   props<{ data: any }>()
);

export const countSubmissionsAndThesisFailure = createAction(
   '[Statistics] Count Submissions And Thesis Failure',
   props<{ error: any }>()
);

export const documentSubmissions = createAction(
   '[Statistics] Document Submissions',
   props<{ submissionId: any }>()
);

export const documentSubmissionsSuccess = createAction(
   '[Statistics] Document Submissions Success',
   props<{ data: any }>()
);

export const documentSubmissionsFailure = createAction(
   '[Statistics] Document Submissions Failure',
   props<{ error: any }>()
);
