// *** Angular
import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';

// *** Services
import { AdministratorStatisticsService } from 'src/app/services/administrator-statistics.service';

@Component({
   selector: 'app-administrator-statistics',
   templateUrl: './administrator-statistics.component.html',
   styleUrls: ['./administrator-statistics.component.scss'],
})
export class AdministratorStatisticsComponent implements OnInit {
   /**
    * Variabel used to store institutiton statistics
    */
   institutionData: any;
   /**
    * Variable used to store number that changes by function and has a part on showing a specific chart a time
    */
   graphToDisplay: number = 1;
   /**
    * Variable used to store number of assigments of professors within institution
    */
   assignmentsStatistics;
   /**
    *Variable used to store number of assigments of submissions within institution
    */
   submissionsStatistics;
   /**
    *Variable used to store number of assigments of thesis within institution
    */
   thesisStatistics;

   constructor(private statistics: AdministratorStatisticsService) {}

   ngOnInit(): void {
      this.statistics
         .institutionStatistics()
         .pipe(first())
         .subscribe(
            (data) => {
               this.institutionData = data.data;
            },
            (error) => {
               console.log('error', error);
            }
         );

      this.statistics
         .institutionAssignments()
         .pipe(first())
         .subscribe(
            (data) => {
               this.assignmentsStatistics = data.professorAssignments;
            },
            (error) => {
               console.log('error', error);
            }
         );

      this.statistics
         .institutionSubmissions(4)
         .pipe(first())
         .subscribe(
            (data) => {
               this.submissionsStatistics = data.submissions;
            },
            (error) => {
               console.log('error', error);
            }
         );

      this.statistics
         .institutionThesis('bachelor')
         .pipe(first())
         .subscribe(
            (data) => {
               this.thesisStatistics = data.thesis;
            },
            (error) => {
               console.log('error', error);
            }
         );
   }

   /**
    * Method used to change the statistics of submissions by professors or students.
    * @param event
    */
   changeType(event) {
      this.statistics
         .institutionSubmissions(event)
         .pipe(first())
         .subscribe(
            (data) => {
               this.submissionsStatistics = data.submissions;
            },
            (error) => {
               console.log('error', error);
            }
         );
   }

   /**
    * Method used to change the statistics of thesis by types (Bachelor, Master or Ph.D).
    * @param event
    */
   changeThesisType(event) {
      this.statistics
         .institutionThesis(event)
         .pipe(first())
         .subscribe(
            (data) => {
               this.thesisStatistics = data.thesis;
            },
            (error) => {
               console.log('error', error);
            }
         );
   }
   /**
    * Function is used to change which chart will be shown
    * @param value
    */
   filter(value) {
      value = parseInt(value);
      this.graphToDisplay = value;
   }
}
