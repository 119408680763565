import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, catchError, concatMap, switchMap } from 'rxjs/operators';
import * as thesisActions from '../../thesis/action/thesis.actions';
import { UserService } from '../../../../services/user.service';
import { ThesisService } from '../../../../services/thesis.service';
import * as professorActions from '../../professor/action/professor.actions';

@Injectable()
export class ThesisEffects {
   constructor(
      private actions$: Actions,
      private userService: UserService,
      private thesisService: ThesisService
   ) {}

   /**
    * Effect listening for loadThesisDetails Action, when loadThesisDetails is dispatched the effect with call institutionThesisUsage() from thesisService.
    * In case of success response the effect it will trigger loadThesisDetailsSuccess.
    * In case of Error it will trigger loadThesisDetailsFailure with error property.
    */
   getThesisDetails$ = createEffect(() => {
      return this.actions$.pipe(
         ofType(thesisActions.loadThesisDetails),
         concatMap((action) => {
            return this.thesisService
               .institutionThesisUsage(
                  action.page,
                  action.name,
                  action.level,
                  action.faculty,
                  action.department
               )
               .pipe(
                  map((data) => {
                     return thesisActions.loadThesisDetailsSuccess({
                        thesis: data,
                     });
                  }),
                  catchError((error) =>
                     of(thesisActions.loadThesisDetailsFailure({ error }))
                  )
               );
         })
      );
   });
}
