import { createFeatureSelector, createSelector } from '@ngrx/store';
import { thesisSubmissionsStatisticsState } from '../reducer/thesis-statistics.reducer';

/**
 * Selectors are pure functions used for obtaining slices of store state.
 */

/**
 * getThesisSubmissionsStatistics represent top level of the assignment state.
 * The createFeatureSelector is a convenience method for returning a top level feature state.
 */
const getThesisSubmissionsStatistics =
   createFeatureSelector<thesisSubmissionsStatisticsState>(
      'thesisSubmissionsStatistics'
   );

/**
 * Selector used to get statisticsOverview details state.
 */
export const getThesisSubmissionsStatisticsDetails = createSelector(
   getThesisSubmissionsStatistics,
   (state) => state
);
