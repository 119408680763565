import { Injectable } from '@angular/core';
import { Actions, ofType, Effect, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, catchError, concatMap } from 'rxjs/operators';

import * as submissionsActions from '../actions/submissions.actions';
import { SubmissionsService } from '../../../services/submissions.service';
/**
 * Submissions Effects responsible to listen for dispatched actions that call service methods related to submissions
 */
@Injectable()
export class SubmissionsEffects {
   /**
    * Effect Constructor
    * @param actions$
    * @param submissionsService
    */
   constructor(
      private actions$: Actions,
      private submissionsService: SubmissionsService
   ) {}
   /**
    * Effect listening for loadSubmissionsDetails Action, when loadSubmissionsDetails is dispatched the effect with call getUserSubmissionsDetails() from SubmissionService.
    * In case of success response the effect it will trigger loadSubmissionsDetailsSuccess with the following properties (Total submission of the user, User suspicious submissions, User Credits,User used credits).
    * In case of Error it will trigger loadSubmissionsFailure with error property.
    */
   getSubmissionsDetails$ = createEffect(() => {
      return this.actions$.pipe(
         ofType(submissionsActions.loadSubmissionsDetails),
         concatMap((action) => {
            return this.submissionsService.getUserSubmissionsDetails().pipe(
               map((data) => {
                  return submissionsActions.loadSubmissionsDetailsSuccess({
                     totalSubmissions: data.totalUserSubmissions,
                     userSuspiciousSubmissions: data.userSuspiciousSubmissions,
                     userTotalCredits: data.userTotalCredits,
                     userUsedCredits: data.userUsedCredits,

                     totalUserDocuments: data.totalUserDocuments,
                     totalUserDocumentsUsed: data.totalUserDocumentsUsed,
                     totalUserDrafts: data.totalUserDrafts,
                     totalUserDraftsUsed: data.totalUserDraftsUsed,
                     totalUserSubmissions: data.totalUserSubmissions,
                     totalUserSubmissionsAll: data.totalUserSubmissionsAll,
                     userReSubmissions: data.userReSubmissions,
                  });
               }),
               catchError((error) =>
                  of(
                     submissionsActions.loadSubmissionsDetailsFailure({ error })
                  )
               )
            );
         })
      );
   });
   /**
    * Effect listening for loadSubmissions Action, when action is dispatched the effect with call getUserSubmissions(page,title,filter,pageSize) from SubmissionService.
    *  In case of success response the effect it will trigger loadSubmissionsSuccess with the following properties (User submissions, Number of pages, Number of user submissions).
    * In case of Error it will trigger loadSubmissionsFailure with error property.
    */
   getSubmissions$ = createEffect(() => {
      return this.actions$.pipe(
         ofType(submissionsActions.loadSubmissions),
         concatMap((action) => {
            return this.submissionsService
               .getUserSubmissions(
                  action.page,
                  action.title,
                  action.filter,
                  action.pageSize,
                  action.reSubmissions
               )
               .pipe(
                  map((data) => {
                     return submissionsActions.loadSubmissionsSuccess({
                        submissions: data.submissions,
                        pages: data.pages,
                        submissionsNumber: data.submissionsNumber,
                     });
                  }),
                  catchError((error) =>
                     of(submissionsActions.loadSubmissionsFailure({ error }))
                  )
               );
         })
      );
   });

   /**
    * Effect listening for loadSubmissions Graph Action, when action is triggered the effect with call getSubmissionGraph(year) from SubmissionService.
    * In case of success response the effect it will trigger loadSubmissionsGraphSuccess  with the following properties (Submissions Graph Data).
    * In case of error loadSubmissionsFailure Action with error property.
    */
   getSubmissionsGraphData$ = createEffect(() => {
      return this.actions$.pipe(
         ofType(submissionsActions.loadSubmissionsGraph),
         concatMap((action) => {
            return this.submissionsService.getSubmissionGraph(action.year).pipe(
               map((data) => {
                  return submissionsActions.loadSubmissionsGraphSuccess({
                     submissionsGraph: data,
                  });
               }),
               catchError((error) =>
                  of(submissionsActions.loadSubmissionsFailure({ error }))
               )
            );
         })
      );
   });

   /**
    * Effect listening for loadSubmissions Graph Action, when action is triggered the effect with call getSubmissionGraph(year) from SubmissionService.
    * In case of success response the effect it will trigger loadSubmissionsGraphSuccess  with the following properties (Submissions Graph Data).
    * In case of error loadSubmissionsFailure Action with error property.
    */
   getSubmissionsGraphDataIndividual$ = createEffect(() => {
      return this.actions$.pipe(
         ofType(submissionsActions.loadSubmissionsGraphIndividual),
         concatMap((action) => {
            return this.submissionsService
               .getSubmissionGraphIndividual(action.year)
               .pipe(
                  map((data) => {
                     return submissionsActions.loadSubmissionsGraphIndividualSuccess(
                        {
                           graph: data,
                        }
                     );
                  }),
                  catchError((error) =>
                     of(
                        submissionsActions.loadSubmissionsGraphIndividualFailure(
                           { error }
                        )
                     )
                  )
               );
         })
      );
   });

   /**
    * Effect listening for deleteSubmission  Action, when action is triggered the effect with call deleteSubmission(id, showSubmission) from SubmissionService.
    * In case of success response the effect it will trigger deleteSubmissionSuccess with the following properties (submissionToUpdate).
    * In case of error loadSubmissionsFailure Action with error property.
    */
   deleteSubmission$ = createEffect(() => {
      return this.actions$.pipe(
         ofType(submissionsActions.deleteSubmission),
         concatMap((action) => {
            return this.submissionsService
               .deleteSubmission(action.id, action.showSubmission)
               .pipe(
                  map((data) => {
                     return submissionsActions.deleteSubmissionSuccess({
                        submission: data.submissionToUpdate,
                     });
                  }),
                  catchError((error) =>
                     of(submissionsActions.deleteSubmissionFailure({ error }))
                  )
               );
         })
      );
   });
}
