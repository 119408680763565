import * as AppState from '../../../../store/app.state';
import * as professorActions from '../action/professor.actions';
import { createReducer } from '@ngrx/store';
import { on } from '@ngrx/store';
import { User } from 'src/app/models/user';

/**
 * professor reducer used to updated store values based on certain action.
 */

/**
 * State interface extends App state State and add user state to the main state.
 */
export interface State extends AppState.State {
   professors: ProfessorState;
}

/**
 * An professorState interface, which represents the blueprint of the User State.
 */
export interface ProfessorState {
   professors: User[];
   professorsNumber: number;
   errorMessage: string;
   professorRegistered: string;
}

/**
 * professorState initial state.
 */
export const initialState: ProfessorState = {
   professors: null,
   professorsNumber: null,
   errorMessage: null,
   professorRegistered: null,
};

export const professorReducer = createReducer(
   initialState,
   /**
    * On loadProfessorsDetailsSuccess action updated current state with the following properties.
    */
   on(
      professorActions.loadProfessorsDetailsSuccess,
      (state, action): ProfessorState => {
         return {
            ...state,
            professors: action.professors,
            professorsNumber: action.professorsNumber,
         };
      }
   ),

   /**
    * On loadProfessorsDetailsFailure action updated current state with the following properties.
    */
   on(
      professorActions.loadProfessorsDetailsFailure,
      (state, action): ProfessorState => {
         return {
            ...state,
            errorMessage: action.error,
         };
      }
   ),
   on(
      professorActions.professorRegisterSuccess,
      (state, action): ProfessorState => {
         return {
            ...state,
            professorRegistered: 'professor registered',
         };
      }
   ),
   on(
      professorActions.setProfessorRegisterToNull,
      (state, action): ProfessorState => {
         return {
            ...state,
            professorRegistered: null,
         };
      }
   )
);
