import { Component, OnInit } from '@angular/core';

@Component({
   selector: 'app-administrator-dashboard',
   templateUrl: './administrator-dashboard.component.html',
   styleUrls: ['./administrator-dashboard.component.scss'],
})
export class AdministratorDashboardComponent implements OnInit {
   constructor() {}

   ngOnInit(): void {}
}
