import { createAction, props } from '@ngrx/store';

export const loadProfessorsSubmissionsStatistics = createAction(
   '[ ProfessorsSubmissions Statistics] Load  ProfessorsSubmissions Statistics',
   props<{
      page?: any;
      facultyId?: string;
      departmentId?: string;
      professorId?: string;
      submissionName?: string;
   }>()
);

export const loadProfessorsSubmissionsStatisticsSuccess = createAction(
   '[ ProfessorsSubmissions Statistics] Load  ProfessorsSubmissions Statistics Success',
   props<{ data: any }>()
);

export const loadProfessorsSubmissionsStatisticsFailure = createAction(
   '[ ProfessorsSubmissions Statistics] Load  ProfessorsSubmissions Statistics Failure',
   props<{ error: any }>()
);
