// *** Angular
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { first } from 'rxjs/operators';

// *** Actions
import * as professorsSubmissionsActions from '../state/action/professors-submissions.actions';
import * as facultyActions from '../../../../state/faculty/action/faculty.actions';
import * as professorActions from '../../../../../administrator-components/state/professor/action/professor.actions';
import * as filtersActions from '../../../../../administrator-components/state/filters/action/filters.actions';

// *** Selectors
import { getProfessorsSubmissionsDetails } from '../state/selector/professors-submissions.selectors';
import { getInstitutionFacultiesStateDetails } from '../../../../state/faculty/selector/faculty.selectors';
import { getInstitutionProfessorsStateDetails } from '../../../../../administrator-components/state/professor/selector/professor.selectors';
import { getFiltersStateDetails } from '../../../../../administrator-components/state/filters/selector/filters.selectors';

// *** Services
import { DepartmentService } from 'src/app/services/department.service';
import { environment } from 'src/environments/environment';


@Component({
   selector: 'app-professors-submissions',
   templateUrl: './professors-submissions.component.html',
   styleUrls: ['./professors-submissions.component.scss'],
})
export class ProfessorsSubmissionsComponent implements OnInit, OnDestroy {
   /**
    * Variable is used to store departments of selected faculty
    */
   departments: any;
   /**
    * Variable is used to store facultyId to filter professor submissions of specific faculty.
    */
   facultyId = '-1';
   /**
    * Variable is used to store departmentId that will be used to filter professor submissions of specific department
    */
   departmentId = '-1';
   /**
    * Variable used to store name of submission and used to filter submissions by title
    */
   submissionName: string;
   /**
    * Variable used to store assignment type that has been used to filter submissions
    */
   assignmentType: any;
   /**
    * Variable used to store professor id that used to retrieve submissions of specific professor
    */
   professorId = '-1';
   getFiltersState = true;
   /**
    * Variable used to store information that a thesis is been clicked for expand or not
    */
   expandInfo: any;
   /**
    * Variabel used to store submissions of institutcion
    */
   submissions;
   /**
    * Variable used to store student id that used to expand specific row on HTML
    */
   studentId;
   /**
    * Symbolizing the page of pagination
    */
   page: number = 1;
   /**
    * Timer used for search delay
    */
   timer: any;
   /**
    * Variable used to store a overview of total submission number
    */
   submissionsNumber;
   /**
    * Variable used to store a overview of statistics
    */
   statistics$;
   /**
    * Variabels used to retrieve faculties of insitution from store.
    */
   faculties;
   faculties$;
   /**
    * Variabels used to retrieve professors of insitution from store.
    */
   professors;
   professors$;
   getFiltersStateDetails$: any;
   professorsNames$: any;
   constructor(
      private store: Store,
      private departmentService: DepartmentService
   ) {}
   ngOnDestroy(): void {
      this.getFiltersStateDetails$.unsubscribe();
      this.faculties$.unsubscribe();
      this.professorsNames$.unsubscribe();
      this.statistics$.unsubscribe();
   }

   ngOnInit(): void {
       this.store.dispatch(professorActions.loadProfessorsDetails({ page: 0 }));
       this.professorsNames$ = this.store
       .select(getInstitutionProfessorsStateDetails)
       .subscribe((data) => {
          if (data.professors !== null && !this.professors) {
             this.professors = data.professors;
          }
       });

      this.getFiltersStateDetails$ = this.store
         .select(getFiltersStateDetails)
         .subscribe((data) => {
            if (data.professorSubmissionsFilters.faculty != null) {
               this.facultyId = data.professorSubmissionsFilters.faculty;
               this.departmentService
                  .getDepartmentsOfFaculty(this.facultyId)
                  .pipe(first())
                  .subscribe(
                     (data) => {
                        this.departments = data.departments;
                     },
                     (error) => {
                        console.log(error);
                     }
                  );
            }
            if (data.professorSubmissionsFilters.department != null) {
               this.departmentId = data.professorSubmissionsFilters.department;
            }
            if (data.professorSubmissionsFilters.professor != null) {
               this.professorId = data.professorSubmissionsFilters.professor;
            }
         });

      this.store.dispatch(
         professorsSubmissionsActions.loadProfessorsSubmissionsStatistics({
            page: this.page,
            facultyId: this.facultyId == '-1' ? undefined : this.facultyId,
            departmentId:
               this.departmentId == '-1' ? undefined : this.departmentId,
            submissionName: this.submissionName,
            professorId:
               this.professorId == '-1' ? undefined : this.professorId,
         })
      );

      this.store.dispatch(facultyActions.loadFacultiesDetails({ page: 0 }));

      this.faculties$ = this.store
         .select(getInstitutionFacultiesStateDetails)
         .subscribe((data) => {
            if (data.faculties !== null && this.faculties == undefined) {
               this.faculties = data.faculties;
            }
         });

      this.statistics$ = this.store
         .select(getProfessorsSubmissionsDetails)
         .subscribe((data) => {
            if (data !== null) {
               this.submissions = data.professorsSubmissions;
               this.submissionsNumber = data.submissionsNumber;
            }
         });
   }
   
   /**
    * Function used to filter submissions by title
    */
   titleFilter() {
      let time;
      time = 500;
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
         this.store.dispatch(
            professorsSubmissionsActions.loadProfessorsSubmissionsStatistics({
               page: this.page,
               facultyId: this.facultyId == '-1' ? undefined : this.facultyId,
               departmentId:
                  this.departmentId == '-1' ? undefined : this.departmentId,
               submissionName: this.submissionName,
               professorId:
                  this.professorId == '-1' ? undefined : this.professorId,
            })
         );
      }, time);
   }

   goToLink(id: string): void {
      let url = `${environment.currentUrl}/administrator/submission/${id}/report`;
      window.open(url, '_blank');
   }

   /**
    * Function is used to filter submissions by a faculty
    */
   selectedFaculty() {
      this.departmentId = '-1';
      this.getFiltersState = false;
      this.store.dispatch(
         filtersActions.professorSubmissionsFilters({
            department: this.departmentId,
            faculty: this.facultyId,
         })
      );
      this.store.dispatch(
         professorsSubmissionsActions.loadProfessorsSubmissionsStatistics({
            page: this.page,
            facultyId: this.facultyId == '-1' ? undefined : this.facultyId,
            departmentId:
               this.departmentId == '-1' ? undefined : this.departmentId,
            submissionName: this.submissionName,
            professorId:
               this.professorId == '-1' ? undefined : this.professorId,
         })
      );

      if (this.facultyId !== '') {
         this.departmentService
            .getDepartmentsOfFaculty(this.facultyId)
            .pipe(first())
            .subscribe(
               (data) => {
                  this.departments = data.departments;
               },
               (error) => {
                  console.log(error);
               }
            );
      }
   }
   /**
    * Function is used to filter submissions by a department
    */
   selectedDepartment() {
      this.getFiltersState = false;
      this.store.dispatch(
         filtersActions.professorSubmissionsFilters({
            department: this.departmentId,
            faculty: this.facultyId,
         })
      );
      this.store.dispatch(
         professorsSubmissionsActions.loadProfessorsSubmissionsStatistics({
            page: this.page,
            facultyId: this.facultyId == '-1' ? undefined : this.facultyId,
            departmentId:
               this.departmentId == '-1' ? undefined : this.departmentId,
            submissionName: this.submissionName,
            professorId:
               this.professorId == '-1' ? undefined : this.professorId,
         })
      );
   }
   /**
    * Function is used to filter submissions by a professor
    */
   filterByProfessor() {
      this.store.dispatch(
         professorsSubmissionsActions.loadProfessorsSubmissionsStatistics({
            page: this.page,
            facultyId: this.facultyId == '-1' ? undefined : this.facultyId,
            departmentId:
               this.departmentId == '-1' ? undefined : this.departmentId,
            submissionName: this.submissionName,
            professorId:
               this.professorId == '-1' ? undefined : this.professorId,
         })
      );
   }

   /**
    * Method used to change pagination page. It dispatches an action with page as param.
    * @param event
    */
   changePage(event): void {
      this.page = event;
      this.store.dispatch(
         professorsSubmissionsActions.loadProfessorsSubmissionsStatistics({
            page: this.page,
            facultyId: this.facultyId == '-1' ? undefined : this.facultyId,
            departmentId:
               this.departmentId == '-1' ? undefined : this.departmentId,
            submissionName: this.submissionName,
            professorId:
               this.professorId == '-1' ? undefined : this.professorId,
         })
      );
   }
   /**
    * Function is used to exapnd thesis information
    * @param studentId
    */
   expand(studentId) {
      this.studentId = studentId;
      if (this.expandInfo !== studentId) {
         this.expandInfo = studentId;
      } else {
         this.expandInfo = -1;
      }
   }
   /**
    * Function is used to reset filters
    */
   resetFilter() {
      this.facultyId = '-1';
      this.departmentId = '-1';
      this.page = 1;
      this.submissionName = undefined;
      this.professorId = '-1';
      this.store.dispatch(
         filtersActions.professorSubmissionsFilters({
            department: this.departmentId,
            faculty: this.facultyId,
         })
      );
      this.store.dispatch(
         professorsSubmissionsActions.loadProfessorsSubmissionsStatistics({
            page: this.page,
            facultyId: this.facultyId == '-1' ? undefined : this.facultyId,
            departmentId:
               this.departmentId == '-1' ? undefined : this.departmentId,
            submissionName: this.submissionName,
            professorId:
               this.professorId == '-1' ? undefined : this.professorId,
         })
      );
   }
}
