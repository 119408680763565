import { createFeatureSelector, createSelector } from '@ngrx/store';
import { assignmentSubmissionsState } from '../reducer/assignment-submissions.reducer';

/**
 * Selectors are pure functions used for obtaining slices of store state.
 */

/**
 * getAssignmentSubmissions represent top level of the assignment state.
 * The createFeatureSelector is a convenience method for returning a top level feature state.
 */
const getAssignmentSubmissions =
   createFeatureSelector<assignmentSubmissionsState>('assignmentSubmissions');

/**
 * Selector used to get statisticsOverview details state.
 */
export const getAssignmentSubmissionsDetails = createSelector(
   getAssignmentSubmissions,
   (state) => state
);
