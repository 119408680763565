// *** Angular
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
   selector: 'app-manage',
   templateUrl: './manage.component.html',
   styleUrls: ['./manage.component.scss'],
})
export class ManageComponent implements OnInit {
   constructor(public router: Router) {}

   ngOnInit(): void {}

   /**
    * Method checks if the route contains a specific route url. Used to hide app header & nav component at report url.
    * @param route
    */
   public hasRoute(route: string) {
      return this.router.url.includes(route);
   }
}
