import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';

@Injectable({
   providedIn: 'root',
})
export class FacultyService {
   constructor(private http: HttpClient, private authService: AuthService) {}

   /**
    * Method used to call api for creating faculty/faculties for institution.
    * @param faculties
    */
   create(faculties: any) {
      return this.http
         .post<any>(`${environment.apiUrl}/faculty`, {
            faculties,
         })
         .pipe(
            map((data) => {
               return data;
            })
         );
   }

   /**
    * Service is used to retrieve faculties of institution that user is registered.
    * @returns faculties
    */
   getFacultiesOfInstitution(page: number,search?) {
      return this.http
         .get<any>(`${environment.apiUrl}/institution/faculties/?page=${page}`+
         (search ? `&search=${search}` : '')
         )
         .pipe(
            map((data) => {
               return data;
            })
         );
   }
   /**
    * Service is used to call api that return information for specific faculty.
    * @param facultyId
    * @returns
    */
   faculty(facultyId: string) {
      return this.http
         .get<any>(`${environment.apiUrl}/faculty/${facultyId}`)
         .pipe(
            map((data) => {
               return data;
            })
         );
   }
   /**
    * Service is used to call api that will submit edited information of specific faculty.
    * @param facultyId
    * @param facultyName
    * @param facultyDescription
    * @returns
    */
   editFaculty(
      facultyId: string,
      facultyName: string,
      facultyDescription: string
   ) {
      return this.http
         .put<any>(`${environment.apiUrl}/faculty`, {
            facultyId,
            facultyName,
            facultyDescription,
         })
         .pipe(
            map((data) => {
               return data;
            })
         );
   }

   /**
    * Service is used to call api that will remove a specific faculty.
    * @param facultyId
    * @returns
    */
   remove(facultyId: string) {
      return this.http
         .delete<any>(`${environment.apiUrl}/faculty/${facultyId}`)
         .pipe(
            map((data) => {
               return data;
            })
         );
   }
}
