// *** Angular
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';

// *** Actions
import * as basicProfessorsActions from '../state/action/basic-professors-statistics.actions';
import * as facultyActions from '../../../../../state/faculty/action/faculty.actions';
import * as filtersActions from '../../../../../../administrator-components/state/filters/action/filters.actions';

// *** Selectors
import { getBasicProfessorsStatisticsDetails } from '../state/selector/basic-professors-statistics.selectors';
import { getInstitutionFacultiesStateDetails } from '../../../../../state/faculty/selector/faculty.selectors';

// *** Services
import { DepartmentService } from 'src/app/services/department.service';

@Component({
   selector: 'app-basic-professors-statistics',
   templateUrl: './basic-professors-statistics.component.html',
   styleUrls: ['./basic-professors-statistics.component.scss'],
})
export class BasicProfessorsStatisticsComponent implements OnInit, OnDestroy {
   /**
    * Variable is used to store departments of selected faculty
    */
   departments: any;
   /**
    * Variable is used to store facultyId to filter submissions of assignments within specific faculty.
    */
   facultyId = '-1';
   /**
    * Variable is used to store facultyId to filter submissions of assignments within specific faculty.
    */
   departmentId = '-1';
   /**
    * Variable used to store professor name that used to filter statistics based on name
    */
   professorName: string;
   /**
    * Variabel used to filter statistics based on subject title
    */
   professorSubject: any;
   /**
    * Variable used to store information that a thesis is been clicked for expand or not
    */
   expandInfo: any;
   /**
    * Variabel used to store statistics of professors
    */
   professors;
   /**
    * Variable used to store professor id that used to expand specific row on HTML
    */
   professorId;
   /**
    * Symbolizing the page of pagination
    */
   page: number = 1;
   /**
    * Timer used for search delay
    */
   timer: any;
   /**
    * Variabel used to store total number of professors without pagination
    */
   professorsNumber;
   /**
    * Variabels used to retrieve faculties of insitution from store.
    */
   faculties;
   faculties$;
   getInstitutionFacultiesStateDetails$: any;
   getBasicProfessorsStatisticsDetails$: any;
   constructor(
      private store: Store,
      private departmentService: DepartmentService,
      private router: Router
   ) {}
   ngOnDestroy(): void {
      this.getInstitutionFacultiesStateDetails$.unsubscribe();
      this.getBasicProfessorsStatisticsDetails$.unsubscribe();
   }

   ngOnInit(): void {
      this.store.dispatch(facultyActions.loadFacultiesDetails({ page: 0 }));

      this.store.dispatch(
         basicProfessorsActions.loadBasicProfessorsStatistics({
            page: this.page,
            faculty: this.facultyId == '-1' ? undefined : this.facultyId,
            department:
               this.departmentId == '-1' ? undefined : this.departmentId,
            name: this.professorName,
            subject: this.professorSubject,
         })
      );

      this.getInstitutionFacultiesStateDetails$ = this.store
         .select(getInstitutionFacultiesStateDetails)
         .subscribe((data) => {
            if (data.faculties !== null && this.faculties == undefined) {
               this.faculties = data.faculties;
            }
         });

      this.getBasicProfessorsStatisticsDetails$ = this.store
         .select(getBasicProfessorsStatisticsDetails)
         .subscribe((data) => {
            if (data !== null) {
               this.professors = data.basicProfessorsStatistics;
               this.professorsNumber = data.professorsNumber;
            }
         });
   }
   /**
    * Function is used to filter by title
    */
   filterWithTimeout() {
      let time;
      time = 500;
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
         this.store.dispatch(
            basicProfessorsActions.loadBasicProfessorsStatistics({
               page: this.page,
               faculty: this.facultyId == '-1' ? undefined : this.facultyId,
               department:
                  this.departmentId == '-1' ? undefined : this.departmentId,
               name: this.professorName,
               subject: this.professorSubject,
            })
         );
      }, time);
   }
   /**
    * Function is used to filter by selected professor
    */
   professorFilter() {
      let time;
      time = 500;
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
         this.store.dispatch(
            basicProfessorsActions.loadBasicProfessorsStatistics({
               page: this.page,
               faculty: this.facultyId == '-1' ? undefined : this.facultyId,
               department:
                  this.departmentId == '-1' ? undefined : this.departmentId,
               name: this.professorName,
               subject: this.professorSubject,
            })
         );
      }, time);
   }
   /**
    * Function is used to filter by selected faculty
    */
   selectedFaculty() {
      this.store.dispatch(
         basicProfessorsActions.loadBasicProfessorsStatistics({
            page: this.page,
            faculty: this.facultyId == '-1' ? undefined : this.facultyId,
            department:
               this.departmentId == '-1' ? undefined : this.departmentId,
            name: this.professorName,
            subject: this.professorSubject,
         })
      );

      if (this.facultyId !== '') {
         this.departmentService
            .getDepartmentsOfFaculty(this.facultyId)
            .pipe(first())
            .subscribe(
               (data) => {
                  this.departments = data.departments;
               },
               (error) => {
                  console.log(error);
               }
            );
      }
   }
   /**
    * Function is used to filter by selected department
    */
   selectedDepartment() {
      this.store.dispatch(
         basicProfessorsActions.loadBasicProfessorsStatistics({
            page: this.page,
            faculty: this.facultyId == '-1' ? undefined : this.facultyId,
            department:
               this.departmentId == '-1' ? undefined : this.departmentId,
            name: this.professorName,
            subject: this.professorSubject,
         })
      );
   }

   /**
    * Method used to change pagination page. It dispatches an action with page as param.
    * @param event
    */
   changePage(event): void {
      this.page = event;
      this.store.dispatch(
         basicProfessorsActions.loadBasicProfessorsStatistics({
            page: this.page,
            faculty: this.facultyId == '-1' ? undefined : this.facultyId,
            department:
               this.departmentId == '-1' ? undefined : this.departmentId,
            name: this.professorName,
            subject: this.professorSubject,
         })
      );
   }
   /**
    * Function is used to exapnd information
    * @param professorId
    */
   expand(professorId) {
      this.professorId = professorId;
      if (this.expandInfo !== professorId) {
         this.expandInfo = professorId;
      } else {
         this.expandInfo = -1;
      }
   }
   /**
    * Function is used when clicked on number of thesis of professor to redirect to thesis with filter activated to clicked professor.
    * @param professorId
    */
   thesisFilter(professorId) {
      this.store.dispatch(
         filtersActions.thesisSubmissionsFilters({
            professor: professorId,
         })
      );
      this.router.navigateByUrl('/administrator/statistics/submissions/thesis');
   }
   /**
    * Function is used when clicked on number of subjects of professor to redirect to subjects with filter activated to clicked professor.
    * @param professorId
    */
   subjectsFilter(professorId) {
      this.store.dispatch(
         filtersActions.subjectsFilters({
            professor: professorId,
         })
      );
      this.router.navigateByUrl(
         '/administrator/statistics/academic-units/subjects'
      );
   }
   /**
    * Function is used when clicked on number of assignments of professor to redirect to assigments with filter activated to clicked professor.
    * @param professorId
    */
   assignmentFilter(professorId) {
      this.store.dispatch(
         filtersActions.assignmentFilters({
            professor: professorId,
         })
      );
      this.router.navigateByUrl(
         '/administrator/statistics/academic-units/assignments'
      );
   }
   /**
    * Function is used to reset filters
    */
   resetFilter() {
      this.professorSubject = undefined;
      this.facultyId = '-1';
      this.departmentId = '-1';
      this.professorName = undefined;
      this.page = 1;
      this.store.dispatch(
         basicProfessorsActions.loadBasicProfessorsStatistics({
            page: this.page,
            faculty: this.facultyId == '-1' ? undefined : this.facultyId,
            department:
               this.departmentId == '-1' ? undefined : this.departmentId,
            name: this.professorName,
            subject: this.professorSubject,
         })
      );
   }
}
