import { createAction, props } from '@ngrx/store';

export const loadRegisteredSubjects = createAction(
   '[Administration] Load Registered Subjects',
   props<{ generation?: any }>()
);

export const loadRegisteredSubjectsSuccess = createAction(
   '[Administration] Load Registered Subjects Success',
   props<{ data: any }>()
);

export const loadRegisteredSubjectsFailure = createAction(
   '[Administration] Load Registered Subjects  Failure',
   props<{ error: any }>()
);

export const loadSubjectsToRegister = createAction(
   '[Administration] Load Subjects To Register',
   props<{ generation?: any; year?: any }>()
);

export const loadSubjectsToRegisterSuccess = createAction(
   '[Administration] Load Subjects To Register Success',
   props<{ data: any }>()
);

export const loadSubjectsToRegisterFailure = createAction(
   '[Administration] Load Subjects To Register Failure',
   props<{ error: any }>()
);

export const registerSubject = createAction(
   '[Administration] Register Subject',
   props<{ subject: any; professor: any; year?: any; generation?: any }>()
);

export const registerSubjectSuccess = createAction(
   '[Administration] Register Subject Success',
   props<{ data: any }>()
);

export const registerSubjectFailure = createAction(
   '[Administration] Register Subject Failure',
   props<{ error: any }>()
);

export const displaySubjectsWithCode = createAction(
   '[Administration] Display Subjects With Code',
   props<{ code: any }>()
);

export const displaySubjectsWithCodeSuccess = createAction(
   '[Administration] Display Subjects With Code Success',
   props<{ data: any }>()
);

export const displaySubjectsWithCodeFailure = createAction(
   '[Administration] Display Subjects With Code Failure',
   props<{ error: any }>()
);

export const registerSubjectWithPassword = createAction(
   '[Administration] Register Subject With Password',
   props<{ password: any; subject?: any; professor?: any; year?; generation?}>()
);

export const registerSubjectWithPasswordSuccess = createAction(
   '[Administration] Register Subject With Password Success',
   props<{ data: any }>()
);

export const registerSubjectWithPasswordFailure = createAction(
   '[Administration] Register Subject With Password Failure',
   props<{ error: any }>()
);

export const deregisterSubject = createAction(
   '[Administration] Deregister Subject',
   props<{ subject?: any; year?; generation? }>()
);

export const deregisterSubjectSuccess = createAction(
   '[Administration] Deregister Subject Success',
   props<{ data: any }>()
);

export const deregisterSubjectFailure = createAction(
   '[Administration] Deregister Subject Failure',
   props<{ error: any }>()
);

export const declineSubject = createAction(
   '[Administration] Decline Subject',
   props<{ subjectCourseId?: any; year?; generation? }>()
);

export const declineSubjectSuccess = createAction(
   '[Administration] Decline Subject Success',
   props<{ data: any }>()
);

export const declineSubjectFailure = createAction(
   '[Administration] Decline Subject Failure',
   props<{ error: any }>()
);
