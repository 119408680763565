import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DepartmentState } from '../../department/reducer/department.reducer';

/**
 * Selectors are pure functions used for obtaining slices of store state.
 */

/**
 * getInstitutionDepartmentsState represent top level of the department state.
 * The createFeatureSelector is a convenience method for returning a top level feature state.
 */
const getInstitutionDepartmentsState = createFeatureSelector<DepartmentState>(
   'departments'
);

/**
 * Selector used to get departments details state.
 */
export const getInstitutionDepartmentsStateDetails = createSelector(
   getInstitutionDepartmentsState,
   (state) => state
);
