import { createAction, props } from '@ngrx/store';

export const loadInstitutionalProfessorsStatistics = createAction(
   '[Institutional Professors Statistics] Load Institutional Professors Statistics',
   props<{
      page?: any;
      faculty?: string;
      department?: string;
      name?: string;
      subject?: string;
   }>()
);

export const loadInstitutionalProfessorsStatisticsSuccess = createAction(
   '[Institutional Professors Statistics] Load Institutional Professors Statistics Success',
   props<{ data: any }>()
);

export const loadInstitutionalProfessorsStatisticsFailure = createAction(
   '[Institutional Professors Statistics] Load Institutional Professors Statistics Failure',
   props<{ error: any }>()
);
