import { createAction, props } from '@ngrx/store';

export const loadThesisSubmissionStatistics = createAction(
   '[ Thesis Submissions Statistics] Load Thesis Submission Details Statistics',
   props<{
      page?: any;
      facultyId?: string;
      departmentId?: string;
      professorId?: string;
      level?: string;
      studentName?: string;
   }>()
);

export const loadThesisSubmissionStatisticsSuccess = createAction(
   '[ Thesis Submissions Statistics] Load Thesis Submission Details Statistics Success',
   props<{ data: any }>()
);

export const loadThesisSubmissionStatisticsFailure = createAction(
   '[Thesis Submissions Statistics] Load Thesis Submission Details Statistics Failure',
   props<{ error: any }>()
);

export const loadThesisSubmissionDetails = createAction(
   '[ Thesis Submission Details Statistics] Load Thesis Submission Details Statistics',
   props<{
      thesisId: string;
   }>()
);

export const loadThesisSubmissionDetailsSuccess = createAction(
   '[ Thesis Submission Details Statistics] Load Thesis Submission Details Statistics Success',
   props<{ data: any }>()
);

export const loadThesisSubmissionDetailsFailure = createAction(
   '[Thesis Submission Details Statistics] Load Thesis Submission Details Statistics Failure',
   props<{ error: any }>()
);
