// *** Angular
import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';

// *** Services
import { StoreService } from 'src/app/services/store.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
   selector: 'app-manage-users',
   templateUrl: './manage-users.component.html',
   styleUrls: ['./manage-users.component.scss'],
})
export class ManageUsersComponent implements OnInit {
   constructor(
      private storeService: StoreService,
      public router: Router,
      private spinner: NgxSpinnerService
   ) {}
   /**
    * Variable used to store users details within institotion plan that can manage them.
    */
   users;
   /**
    * Varioable used to store state of managing process
    */
   managingStarted: boolean = false;
   /**
    * Variable used to store administrator accounts requested, that later used to send those inputs to calucalte price
    */
   administratorsNumber;
   /**
    * Variable used to store academic Integrity Officer accounts requested, that later used to send those inputs to calucalte price
    */
   academicIntegrityOfficersNumber;
   /**
    * Variable used to store professor accounts requested, that later used to send those inputs to calucalte price
    */
   professorsNumber;
   /**
    * Variable used to store teaching assistant accounts requested, that later used to send those inputs to calucalte price
    */
   teachingAssistantsNumber;
   /**
    * Variable used to store student accounts requested, that later used to send those inputs to calucalte price
    */
   studentsNumber;

   /**
    * Variable used to store returned price after calculation
    */
   calculatedPrice: number = 0;

   ngOnInit(): void {
      this.spinner.show();
      this.storeService
         .institutionLicenses()
         .pipe(first())
         .subscribe(
            (data) => {
               this.users = data.licenses;
               this.spinner.hide();

               this.administratorsNumber = this.users[0]?.administrators;
               this.academicIntegrityOfficersNumber =
                  this.users[0]?.academicIntegrityOfficers;
               this.professorsNumber = this.users[0]?.professors;
               this.teachingAssistantsNumber =
                  this.users[0]?.teachingAssistants;
               this.studentsNumber = this.users[0]?.students;
            },
            (error) => {
               console.log('error', error);
               this.spinner.hide();
            }
         );
   }
   startManage() {
      this.managingStarted = !this.managingStarted;
   }

   calculatePrice() {
      this.storeService
         .calculatePrice({
            administratorsNumber: this.administratorsNumber,
            academicIntegrityOfficersNumber:
               this.academicIntegrityOfficersNumber,
            professorsNumber: this.professorsNumber,
            teachingAssistantsNumber: this.teachingAssistantsNumber,
            studentsNumber: this.studentsNumber,
         })
         .pipe(first())
         .subscribe(
            (data) => {
               this.calculatedPrice = data.price;
               this.spinner.hide();
            },
            (error) => {
               console.log('error', error);
               this.spinner.hide();
            }
         );
   }
}
