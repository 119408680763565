<h4>Invite student</h4>
<form [formGroup]="studentInvite">
   <div class="editimi-fakultetit-inputs">
      <div class="inside-inputs">
         <label for="studentName">Student Name</label>
         <input formControlName="studentName" type="text" />
      </div>
      <div
         *ngIf="
            studentInvite.controls.studentName.touched &&
            studentInvite.controls.studentName.invalid
         "
         class="invalid"
      >
         Student name is required*
      </div>
      <div
         *ngIf="studentInvite.controls.studentName.errors?.minlength"
         class="invalid"
      >
         Student name should contain at least 3 characters!
      </div>
      <div class="inside-inputs">
         <label for="studentEmail">Email</label>
         <input formControlName="studentEmail" type="email" />
      </div>
      <div
         *ngIf="
            studentInvite.controls.studentEmail.touched &&
            studentInvite.controls.studentEmail.invalid
         "
         class="invalid"
      >
         Email is required*
      </div>

      <div
         *ngIf="studentInvite.controls.studentEmail.errors?.minlength"
         class="invalid"
      >
         Student email should contain at least 3 characters!
      </div>
      <div class="inside-inputs">
         <label for="documentLanguage">Document Language</label>
         <select
            name=""
            id=""
            formControlName="documentLanguage"
            (change)="this.languageSelected(1)"
         >
            <option value="0" disabled selected>Choose Language</option>
            <ng-container *ngFor="let language of this.languages">
               <option
                  value="{{ language.value }}"
                  *ngIf="language.selectedTranslateLanguage === false"
               >
                  {{ language.text }}
               </option>
            </ng-container>
         </select>
      </div>

      <div class="inside-inputs">
         <label for="translateTo">Translate to</label>
         <select
            name=""
            id=""
            formControlName="translateTo"
            (change)="this.languageSelected(2)"
         >
            <option value="0" disabled selected>Choose Language</option>
            <ng-container *ngFor="let language of this.languages">
               <option
                  value="{{ language.value }}"
                  *ngIf="language.selectedDocLanguage === false"
               >
                  {{ language.text }}
               </option>
            </ng-container>
         </select>
      </div>
   </div>
   <div class="editimi-fakultetit-fshij-perfundo">
      <button
         [disabled]="studentInvite.invalid"
         [ngClass]="{
            disable: studentInvite.invalid
         }"
         type="submit"
         class="submit"
         (click)="update()"
      >
         Send Invitation
      </button>
   </div>
</form>
