import { createFeatureSelector, createSelector } from '@ngrx/store';
import { professorsSubmissionsState } from '../reducer/professors-submissions.reducer';

/**
 * Selectors are pure functions used for obtaining slices of store state.
 */

/**
 * getProfessorsSubmissions represent top level of the assignment state.
 * The createFeatureSelector is a convenience method for returning a top level feature state.
 */
const getProfessorsSubmissions =
   createFeatureSelector<professorsSubmissionsState>('professorsSubmissions');

/**
 * Selector used to get statisticsOverview details state.
 */
export const getProfessorsSubmissionsDetails = createSelector(
   getProfessorsSubmissions,
   (state) => state
);
