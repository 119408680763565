import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, catchError, concatMap, switchMap } from 'rxjs/operators';
import * as professorActions from '../../professor/action/professor.actions';
import { UserService } from '../../../../services/user.service';

@Injectable()
export class ProfessorEffects {
   constructor(private actions$: Actions, private userService: UserService) {}

   /**
    * Effect listening for registerProfessor Action, when registerProfessor is dispatched the effect with call create() from userService.
    * In case of success response the effect it will trigger registerProfessorSuccess.
    * In case of Error it will trigger registerProfessorFailure with error property.
    */
   registerProfessor$ = createEffect(() => {
      return this.actions$.pipe(
         ofType(professorActions.professorRegister),
         concatMap((action) => {
            return this.userService
               .createProfessor(
                  action.professorName,
                  action.professorFaculty,
                  action.professorDepartment,
                  action.professorSubjects,
                  action.professorEmail,
                  action.professorWords
               )
               .pipe(
                  switchMap((data) => [
                     professorActions.professorRegisterSuccess(),
                  ]),
                  catchError((error) =>
                     of(professorActions.professorRegisterFailure({ error }))
                  )
               );
         })
      );
   });

   /**
    * Effect listening for loadProfessorsDetails Action, when loadProfessorsDetails is dispatched the effect with call courses() from courseService.
    * In case of success response the effect it will trigger loadProfessorsDetailsSuccess.
    * In case of Error it will trigger loadProfessorsDetailsFailure with error property.
    */
   getProfessorsDetails$ = createEffect(() => {
      return this.actions$.pipe(
         ofType(professorActions.loadProfessorsDetails),
         concatMap((action) => {
            return this.userService
               .institutionProfessors(
                  action.page,
                  action.faculty,
                  action.department,
                  action.professorName
               )
               .pipe(
                  map((data) => {
                     return professorActions.loadProfessorsDetailsSuccess({
                        professors: data.professors,
                        professorsNumber: data.professorNumber,
                     });
                  }),
                  catchError((error) =>
                     of(
                        professorActions.loadProfessorsDetailsFailure({ error })
                     )
                  )
               );
         })
      );
   });

   /**
    * Effect listening for editProfessorDetails Action, when editProfessorDetails is dispatched the effect with call editCourse() from courseService.
    * In case of success response the effect it will trigger loadProfessorsDetails that will return all professors of institution.
    * In case of Error it will trigger editProfessorDetailsFailure with error property.
    */
   editProfessorDetails$ = createEffect(() => {
      return this.actions$.pipe(
         ofType(professorActions.editProfessorDetails),
         concatMap((action) => {
            return this.userService
               .editUser(
                  action.professorId,
                  action.professorName,
                  action.professorEmail,
                  action.professorFaculty,
                  action.professorDepartment,
                  action.professorStatus,
                  action.increaseWords,
                  action.professorWordsToAllocate,
                  null,
                  action.professorSubjects
               )
               .pipe(
                  switchMap((data) => [
                     professorActions.editProfessorDetailsSuccess({
                        data:data
                     }),
                     professorActions.loadProfessorsDetails({
                        page:action.page,
                        faculty: action.faculty,
                        department: action.department,
                        professorName: action.professorNameFilter
                      })
                  ]),
                  catchError((error) =>
                     of(
                        professorActions.editProfessorDetailsFailure({
                           error,
                        })
                     )
                  )
               );
         })
      );
   });

   /**
    * Effect listening for removeProfessor Action, when removeProfessor is dispatched the effect with call remove() from courseService.
    * In case of success response the effect it will trigger  will return all professors of institution.
    * In case of Error it will trigger removeProfessorFailure with error property.
    */
   removeProfessor$ = createEffect(() => {
      return this.actions$.pipe(
         ofType(professorActions.removeProfessor),
         concatMap((action) => {
            return this.userService.removeUser(action.professorId).pipe(
               switchMap((data) => [
                  professorActions.removeProfessorSuccess({
                     data:data
                  }),
                  professorActions.loadProfessorsDetails({
                     page:action.page,
                     faculty: action.faculty,
                     department: action.department,
                     professorName: action.professorNameFilter
                   })
               ]),
               catchError((error) =>
                  of(professorActions.removeProfessorFailure({ error }))
               )
            );
         })
      );
   });
}
