import { createFeatureSelector, createSelector } from '@ngrx/store';

import { individualAdministratorState } from '../reducer/individualAdministrator.reducer';

/**
 * Selectors are pure functions used for obtaining slices of store state.
 */

/**
 * The createFeatureSelector is a convenience method for returning a top level feature state.
 */
const getIndividualAdministratorState = createFeatureSelector<individualAdministratorState>(
   'IndividualAdministrator'
);

/**
 * Selector used to get subjects details state.
 */
export const getIndividualAdministratorStateDetails = createSelector(
   getIndividualAdministratorState,
   (state) => state.dashboard
);

/**
 * Selector used to get subjects details state.
 */
export const getIndividualAdministratorStudentsListStateDetails = createSelector(
   getIndividualAdministratorState,
   (state) => state.students
);

/**
 * Selector used to get subjects details state.
 */
export const getIndividualAdministratorStatisticsStateDetails = createSelector(
   getIndividualAdministratorState,
   (state) => state.statistics
);

/**
 * Selector used to get subjects details state.
 */
export const getDocumentSubmissionsStateDetails = createSelector(
   getIndividualAdministratorState,
   (state) => state.documentSubmissions
);

export const DocumentSubmissionsStateDetails = createSelector(
   getIndividualAdministratorState,
   (state) => state.documentSubmissions
);
