<section>
   <h4 class="bold w-87">Dashboard</h4>
   <main style="width: 100%">
      <div class="cards-monitoruesi">
         <a
            routerLink="overview"
            routerLinkActive="active"
            class="flex white-bg border"
         >
            <h4>Overview</h4>
         </a>
         <a
            routerLink="academic-units"
            routerLinkActive="active"
            class="flex white-bg border"
         >
            <h4>Academic units</h4>
         </a>
         <a
            routerLink="users"
            routerLinkActive="active"
            class="flex white-bg border"
         >
            <h4>Users</h4>
         </a>
         <a
            routerLink="submissions"
            routerLinkActive="active"
            class="flex white-bg border"
         >
            <h4>Submissions</h4>
         </a>
      </div>
      <div>
         <router-outlet></router-outlet>
      </div>
   </main>
</section>
