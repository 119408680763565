import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
   providedIn: 'root',
})
export class StoreService {
   constructor(private http: HttpClient) {}

   offers() {
      return this.http
         .get<any>(`${environment.apiUrl}/institution/store/offers`)
         .pipe();
   }

   order(orderId: string) {
      return this.http
         .post<any>(`${environment.apiUrl}/order`, { orderId })
         .pipe();
   }

   institutionLicenses() {
      return this.http
         .get<any>(`${environment.apiUrl}/institution/licenses`)
         .pipe();
   }

   createOffer(
      title,
      commitment,
      bachelor,
      master,
      phd,
      administratorAccounts,
      studentAccounts,
      crossLingual,
      professorUploads,
      repositories,
      billingPlan,
      subscriptions,
      support,
      professorAccounts,
      teachingAssistantAccounts,
      academicIntegrityOfficerAccounts,
      sentEmail
   ) {
      if (subscriptions == '') {
         subscriptions = false;
      }

      if (administratorAccounts == '') {
         administratorAccounts = 1;
      }
      return this.http
         .post<any>(`${environment.apiUrl}/offer`, {
            title,
            commitment,
            bachelor,
            master,
            phd,
            administratorAccounts,
            studentAccounts,
            crossLingual,
            professorUploads,
            repositories,
            billingPlan,
            subscriptions,
            support,
            professorAccounts,
            teachingAssistantAccounts,
            academicIntegrityOfficerAccounts,
            sentEmail
         })
         .pipe();
   }

   editOffer(
      offerId,
      title,
      commitment,
      bachelor,
      master,
      phd,
      administratorAccounts,
      studentAccounts,
      crossLingual,
      professorUploads,
      repositories,
      billingPlan,
      subscriptions,
      support,
      professorAccounts,
      teachingAssistantAccounts,
      academicIntegrityOfficerAccounts,
      sentEmail
   ) {
      return this.http
         .put<any>(`${environment.apiUrl}/offer/${offerId}/edit`, {
            title,
            commitment,
            bachelor,
            master,
            phd,
            administratorAccounts,
            studentAccounts,
            crossLingual,
            professorUploads,
            repositories,
            billingPlan,
            subscriptions,
            support,
            professorAccounts,
            teachingAssistantAccounts,
            academicIntegrityOfficerAccounts,
            sentEmail
         })
         .pipe();
   }

   sentEmailForCustomOffer(name,email,subject,message,offerId) {
      return this.http
         .post<any>(`${environment.apiUrl}/offer/${offerId}/sentEmail`, {
            name,
            email,
            subject,
            message,
         })
         .pipe();
   }

   deleteOffer(id) {
      return this.http
         .delete<any>(`${environment.apiUrl}/offer/${id}`, {})
         .pipe();
   }

   calculatePrice(info) {
      return this.http
         .post<any>(`${environment.apiUrl}/price/calculate`, { info })
         .pipe();
   }

   savePrice(price) {
      return this.http
         .put<any>(`${environment.apiUrl}/price`, { price })
         .pipe();
   }
}
