import { createAction, props } from '@ngrx/store';

export const loadBasicProfessorsStatistics = createAction(
   '[Basic Professors Statistics] Load Basic Professors Statistics',
   props<{
      page?: any;
      faculty?: string;
      department?: string;
      name?: string;
      subject?: string;
   }>()
);

export const loadBasicProfessorsStatisticsSuccess = createAction(
   '[Basic Professors Statistics] Load Basic Professors Statistics Success',
   props<{ data: any }>()
);

export const loadBasicProfessorsStatisticsFailure = createAction(
   '[Basic Professors Statistics] Load Basic Professors Statistics Failure',
   props<{ error: any }>()
);
