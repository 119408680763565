<main>
   <div class="scroll white-bg border">
      <h3
         *ngIf="
            !(
               this.courses?.totalSubjects == 0 &&
               name == undefined &&
               generation == -1 &&
               departmentId == -1 &&
               professorId == -1 &&
               status == -1 &&
               type == -1 &&
               facultyId == '-1'
            )
         "
         class="filtersContainer"
      >
      <h4>Subjects</h4>
         <div class="selectors">
            <div class="generationFilter">
               <select
                  [(ngModel)]="generation"
                  name=""
                  id=""
                  (change)="filterCourses()"
               >
                  <option value="-1" selected disabled>
                     Select academic year
                  </option>
                  <option value="g1819">AY 2018/2019</option>
                  <option value="g1920">AY 2019/2020</option>
                  <option value="g2021">AY 2020/2021</option>
                  <option value="g2122">AY 2021/2022</option>
                  <option value="g2223">AY 2022/2023</option>
                  <option value="g2324">AY 2023/2024</option>
                  <option value="g2425">AY 2024/2025</option>
                  <option value="g2526">AY 2025/2026</option>
                  <option value="0">All</option>
               </select>
            </div>
            <div>
               <select [(ngModel)]="facultyId" (change)="selectedFaculty()">
                  <option value="-1" selected disabled>
                     Filter by school
                  </option>
                  <ng-container *ngFor="let faculty of this.faculties">
                     <option value="{{ faculty.id }}">
                        {{ faculty.name }}
                     </option>
                  </ng-container>
                  <option value="">All</option>
               </select>
            </div>
            <div>
               <select
                  disabled
                  *ngIf="facultyId == '' || facultyId == '-1'"
                  name=""
                  id=""
               >
                  <option value="">Select school first</option>
               </select>
               <select
                  *ngIf="facultyId !== '' && facultyId !== '-1'"
                  [(ngModel)]="departmentId"
                  (change)="filterCourses()"
               >
                  <option value="-1" selected disabled>
                     Filter by department
                  </option>
                  <ng-container *ngFor="let department of this.departments">
                     <option value="{{ department.id }}">
                        {{ department.name }}
                     </option>
                  </ng-container>
                  <option value="">All</option>
               </select>
            </div>
            <div>
               <select [(ngModel)]="professorId" (change)="filterCourses()">
                  <option value="-1" selected disabled>
                     Filter by educator
                  </option>
                  <ng-container *ngFor="let professor of this.professors">
                     <option value="{{ professor.id }}">
                        {{ professor.name }}
                     </option>
                  </ng-container>

                  <option value="">All</option>
               </select>
            </div>
            <div>
               <select
                  [(ngModel)]="status"
                  (change)="filterCourses()"
                  name=""
                  id=""
               >
                  <option value="-1" selected disabled>
                     Select status to filter
                  </option>
                  <option value="1">Active</option>
                  <option value="0">Inactive</option>
                  <option value="">All</option>
               </select>
            </div>
            <div>
               <select
                  [(ngModel)]="type"
                  (change)="filterCourses()"
                  name=""
                  id=""
               >
                  <option value="-1" selected disabled>
                     Filter by type
                  </option>
                  <option value="1">Mandatory</option>
                  <option value="2">Elective</option>
                  <option value="">All</option>
               </select>
            </div>
            <div>
               <input
                  placeholder="Search by name"
                  [(ngModel)]="name"
                  (ngModelChange)="titleFilter()"
                  type="text"
                  name="searchByName"
               />
            </div>
            <button class="reportBtn" (click)="resetFilter()">Reset</button>
         </div>
      </h3>
      <div *ngIf="this.courses?.totalSubjects != 0" class="scroll">
         <table>
            <tr>
               <th>Name</th>
               <th>School</th>
               <th>Department</th>
               <th>Educators</th>
               <th>
                  Id
               </th>
               <th>
                  No of Students
               </th>
               <th>
                  Generation
               </th>
               <th>
                  Type
               </th>
               <th>Status</th>
               <th>Edit</th>
            </tr>
            <ng-container
               *ngFor="
                  let course of this.courses?.subjects
                     | paginate
                        : {
                             itemsPerPage: pageSize,
                             currentPage: page,
                             totalItems: this.courses?.totalSubjects
                          }
               "
            >
               <tr class="shadow">
                  <td>
                     {{ course.title }}
                  </td>
                  <td>
                     {{ course.Department.Faculty.name }}
                  </td>
                  <td>
                     {{ course.Department.name }}
                  </td>
                  <td>
                     <ng-container *ngIf="course.professorCourses.length > 0">
                        <ng-container
                           *ngFor="let professor of course.professorCourses"
                        >
                           {{ professor.User.name }},
                        </ng-container>
                     </ng-container>
                     <ng-container *ngIf="course.professorCourses.length === 0">
                        -- / --
                     </ng-container>
                  </td>
                  <td>
                     {{ course.code }}
                  </td>
                  <td>
                     {{ course.students }}
                  </td>
                  <td>
                     {{ '20'+ course.generation.substring(1, 3) }}/{{ '20'+ course.generation.substring(3, 5) }}
                  </td>
                  <td>
                     <ng-container *ngIf="course.type === 1">
                        Mandatory
                     </ng-container>
                     <ng-container *ngIf="course.type === 2">
                        Elective
                     </ng-container>
                  </td>
                  <td>
                     <ng-container *ngIf="course.status">
                        Active
                     </ng-container>
                     <ng-container *ngIf="!course.status">
                        Inactive
                     </ng-container>
                  </td>
                  <td>
                     <i (click)="this.openModal(course.id)" class="far fa-edit editButton"></i>
                  </td>
               </tr>
            </ng-container>
         </table>
      </div>
      <div
         class="zeroState"
         *ngIf="
            this.courses?.totalSubjects == 0 &&
            (name != undefined ||
               facultyId != '-1' ||
               generation != -1 ||
               departmentId != -1 ||
               professorId != -1 ||
               status != -1 ||
               type != -1)
         "
      >
         No subjects found
      </div>
      <div
         class="zero-state"
         *ngIf="
            this.courses?.totalSubjects == 0 &&
            name == undefined &&
            facultyId == '-1' &&
            generation == -1 &&
            departmentId == -1 &&
            professorId == -1 &&
            status == -1 &&
            type == -1
         "
      >
         <!-- <img src="../../assets/images/icona-image.png" alt="" /> -->
         <h5 class="bold">There are no subjects registered in your institution yet.<br/><span class="clickable" routerLink="/administrator/register-module/course-register">Click here</span> to begin registering subjects.</h5>
      </div>


      <div class=" paginationContainer">
         <div *ngIf="this.courses?.totalSubjects != 0">
            <button class="clone" (click)="openGenerationChangeModal()">
               <i class="fas fa-copy mr-5"></i>Subject Wizard
            </button>
         </div>
         <pagination-controls
         *ngIf="this.courses?.totalSubjects != 0"
         class="num"
         (pageChange)="changePage($event)"
         responsive="true"
         previousLabel=""
         nextLabel=""
      ></pagination-controls>
      <div>
         <button class="reportBtn" (click)="registerSubjectModal()">
            <i class="fas fa-plus mr-5"></i>Register subjects
         </button>
      </div>
      </div>
   </div>
</main>
