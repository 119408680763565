import { createAction, props } from '@ngrx/store';

export const loadUnitsStatisticsDepartments = createAction(
   '[UnitsStatisticsDepartments] Load UnitsStatisticsDepartments',
   props<{ page?: any; title?: string; faculty?: string }>()
);

export const loadUnitsStatisticsDepartmentsSuccess = createAction(
   '[UnitsStatisticsDepartments] Load UnitsStatisticsDepartments Success',
   props<{ data: any }>()
);

export const loadUnitsStatisticsDepartmentsFailure = createAction(
   '[UnitsStatisticsDepartments] Load UnitsStatisticsDepartments Failure',
   props<{ error: any }>()
);
