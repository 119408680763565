// *** Angular
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

// *** Selectors
import { getFacultiesStatisticsDetails } from '../state/selector/units-statistics-faculties.selectors';

// *** Actions
import * as facultiesActions from '../state/action/units-statistics-faculties.actions';
import * as filtersActions from '../../../../../administrator-components/state/filters/action/filters.actions';

import { LanguageService } from 'src/app/services/language.service';

@Component({
   selector: 'app-faculties-statistics',
   templateUrl: './faculties-statistics.component.html',
   styleUrls: ['./faculties-statistics.component.scss'],
})
export class FacultiesStatisticsComponent implements OnInit, OnDestroy {
   /**
    * Variable used to store information that a thesis is been clicked for expand or not
    */
   expandInfo: any;
   /**
    * Variable is used to store institution faculties
    */
   faculties;
   /**
    * Variable used to store faculty id that used to expand specific row on HTML
    */
   facultyId;
   /**
    * Symbolizing the page of pagination
    */
   page: number = 1;
   /**
    * Representing tile model
    */
   facultyName: string;
   /**
    * Timer used for search delay
    */
   timer: any;
   /**
    * Variable used to store total number of faculties without pagination
    */
   totalFaculties;
   /**
    * Observal used to store statistics of faculties
    */
   statistics$;
   languageName: string;
   constructor(private store: Store, private router: Router, private languageService: LanguageService) {}
   ngOnDestroy(): void {
      this.statistics$.unsubscribe();
   }

   ngOnInit(): void {
      this.store.dispatch(
         facultiesActions.loadUnitsStatisticsFaculties({ page: this.page })
      );

      this.statistics$ = this.store
         .select(getFacultiesStatisticsDetails)
         .subscribe((data) => {
            if (data !== null) {
               this.faculties = data.facultiesStatistics;
               this.totalFaculties = data.facultiesNumber;

              // Assuming faculty.language[0] contains the language code
            //   if (this.faculties && this.faculties.length > 0) {
            //     const firstFaculty = this.faculties[0];
            //     const languageCode = firstFaculty.language[0];
            //     this.languageName = this.languageService.findLanguageName(languageCode);
            //   }
            }
         });
   }

   setLanguageName(lang) {
      if(lang == '-') {
         return '-'
      }      
return this.languageService.findLanguageName(lang);
   }
   /**
    * Function is used to filter faculties by title
    */
   titleFilter() {
      let time;
      time = 500;
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
         this.store.dispatch(
            facultiesActions.loadUnitsStatisticsFaculties({
               page: this.page,
               title: this.facultyName,
            })
         );
      }, time);
   }

   /**
    * Method used to change pagination page. It dispatches an action with page as param.
    * @param event
    */
   changePage(event): void {
      this.page = event;
      this.store.dispatch(
         facultiesActions.loadUnitsStatisticsFaculties({
            page: this.page,
            title: this.facultyName,
         })
      );
   }
   /**
    * Function is used to exapnd information
    * @param departmentId
    */
   expand(facultyId) {
      this.facultyId = facultyId;
      if (this.expandInfo !== facultyId) {
         this.expandInfo = facultyId;
      } else {
         this.expandInfo = -1;
      }
   }
   /**
    * Function is used when clicked on number of departments of faculty to redirect to departments with filter activated to clicked faculty.
    * @param facultyId
    */
   departmentsFilter(facultyId) {
      this.store.dispatch(
         filtersActions.departmentsFilters({
            faculty: facultyId,
         })
      );
      this.router.navigateByUrl(
         '/administrator/statistics/academic-units/departments'
      );
   }
   /**
    * Function is used when clicked on number of subjects of faculty to redirect to subjects with filter activated to clicked faculty.
    * @param facultyId
    */
   subjectsFilter(facultyId) {
      this.store.dispatch(
         filtersActions.subjectsFilters({
            faculty: facultyId,
         })
      );
      this.router.navigateByUrl(
         '/administrator/statistics/academic-units/subjects'
      );
   }
   /**
    * Function is used when clicked on number of assignments of faculty to redirect to assignments with filter activated to clicked faculty.
    * @param facultyId
    */
   assignmentFilter(facultyId) {
      this.store.dispatch(
         filtersActions.assignmentFilters({
            faculty: facultyId,
         })
      );
      this.router.navigateByUrl(
         '/administrator/statistics/academic-units/assignments'
      );
   }
   /**
    * Function is used when clicked on number of professors of faculty to redirect to professors with filter activated to clicked faculty.
    * @param facultyId
    */
   professorFilter(facultyId) {
      this.store.dispatch(
         filtersActions.professorsFilters({
            faculty: facultyId,
         })
      );
      this.router.navigateByUrl(
         '/administrator/statistics/users/professors'
      );
   }
   /**
    * Function is used when clicked on number of students of faculty to redirect to students with filter activated to clicked faculty.
    * @param facultyId
    */
   studentsFilter(facultyId) {
      this.store.dispatch(
         filtersActions.studentsFilters({
            faculty: facultyId,
         })
      );
      this.router.navigateByUrl('/administrator/statistics/users/students');
   }
   /**
    * Function is used when clicked on number of thesis of faculty to redirect to thesis with filter activated to clicked faculty.
    * @param facultyId
    */
   thesisFilter(facultyId) {
      this.store.dispatch(
         filtersActions.thesisSubmissionsFilters({
            faculty: facultyId,
         })
      );
      this.router.navigateByUrl('/administrator/statistics/submissions/thesis');
   }
   /**
    * Function is used when clicked on number of assignments submissions of faculty to redirect to assignments submissions with filter activated to clicked faculty.
    * @param facultyId
    */
   assignmentSubmissionsFilter(facultyId) {
      this.store.dispatch(
         filtersActions.assignmentSubmissionsFilters({
            faculty: facultyId,
         })
      );
      this.router.navigateByUrl(
         '/administrator/statistics/submissions/assignment-submissions'
      );
   }
   /**
    * Function is used when clicked on number of professors submissions of faculty to redirect to professors submissions with filter activated to clicked faculty.
    * @param facultyId
    */
   professorSubmissionsFilter(facultyId) {
      this.store.dispatch(
         filtersActions.professorSubmissionsFilters({
            faculty: facultyId,
         })
      );
      this.router.navigateByUrl(
         '/administrator/statistics/submissions/professors-submissions'
      );
   }
   /**
    * Function is used to reset filters
    */
   resetFilter() {
      this.facultyName = undefined;
      this.page = 1;
      this.store.dispatch(
         facultiesActions.loadUnitsStatisticsFaculties({
            page: this.page,
            title: this.facultyName,
         })
      );
   }
}
