// *** Angular
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';

// *** Actions
import * as subjectsActions from '../../subjects/state/action/units-statistics-subject.actions';
import * as facultyActions from '../../../../state/faculty/action/faculty.actions';
import * as filtersActions from '../../../../../administrator-components/state/filters/action/filters.actions';
import * as professorActions from '../../../../../administrator-components/state/professor/action/professor.actions';

// *** Selectors
import { getSubjectsStatisticsDetails } from '../../subjects/state/selector/units-statistics-subject.selectors';
import { getInstitutionFacultiesStateDetails } from '../../../../state/faculty/selector/faculty.selectors';
import { getFiltersStateDetails } from '../../../../../administrator-components/state/filters/selector/filters.selectors';
import { getInstitutionProfessorsStateDetails } from '../../../../../administrator-components/state/professor/selector/professor.selectors';

// *** Services
import { DepartmentService } from 'src/app/services/department.service';
import { LanguageService } from 'src/app/services/language.service';
@Component({
   selector: 'app-subjects-statistics',
   templateUrl: './subjects-statistics.component.html',
   styleUrls: ['./subjects-statistics.component.scss'],
})
export class SubjectsStatisticsComponent implements OnInit, OnDestroy {
   /**
    * Variable is used to store departments of selected faculty
    */
   departments: any;
   /**
    * Variable is used to store facultyId to filter submissions of assignments within specific faculty.
    */
   facultyId = '-1';
   /**
    * Variable is used to store departmentId to filter submissions of assignments within specific department.
    */
   departmentId = '-1';
   /**
    * Variabel used to store statistics of professors
    */
   professors;
   /**
    * Variable is used to store professor id to filter subjects.
    */
   professor = '-1';
   /**
    * Variable used to store information that a thesis is been clicked for expand or not
    */
   expandInfo: any;
   /**
    * Variable used to store subjects of institution
    */
   subjects;
   /**
    * Variable used to store professor id that used to expand specific row on HTML
    */
   subjectId;
   /**
    * Symbolizing the page of pagination
    */
   page: number = 1;
   /**
    * Representing title model
    */
   subjectName: string;
   /**
    * Variable used to store student name that used to filter subjects
    */
   studentName: string;
   /**
    * Timer used for search delay
    */
   timer: any;
   /**
    * Variabel used to store total number of subjects without pagination
    */
   totalSubjects;
   /**
    * Variabels used to retrieve faculties of insitution from store.
    */
   faculties;
   faculties$;
   getFiltersStateDetails$: any;
   getInstitutionProfessorsStateDetails$: any;
   getInstitutionFacultiesStateDetails$: any;
   getSubjectsStatisticsDetails$: any;
   languageName: string;
   constructor(
      private store: Store,
      private departmentService: DepartmentService,
      private router: Router,
      private languageService: LanguageService
   ) {}
   ngOnDestroy(): void {
      this.getFiltersStateDetails$.unsubscribe();
      this.getInstitutionProfessorsStateDetails$.unsubscribe();
      this.getInstitutionFacultiesStateDetails$.unsubscribe();
      this.getSubjectsStatisticsDetails$.unsubscribe();
   }

   getFiltersState = true;
   ngOnInit(): void {
      this.getFiltersStateDetails$ = this.store
         .select(getFiltersStateDetails)
         .subscribe((data) => {
            if (data.subjectsFilters.faculty != null) {
               this.facultyId = data.subjectsFilters.faculty;
               this.departmentService
                  .getDepartmentsOfFaculty(this.facultyId)
                  .pipe(first())
                  .subscribe(
                     (departmentsDetails) => {
                        this.departments = departmentsDetails.departments;
                     },
                     (error) => {
                        console.log(error);
                     }
                  );
            }
            if (data.subjectsFilters.professor != null) {
               this.professor = data.subjectsFilters.professor;
            }
            if (data.subjectsFilters.department != null) {
               this.departmentId = data.subjectsFilters.department;
            }
            if (data.subjectsFilters.student != null) {
               this.studentName = data.subjectsFilters.student;
            }
         });

      this.store.dispatch(facultyActions.loadFacultiesDetails({ page: 0 }));

      this.store.dispatch(
         subjectsActions.loadUnitsStatisticsSubjects({
            page: this.page,
            title: this.subjectName,
            studentName: this.studentName,
            faculty: this.facultyId == '-1' ? undefined : this.facultyId,
            professor: this.professor == '-1' ? undefined : this.professor,
            department:
               this.departmentId == '-1' ? undefined : this.departmentId,
         })
      );

      this.store.dispatch(professorActions.loadProfessorsDetails({ page: 0 }));

      this.getInstitutionProfessorsStateDetails$ = this.store
         .select(getInstitutionProfessorsStateDetails)
         .subscribe((data) => {
            if (data.professors !== null && this.professors == undefined) {
               this.professors = data.professors;
            }
         });

      this.getInstitutionFacultiesStateDetails$ = this.store
         .select(getInstitutionFacultiesStateDetails)
         .subscribe((data) => {
            if (data.faculties !== null && this.faculties == undefined) {
               this.faculties = data.faculties;
            }
         });

      this.getSubjectsStatisticsDetails$ = this.store
         .select(getSubjectsStatisticsDetails)
         .subscribe((data) => {
            if (data !== null) {
               this.subjects = data.subjectsStatistics;
               this.totalSubjects = data.subjectsNumber;

            //    if (this.subjects && this.subjects.length > 0) {
            //     const firstSubject = this.subjects[0];
            //     const languageCode = firstSubject.language[0];
            //     this.languageName = this.languageService.findLanguageName(languageCode);
            //   }
            }
         });
   }

   setLanguageName(lang) {
      if(lang == '-') {
         return '-'
      }      
return this.languageService.findLanguageName(lang);
   }
   /**
    * Function is used to filter by title
    */

   titleFilter() {
      this.getFiltersState = false;
      this.store.dispatch(
         filtersActions.subjectsFilters({
            professor: this.professor,
            department: this.departmentId,
            faculty: this.facultyId,
            student: this.studentName,
         })
      );
      let time;
      time = 500;
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
         this.store.dispatch(
            subjectsActions.loadUnitsStatisticsSubjects({
               page: this.page,
               title: this.subjectName,
               studentName: this.studentName,
               faculty: this.facultyId == '-1' ? undefined : this.facultyId,
               department:
                  this.departmentId == '-1' ? undefined : this.departmentId,
               professor: this.professor == '-1' ? undefined : this.professor,
            })
         );
      }, time);
   }
   /**
    * Function is used to filter by selected faculty
    */
   selectedFaculty() {
      this.getFiltersState = false;
      this.store.dispatch(
         filtersActions.subjectsFilters({
            professor: this.professor,
            department: this.departmentId,
            faculty: this.facultyId,
            student: this.studentName,
         })
      );
      this.departmentId = '-1';
      this.store.dispatch(
         subjectsActions.loadUnitsStatisticsSubjects({
            page: this.page,
            title: this.subjectName,
            studentName: this.studentName,
            faculty: this.facultyId == '-1' ? undefined : this.facultyId,
            professor: this.professor == '-1' ? undefined : this.professor,
         })
      );

      if (this.facultyId !== '') {
         this.departmentService
            .getDepartmentsOfFaculty(this.facultyId)
            .pipe(first())
            .subscribe(
               (data) => {
                  this.departments = data.departments;
               },
               (error) => {
                  console.log(error);
               }
            );
      }
   }
   /**
    * Function is used to filter by selected department
    */
   selectedDepartment() {
      this.getFiltersState = false;
      this.store.dispatch(
         filtersActions.subjectsFilters({
            professor: this.professor,
            department: this.departmentId,
            faculty: this.facultyId,
            student: this.studentName,
         })
      );
      this.store.dispatch(
         subjectsActions.loadUnitsStatisticsSubjects({
            page: this.page,
            title: this.subjectName,
            studentName: this.studentName,
            faculty: this.facultyId == '-1' ? undefined : this.facultyId,
            department:
               this.departmentId == '-1' ? undefined : this.departmentId,
            professor: this.professor == '-1' ? undefined : this.professor,
         })
      );
   }
   /**
    * Function is used to filter by selected professor
    */
   filterByProfessor() {
      this.getFiltersState = false;
      this.store.dispatch(
         filtersActions.subjectsFilters({
            professor: this.professor,
            department: this.departmentId,
            faculty: this.facultyId,
            student: this.studentName,
         })
      );
      this.store.dispatch(
         subjectsActions.loadUnitsStatisticsSubjects({
            page: this.page,
            title: this.subjectName,
            studentName: this.studentName,
            faculty: this.facultyId == '-1' ? undefined : this.facultyId,
            department:
               this.departmentId == '-1' ? undefined : this.departmentId,
            professor: this.professor == '-1' ? undefined : this.professor,
         })
      );
   }

   /**
    * Method used to change pagination page. It dispatches an action with page as param.
    * @param event
    */
   changePage(event): void {
      this.page = event;
      this.store.dispatch(
         subjectsActions.loadUnitsStatisticsSubjects({
            page: this.page,
            title: this.subjectName,
            studentName: this.studentName,
            faculty: this.facultyId == '-1' ? undefined : this.facultyId,
            department:
               this.departmentId == '-1' ? undefined : this.departmentId,
            professor: this.professor == '-1' ? undefined : this.professor,
         })
      );
   }

   /**
    * Function is used to exapnd information
    * @param professorId
    */
   expand(subjectId) {
      this.subjectId = subjectId;
      if (this.expandInfo !== subjectId) {
         this.expandInfo = subjectId;
      } else {
         this.expandInfo = -1;
      }
   }
   /**
    * Function is used when clicked on number of assignments within subject and will redirect to assignments with filter activated to clicked subject.
    * @param professorId
    */
   filterAssignment(subjectName) {
      this.store.dispatch(
         filtersActions.assignmentFilters({
            subject: subjectName,
         })
      );
      this.router.navigateByUrl(
         '/administrator/statistics/academic-units/assignments'
      );
   }
   /**
    * Function is used when clicked on number of professors within subject and will redirect to professors with filter activated to clicked subject.
    * @param professorId
    */
   filterProfessors(subjectName) {
      this.store.dispatch(
         filtersActions.professorsFilters({
            subject: subjectName,
         })
      );
      this.router.navigateByUrl(
         '/administrator/statistics/users/professors'
      );
   }

   /**
    * Function is used when clicked on number of submissions within subject and will redirect to assignments submissions with filter activated to clicked subject.
    * @param professorId
    */
   filterSubmissions(subjectName) {
      this.store.dispatch(
         filtersActions.assignmentSubmissionsFilters({
            subject: subjectName,
         })
      );
      this.router.navigateByUrl(
         '/administrator/statistics/submissions/assignment-submissions'
      );
   }

   /**
    * Function is used when clicked on number of student within subject and will redirect to students with filter activated to clicked subject.
    * @param professorId
    */
   filterStudents(subjectName) {
      this.store.dispatch(
         filtersActions.studentsFilters({
            subject: subjectName,
         })
      );
      this.router.navigateByUrl('/administrator/statistics/users/students');
   }

   /**
    * Function is used to reset filters
    */
   resetFilter() {
      this.facultyId = '-1';
      this.departmentId = '-1';
      this.professor = '-1';
      this.subjectName = undefined;
      this.studentName = undefined;
      this.store.dispatch(
         filtersActions.subjectsFilters({
            professor: this.professor,
            department: this.departmentId,
            faculty: this.facultyId,
            student: this.studentName,
         })
      );
      this.page = 1;
      this.store.dispatch(
         subjectsActions.loadUnitsStatisticsSubjects({
            page: this.page,
            title: this.subjectName,
            studentName: this.studentName,
            faculty: this.facultyId == '-1' ? undefined : this.facultyId,
            department:
               this.departmentId == '-1' ? undefined : this.departmentId,
            professor: this.professor == '-1' ? undefined : this.professor,
         })
      );
   }
}
