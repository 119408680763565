import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NgxSpinnerService } from 'ngx-spinner';
import { tap } from 'rxjs/operators';
import * as reportActions from 'src/app/sharedModule/state/actions/report.actions';
import * as authActions from '../../authModule/state/actions/authentication.actions';
import * as submissionsActions from '../../individual-components/state/actions/submissions.actions';
import * as facultyActions from '../../administrator-components/state/faculty/action/faculty.actions';
import * as departmentActions from '../../administrator-components/state/department/action/department.actions';
import * as subjectActions from '../../administrator-components/state/subject/action/subject.actions';
import * as professorActions from '../../administrator-components/state/professor/action/professor.actions';
import * as studentActions from '../../administrator-components/state/student/action/student.actions';
import * as thesisActions from '../../administrator-components/state/thesis/action/thesis.actions';

import * as professorThesisActions from '../../professor-components/state/thesis/action/thesis.actions';
import * as professorSubjectsActions from '../../professor-components/state/subjects/action/subject.actions';
import * as assignmentActions from '../../professor-components/state/assignments/action/assignment.actions';

import * as professorSubmissionsActions from '../../professor-components/state/submissions/action/submissions.actions';

import * as professorStudentsActions from '../../professor-components/state/students/action/student.actions';
import * as individualAdministratorActions from '../../individual-administrator-components/state/action/individualAdministrator.actions';

import * as professorStatisticsActions from '../../professor-components/state/statistics/action/statistics.actions';
import * as professorRequestsActions from '../../professor-components/state/requests/action/request.actions';
import * as studentStatisticsActions from '../../student-components/state/statistics/action/statistics.actions';
import * as studentAdministrationActions from '../../student-components/state/administration/action/administration.actions';

import * as studentRequestsActions from '../../student-components/state/request/action/request.actions';

import * as academicUnitsStatisticsActions from '../../administrator-components/administrator-statistics/academic-units-statistics/state/action/academic-units-statistics.actions';
import * as facultiesActions from '../../administrator-components/administrator-statistics/academic-units-statistics/faculties/state/action/units-statistics-faculties.actions';
import * as departmentsActions from '../../administrator-components/administrator-statistics/academic-units-statistics/departments/state/action/units-statistics-departments.actions';
import * as subjectsActions from '../../administrator-components/administrator-statistics/academic-units-statistics/subjects/state/action/units-statistics-subject.actions';
import * as assignmentsActions from '../../administrator-components/administrator-statistics/academic-units-statistics/assignments/state/action/units-statistics-assignments.actions';

import * as insProfessorsActions from '../../administrator-components/administrator-statistics/users/professors/institutional/state/action/institutional-professors-statistics.actions';
import * as basicProfessorsActions from '../../administrator-components/administrator-statistics/users/professors/basic/state/action/basic-professors-statistics.actions';
import * as admStudentStatisticsActions from '../../administrator-components/administrator-statistics/users/students/state/action/students-statistics.actions';

import * as assignmentSubmissionsActions from '../../administrator-components/administrator-statistics/submissions/assignment-submissions/state/action/assignment-submissions.actions';
import * as professorsSubmissionsActions from '../../administrator-components/administrator-statistics/submissions/professor-submissions/state/action/professors-submissions.actions';
import * as thesisSubmissionsActions from '../../administrator-components/administrator-statistics/submissions/thesis/state/action/thesis-statistics.actions';

import * as administratorProfessorActions from '../../administrator-components/state/professor/action/professor.actions';
import * as offersActions from '../../administrator-components/administrator-store/licenses/offers/state/action/offers.actions';

/**
 * Spinner Effect responsible to show & hide spinner on api calls
 */
@Injectable()
export class SpinnerEffects {
   /**
    * Spinner Effect Constructor
    * @param actions$
    * @param spinner Used to call spinner methods
    */
   constructor(private actions$: Actions, private spinner: NgxSpinnerService) {}
   /**
    * Effect listening for dispatched actions that will trigger api call, then show spinner through show() method of spinner service.
    */
   spinnerOn$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(
               reportActions.excludeSource,
               authActions.login,
               authActions.googleLogin,
               authActions.linkedInLogin,
               authActions.LTILogin,
               submissionsActions.loadSubmissionsDetails,
               submissionsActions.loadSubmissions,
               submissionsActions.loadSubmissionsGraph,
               reportActions.loadCurrentSubmission,
               reportActions.resetFilter,
               reportActions.excludeSentence,
               reportActions.includeSentence,
               reportActions.getSubmissionPlag,
               reportActions.updateComment,
               reportActions.deleteComment,
               reportActions.changeSource,
               authActions.updateUserInfo,
               submissionsActions.deleteSubmission,
               authActions.signUp,
               facultyActions.facultiesRegister,
               facultyActions.loadFacultiesDetails,
               facultyActions.editFacultyDetails,
               facultyActions.removeFaculty,
               departmentActions.departmentsRegister,
               departmentActions.loadDepartmentsDetails,
               departmentActions.removeDepartment,
               subjectActions.subjectsRegister,
               subjectActions.loadSubjectsDetails,
               subjectActions.editSubjectDetails,
               subjectActions.removeSubject,
               professorActions.professorRegister,
               professorActions.loadProfessorsDetails,
               professorActions.editProfessorDetails,
               professorActions.removeProfessor,
               studentActions.studentRegister,
               studentActions.loadStudentsDetails,
               studentActions.editStudentDetails,
               studentActions.removeStudent,
               professorStudentsActions.inviteStudents,
               thesisActions.loadThesisDetails,
               thesisActions.assignThesis,
               professorThesisActions.createThesis,
               assignmentActions.createAssignment,
               assignmentActions.loadAssignments,
               assignmentActions.editAssignment,
               professorThesisActions.loadProfessorThesis,
               professorSubjectsActions.loadProfessorSubjects,
               professorSubmissionsActions.loadProfessorSubmissions,
               professorStudentsActions.loadStudents,
               professorStudentsActions.inviteStudent,
               professorStudentsActions.verifyStudent,
               professorStatisticsActions.overviewStatistics,
               professorStatisticsActions.graphStatistics,
               professorStatisticsActions.subjectsStatistics,
               professorStatisticsActions.studentsStatistics,
               professorStatisticsActions.assignmentsStatistics,
               professorStatisticsActions.assignmentsSubmissionsStatistics,
               professorStatisticsActions.thesisSubmissions,
               assignmentActions.deleteAssignment,
               professorThesisActions.deleteThesis,
               professorSubjectsActions.registerSubjects,
               professorSubjectsActions.editSubjects,
               professorSubjectsActions.duplicateSubjects,
               professorSubjectsActions.deleteSubjects,
               individualAdministratorActions.loadStudentsLists,
               individualAdministratorActions.editStudent,
               individualAdministratorActions.administratorIndividualDashboardDetails,
               individualAdministratorActions.toggleSubmissionAccess,
               individualAdministratorActions.SubmissionsStatisticsDetails,
               individualAdministratorActions.GeneralStatisticsDetails,
               individualAdministratorActions.getDocumentSubmissions,
               studentStatisticsActions.studentGraph,
               studentStatisticsActions.studentOverview,
               studentStatisticsActions.subjectsStatistics,
               studentStatisticsActions.thesisStatistics,
               studentStatisticsActions.assignmentsStatistics,
               studentStatisticsActions.assignmentsSubmissionsStatistics,
               studentStatisticsActions.documentSubmissions,
               studentAdministrationActions.loadSubjectsToRegister,
               studentAdministrationActions.registerSubjectWithPassword,
               studentRequestsActions.sentRequest,
               studentRequestsActions.loadRequests,
               reportActions.getSubmissionPlag,
               academicUnitsStatisticsActions.overviewStatistics,
               facultiesActions.loadUnitsStatisticsFaculties,
               departmentsActions.loadUnitsStatisticsDepartments,
               subjectsActions.loadUnitsStatisticsSubjects,
               assignmentsActions.loadUnitsStatisticsAssignments,
               insProfessorsActions.loadInstitutionalProfessorsStatistics,
               basicProfessorsActions.loadBasicProfessorsStatistics,
               admStudentStatisticsActions.loadStudentsStatistics,
               assignmentSubmissionsActions.loadAssignmentSubmissionsStatistics,
               professorsSubmissionsActions.loadProfessorsSubmissionsStatistics,
               thesisSubmissionsActions.loadThesisSubmissionDetails,
               thesisSubmissionsActions.loadThesisSubmissionStatistics,
               professorRequestsActions.loadRequests,
               administratorProfessorActions.loadProfessorsDetails,
               offersActions.createOffer,
               offersActions.loadOffers,
               professorStudentsActions.registerStudent,
               offersActions.deleteOffer,
               studentAdministrationActions.displaySubjectsWithCode
            ),
            tap(() => this.spinner.show())
         ),
      /**
       * Whenever you are not dispatching any actions we use dispatch false
       */
      { dispatch: false }
   );
   /**
    * Effect listening for dispatched actions that will trigger api call, then hide spinner through hide() method of spinner service.
    */
   spinnerOff$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(
               authActions.updateUserInfoSuccess,
               authActions.updateUserInfoFailure,
               authActions.loginSuccess,
               authActions.loginFailure,
               authActions.googleLoginSuccess,
               authActions.LTILoginSuccess,
               authActions.LTILoginFailure,
               authActions.googleLoginFailure,
               authActions.linkedInLoginSuccess,
               authActions.linkedInLoginFailure,
               submissionsActions.loadSubmissionsDetailsSuccess,
               submissionsActions.loadSubmissionsDetailsFailure,
               submissionsActions.loadSubmissionsSuccess,
               submissionsActions.loadSubmissionsFailure,
               submissionsActions.loadSubmissionsGraphSuccess,
               submissionsActions.loadSubmissionsGraphFailure,
               reportActions.loadCurrentSubmissionSuccess,
               reportActions.loadCurrentSubmissionFailure,
               reportActions.excludeSentenceSuccess,
               reportActions.excludeSentenceFailure,
               reportActions.includeSentenceSuccess,
               reportActions.includeSentenceFailure,
               reportActions.getSubmissionPlagSuccess,
               reportActions.getSubmissionPlagFailure,
               reportActions.updateCommentSuccess,
               reportActions.updateCommentFailure,
               reportActions.deleteCommentSuccess,
               reportActions.deleteCommentFailure,
               reportActions.resetFilterSuccess,
               reportActions.excludeSourceSuccess,
               reportActions.excludeSourceFailure,
               reportActions.includeSourceFailure,
               reportActions.changeSourceSuccess,
               reportActions.changeSourceFailure,
               submissionsActions.deleteSubmissionSuccess,
               submissionsActions.deleteSubmissionFailure,
               authActions.signUpSuccess,
               authActions.signUpFailure,
               facultyActions.facultiesRegisterSuccess,
               facultyActions.facultiesRegisterFailure,
               facultyActions.loadFacultiesDetailsSuccess,
               facultyActions.loadFacultiesDetailsFailure,
               facultyActions.editFacultyDetailsFailure,
               facultyActions.recallFaculties,
               departmentActions.departmentsRegisterSuccess,
               departmentActions.departmentsRegisterFailure,
               departmentActions.loadDepartmentsDetailsSuccess,
               departmentActions.loadDepartmentsDetailsFailure,
               departmentActions.removeDepartmentFailure,
               subjectActions.subjectsRegisterSuccess,
               subjectActions.subjectsRegisterFailure,
               subjectActions.loadSubjectsDetailsSuccess,
               subjectActions.loadSubjectsDetailsFailure,
               subjectActions.editSubjectDetailsFailure,
               subjectActions.removeSubjectFailure,
               professorActions.professorRegisterSuccess,
               professorActions.professorRegisterFailure,
               professorActions.editProfessorDetailsFailure,
               professorActions.loadProfessorsDetailsSuccess,
               professorActions.loadProfessorsDetailsFailure,
               professorActions.removeProfessorFailure,
               studentActions.studentRegisterSuccess,
               studentActions.studentRegisterFailure,
               studentActions.loadStudentsDetailsSuccess,
               studentActions.editStudentDetailsFailure,
               studentActions.loadStudentsDetailsSuccess,
               studentActions.loadStudentsDetailsFailure,
               studentActions.removeStudentFailure,
               thesisActions.loadThesisDetailsSuccess,
               thesisActions.loadThesisDetailsFailure,
               thesisActions.assignThesisFailure,
               professorThesisActions.createThesisFailure,
               professorThesisActions.createThesisSuccess,
               assignmentActions.createAssignmentFailure,
               assignmentActions.createAssignmentSuccess,
               assignmentActions.loadAssignmentsFailure,
               assignmentActions.loadAssignmentsSuccess,
               assignmentActions.editAssignmentFailure,
               assignmentActions.editAssignmentSuccess,
               professorThesisActions.loadProfessorThesisFailure,
               professorThesisActions.loadProfessorThesisSuccess,
               professorSubjectsActions.loadProfessorSubjectsFailure,
               professorSubjectsActions.loadProfessorSubjectsSuccess,
               professorSubmissionsActions.loadProfessorSubmissionsFailure,
               professorSubmissionsActions.loadProfessorSubmissionsSuccess,
               professorStudentsActions.loadStudentsFailure,
               professorStudentsActions.loadStudentsSuccess,
               professorStudentsActions.inviteStudentsSuccess,
               professorStudentsActions.inviteStudentsFailure,
               professorStudentsActions.inviteStudentFailure,
               professorStudentsActions.inviteStudentSuccess,
               professorStudentsActions.verifyStudentFailure,
               professorStudentsActions.verifyStudentSuccess,
               professorStatisticsActions.overviewStatisticsFailure,
               professorStatisticsActions.overviewStatisticsSuccess,
               professorStatisticsActions.graphStatisticsFailure,
               professorStatisticsActions.graphStatisticsSuccess,
               professorStatisticsActions.subjectsStatisticsFailure,
               professorStatisticsActions.subjectsStatisticsSuccess,
               professorStatisticsActions.studentsStatisticsFailure,
               professorStatisticsActions.studentsStatisticsSuccess,
               professorStatisticsActions.assignmentsStatisticsFailure,
               professorStatisticsActions.assignmentsStatisticsSuccess,
               professorStatisticsActions.assignmentsSubmissionsStatisticsFailure,
               professorStatisticsActions.assignmentsSubmissionsStatisticsSuccess,
               professorStatisticsActions.thesisSubmissionsFailure,
               professorStatisticsActions.thesisSubmissionsSuccess,
               assignmentActions.deleteAssignmentFailure,
               assignmentActions.deleteAssignmentSuccess,
               professorThesisActions.deleteThesisFailure,
               professorThesisActions.deleteThesisSuccess,
               professorSubjectsActions.registerSubjectsFailure,
               professorSubjectsActions.registerSubjectsSuccess,
               professorSubjectsActions.editSubjectsSuccess,
               professorSubjectsActions.editSubjectsFailure,
               professorSubjectsActions.duplicateSubjectsFailure,
               professorSubjectsActions.duplicateSubjectsSuccess,
               professorSubjectsActions.deleteSubjectsFailure,
               professorSubjectsActions.deleteSubjectsSuccess,
               individualAdministratorActions.loadStudentsListsSuccess,
               individualAdministratorActions.loadStudentsListsFailure,
               individualAdministratorActions.editStudentFailure,
               individualAdministratorActions.administratorIndividualDashboardDetailsSuccess,
               individualAdministratorActions.administratorIndividualDashboardDetailsFailure,
               individualAdministratorActions.toggleSubmissionAccessFailure,
               individualAdministratorActions.SubmissionsStatisticsDetailsSuccess,
               individualAdministratorActions.SubmissionsStatisticsDetailsFailure,
               individualAdministratorActions.GeneralStatisticsDetailsSuccess,
               individualAdministratorActions.GeneralStatisticsDetailsFailure,
               individualAdministratorActions.getDocumentSubmissionsFailure,
               individualAdministratorActions.getDocumentSubmissionsSuccess,
               studentStatisticsActions.assignmentsSubmissionsStatisticsFailure,
               studentStatisticsActions.assignmentsSubmissionsStatisticsSuccess,
               studentStatisticsActions.studentGraphFailure,
               studentStatisticsActions.studentGraphSuccess,
               studentStatisticsActions.studentOverviewFailure,
               studentStatisticsActions.studentOverviewSuccess,
               studentStatisticsActions.subjectsStatisticsFailure,
               studentStatisticsActions.subjectsStatisticsSuccess,
               studentStatisticsActions.thesisStatisticsFailure,
               studentStatisticsActions.thesisStatisticsSuccess,
               studentStatisticsActions.assignmentsStatisticsFailure,
               studentStatisticsActions.assignmentsStatisticsSuccess,
               studentStatisticsActions.documentSubmissionsFailure,
               studentStatisticsActions.documentSubmissionsSuccess,
               studentAdministrationActions.loadSubjectsToRegisterSuccess,
               studentAdministrationActions.loadSubjectsToRegisterFailure,
               studentAdministrationActions.registerSubjectWithPasswordFailure,
               studentAdministrationActions.registerSubjectWithPasswordSuccess,
               studentRequestsActions.sentRequestFailure,
               studentRequestsActions.sentRequestSuccess,
               studentRequestsActions.loadRequestsFailure,
               studentRequestsActions.loadRequestsSuccess,
               reportActions.getSubmissionPlagSuccess,
               reportActions.getSubmissionPlagFailure,
               academicUnitsStatisticsActions.overviewStatisticsSuccess,
               academicUnitsStatisticsActions.overviewStatisticsFailure,
               facultiesActions.loadUnitsStatisticsFacultiesSuccess,
               facultiesActions.loadUnitsStatisticsFacultiesFailure,
               departmentsActions.loadUnitsStatisticsDepartmentsSuccess,
               departmentsActions.loadUnitsStatisticsDepartmentsFailure,
               subjectsActions.loadUnitsStatisticsSubjectsSuccess,
               subjectsActions.loadUnitsStatisticsSubjectsFailure,
               assignmentsActions.loadUnitsStatisticsAssignmentsSuccess,
               assignmentsActions.loadUnitsStatisticsAssignmentsFailure,
               insProfessorsActions.loadInstitutionalProfessorsStatisticsSuccess,
               insProfessorsActions.loadInstitutionalProfessorsStatisticsFailure,
               basicProfessorsActions.loadBasicProfessorsStatisticsSuccess,
               basicProfessorsActions.loadBasicProfessorsStatisticsFailure,
               admStudentStatisticsActions.loadStudentsStatisticsSuccess,
               admStudentStatisticsActions.loadStudentsStatisticsFailure,
               assignmentSubmissionsActions.loadAssignmentSubmissionsStatisticsSuccess,
               assignmentSubmissionsActions.loadAssignmentSubmissionsStatisticsFailure,
               professorsSubmissionsActions.loadProfessorsSubmissionsStatisticsSuccess,
               professorsSubmissionsActions.loadProfessorsSubmissionsStatisticsFailure,
               thesisSubmissionsActions.loadThesisSubmissionDetailsSuccess,
               thesisSubmissionsActions.loadThesisSubmissionDetailsFailure,
               thesisSubmissionsActions.loadThesisSubmissionStatisticsSuccess,
               thesisSubmissionsActions.loadThesisSubmissionStatisticsFailure,
               professorRequestsActions.loadRequestsFailure,
               professorRequestsActions.loadRequestsSuccess,
               administratorProfessorActions.loadProfessorsDetailsFailure,
               administratorProfessorActions.loadProfessorsDetailsSuccess,
               offersActions.loadOffersSuccess,
               offersActions.loadOffersFailure,
               offersActions.createOfferFailure,
               professorStudentsActions.registerStudentSuccess,
               professorStudentsActions.registerStudentFailure,
               offersActions.deleteOfferFailure,
               studentAdministrationActions.displaySubjectsWithCodeFailure,
               studentAdministrationActions.displaySubjectsWithCodeSuccess
            ),
            tap(() => this.spinner.hide())
         ),
      /**
       * Whenever you are not dispatching any actions we use dispatch false
       */
      { dispatch: false }
   );
}
