import { createFeatureSelector, createSelector } from '@ngrx/store';
import { subjectsStatisticsState } from '../reducer/units-statistics-subject.reducer';

/**
 * Selectors are pure functions used for obtaining slices of store state.
 */

/**
 * getSubjectsStatistics represent top level of the subject state.
 * The createFeatureSelector is a convenience method for returning a top level feature state.
 */
const getSubjectsStatistics =
   createFeatureSelector<subjectsStatisticsState>('subjectsStatistics');

/**
 * Selector used to get statisticsOverview details state.
 */
export const getSubjectsStatisticsDetails = createSelector(
   getSubjectsStatistics,
   (state) => state
);
