import { createAction, props } from '@ngrx/store';
/**
 * Actions related to Department.
 * Each action accepts a set of properties.
 * Each action most of the cases has a success and failure action, that updates the store based on successfully/failure api responses from api.
 */

/**
 * Action used to register a department.
 */
export const departmentsRegister = createAction(
   '[Department] Departments Register',
   props<{
      data: any;
   }>()
);

/**
 * Action is called when there is a success status returned from API.
 */
export const departmentsRegisterSuccess = createAction(
   '[Department] Departments Register Success',
   props<{ data: any }>()
);

/**
 * Action is called when there is a failure status returned from API.
 */
export const departmentsRegisterFailure = createAction(
   '[Department] Departments Register Failure',
   props<{ error: any }>()
);

/**
 * Action used to load departments of institution.
 */
export const loadDepartmentsDetails = createAction(
   '[Department] Load Departments Details',
   props<{
      page;
      faculty?;
      name?;
   }>()
);

/**
 * Action is called when there is a success status returned from API.
 */
export const loadDepartmentsDetailsSuccess = createAction(
   '[Department] Load Departments Details Success',
   props<{
      departments;
      totalDepartments;
   }>()
);

/**
 * Action is called when there is a failure status returned from API.
 */
export const loadDepartmentsDetailsFailure = createAction(
   '[Department] Load Departments Details Failure',
   props<{ error: any }>()
);

/**
 * Action used to edit Department details.
 */
export const editDepartmentDetails = createAction(
   '[Department] Edit Department Details',
   props<{
      departmentId;
      departmentName;
      departmentFaculty;
      departmentDescription;
      departmentYear;
      departmentLevel;
      page?;
      faculty?;
      name?;
   }>()
);


/**
 * Action is called when there is a success status returned from API.
 */
 export const editDepartmentDetailsSuccess = createAction(
   '[Department] Edit Department Details Success',
   props<{
 data
   }>()
);


/**
 * Action is called when there is a failure status returned from API.
 */
export const editDepartmentDetailsFailure = createAction(
   '[Department] Edit Department Details Failure',
   props<{ error: any }>()
);

/**
 * Action used to remove department from institution.
 */
export const removeDepartment = createAction(
   '[Department] Remove Department',
   props<{
      departmentId;
      page?;
      name?;
      faculty?
   }>()
);

/**
 * Action is called when there is a success status returned from API.
 */
 export const removeDepartmentSuccess = createAction(
   '[Department] Remove Department Success',
   props<{
 data
   }>()
);


/**
 * Action is called when there is a failure status returned from API.
 */
export const removeDepartmentFailure = createAction(
   '[Department] Remove Department Failure',
   props<{ error: any }>()
);

export const setRegisterDepartmentToNull = createAction(
   '[Department] Set Register Department To Null'
);
