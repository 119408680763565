import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, catchError, concatMap } from 'rxjs/operators';
import * as assignmentsSubmissionsActions from '../action/assignment-submissions.actions';
import { AdministratorStatisticsService } from '../../../../../../services/administrator-statistics.service';

@Injectable()
export class AssignmentSubmissionsEffects {
   constructor(
      private actions$: Actions,
      private administratorStatistics: AdministratorStatisticsService
   ) {}

   /**
    * Effect listening for AssignmentSubmissionsStatistics Action, when AssignmentSubmissionsStatistics is dispatched the effect with call create() from courseService.
    * In case of success response the effect it will trigger AssignmentSubmissionsStatisticsSuccess.
    * In case of Error it will trigger AssignmentSubmissionsStatisticsFailure with error property.
    */
   AssignmentSubmissionsStatistics$ = createEffect(() => {
      return this.actions$.pipe(
         ofType(
            assignmentsSubmissionsActions.loadAssignmentSubmissionsStatistics
         ),
         concatMap((action) => {
            console.log('action', action);

            return this.administratorStatistics
               .assignmentsSubmissionsStatistics(
                  action.page,
                  action.facultyId,
                  action.departmentId,
                  action.subjectName,
                  action.assignmentType,
                  action.submissionName,
                  action.assignmentName,
                  action.studentName
               )
               .pipe(
                  map((data) => {
                     return assignmentsSubmissionsActions.loadAssignmentSubmissionsStatisticsSuccess(
                        {
                           data: data,
                        }
                     );
                  }),
                  catchError((error) =>
                     of(
                        assignmentsSubmissionsActions.loadAssignmentSubmissionsStatisticsFailure(
                           {
                              error,
                           }
                        )
                     )
                  )
               );
         })
      );
   });
}
