import * as AppState from '../../../../../store/app.state';
import * as userStatisticsActions from '../action/users-statistics.actions';
import { createReducer } from '@ngrx/store';
import { on } from '@ngrx/store';

/**
 * subject reducer used to updated store values based on certain action.
 */

/**
 * State interface extends App state State and add user state to the main state.
 */
export interface State extends AppState.State {
   usersStatisticsOverview: usersStatisticsOverviewState;
}

/**
 * An SubjectState interface, which represents the blueprint of the User State.
 */
export interface usersStatisticsOverviewState {
   usersStatisticsOverview: any;
   errorMessage: string;
}

/**
 * SubjectState initial state.
 */
export const initialState: usersStatisticsOverviewState = {
   usersStatisticsOverview: null,
   errorMessage: null,
};

/**
 * Subject Reducer used to update the state upon Subject actions.
 */
export const usersStatisticsOverviewReducer = createReducer(
   initialState,
   /**
    * On loadSubjectsDetailsSuccess action updated current state with the following properties.
    */
   on(
      userStatisticsActions.userStatisticsSuccess,
      (state, action): usersStatisticsOverviewState => {
         return {
            ...state,
            usersStatisticsOverview: action.data,
         };
      }
   ),

   /**
    * On loadSubjectsDetailsFailure action updated current state with the following properties.
    */
   on(
      userStatisticsActions.userStatisticsFailure,
      (state, action): usersStatisticsOverviewState => {
         return {
            ...state,
            errorMessage: action.error,
         };
      }
   )
);
