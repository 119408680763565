import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserState } from './authentication.reducer';
/**
 * Selector related to user state.
 */
const getUserFeatureState = createFeatureSelector<UserState>('user');

/**
 * Selector to get the current user from the store
 */
export const getCurrentUser = createSelector(
   getUserFeatureState,
   (state) => state.user
);
