import { createFeatureSelector, createSelector } from '@ngrx/store';
import { studentsStatisticsState } from '../reducer/students-statistics.reducer';

/**
 * Selectors are pure functions used for obtaining slices of store state.
 */

/**
 * getStudentsStatistics represent top level of the assignment state.
 * The createFeatureSelector is a convenience method for returning a top level feature state.
 */
const getStudentsStatistics =
   createFeatureSelector<studentsStatisticsState>('studentsStatistics');

/**
 * Selector used to get statisticsOverview details state.
 */
export const getStudentsStatisticsDetails = createSelector(
   getStudentsStatistics,
   (state) => state
);
