<div class="documents-2 shadow white-bg">
   <h3
      *ngIf="
         !(
            this.studentsNumber == 0 &&
            studentName == undefined &&
            facultyId == '-1' &&
            departmentId == '-1' &&
            studentSubject == undefined
         )
      "
      class="filtersContainer"
   >
      <h4>Overview</h4>
      <div class="selectors">
         <div>
            <select [(ngModel)]="facultyId" (change)="selectedFaculty()">
               <option value="-1" selected disabled>
                  Filter by school
               </option>
               <ng-container *ngFor="let faculty of this.faculties">
                  <option value="{{ faculty.id }}">{{ faculty.name }}</option>
               </ng-container>
               <option value="">All</option>
            </select>
         </div>
         <div>
            <select
               disabled
               *ngIf="facultyId == '-1' || facultyId == ''"
               name=""
               id=""
            >
               <option>Select school first</option>
            </select>
            <select
               *ngIf="facultyId !== '-1' && facultyId !== ''"
               [(ngModel)]="departmentId"
               (change)="selectedDepartment()"
            >
               <option value="-1" selected disabled>
                  Filter by department
               </option>
               <ng-container *ngFor="let department of this.departments">
                  <option value="{{ department.id }}">
                     {{ department.name }}
                  </option>
               </ng-container>
               <option value="">All</option>
            </select>
         </div>
         <div>
            <input
               placeholder="Search by subject"
               [(ngModel)]="studentSubject"
               (ngModelChange)="subjectFilter()"
               type="text"
               name="searchBySubject"
            />
         </div>
         <div>
            <input
               placeholder="Search by name"
               [(ngModel)]="studentName"
               (ngModelChange)="studentFilter()"
               type="text"
               name="searchByName"
            />
         </div>
         <button class="reportBtn" (click)="resetFilter()">Reset</button>
      </div>
   </h3>
   <div
      *ngIf="this.studentsNumber != 0"
      class="tableWithExpandedInfoAssignments"
   >
      <ng-container
         *ngFor="
            let student of this.students
               | paginate
                  : {
                       itemsPerPage: 5,
                       currentPage: this.page,
                       totalItems: this.studentsNumber
                    };
            let i = index
         "
      >
         <div>
            <div>
               <h4 class="blueText bold">
                  {{ student.studentInfo }}
               </h4>
               <div>
                  <div>
                     <span>School</span>
                     <span>Department</span>
                     <span>Subjects</span>
                     <span>Submissions</span>
                     <span>Late submissions</span>
                     <span>Theses</span>
                  </div>
                  <div>
                     <span class="bold">{{ student.facultyName }}</span>
                     <span class="bold">{{ student.departmentName }}</span>
                     <span
                        (click)="filterSubjects(student.studentInfo)"
                        class="bold clickable"
                        >{{ student.subjects }}</span
                     >
                     <span
                        (click)="
                           filterAssignmentsSubmissions(student.studentInfo)
                        "
                        class="bold clickable"
                        >{{ student.assignmentsSubmissions }}</span
                     >
                     <span class="bold"
                        >{{ student.lateSubmissions }}</span
                     >
                     <span
                        (click)="filterThesisSubmissions(student.studentInfo)"
                        class="bold clickable"
                        >{{ student.thesisSubmissions }}</span
                     >
                  </div>
               </div>
               <!-- <a
                  *ngIf="expandInfo !== i"
                  class="blueText underline vold"
                  (click)="expand(i)"
                  >Expand</a
               >
               <a
                  *ngIf="expandInfo === i"
                  class="blueText underline vold"
                  (click)="expand(i)"
                  >Collapse</a
               > -->
            </div>
            <div
               [ngClass]="{ expandedInfo: expandInfo == i }"
               class="moreInfo"
               style="grid-template: none !important"
            >
               <div style="display: flex; justify-content: space-between">
                  <div style="grid-template: none">
                     <span>
                        Educators:
                        <b>{{ student.professors }}</b></span
                     >
                     <span>
                        Late Submissions:
                        <b>{{ student.lateSubmissions }}</b></span
                     >
                     <span>
                        Assignments:
                        <b>{{ student.assignments }}</b></span
                     >
                  </div>
                  <div style="grid-template: none">
                     <span>
                        Assignments per month:
                        <b>{{ student.avgAssignmentsMonth }}</b></span
                     >
                     <span>
                        Avg. Words per Assignment:
                        <b>{{
                           (
                              student.submissions._avg.wordCount /
                              student.assignments
                           ).toFixed(2) === 'NaN'
                              ? 0
                              : (
                                   student.submissions._avg.wordCount /
                                   student.assignments
                                ).toFixed(2)
                        }}</b>
                     </span>
                  </div>

                  <div style="grid-template: none">
                     <span>
                        High similarity submissions:
                        <b>{{ student.highSimilarity }}</b></span
                     >
                     <span>
                        Medium similarity submissions:
                        <b>{{ student.mediumSimilarity }}</b></span
                     >
                     <span>
                        Low similarity submissions:
                        <b>{{ student.lowSimilarity }}</b></span
                     >
                  </div>
               </div>
            </div>
         </div>
      </ng-container>
   </div>
   <div
      class="zeroState"
      *ngIf="
         this.studentsNumber == 0 &&
         (studentName != undefined ||
            facultyId != '-1' ||
            departmentId != '-1' ||
            studentSubject != undefined)
      "
   >
      No users found
   </div>
   <pagination-controls
      *ngIf="this.studentsNumber != 0"
      class="num"
      (pageChange)="changePage($event)"
      responsive="true"
      previousLabel=""
      nextLabel=""
   ></pagination-controls>
   <div
      class="zero-state"
      *ngIf="
         this.studentsNumber == 0 &&
         studentName == undefined &&
         facultyId == '-1' &&
         departmentId == '-1' &&
         studentSubject == undefined
      "
   >
      <!-- <img src="../../assets/images/icona-image.png" alt="" /> -->
      <h5 class="bold" style="z-index: 1;">There are no students registered in your institution.
         <a
           routerLink ="/administrator/register-module/student-register"
           routerLinkAction="active">Click here</a>
          to begin registering students.</h5>
   </div>
</div>
