// *** Angular
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';

// *** Components
import { EditDepartmentComponent } from '../modals/edit-department/edit-department.component';

// *** Actions
import * as departmentActions from '../state/department/action/department.actions';
import * as facultyActions from '../state/faculty/action/faculty.actions';

// *** Selectors
import { getInstitutionDepartmentsStateDetails } from '../state/department/selector/department.selectors';
import { getInstitutionFacultiesStateDetails } from '../state/faculty/selector/faculty.selectors';
import { DepartmentRegisterComponent } from '../department-register/department-register.component';

@Component({
   selector: 'app-units-departments',
   templateUrl: './units-departments.component.html',
   styleUrls: ['./units-departments.component.scss'],
})
export class UnitsDepartmentsComponent implements OnInit, OnDestroy {
   /**
    * Variable is used to store retrieved departments from service.
    */
   departmentsDetails$: any;
   departmentsDetails;
   /**
    * Symbolizing the page of pagination
    */
   page: number = 1;
   /**
    * Symbolizing the size of the page
    */
   pageSize: number = 5;
   /**
    * Variables used to store faculties retrived from store
    */
   faculties$: any;
   faculties;
   /**
    * Variables used to filter departments by faculty.
    */
   faculty = '-1';
   /**
    * Variable used to store name of department that is used for filterin departments by name.
    */
   name: any;
   /**
    * Variable used to store a time for function to trigger.(x time after key is up call y function.)
    */
   timer: any;
   constructor(private store: Store, public dialog: MatDialog) {}
   ngOnDestroy(): void {
      this.faculties$.unsubscribe();
      this.departmentsDetails$.unsubscribe();
   }

   ngOnInit(): void {
      this.store.dispatch(facultyActions.loadFacultiesDetails({ page: 0 }));

      this.faculties$ = this.store
         .select(getInstitutionFacultiesStateDetails)
         .subscribe((data) => {
            if (data !== null) {
               this.faculties = data.faculties;
            }
         });

      this.store.dispatch(
         departmentActions.loadDepartmentsDetails({
            page: this.page,
            faculty: this.faculty == '-1' ? undefined : this.faculty,
            name: this.name,
         })
      );

      this.departmentsDetails$ = this.store
         .select(getInstitutionDepartmentsStateDetails)
         .subscribe((data) => {
            if (data !== null) {
               this.departmentsDetails = data;
            }
            if (data.departmentRegistered !== null) {
               this.store.dispatch(
                  departmentActions.setRegisterDepartmentToNull()
               );
               this.resetFilter();
            }
         });
   }

   /**
    * Method used to change pagination page. It dispatches an action with page as param.
    * @param event
    */
   changePage(event): void {
      this.page = event;
      this.store.dispatch(
         departmentActions.loadDepartmentsDetails({
            page: event,
            faculty: this.faculty == '-1' ? undefined : this.faculty,
            name: this.name,
         })
      );
   }

   filterDepartments() {
      this.store.dispatch(
         departmentActions.loadDepartmentsDetails({
            page: this.page,
            faculty: this.faculty == '-1' ? undefined : this.faculty,
            name: this.name,
         })
      );
   }

   /**
    * Method is used to open a modal that will contain a department information to edit or delete.
    * @param id
    */
   openModal(id) {
      console.log('this.faculty', this.faculty);

      this.dialog.open(EditDepartmentComponent, {
         data: {
            departmentId: id,
            page: this.page,
            faculty: this.faculty == '-1' ? undefined : this.faculty,
            name: this.name,
         },
         width: '50%',
         height: '90%',
      });
   }

   /**
    * Method is used to open a modal that will contain a department information to register departments.
    */
   registerDepartmentModal() {
      this.dialog.open(DepartmentRegisterComponent, {
         width: '50%',
         height: '95%',
      });
   }

   titleFilter() {
      let time;
      time = 500;
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
         this.store.dispatch(
            departmentActions.loadDepartmentsDetails({
               page: this.page,
               faculty: this.faculty == '-1' ? undefined : this.faculty,
               name: this.name,
            })
         );
      }, time);
   }

   resetFilter() {
      this.faculty = '-1';
      this.page = 1;
      this.name = undefined;
      this.store.dispatch(
         departmentActions.loadDepartmentsDetails({
            page: this.page,
            faculty: this.faculty == '-1' ? undefined : this.faculty,
            name: this.name,
         })
      );
   }
}
