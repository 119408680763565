import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
   selector: 'app-upload-form',
   templateUrl: './upload-form.component.html',
   styleUrls: ['./upload-form.component.scss'],
})
export class UploadFormComponent implements OnInit {
   uploadForm: FormGroup;
   advancedOptionsIndex;
   @Input() file: any;
   @Input() pdfUrl: string;
   previewSrc: string = null;
   constructor(private fb: FormBuilder) {}
   /**
    * Method used to be called whenever the component is loaded and all the methods in it, will be triggered.
    */
   ngOnInit(): void {}
}
