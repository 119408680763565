<div class="modalContainer">

    <div class="modalHeader">
        <h3>Permissions</h3>
        <i class="far fa-edit"></i>
    </div>
    <div class="btnContainer" *ngIf="((data.permissions.deleteInArchive && !(data.isDocumentInArchive == undefined)) || data.  permissions.deleteSources || data.permissions.deleteEverything)">
        <div *ngIf="data.permissions.deleteInArchive && !(data.isDocumentInArchive == undefined)">
            <span>Delete your document from our archive</span>
            <button class="deleteBtn"  (click)="deleteDocument(1)">Delete</button></div>
        <div *ngIf="data.permissions.deleteSources">
            <span>Delete only the sources we found similarity with</span>
            <button class="deleteBtn"  (click)="deleteDocument(0)">Delete</button></div>
        <div *ngIf="data.permissions.deleteEverything">
           <span>Delete everything</span>
           <button class="deleteBtn"  (click)="deleteDocument(2)">Delete</button></div>
        </div>
    
        <p *ngIf="!((data.permissions.deleteInArchive && !(data.isDocumentInArchive == undefined)) || data.permissions.deleteSources || data.permissions.deleteEverything)">You don't have permissions to delete anything</p>
    
    <div class="cancel">
        <button (click)="close()">Cancel</button>
    </div>
</div>

