import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ThesisState } from '../../thesis/reducer/thesis.reducer';

/**
 * Selectors are pure functions used for obtaining slices of store state.
 */

/**
 * getInstitutionThesisState represent top level of the thesis state.
 * The createFeatureSelector is a convenience method for returning a top level feature state.
 */
const getInstitutionThesisState = createFeatureSelector<ThesisState>('thesis');

/**
 * Selector used to get thesis details state.
 */
export const getInstitutionThesisStateDetails = createSelector(
   getInstitutionThesisState,
   (state) => state
);
