// *** Angular
import { Component, OnInit } from '@angular/core';

@Component({
   selector: 'app-submissions-statistics',
   templateUrl: './submissions-statistics.component.html',
   styleUrls: ['./submissions-statistics.component.scss'],
})
export class SubmissionsStatisticsComponent implements OnInit {
   constructor() {}

   ngOnInit(): void {}
}
