import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { SubmissionsService } from '../../../services/submissions.service';
import swal from 'sweetalert2';

@Component({
  selector: 'app-delete-modal',
  templateUrl: './delete-modal.component.html',
  styleUrls: ['./delete-modal.component.scss']
})
export class DeleteModalComponent implements OnInit {

  constructor(private spinner: NgxSpinnerService,private SubmissionsService: SubmissionsService,
    public dialog: MatDialog, @Optional() public dialogRef: MatDialogRef<any>,
    /**
     * Used to pass data in component through dialog
     */
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
  }

  deleteDocument(type){

   let title;
   let paragraph;

   let deleteText;
   if(type == 0 ){
      title = 'All the sources we found similarities with will be removed. Are you sure?'
      deleteText = '<span style="color:red; display: block; font-weight: bold;font-size: 1rem;margin-left:20px">This is permanent - you will not be able to revert this</span>',
      paragraph = 'Sources deleted successfully'
      if(this.data.sourcesAreDeleted){
         swal.fire('Sources of this document are already deleted.', '', 'info');
         return true;
      }
   } else if (type == 1){
      title = 'Your document will be removed from our archive.Are you sure?'
      deleteText = 'After you delete this document in our archives if other student plagiarise in this document we wont be able to detect it <span style="color:red; display: block; font-weight: bold;font-size: 1rem;margin-left:20px">You wont be able to revert this!</span>'
      paragraph = 'Document deleted successfully in our archives'
      if(this.data.isDocumentInArchive == false){
         swal.fire('This document is already deleted from our archive.', '', 'info');
         return true;
      }
      
   } else if(type == 2) {
      title = 'The document will be deleted permanently. Are you sure?'
      deleteText = '<span style="color:red; display: block; font-weight: bold;font-size: 1rem;margin-left:20px">This is permanent - you will not be able to revert this</span>'
      paragraph = 'Document deleted successfully'
   }
       swal.fire({
                     title: title,
                     showDenyButton: true,
                     confirmButtonText: `Yes`,
                     denyButtonText: `No`,
                     icon: 'warning',
                     html: deleteText
                  })
                  .then((result) => {
                     if (result.isConfirmed) {
                        this.spinner.show('loading');
                        this.SubmissionsService.deleteSubmissionInElastic(this.data.submissionId,type)
                        .pipe()
                        .subscribe(
                           (data: any) => {
                              this.spinner.hide('loading');
                              swal.fire('', `${paragraph}`, 'info');
                           },
                           (error) => {
                              console.log('error', error);
                              this.spinner.hide('loading');
                           }
                        );
                     }
                  });

 }

 close() {
   this.dialogRef.close();
 }
}
