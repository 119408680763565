import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, catchError, concatMap, switchMap } from 'rxjs/operators';
import * as subjectActions from '../../subject/action/subject.actions';
import { CourseService } from '../../../../services/course.service';

@Injectable()
export class SubjectEffects {
   constructor(
      private actions$: Actions,
      private courseService: CourseService
   ) {}

   /**
    * Effect listening for subjectsRegister Action, when subjectsRegister is dispatched the effect with call create() from courseService.
    * In case of success response the effect it will trigger subjectsRegisterSuccess.
    * In case of Error it will trigger subjectsRegisterFailure with error property.
    */
   subjectsRegister$ = createEffect(() => {
      return this.actions$.pipe(
         ofType(subjectActions.subjectsRegister),
         concatMap((action) => {
            return this.courseService.create(action.data).pipe(
               switchMap((data) => [
                  subjectActions.subjectsRegisterSuccess({
                     data: data.departmentDetails,
                  })
               ]),
               catchError((error) =>
                  of(subjectActions.subjectsRegisterFailure({ error }))
               )
            );
         })
      );
   });

   /**
    * Effect listening for loadSubjectsDetails Action, when loadSubjectsDetails is dispatched the effect with call courses() from courseService.
    * In case of success response the effect it will trigger loadSubjectsDetailsSuccess.
    * In case of Error it will trigger loadSubjectsDetailsFailure with error property.
    */
   getSubjectsDetails$ = createEffect(() => {
      return this.actions$.pipe(
         ofType(subjectActions.loadSubjectsDetails),
         concatMap((action) => {
            return this.courseService
               .courses(
                  action.generation,
                  action.page,
                  action.faculty,
                  action.department,
                  action.professor,
                  action.status,
                  action.subjectType,
                  action.name
               )
               .pipe(
                  map((data) => {
                     return subjectActions.loadSubjectsDetailsSuccess({
                        subjects: data.courses,
                        totalSubjects: data.totalSubjects,
                     });
                  }),
                  catchError((error) =>
                     of(subjectActions.loadSubjectsDetailsFailure({ error }))
                  )
               );
         })
      );
   });

   /**
    * Effect listening for editSubjectDetails Action, when editSubjectDetails is dispatched the effect with call editCourse() from courseService.
    * In case of success response the effect it will trigger loadSubjectsDetails that will return all subjects of institution including the one that got edited as well.
    * In case of Error it will trigger editSubjectDetailsFailure with error property.
    */
   editSubjectDetails$ = createEffect(() => {
      return this.actions$.pipe(
         ofType(subjectActions.editSubjectDetails),
         concatMap((action) => {
            return this.courseService
               .editCourse(
                  action.subjectId,
                  action.subjectTitle,
                  action.subjectProfessors,
                  action.subjectFaculty,
                  action.subjectDepartment,
                  action.subjectType,
                  action.subjectDescription,
                  action.subjectCode,
                  action.subjectGeneration,
                  action.subjectYear,
                  action.subjectDuplicate,
                  action.saveAndDuplicate,
                  action.status
               )
               .pipe(
                  switchMap((data) => [
                     subjectActions.editSubjectDetailsSuccess({
                        data:data
                     }),
                     subjectActions.loadSubjectsDetails({
                        generation: action.generation,
                        page: action.page,
                        faculty: action.faculty,
                        department: action.department,
                        professor: action.professor,
                        subjectType: action.subjectTypeFilter,
                        name: action.name
                     }),
                  ]),
                  catchError((error) =>
                     of(
                        subjectActions.editSubjectDetailsFailure({
                           error,
                        })
                     )
                  )
               );
         })
      );
   });

   /**
    * Effect listening for removeSubject Action, when removeSubject is dispatched the effect with call remove() from courseService.
    * In case of success response the effect it will trigger  that will return all subjects of institution.
    * In case of Error it will trigger removeSubjectFailure with error property.
    */
   removeSubject$ = createEffect(() => {
      return this.actions$.pipe(
         ofType(subjectActions.removeSubject),
         concatMap((action) => {
            return this.courseService.remove(action.subjectId).pipe(
               switchMap((data) => [
                  subjectActions.removeSubjectSuccess({
                     data:data
                  }),
                  subjectActions.loadSubjectsDetails({
                     generation: action.generation,
                     page: action.page,
                     faculty: action.faculty,
                     department: action.department,
                     professor: action.professor,
                     status: action.status,
                     subjectType: action.subjectTypeFilter,
                     name: action.name
                  }),
               ]),
               catchError((error) =>
                  of(subjectActions.removeSubjectFailure({ error }))
               )
            );
         })
      );
   });

      /**
    * Effect listening for editSubjectDetails Action, when editSubjectDetails is dispatched the effect with call editCourse() from courseService.
    * In case of success response the effect it will trigger loadSubjectsDetails that will return all subjects of institution including the one that got edited as well.
    * In case of Error it will trigger editSubjectDetailsFailure with error property.
    */
       duplicateSubject$ = createEffect(() => {
         return this.actions$.pipe(
            ofType(subjectActions.duplicateSubject),
            concatMap((action) => {
               return this.courseService
                  .editCourse(
                     action.subjectId,
                     action.subjectTitle,
                     action.subjectProfessors,
                     action.subjectFaculty,
                     action.subjectDepartment,
                     action.subjectType,
                     action.subjectDescription,
                     action.subjectCode,
                     action.subjectGeneration,
                     action.subjectYear,
                     action.subjectDuplicate,
                     action.saveAndDuplicate,
                     action.status
                  )
                  .pipe(
                     switchMap((data) => [
                        subjectActions.duplicateSubjectSuccess({
                           data:data
                        })
                     ]),
                     catchError((error) =>
                        of(
                           subjectActions.duplicateSubjectFailure({
                              error,
                           })
                        )
                     )
                  );
            })
         );
      });

   /**
    * Effect listening for changeSubjectsGeneration Action, when changeSubjectsGeneration is dispatched the effect with call editCourses() from courseService.
    * In case of success response the effect it will trigger  that will return all subjects of institution.
    * In case of Error it will trigger changeSubjectsGenerationFailure with error property.
    */
   changeSubjectsGeneration$ = createEffect(() => {
      return this.actions$.pipe(
         ofType(subjectActions.changeSubjectsGeneration),
         concatMap((action) => {
            return this.courseService
               .editCourses(action.subjects, action.generation)
               .pipe(
                  switchMap((data) => [
                     subjectActions.changeSubjectsGenerationSuccess({
                        data:data
                     }),
                     subjectActions.loadSubjectsDetails({
                        generation: 0,
                        page: 1,
                     })
                  ]),
                  catchError((error) =>
                     of(
                        subjectActions.changeSubjectsGenerationFailure({
                           error,
                        })
                     )
                  )
               );
         })
      );
   });
}
