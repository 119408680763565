import { ToastrService } from 'ngx-toastr';
import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import * as authActions from '../state/actions/authentication.actions';
import { Validators, FormBuilder } from '@angular/forms';
import { SocialUser } from 'angularx-social-login';
import { SocialAuthService } from 'angularx-social-login';
import { GoogleLoginProvider } from 'angularx-social-login';
import { TermsAndConditionsComponent } from '../signup/terms-and-conditions/terms-and-conditions.component';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { User } from 'src/app/models/user';
import { Assignment } from 'src/app/models/assignment';
import { TawkService } from 'src/app/services/tawk.service';
/**
 * Component used by visitors that will try to login to platform
 */
@Component({
   selector: 'app-login',
   templateUrl: './login.component.html',
   styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
   /**
    * FromGroup used to get  email and password
    */
   loginForm = this.fb.group({
      email: ['', [Validators.email, Validators.required]],
      password: ['', [Validators.required]],
      captcha: ['']
   });

   /**
    * User email
    */
   email;
   /**
    * User Password
    */
   password;
   /**
    * Used to show hide/show password
    */
   showPassword;

   expandedIndex;
   invalidUsernameOrPassword;
   /**
    * User that we get from google.
    */
   user: SocialUser;
   /**
    * Used to store accessCode
    */
   linkedInToken: string;

   LTIParam: string;
   siteKey;
   /**
    * Component constructor
    * @param authService
    * @param router
    * @param route
    * @param store
    * @param toastrService
    * @param fb
    * @param socialAuthService
    * @param dialog
    */
   constructor(
      private authService: AuthService,
      private router: Router,
      private route: ActivatedRoute,
      private store: Store,
      private toastrService: ToastrService,
      private fb: FormBuilder,
      private socialAuthService: SocialAuthService,
      public dialog: MatDialog,
      private tawkService: TawkService,
   ) {}
   // Init
   /**
    * Method used to be called whenever the component is loaded and all the methods in it, will be triggered.
    */
   ngOnInit(): void {
      this.tawkService.SetChatVisibility(true);
      this.linkedInToken = this.route.snapshot.queryParams['code'];
      this.siteKey = "6LfbQqwhAAAAAL1fuoe1q00lRdcKYbQXpoIBXevQ";
      if (this.linkedInToken) {
         this.linkedInAuth();
      }
      this.LTIParam = this.route.snapshot.queryParams['LTI'];
      if (this.LTIParam) {
         const user = JSON.parse(this.route.snapshot.queryParams['data']);
         const assignment = JSON.parse(
            this.route.snapshot.queryParams['assignment']
         );
         const token = this.route.snapshot.queryParams['token'];
         const assignmentId = this.route.snapshot.queryParams['assignmentId'];
         const courseId = this.route.snapshot.queryParams['courseId'];
         this.signInWithLTI({
            user,
            token,
            assignment,
            assignmentId,
            courseId,
         });
      }
      this.expandedIndex = -1;
      this.invalidUsernameOrPassword = 0;
      if (this.authService.isLoggedIn()) {
         if (this.authService.isLoggedInRole() == '2') {
            this.router.navigateByUrl('/individual');
         } else if (this.authService.isLoggedInRole() == '1') {
            this.router.navigateByUrl('/admin');
         } else if (this.authService.isLoggedInRole() == '3') {
            this.router.navigateByUrl('/administrator');
         } else if (this.authService.isLoggedInRole() == '4') {
            this.router.navigateByUrl('/professor');
         } else if (this.authService.isLoggedInRole() == '6') {
            this.router.navigateByUrl('/vleresues');
         } else if (this.authService.isLoggedInRole() == '7') {
            this.router.navigateByUrl('/super-admin');
         }
      }
   }

   signInWithLTI({
      user,
      token,
      assignment,
      assignmentId,
      courseId,
   }: {
      user: User;
      token: string;
      assignment?: any;
      courseId?: string;
      assignmentId?: string;
   }) {
      this.store.dispatch(
         authActions.LTILogin({
            token,
            user,
            assignment,
            assignmentId,
            courseId,
         })
      );
   }

   /**
    * Method used to sign in with google,
    * calls socialAuthService that returns Social User
    * then dispaches the googleLogin action with user name and email
    */
   async signInWithGoogle() {
      const userData = this.socialAuthService.signIn(
         GoogleLoginProvider.PROVIDER_ID
      );
      // Now sign-in with userData
      if (userData != null) {
         this.user = await userData;
         this.store.dispatch(
            authActions.googleLogin({
               name: this.user.name,
               email: this.user.email,
            })
         );
      }
   }
   /**
    * Method used to redirect user to linkedIn to get authCode
    */
   loginLinkedin() {
      window.location.href = `https://www.linkedin.com/uas/oauth2/authorization?response_type=code&scope=r_liteprofile%20r_emailaddress&client_id=${environment.linkedInKey}&redirect_uri=${environment.currentUrl}`;
   }
   /**
    * Method called after we get authcode from linkedIn,
    * Dispaches linkedInLogin action
    */
   linkedInAuth() {
      this.store.dispatch(
         authActions.linkedInLogin({ authCode: this.linkedInToken })
      );
   }

   /**
    * Method used to show/hide password
    */
   currentPassword() {
      this.showPassword = !this.showPassword;
   }
   /**
    * Method used to get controls from the group form, includin values
    */
   get loginFormValues() {
      return this.loginForm.controls;
   }
   /**
    * Method used by user to sign in in system
    */
   signIn(): void {
      if (this.loginFormValues.email.value == '') {
         this.toastrService.warning('Please Enter a valid email');
         return;
      }

      if (this.loginFormValues.password.value == '') {
         this.toastrService.warning('Please enter your password');
         return;
      }

      this.store.dispatch(
         authActions.login({
            email: this.loginForm.value.email,
            password: this.loginForm.value.password,
         })
      );
   }
   openModal() {
      this.dialog.open(TermsAndConditionsComponent, {
         width: '70%',
      });
   }
   /**
    * Method used to show warning message upon specific conditions & accepts @param message as a parameter
    */
   showToasterWarning(message) {
      this.toastrService.warning(message);
   }
   onKeydown(event) {
      this.signIn();
   }

   resolved(captchaResponse: string) {
      console.log(`Resolved captcha with response: ${captchaResponse}`);
    }
}
