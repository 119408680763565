<div class="personal-information">
   <main>
      <form action="" [formGroup]="userInfoForm">
         <h5>{{ 'general.first_name' | translate }}</h5>
         <input type="text" formControlName="firstName" />
         <div
            *ngIf="form.firstName.invalid && form.firstName.touched"
            class="invalid"
         >
            <div *ngIf="form.firstName.errors.required">
               {{ 'validators.first_name_required' | translate }}
            </div>
            <div
               *ngIf="
                  form.firstName.errors.minlength &&
                  !form.firstName.errors.pattern
               "
            >
               {{ 'validators.first_name_minLength' | translate }}
            </div>
            <div *ngIf="form.firstName.errors.pattern">
               {{ 'validators.first_name_only_letters' | translate }}
            </div>
         </div>
         <h5>{{ 'general.last_name' | translate }}</h5>
         <input type="text" name="" formControlName="lastName" />
         <div
            *ngIf="form.lastName.invalid && form.lastName.touched"
            class="invalid"
         >
            <div *ngIf="form.lastName.errors.required">
               {{ 'validators.last_name_required' | translate }}
            </div>
            <div *ngIf="form.lastName.errors.pattern">
               {{ 'validators.last_name_only_letters' | translate }}
            </div>
            <div
               *ngIf="
                  form.lastName.errors.minlength &&
                  !form.lastName.errors.pattern
               "
            >
               {{ 'validators.last_name_minLength' | translate }}
            </div>
         </div>
         <h5>{{ 'general.email' | translate }}</h5>
         <input disabled type="text" formControlName="email" />
         <div *ngIf="form.email.invalid && form.email.touched" class="invalid">
            <div *ngIf="form.email.errors.required">email is required*</div>
            <div *ngIf="form.email.errors.email">
               {{ 'validators.email_required' | translate }}
            </div>
         </div>
         <div class="center">
            <button
               [disabled]="userInfoForm.invalid"
               [ngClass]="{
                  disable: userInfoForm.invalid
               }"
               class="submit"
               type="submit"
               (click)="updateUserInfo()"
            >
               {{ 'general.save' | translate }}
            </button>
         </div>
      </form>
   </main>
</div>
