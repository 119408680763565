import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, catchError, concatMap, switchMap } from 'rxjs/operators';
import * as departmentActions from '../../department/action/department.actions';
import { DepartmentService } from '../../../../services/department.service';

/**
 * Departments Effects responsible to listen for dispatched actions that call service methods related to departments
 */
@Injectable()
export class DepartmentEffects {
   constructor(
      private actions$: Actions,
      private departmentService: DepartmentService
   ) {}

   /**
    * Effect listening for departmentsRegister Action, when departmentsRegister is dispatched the effect with call create() from departmentService.
    * In case of success response the effect it will trigger departmentsRegisterSuccess.
    * In case of Error it will trigger departmentsRegisterFailure with error property.
    */
   registerDepartment$ = createEffect(() => {
      return this.actions$.pipe(
         ofType(departmentActions.departmentsRegister),
         concatMap((action) => {
            return this.departmentService.create(action.data).pipe(
               switchMap((data) => [
                  departmentActions.departmentsRegisterSuccess({
                     data: data.departmentDetails,
                  })
               ]),
               catchError((error) =>
                  of(departmentActions.departmentsRegisterFailure({ error }))
               )
            );
         })
      );
   });

   /**
    * Effect listening for loadDepartmentsDetails Action, when loadDepartmentsDetails is dispatched the effect with call departments() from departmentService.
    * In case of success response the effect it will trigger loadDepartmentsDetailsSuccess.
    * In case of Error it will trigger loadDepartmentsDetailsFailure with error property.
    */
   getDepartmentsDetails$ = createEffect(() => {
      return this.actions$.pipe(
         ofType(departmentActions.loadDepartmentsDetails),
         concatMap((action) => {
            return this.departmentService
               .departments(action.page, action.faculty, action.name)
               .pipe(
                  map((data) => {
                     return departmentActions.loadDepartmentsDetailsSuccess({
                        departments: data.departments,
                        totalDepartments: data.totalDepartments,
                     });
                  }),
                  catchError((error) =>
                     of(
                        departmentActions.loadDepartmentsDetailsFailure({
                           error,
                        })
                     )
                  )
               );
         })
      );
   });

   /**
    * Effect listening for editDepartmentDetails Action, when editDepartmentDetails is dispatched the effect with call editDepartment() from departmentService.
    * In case of success response the effect it will trigger recallDepartments that will return all departments of institution including the one that got edited as well.
    * In case of Error it will trigger editDepartmentDetailsFailure with error property.
    */
   editDepartmentDetails$ = createEffect(() => {
      return this.actions$.pipe(
         ofType(departmentActions.editDepartmentDetails),
         concatMap((action) => {
            return this.departmentService
               .editDepartment(
                  action.departmentId,
                  action.departmentName,
                  action.departmentFaculty,
                  action.departmentDescription,
                  action.departmentYear,
                  action.departmentLevel
               )
               .pipe(
                  switchMap((data) => [
                     departmentActions.editDepartmentDetailsSuccess({
                        data:data
                     }),
                     departmentActions.loadDepartmentsDetails({
                        page: action.page,
                        name: action.name,
                        faculty: action.faculty
                     }),
                  ]),
                  catchError((error) =>
                     of(
                        departmentActions.editDepartmentDetailsFailure({
                           error,
                        })
                     )
                  )
               );
         })
      );
   });

   /**
    * Effect listening for removeDepartment Action, when removeDepartment is dispatched the effect with call remove() from departmentService.
    * In case of success response the effect it will trigger recallDepartments that will return all departments of institution.
    * In case of Error it will trigger removeDepartmentFailure with error property.
    */
   removeDepartment$ = createEffect(() => {
      return this.actions$.pipe(
         ofType(departmentActions.removeDepartment),
         concatMap((action) => {
            return this.departmentService.remove(action.departmentId).pipe(
               switchMap((data) => [
                  departmentActions.removeDepartmentSuccess({
                     data:data
                  }),
                  departmentActions.loadDepartmentsDetails({
                     page: action.page,
                     name: action.name,
                     faculty: action.faculty
                  }),
               ]),
               catchError((error) =>
                  of(departmentActions.removeDepartmentFailure({ error }))
               )
            );
         })
      );
   });
}
