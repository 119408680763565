// *** Angular
import { Component, Inject, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as facultyActions from '../../state/faculty/action/faculty.actions';
import { getInstitutionFacultiesStateDetails } from '../../state/faculty/selector/faculty.selectors';

import { getInstitutionSubjectsStateDetails } from '../../state/subject/selector/subject.selectors';

// *** Packages
import {
   MatDialog,
   MatDialogRef,
   MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import swal from 'sweetalert2';

// *** Services
import { UserService } from 'src/app/services/user.service';

// *** Actions
import * as subjectActions from '../../state/subject/action/subject.actions';
import { DepartmentService } from 'src/app/services/department.service';
import { CourseService } from 'src/app/services/course.service';

@Component({
   selector: 'app-edit-subjects-generation',
   templateUrl: './edit-subjects-generation.component.html',
   styleUrls: ['./edit-subjects-generation.component.scss'],
})
export class EditSubjectsGenerationComponent implements OnInit {
   /**
    * Variabel used to store group of courses that their generation will be changed
    */
   courses: any;
   /**
    * Variabel used to store configuration of 3rd party package
    */
   config: {
      displayKey: string; //if objects array passed which key to be displayed defaults to description
      search: boolean; //true/false for the search functionality defaults to false,
      height: string; //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
      placeholder: string; // text to be displayed when no item is selected defaults to Select,
      limitTo: number; // number thats limits the no of options displayed in the UI (if zero, options will not be limited)
      moreText: string; // text to be displayed when more than one items are selected like Option 1 + 5 more
      noResultsFound: string; // text to be displayed when no items are found while searching
      searchPlaceholder: string; // label thats displayed in search input,
      searchOnKey: string;
   };
   /**
    * Variabel used to store professors of institution
    */
   professors;
   /**
    * Variable used to store selected generation for courses
    */
   selectedGeneration = '-1';
   faculties$: any;
   faculties;
   page: number;
   departmentId = '-1';
   facultyId = '-1';
   departments: any;
   courses$: any;
   showSubjects= false;
   generationToChange;
   showError = false;
   
   constructor(
      public dialogRef: MatDialogRef<EditSubjectsGenerationComponent>,
      public dialog: MatDialog,
      /**
       * Used to pass data in component through dialog
       */
      @Inject(MAT_DIALOG_DATA) public data: any,
      private userService: UserService,
      private store: Store,
      private departmentService: DepartmentService,
      private courseService: CourseService,
   ) {}

   ngOnDestroy(): void {
      this.faculties$.unsubscribe();
   }

   ngOnInit(): void {
      this.store.dispatch(facultyActions.loadFacultiesDetails({ page: 0 }));
      this.faculties$ = this.store
      .select(getInstitutionFacultiesStateDetails)
      .subscribe((data) => {
         if (data !== null) {
            this.faculties = data.faculties;
         }
      });
      this.config = {
         displayKey: 'name', //if objects array passed which key to be displayed defaults to description
         search: true, //true/false for the search functionality defaults to false,
         height: 'auto', //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
         placeholder: 'Select', // text to be displayed when no item is selected defaults to Select,
         limitTo: 0, // number thats limits the no of options displayed in the UI (if zero, options will not be limited)
         moreText: 'more', // text to be displayed when more than one items are selected like Option 1 + 5 more
         noResultsFound: 'No results found!', // text to be displayed when no items are found while searching
         searchPlaceholder: 'Search', // label thats displayed in search input,
         searchOnKey: 'name', // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
      };

   }

   selectedFaculty() {
      this.page = -2;
      if (this.facultyId !== '') {
         this.departmentService
            .getDepartmentsOfFaculty(this.facultyId)
            .pipe(first())
            .subscribe(
               (data) => {
                  this.departments = data.departments;
               },
               (error) => {
                  console.log(error);
               }
            );
      }
   }
   /**
    * Function used to save choosen generation for selected courses
    * @returns
    */
   async save() {
      let coursesToSend = [];
      if (!this.generationToChange ) {
         swal.fire({
            title: 'Please select the academic year you want to renew subjects.',
            confirmButtonText: 'I understand',
            confirmButtonColor: '#03a84e',
            allowOutsideClick: false,

            icon: 'info',
         });
         return;
      }
      if (!(this.selectedGeneration == this.generationToChange)) {
         const result = await swal.fire({
            title: 'You are about to renew subjects. Please confirm your action by clicking “Confirm”.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#b5adad',
            confirmButtonText: 'Confirm',
         });
         if (result.isConfirmed) {
            for (let course of this.courses) {
               if (!course.hide) {
                  coursesToSend.push(course);
               }
            }
            this.store.dispatch(
               subjectActions.changeSubjectsGeneration({
                  subjects: coursesToSend,
                  generation: this.selectedGeneration,
               })
            );
            this.dialogRef.close();
         }

      } else {
         swal.fire({
            title: 'Duplicated generation!',
            confirmButtonText: 'I understand',
            confirmButtonColor: '#03a84e',
            allowOutsideClick: false,
            html:
               'Please delete courses that already have their generations changed to ' +
               this.selectedGeneration,
            icon: 'info',
         });
      }
   }
   /**
    * Function is used to set selected generation to courses
    * @param value
    */
   /**
    * Function is used to remove a course from array
    * @param index
    */
   removeIndex(index) {
      this.courses[index].hide = true;
   }

   closeModal() {
      this.dialogRef.close();
   }

   submit() {

      if(this.facultyId == '' || this.facultyId == '-1' || this.departmentId == '-1' || this.departmentId == '' || this.selectedGeneration == '-1' || this.selectedGeneration == ''){
         this.showError = true;
         return;
      }
      this.showSubjects = true;

      this.courseService
      .getSubjectsToDuplicate(this.facultyId,this.departmentId,this.selectedGeneration)
      .pipe(first())
      .subscribe(
         (data) => {
            this.courses = data.courses;            
                  this.userService
         .institutionProfessors()
         .pipe(first())
         .subscribe(
            (data) => {
               this.professors = data.professors;

               for (let course of this.courses) {
                  course.professors = [];
                  course.error = false;
                  course.hide = false;
                  course.professorsToAdd = [];
                  for (let x in this.professors) {
                     for (let professorCourse of course.professorCourses) {
                        if (
                           professorCourse.professorId === this.professors[x].id
                        ) {
                           course.professors.push(this.professors[x]);
                        }
                     }
                  }
                  for (let x in this.professors) {
                     let same = 0;
                     for (let professors of course.professors) {
                        if (this.professors[x].id === professors.id) {
                           same++;
                        }
                     }
                     if (same === 0) {
                        course.professorsToAdd.push(this.professors[x]);
                     }
                  }
               }
            },
            (error) => {
               console.log('error', error);
            }
         );
         },
         (error) => {
            console.log(error);
         }
      );
      
   }

   changeFilters() {
      this.showSubjects = false
   }
}
