import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, catchError, concatMap } from 'rxjs/operators';
import * as thesisSubmissionsActions from '../action/thesis-statistics.actions';
import { AdministratorStatisticsService } from '../../../../../../services/administrator-statistics.service';

@Injectable()
export class ThesisStatisticsEffects {
   constructor(
      private actions$: Actions,
      private administratorStatistics: AdministratorStatisticsService
   ) {}

   /**
    * Effect listening for ThesisSubmissionsStatistics Action, when ThesisSubmissionsStatistics is dispatched the effect with call create() from courseService.
    * In case of success response the effect it will trigger ThesisSubmissionsStatisticsSuccess.
    * In case of Error it will trigger ThesisSubmissionsStatisticsFailure with error property.
    */
   ThesisSubmissionsStatistics$ = createEffect(() => {
      return this.actions$.pipe(
         ofType(thesisSubmissionsActions.loadThesisSubmissionStatistics),
         concatMap((action) => {
            return this.administratorStatistics
               .thesisSubmissionsStatistics(
                  action.page,
                  action.facultyId,
                  action.departmentId,
                  action.professorId,
                  action.level,
                  action.studentName
               )
               .pipe(
                  map((data) => {
                     return thesisSubmissionsActions.loadThesisSubmissionStatisticsSuccess(
                        {
                           data: data,
                        }
                     );
                  }),
                  catchError((error) =>
                     of(
                        thesisSubmissionsActions.loadThesisSubmissionStatisticsFailure(
                           {
                              error,
                           }
                        )
                     )
                  )
               );
         })
      );
   });

   /**
    * Effect listening for ThesisSubmissionsDetails Action, when ThesisSubmissionsDetails is dispatched the effect with call create() from courseService.
    * In case of success response the effect it will trigger ThesisSubmissionsDetailsSuccess.
    * In case of Error it will trigger ThesisSubmissionsDetailsFailure with error property.
    */
   ThesisSubmissionsDetails$ = createEffect(() => {
      return this.actions$.pipe(
         ofType(thesisSubmissionsActions.loadThesisSubmissionDetails),
         concatMap((action) => {
            return this.administratorStatistics
               .thesisSubmissionDetails(action.thesisId)
               .pipe(
                  map((data) => {
                     return thesisSubmissionsActions.loadThesisSubmissionDetailsSuccess(
                        {
                           data: data,
                        }
                     );
                  }),
                  catchError((error) =>
                     of(
                        thesisSubmissionsActions.loadThesisSubmissionDetailsFailure(
                           {
                              error,
                           }
                        )
                     )
                  )
               );
         })
      );
   });
}
