import * as AppState from '../../../../store/app.state';
import * as studentActions from '../action/student.actions';
import { createReducer } from '@ngrx/store';
import { on } from '@ngrx/store';
import { User } from 'src/app/models/user';

/**
 * student reducer used to updated store values based on certain action.
 */

/**
 * State interface extends App state State and add user state to the main state.
 */
export interface State extends AppState.State {
   students: StudentState;
}

/**
 * An StudentState interface, which represents the blueprint of the User State.
 */
export interface StudentState {
   students: User[];
   studentsNumber: number;
   errorMessage: string;
   studentRegistered: string;
}

/**
 * StudentState initial state.
 */
export const initialState: StudentState = {
   students: null,
   studentsNumber: null,
   errorMessage: null,
   studentRegistered: null,
};

export const studentReducer = createReducer(
   initialState,
   /**
    * On loadStudentsDetailsSuccess action updated current state with the following properties.
    */
   on(
      studentActions.loadStudentsDetailsSuccess,
      (state, action): StudentState => {
         return {
            ...state,
            students: action.students,
            studentsNumber: action.studentsNumber,
         };
      }
   ),

   /**
    * On loadStudentsDetailsFailure action updated current state with the following properties.
    */
   on(
      studentActions.loadStudentsDetailsFailure,
      (state, action): StudentState => {
         return {
            ...state,
            errorMessage: action.error,
         };
      }
   ),
   on(studentActions.studentRegisterSuccess, (state, action): StudentState => {
      return {
         ...state,
         studentRegistered: '',
      };
   }),
   on(
      studentActions.setStudentRegisterToNull,
      (state, action): StudentState => {
         return {
            ...state,
            studentRegistered: null,
         };
      }
   )
);
