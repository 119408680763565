// *** Angular
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

// *** Actions
import * as academicUnitsStatisticsActions from './state/action/academic-units-statistics.actions';

// *** Selectors
import { getStatisticsOverviewDetails } from './state/selector/academic-units-statistics.selectors';
@Component({
   selector: 'app-academic-units-statistics',
   templateUrl: './academic-units-statistics.component.html',
   styleUrls: ['./academic-units-statistics.component.scss'],
})
export class AcademicUnitsStatisticsComponent implements OnInit, OnDestroy {
   constructor(private store: Store) {}

   ngOnDestroy(): void {
      this.overview$.unsubscribe();
   }
   /**
    * Variable used to store overview of statistics
    */
   overview;
   overview$;
   ngOnInit(): void {
      this.store.dispatch(academicUnitsStatisticsActions.overviewStatistics());

      this.overview$ = this.store
         .select(getStatisticsOverviewDetails)
         .subscribe((data) => {
            if (data !== null) {
               this.overview = data.statisticsOverview;
            }
         });
   }
}
