// *** Angular
import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { first } from 'rxjs/operators';

// *** Packakges
import {
   ApexAxisChartSeries,
   ApexChart,
   ApexTitleSubtitle,
   ApexXAxis,
} from 'ng-apexcharts';

// *** Services
import { AdministratorStatisticsService } from 'src/app/services/administrator-statistics.service';

export type ChartOptions = {
   series: ApexAxisChartSeries;
   chart: ApexChart;
   xaxis: ApexXAxis;
   title: ApexTitleSubtitle;
   labels: string[];
};
@Component({
   selector: 'app-submissions-institutional',
   templateUrl: './submissions-institutional.component.html',
   styleUrls: ['./submissions-institutional.component.scss'],
})
export class SubmissionsInstitutionalComponent implements OnInit {
   /**
    * Variabel used to store data that are shown on chart
    */
   institutionSubmissionsData: any;
   /**
    * Variabel used to store options for chart
    */
   chartOptions = {
      series: [
         {
            name: 'High similarity',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
         },
         {
            name: 'Medium similarity',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
         },
         {
            name: 'Low similarity',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
         },
      ],
      colors: ['#ff0000', '#d0c23b', '#33d133'],
      chart: {
         height: 350,
         type: 'line',
         zoom: {
            enabled: true,
         },
      },
      dataLabels: {
         enabled: true,
         style: {
            colors: ['#ff0000', '#d0c23b', '#33d133'],
         },
      },
      title: {
         text: 'Document Similarity ',
         align: 'center',
      },
      xaxis: {
         categories: [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
         ],
      },
      grid: {
         borderColor: '#f1f1f1',
      },
   };
   /**
    * Variabel used to store current year
    */
   year: number;
   lastFourYears: number[] = [];
   availableYears: number[] = [];
   constructor(
      private statistics: AdministratorStatisticsService,
      private spinner: NgxSpinnerService
   ) {}

   ngOnInit(): void {
      this.year = new Date().getFullYear();
      this.lastFourYears = Array.from({ length: 5 }, (_, index) => this.year - index);
      this.availableYears = this.lastFourYears.reverse();
      this.statistics
         .institutionSubmissionsStatistics(this.year)
         .pipe(first())
         .subscribe(
            (data) => {
               this.institutionSubmissionsData = data;
               this.chartOptions = {
                  series: [
                     {
                        name: 'High similarity',
                        data: data.highSimilarity,
                     },
                     {
                        name: 'Medium similarity',
                        data: data.mediumSimilarity,
                     },
                     {
                        name: 'Low similarity',
                        data: data.lowSimilarity,
                     },
                  ],
                  colors: ['#ff0000', '#d0c23b', '#33d133'],
                  chart: {
                     height: 350,
                     type: 'line',
                     zoom: {
                        enabled: true,
                     },
                  },
                  dataLabels: {
                     enabled: true,
                     style: {
                        colors: ['#ff0000', '#d0c23b', '#33d133'],
                     },
                  },
                  title: {
                     text: 'Student submissions',
                     align: 'left',
                  },
                  xaxis: {
                     categories: [
                        'January',
                        'February',
                        'March',
                        'April',
                        'May',
                        'June',
                        'July',
                        'August',
                        'September',
                        'October',
                        'November',
                        'December',
                     ],
                  },
                  grid: {
                     borderColor: '#f1f1f1',
                  },
               };
            },
            (error) => {
               console.log('error', error);
            }
         );
   }
   /**
    * Function is used to filter statitistics by year
    * @param value
    */
   filterByYear(value) {
      this.spinner.show();
      this.year = parseInt(value);
      this.statistics
         .institutionSubmissionsStatistics(this.year)
         .pipe(first())
         .subscribe(
            (data) => {
               this.institutionSubmissionsData = data;
               this.chartOptions = {
                  series: [
                     {
                        name: 'High similarity',
                        data: this.institutionSubmissionsData.highSimilarity,
                     },
                     {
                        name: 'Medium similarity',
                        data: this.institutionSubmissionsData.mediumSimilarity,
                     },
                     {
                        name: 'Low similarity',
                        data: this.institutionSubmissionsData.lowSimilarity,
                     },
                  ],
                  colors: ['#ff0000', '#d0c23b', '#33d133'],
                  chart: {
                     height: 350,
                     type: 'line',
                     zoom: {
                        enabled: true,
                     },
                  },
                  dataLabels: {
                     enabled: true,
                     style: {
                        colors: ['#ff0000', '#d0c23b', '#33d133'],
                     },
                  },
                  title: {
                     text: 'Student submissions',
                     align: 'left',
                  },
                  xaxis: {
                     categories: [
                        'January',
                        'February',
                        'March',
                        'April',
                        'May',
                        'June',
                        'July',
                        'August',
                        'September',
                        'October',
                        'November',
                        'December',
                     ],
                  },
                  grid: {
                     borderColor: '#f1f1f1',
                  },
               };
               this.spinner.hide();
            },
            (error) => {
               this.spinner.hide();
               console.log('error', error);
            }
         );
   }
}
