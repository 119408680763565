import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
   providedIn: 'root',
})
export class CourseService {
   constructor(private http: HttpClient) {}

   /**
    * Method used to call api for creating course department.
    * @param courses
    * @returns
    */
   create(courses) {
      console.log('courses', courses);
      return this.http.post<any>(`${environment.apiUrl}/course`, {
         courses,
      });
   }

   /**
    * Method is used to retrieve courses of given department id.
    * @param departmentId
    * @returns
    */
   getDepartmentCourses(departmentId: string) {
      return this.http
         .get<any>(`${environment.apiUrl}/department/${departmentId}/courses`)
         .pipe(
            map((data) => {
               return data;
            })
         );
   }

   /**
    * Method is used to retrieve all courses within institution.
    * @returns
    */
   courses(
      generation?,
      page?,
      faculty?,
      department?,
      professor?,
      status?,
      type?,
      name?
   ) {
      return this.http
         .get<any>(
            `${environment.apiUrl}/courses?page=${page}` +
               (generation ? `&generation=${generation}` : '') +
               (faculty ? `&faculty=${faculty}` : '') +
               (department ? `&department=${department}` : '') +
               (professor ? `&professor=${professor}` : '') +
               (status ? `&status=${status}` : '') +
               (type ? `&type=${type}` : '') +
               (name ? `&name=${name}` : '')
         )
         .pipe(
            map((data) => {
               return data;
            })
         );
   }

   /**
    * Service is used to call api that return information for specific @Course.
    * @param courseId
    * @returns
    */
   course(courseId: string) {
      return this.http
         .get<any>(`${environment.apiUrl}/course/${courseId}`)
         .pipe(
            map((data) => {
               return data;
            })
         );
   }

   /**
    * Service is used to call api that will submit edited information of specific Course.
    * @param courseId
    * @param courseTitle
    * @param courseProfessors
    * @param courseFaculty
    * @param courseDepartment
    * @param courseType
    * @param courseDescription
    * @param courseCode
    * @param courseGeneration
    * @param courseYear
    * @returns
    */
   editCourse(
      courseId: string,
      courseTitle: string,
      courseProfessors: object,
      courseFaculty: string,
      courseDepartment: string,
      courseType: number,
      courseDescription: string,
      courseCode: string,
      courseGeneration: string,
      courseYear: number,
      subjectDuplicate: boolean,
      saveAndDuplicate: boolean,
      status: boolean
   ) {
      return this.http
         .put<any>(`${environment.apiUrl}/course`, {
            courseId,
            courseTitle,
            courseProfessors,
            courseFaculty,
            courseDepartment,
            courseType,
            courseDescription,
            courseCode,
            courseGeneration,
            courseYear,
            subjectDuplicate,
            saveAndDuplicate,
            status
         })
         .pipe(
            map((data) => {
               return data;
            })
         );
   }

   editCourses(courses, generation: String) {
      return this.http
         .put<any>(`${environment.apiUrl}/courses`, {
            courses,
            generation,
         })
         .pipe(
            map((data) => {
               return data;
            })
         );
   }

   /**
    * Service is used to call api that will remove a specific Course.
    * @param courseId
    * @returns
    */
   remove(courseId: string) {
      return this.http
         .delete<any>(`${environment.apiUrl}/course/${courseId}`)
         .pipe(
            map((data) => {
               return data;
            })
         );
   }

   getProfessorSubjects(userId, page, status, title, generation) {
      return this.http
         .get<any>(
            `${environment.apiUrl}/subject/?page=${page}` +
               (title ? `&title=${title}` : '') +
               (status ? `&status=${status}` : '') +
               (generation ? `&generation=${generation}` : '')
         )
         .pipe(
            map((data) => {
               return data;
            })
         );
   }

   getProfessorStudents(userId, page, name, invitedStudents) {
      return this.http
         .get<any>(
            `${environment.apiUrl}/professor/students/?page=${page}` +
               (name ? `&name=${name}` : '') +
               (invitedStudents ? `&invitedStudents=${invitedStudents}` : '')
         )
         .pipe(
            map((data) => {
               return data;
            })
         );
   }

   inviteStudent(studentId, subject, email) {
      return this.http
         .get<any>(
            `${environment.apiUrl}/student/${studentId}/inviteStudent/?subject=${subject}` +
               (email ? `&email=${email}` : '')
         )
         .pipe(
            map((data) => {
               return data;
            })
         );
   }

   editProfessorSubject(courses, subjectId) {
      return this.http
         .put<any>(`${environment.apiUrl}/subject/${subjectId}`, {
            courses,
         })
         .pipe(
            map((data) => {
               return data;
            })
         );
   }

   duplicateProfessorSubject(courses, subjectId) {
      return this.http
         .put<any>(`${environment.apiUrl}/subject/${subjectId}/duplicate`, {
            courses,
         })
         .pipe(
            map((data) => {
               return data;
            })
         );
   }

   deleteProfessorSubject(subjectId) {
      return this.http
         .delete<any>(`${environment.apiUrl}/subject/${subjectId}`, {})
         .pipe(
            map((data) => {
               return data;
            })
         );
   }

   studentSubjectsToRegister(year, generation) {
      return this.http
         .get<any>(
            `${environment.apiUrl}/student/subject/register/list?year=${year}` +
               (generation ? `&generation=${generation}` : '')
         )
         .pipe(
            map((data) => {
               return data;
            })
         );
   }

   studentRegisterSubject(professorId, subjectId) {
      return this.http
         .post<any>(`${environment.apiUrl}/student/subject`, {
            professorId,
            subjectId,
         })
         .pipe(
            map((data) => {
               return data;
            })
         );
   }

   unregisterSubject(subjectId) {
      return this.http
         .delete<any>(`${environment.apiUrl}/student/subject/${subjectId}`)
         .pipe(
            map((data) => {
               return data;
            })
         );
   }

   declineSubject(subjectCourseId) {
      return this.http
         .delete<any>(
            `${environment.apiUrl}/student/subject/decline?subjectCourseId=${subjectCourseId}`
         )
         .pipe(
            map((data) => {
               return data;
            })
         );
   }

   registerSubjectByPassword(password, professorId, subjectId) {
      return this.http
         .post<any>(`${environment.apiUrl}/student/subject/password`, {
            subjectId,
            professorId,
            password,
         })
         .pipe(
            map((data) => {
               return data;
            })
         );
   }

   getSubjectsWithCode(code) {
      return this.http
         .get<any>(
            `${environment.apiUrl}/student/subject/code?code=${code}`,
            {}
         )
         .pipe(
            map((data) => {
               return data;
            })
         );
   }

   checkCode(code) {
      return this.http
         .post<any>(`${environment.apiUrl}/course/code/validity`, { code })
         .pipe();
   }

   isSubjectRegistered(professorId, subjectId) {
      return this.http
         .get<any>(
            `${environment.apiUrl}/student/subject/registered?professorId=${professorId}` +
               (subjectId ? `&subjectId=${subjectId}` : ''),
            {}
         )
         .pipe(
            map((data) => {
               return data;
            })
         );
   }

   getSubjectsToDuplicate(faculty,department,generation) {
      return this.http
      .get<any>(
         `${environment.apiUrl}/coursesToduplicate?faculty=${faculty}` +
            (department ? `&department=${department}` : '') +
            (generation ? `&generation=${generation}` : '')
      )
         .pipe(
            map((data) => {
               return data;
            })
         );
   }


}
