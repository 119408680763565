import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
   providedIn: 'root',
})
export class DepartmentService {
   constructor(private http: HttpClient) {}

   /**
    * Method used to call api for creating department for faculty.
    * @param departments
    */
   create(departments) {
      return this.http
         .post<any>(`${environment.apiUrl}/department`, {
            departments,
         })
         .pipe(
            map((data) => {
               return data;
            })
         );
   }

   /**
    *  Method is used to retrieve departments of faculty.
    * @param facultyId
    * @returns
    */
   getDepartmentsOfFaculty(facultyId: string) {
      if (facultyId !== '' && facultyId !== '-1') {
         return this.http
            .get<any>(`${environment.apiUrl}/faculty/${facultyId}/departments`)
            .pipe(
               map((data) => {
                  return data;
               })
            );
      }
   }

   /**
    * Method is used to retrieve all departments within institution.
    * @returns
    */
   departments(page: number, faculty, name) {
      return this.http
         .get<any>(
            `${environment.apiUrl}/departments/?page=${page}` +
               (faculty ? `&faculty=${faculty}` : '') +
               (name ? `&name=${name}` : '')
         )
         .pipe(
            map((data) => {
               return data;
            })
         );
   }

   /**
    * Service is used to call api that return information for specific department.
    * @param departmentId
    * @returns
    */
   department(departmentId: string) {
      return this.http
         .get<any>(`${environment.apiUrl}/department/${departmentId}`)
         .pipe(
            map((data) => {
               return data;
            })
         );
   }

   /**
    * Service is used to call api that will submit edited information of specific department.
    * @param departmentId
    * @returns
    */
   editDepartment(
      departmentId: string,
      departmentName: string,
      departmentFaculty: string,
      departmentDescription: string,
      departmentYear: string,
      departmentLevel: string
   ) {
      return this.http
         .put<any>(`${environment.apiUrl}/department`, {
            departmentId,
            departmentName,
            departmentFaculty,
            departmentDescription,
            departmentYear,
            departmentLevel,
         })
         .pipe(
            map((data) => {
               return data;
            })
         );
   }

   /**
    * Service is used to call api that will remove a specific department.
    * @param departmentId
    * @returns
    */
   remove(departmentId: string) {
      return this.http
         .delete<any>(`${environment.apiUrl}/department/${departmentId}`)
         .pipe(
            map((data) => {
               return data;
            })
         );
   }
}
