import { createAction, props } from '@ngrx/store';

export const createAssignment = createAction(
   '[Assignment] Assignment Register',
   props<{
      data: any;
   }>()
);

export const createAssignmentSuccess = createAction(
   '[Assignment] Assignment Register Success',
   props<{
      data: any;
   }>()
);

export const createAssignmentFailure = createAction(
   '[Assignment] Assignment Register Failure',
   props<{ error: any }>()
);

/**
 * Action used to load professor assignments .
 */
export const loadAssignments = createAction(
   '[Assignment] Load Professor Assignments',
   props<{
      user?: any;
      subject?: any;
      assignmentType?: any;
      name?: any;
      page: any;
   }>()
);

/**
 * Action is called when there is a success status returned from API.
 */
export const loadAssignmentsSuccess = createAction(
   '[Assignment] Load Professor Assignments Success',
   props<{
      data: any;
   }>()
);

/**
 * Action is called when there is a failure status returned from API.
 */
export const loadAssignmentsFailure = createAction(
   '[Assignment] Load Professor Assignments Failure',
   props<{ error: any }>()
);

export const editAssignment = createAction(
   '[Assignment] Edit Assignment',
   props<{
      data: any;
      id: any;
      subject?: any;
      assignmentType?: any;
      name?: any;
      page?: any;
   }>()
);

export const editAssignmentSuccess = createAction(
   '[Assignment] Edit Assignment Success'
);

export const editAssignmentFailure = createAction(
   '[Assignment] Edit Assignment Failure',
   props<{ error: any }>()
);

export const deleteAssignment = createAction(
   '[Assignment] Delete Assignment',
   props<{
      data: any;
      subject?: any;
      assignmentType?: any;
      name?: any;
      page?: any;
   }>()
);

export const deleteAssignmentSuccess = createAction(
   '[Assignment] Delete Assignment Success',
   props<{
      data: any;
   }>()
);

export const deleteAssignmentFailure = createAction(
   '[Assignment] Delete Assignment Failure',
   props<{ error: any }>()
);

export const setCreatedAssignmentToNull = createAction(
   '[Assignment] Set Created Assignment To Null'
);
