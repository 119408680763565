import { createFeatureSelector, createSelector } from '@ngrx/store';
import { statisticsOverviewState } from '../reducer/academic-units-statistics.reducer';

/**
 * Selectors are pure functions used for obtaining slices of store state.
 */

/**
 * getStatisticsOverview represent top level of the subject state.
 * The createFeatureSelector is a convenience method for returning a top level feature state.
 */
const getStatisticsOverview =
   createFeatureSelector<statisticsOverviewState>('statisticsOverview');

/**
 * Selector used to get statisticsOverview details state.
 */
export const getStatisticsOverviewDetails = createSelector(
   getStatisticsOverview,
   (state) => state
);
