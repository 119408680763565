<div *ngIf="!this.hasRoute('/manage/')">
   <div>
      <span (click)="this.router.navigateByUrl('administrator/store/offers')"
         >Offers</span
      >
   </div>
   <div>
      <div><span> Manage </span></div>
      <div>
         <div routerLink="commitment"><span>Commitment</span></div>
         <div routerLink="users"><span>Users</span></div>
         <div routerLink="addons"><span>Addons</span></div>
         <div routerLink="repositories"><span>Repositories</span></div>
      </div>
   </div>
</div>

<ng-container *ngIf="this.hasRoute('/manage/')">
   <router-outlet></router-outlet>
</ng-container>
