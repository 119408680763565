<div class="filtersContainer">
   <select name="" id="" (change)="this.filterByYear($event.target.value)">
    <option *ngFor="let currYear of availableYears" [value]="currYear" [selected]="currYear === year">{{ currYear }}</option>
      <!-- <option value="2019" [selected]="this.year === 2019">2019</option>
      <option value="2020" [selected]="this.year === 2020">2020</option>
      <option value="2021" [selected]="this.year === 2021">2021</option>
      <option value="2022" [selected]="this.year === 2022">2022</option>
      <option value="2023" [selected]="this.year === 2023">2023</option> -->
   </select>
</div>
<apx-chart
   [series]="chartOptions.series"
   [chart]="chartOptions.chart"
   [xaxis]="chartOptions.xaxis"
   [dataLabels]="chartOptions.dataLabels"
   [colors]="chartOptions.colors"
></apx-chart>
