<ng-container>
   <app-header *ngIf="!this.hasRoute('/report')"></app-header>
   <section
      [ngClass]="{
         'container-section': !this.hasRoute('/report'),
         container: !this.hasRoute('/report')
      }"
   >
      <div *ngIf="!this.hasRoute('/report')" class="mobile-nav">
         <div
            class="toggler"
            (click)="toggler()"
            [ngClass]="{ onclick: this.expandedToggler === 1 }"
            #toggleButton
         >
            <span class="first"></span>
            <span class="second"></span>
            <span class="third"></span>
         </div>
         <a
            routerLink="upload-files"
            class="plus active upload-mobile"
            *ngIf="this.userRole != 6"
         >
            <i class="fas fa-plus"></i>
            <span>Upload</span>
         </a>
      </div>
      <nav
         *ngIf="!this.hasRoute('/report')"
         class="nav-bar"
         [ngClass]="{ expand: this.expandedToggler === 1 }"
         #navbar
      >
         <a routerLink="dashboard" routerLinkActive="active" (click)="toggler()" title="Dashboard"
            ><svg 
               id="dashboard"
               xmlns="http://www.w3.org/2000/svg"
               width="38"
               height="38"
               viewBox="0 0 38 38"
            >
               <path
                  id="Path_16"
                  data-name="Path 16"
                  d="M14.643,12.669H2.768A2.775,2.775,0,0,1,0,9.893V2.768A2.768,2.768,0,0,1,2.768,0H14.643a2.768,2.768,0,0,1,2.768,2.768V9.893A2.77,2.77,0,0,1,14.643,12.669ZM2.768,2.375a.4.4,0,0,0-.393.393V9.893a.4.4,0,0,0,.393.393H14.643a.4.4,0,0,0,.393-.393V2.768a.4.4,0,0,0-.393-.393H2.768Z"
                  fill="#434343"
               />
               <path
                  id="Path_17"
                  data-name="Path 17"
                  d="M14.643,235.562H2.768A2.768,2.768,0,0,1,0,232.793V216.168A2.768,2.768,0,0,1,2.768,213.4H14.643a2.768,2.768,0,0,1,2.768,2.768v16.625A2.764,2.764,0,0,1,14.643,235.562ZM2.768,215.768a.4.4,0,0,0-.393.393v16.625a.4.4,0,0,0,.393.393H14.643a.4.4,0,0,0,.393-.393V216.161a.4.4,0,0,0-.393-.393Z"
                  transform="translate(0 -197.562)"
                  fill="#434343"
               />
               <path
                  id="Path_18"
                  data-name="Path 18"
                  d="M292.043,354.062H280.168a2.768,2.768,0,0,1-2.768-2.768v-7.125a2.768,2.768,0,0,1,2.768-2.768h11.875a2.768,2.768,0,0,1,2.768,2.768v7.125A2.768,2.768,0,0,1,292.043,354.062Zm-11.875-10.294a.4.4,0,0,0-.393.393v7.125a.4.4,0,0,0,.393.393h11.875a.4.4,0,0,0,.393-.393v-7.125a.4.4,0,0,0-.393-.393Z"
                  transform="translate(-256.812 -316.062)"
                  fill="#434343"
               />
               <path
                  id="Path_19"
                  data-name="Path 19"
                  d="M292.043,22.261H280.168a2.768,2.768,0,0,1-2.768-2.768V2.868A2.768,2.768,0,0,1,280.168.1h11.875a2.757,2.757,0,0,1,2.768,2.761V19.486A2.775,2.775,0,0,1,292.043,22.261ZM280.168,2.467a.4.4,0,0,0-.393.393V19.486a.4.4,0,0,0,.393.393h11.875a.4.4,0,0,0,.393-.393V2.861a.4.4,0,0,0-.393-.393H280.168Z"
                  transform="translate(-256.812 -0.092)"
                  fill="#434343"
               /></svg
            ><span>Dashboard</span>
         </a>
         <a
            routerLink="students-list"
            routerLinkActive="active"
            (click)="toggler()" title="Students"
         >
            <svg
               xmlns="http://www.w3.org/2000/svg"
               width="50.078"
               height="32.66"
               viewBox="0 0 50.078 32.66"
               id="students"
            >
               <g id="user" transform="translate(0 -85.333)">
                  <g
                     id="Group_446"
                     data-name="Group 446"
                     transform="translate(17.418 85.333)"
                  >
                     <g
                        id="Group_445"
                        data-name="Group 445"
                        transform="translate(0 0)"
                     >
                        <path
                           id="Path_218"
                           data-name="Path 218"
                           d="M178.287,85.333a7.621,7.621,0,1,0,7.621,7.621A7.629,7.629,0,0,0,178.287,85.333Zm0,13.064a5.443,5.443,0,1,1,5.443-5.443A5.451,5.451,0,0,1,178.287,98.4Z"
                           transform="translate(-170.666 -85.333)"
                           fill="#434343"
                        />
                     </g>
                  </g>
                  <g
                     id="Group_448"
                     data-name="Group 448"
                     transform="translate(34.837 94.042)"
                  >
                     <g id="Group_447" data-name="Group 447">
                        <path
                           id="Path_219"
                           data-name="Path 219"
                           d="M346.777,170.667a5.443,5.443,0,1,0,5.443,5.443A5.451,5.451,0,0,0,346.777,170.667Zm0,8.709a3.266,3.266,0,1,1,3.266-3.266A3.27,3.27,0,0,1,346.777,179.376Z"
                           transform="translate(-341.334 -170.667)"
                           fill="#434343"
                        />
                     </g>
                  </g>
                  <g
                     id="Group_450"
                     data-name="Group 450"
                     transform="translate(4.509 94.042)"
                  >
                     <g id="Group_449" data-name="Group 449">
                        <path
                           id="Path_220"
                           data-name="Path 220"
                           d="M49.625,170.667a5.443,5.443,0,1,0,5.443,5.443A5.449,5.449,0,0,0,49.625,170.667Zm0,8.709a3.266,3.266,0,1,1,3.266-3.266A3.27,3.27,0,0,1,49.625,179.376Z"
                           transform="translate(-44.182 -170.667)"
                           fill="#434343"
                        />
                     </g>
                  </g>
                  <g
                     id="Group_452"
                     data-name="Group 452"
                     transform="translate(10.886 102.751)"
                  >
                     <g id="Group_451" data-name="Group 451">
                        <path
                           id="Path_221"
                           data-name="Path 221"
                           d="M120.818,256a14.169,14.169,0,0,0-14.153,14.152,1.089,1.089,0,1,0,2.177,0,11.975,11.975,0,0,1,23.95,0,1.089,1.089,0,1,0,2.177,0A14.169,14.169,0,0,0,120.818,256Z"
                           transform="translate(-106.666 -256)"
                           fill="#434343"
                        />
                     </g>
                  </g>
                  <g
                     id="Group_454"
                     data-name="Group 454"
                     transform="translate(34.682 107.106)"
                  >
                     <g
                        id="Group_453"
                        data-name="Group 453"
                        transform="translate(0 0)"
                     >
                        <path
                           id="Path_222"
                           data-name="Path 222"
                           d="M345.411,298.667a9.8,9.8,0,0,0-5.075,1.415,1.089,1.089,0,1,0,1.13,1.862,7.622,7.622,0,0,1,11.566,6.521,1.089,1.089,0,0,0,2.177,0A9.81,9.81,0,0,0,345.411,298.667Z"
                           transform="translate(-339.813 -298.667)"
                           fill="#434343"
                        />
                     </g>
                  </g>
                  <g
                     id="Group_456"
                     data-name="Group 456"
                     transform="translate(0 107.106)"
                  >
                     <g
                        id="Group_455"
                        data-name="Group 455"
                        transform="translate(0 0)"
                     >
                        <path
                           id="Path_223"
                           data-name="Path 223"
                           d="M14.871,300.08A9.8,9.8,0,0,0,0,308.465a1.089,1.089,0,0,0,2.177,0,7.623,7.623,0,0,1,11.564-6.523,1.089,1.089,0,1,0,1.13-1.862Z"
                           transform="translate(0 -298.667)"
                           fill="#434343"
                        />
                     </g>
                  </g>
               </g>
            </svg>
            <span>Students</span>
         </a>
         <a
            routerLink="statistics"
            routerLinkActive="active"
            (click)="toggler()" title="Dashboard"
            ><svg
               id="statistics-icon"
               xmlns="http://www.w3.org/2000/svg"
               width="40.214"
               height="36.2"
               viewBox="0 0 40.214 36.2"
            >
               <g id="graph" transform="translate(0 -25.55)">
                  <g
                     id="Group_2"
                     data-name="Group 2"
                     transform="translate(0 25.55)"
                  >
                     <path
                        id="Path_6"
                        data-name="Path 6"
                        d="M36.2,25.55a4.011,4.011,0,0,0-2.891,6.8l-7.345,14.7A3.3,3.3,0,0,0,25.476,47a4.012,4.012,0,0,0-1.516.3l-5.7-6.418a4.013,4.013,0,1,0-7.526-1.933,3.991,3.991,0,0,0,.99,2.608L4.737,53.776a3.676,3.676,0,0,0-.715-.071,4.078,4.078,0,1,0,3.032,1.414L14.031,42.9a3.782,3.782,0,0,0,2.223-.228l5.7,6.418a4.018,4.018,0,1,0,6.4-.848l7.345-14.7a3.3,3.3,0,0,0,.487.047A4.022,4.022,0,0,0,36.2,25.55Z"
                        transform="translate(0 -25.55)"
                        fill="#434343"
                     />
                  </g>
               </g></svg
            ><span>Dashboard</span>
         </a>

         <a routerLink="profile" routerLinkActive="active" (click)="toggler()" title="Account"
            ><svg
               id="profile-icon"
               xmlns="http://www.w3.org/2000/svg"
               width="38.334"
               height="42.826"
               viewBox="0 0 38.334 42.826"
            >
               <g id="user" transform="translate(-24.165)">
                  <g
                     id="Group_20"
                     data-name="Group 20"
                     transform="translate(32.216)"
                  >
                     <g id="Group_19" data-name="Group 19">
                        <path
                           id="Path_14"
                           data-name="Path 14"
                           d="M121.91,0a11.119,11.119,0,1,0,11.119,11.119A11.149,11.149,0,0,0,121.91,0Z"
                           transform="translate(-110.791)"
                           fill="#434343"
                        />
                     </g>
                  </g>
                  <g
                     id="Group_22"
                     data-name="Group 22"
                     transform="translate(24.165 23.034)"
                  >
                     <g
                        id="Group_21"
                        data-name="Group 21"
                        transform="translate(0)"
                     >
                        <path
                           id="Path_15"
                           data-name="Path 15"
                           d="M62.417,255.931a10.366,10.366,0,0,0-1.117-2.039,13.821,13.821,0,0,0-9.565-6.021,2.047,2.047,0,0,0-1.408.34,11.852,11.852,0,0,1-13.984,0,1.822,1.822,0,0,0-1.408-.34,13.721,13.721,0,0,0-9.565,6.021,11.952,11.952,0,0,0-1.117,2.039,1.033,1.033,0,0,0,.049.923,19.372,19.372,0,0,0,1.311,1.942,18.457,18.457,0,0,0,2.234,2.525,29.108,29.108,0,0,0,2.234,1.942,22.142,22.142,0,0,0,26.414,0,21.351,21.351,0,0,0,2.234-1.942,22.424,22.424,0,0,0,2.234-2.525,17.026,17.026,0,0,0,1.311-1.942A.829.829,0,0,0,62.417,255.931Z"
                           transform="translate(-24.165 -247.841)"
                           fill="#434343"
                        />
                     </g>
                  </g>
               </g></svg
            ><span>Account</span>
         </a>
         <a
            routerLink="help-center"
            routerLinkActive="active" 
            (click)="toggler()" title="Help Center"
            ><svg
               id="help-center-icon"
               xmlns="http://www.w3.org/2000/svg"
               width="41.771"
               height="34.602"
               viewBox="0 0 41.771 34.602"
            >
               <g id="helPCenter" transform="translate(-169 -202.512)">
                  <path
                     id="Path_27"
                     data-name="Path 27"
                     d="M257.382,255.7a13.127,13.127,0,1,1-3.726,25.713.99.99,0,0,0-1.011.188,13.11,13.11,0,0,1-6.816,2.462c-.84.082-1.7.073-2.544.082-.359,0-.807-.082-.791-.522a1.043,1.043,0,0,1,.571-.685,8.341,8.341,0,0,0,3.669-4.19,2.168,2.168,0,0,0-.228-2.6,11.568,11.568,0,0,1-2.112-5.756,12.243,12.243,0,0,1,.294-4.68A13.04,13.04,0,0,1,257.382,255.7Zm-5.96,13.167a1.22,1.22,0,0,0,1.207,1.141,1.2,1.2,0,0,0,1.15-1.125,1.122,1.122,0,0,0-1.052-1.19A1.234,1.234,0,0,0,251.422,268.866Zm7.125-.049a1.089,1.089,0,0,0-1.166-1.125,1.135,1.135,0,0,0-1.15,1.158,1.214,1.214,0,0,0,1.117,1.158A1.178,1.178,0,0,0,258.548,268.817Zm3.538,1.174a1.182,1.182,0,0,0,1.223-1.1,1.152,1.152,0,0,0-1.06-1.2,1.272,1.272,0,0,0-1.3.97A1.255,1.255,0,0,0,262.086,269.991Z"
                     transform="translate(-67.503 -48.851)"
                     fill="#434343"
                  />
                  <path
                     id="Path_28"
                     data-name="Path 28"
                     d="M175.326,225.388a28.976,28.976,0,0,1-4.044-.041,2.836,2.836,0,0,1-2.258-2.837q-.049-3.608,0-7.231a3.061,3.061,0,0,1,2.813-2.935,11.213,11.213,0,0,1,1.655-.016.921.921,0,0,0,1-.587,17.079,17.079,0,0,1,11.381-8.748,17.355,17.355,0,0,1,19.371,8.723.951.951,0,0,0,1.052.611,11.265,11.265,0,0,1,1.484,0,3.085,3.085,0,0,1,2.968,3.033c.024,2.372.033,4.737,0,7.109a3.092,3.092,0,0,1-3.041,3.025c-.652.016-1.3-.024-1.957.024a.77.77,0,0,0-.546.367,16.414,16.414,0,0,1-11.65,10.036.86.86,0,0,0-.489.408,1.3,1.3,0,0,1-1.223.783c-1.011,0-2.014.008-3.025,0a1.244,1.244,0,1,1-.033-2.487c1.027-.016,2.054-.008,3.082,0a.891.891,0,0,1,.563.13c.448.481.938.277,1.419.13,6.3-1.924,10.232-6.066,11.316-12.6a15.484,15.484,0,1,0-30.018,2.487C175.212,224.988,175.261,225.176,175.326,225.388Z"
                     fill="#434343"
                  />
               </g></svg
            ><span>Help Center</span></a
         >
      </nav>
      <div style="width: 100%; float: right">
         <router-outlet></router-outlet>
      </div>
   </section>
</ng-container>
