import { createFeatureSelector, createSelector } from '@ngrx/store';

import { FiltersState } from '../reducer/filters.reducer';

/**
 * Selectors are pure functions used for obtaining slices of store state.
 */

const getFiltersState = createFeatureSelector<FiltersState>(
   'administratorFilters'
);

/**
 * Selector used to get filters details state.
 */
export const getFiltersStateDetails = createSelector(
   getFiltersState,
   (state) => state
);
