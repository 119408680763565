// *** Angular
import { Component, OnInit } from '@angular/core';

@Component({
   selector: 'app-statistics-dashboard',
   templateUrl: './statistics-dashboard.component.html',
   styleUrls: ['./statistics-dashboard.component.scss'],
})
export class StatisticsDashboardComponent implements OnInit {
   constructor() {}

   ngOnInit(): void {}
}
