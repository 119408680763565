import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, catchError, concatMap } from 'rxjs/operators';
import * as professorsSubmissionsActions from '../action/professors-submissions.actions';
import { AdministratorStatisticsService } from '../../../../../../services/administrator-statistics.service';

@Injectable()
export class ProfessorsSubmissionsEffects {
   constructor(
      private actions$: Actions,
      private administratorStatistics: AdministratorStatisticsService
   ) {}

   /**
    * Effect listening for ProfessorsSubmissionsStatistics Action, when ProfessorsSubmissionsStatistics is dispatched the effect with call create() from courseService.
    * In case of success response the effect it will trigger ProfessorsSubmissionsStatisticsSuccess.
    * In case of Error it will trigger ProfessorsSubmissionsStatisticsFailure with error property.
    */
   ProfessorsSubmissionsStatistics$ = createEffect(() => {
      return this.actions$.pipe(
         ofType(
            professorsSubmissionsActions.loadProfessorsSubmissionsStatistics
         ),
         concatMap((action) => {
            return this.administratorStatistics
               .professorsSubmissionsStatistics(
                  action.page,
                  action.facultyId,
                  action.departmentId,
                  action.professorId,
                  action.submissionName
               )
               .pipe(
                  map((data) => {
                     return professorsSubmissionsActions.loadProfessorsSubmissionsStatisticsSuccess(
                        {
                           data: data,
                        }
                     );
                  }),
                  catchError((error) =>
                     of(
                        professorsSubmissionsActions.loadProfessorsSubmissionsStatisticsFailure(
                           {
                              error,
                           }
                        )
                     )
                  )
               );
         })
      );
   });
}
