// *** Angular
import { Component, OnInit } from '@angular/core';

@Component({
   selector: 'app-expand-offer',
   templateUrl: './expand-offer.component.html',
   styleUrls: ['./expand-offer.component.scss'],
})
export class ExpandOfferComponent implements OnInit {
   constructor() {}

   ngOnInit(): void {}
}
