// *** Angular
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { first } from 'rxjs/operators';

// *** Actions
import * as assignmentSubmissionsActions from '../state/action/assignment-submissions.actions';
import * as facultyActions from '../../../../state/faculty/action/faculty.actions';
import * as professorActions from '../../../../../administrator-components/state/professor/action/professor.actions';
import * as filtersActions from '../../../../../administrator-components/state/filters/action/filters.actions';
import { environment } from 'src/environments/environment';

// *** Selectors
import { getAssignmentSubmissionsDetails } from '../state/selector/assignment-submissions.selectors';
import { getInstitutionFacultiesStateDetails } from '../../../../state/faculty/selector/faculty.selectors';
import { getInstitutionProfessorsStateDetails } from '../../../../../administrator-components/state/professor/selector/professor.selectors';
import { getFiltersStateDetails } from '../../../../../administrator-components/state/filters/selector/filters.selectors';

// *** Services
import { DepartmentService } from 'src/app/services/department.service';
@Component({
   selector: 'app-assignment-submissions',
   templateUrl: './assignment-submissions.component.html',
   styleUrls: ['./assignment-submissions.component.scss'],
})
export class AssignmentSubmissionsComponent implements OnInit, OnDestroy {
   /**
    * Variable is used to store departments of selected faculty
    */
   departments: any;
   /**
    * Variable is used to store facultyId to filter submissions of assignments within specific faculty.
    */
   facultyId = '-1';
   /**
    * Variable is used to store departmentId to filter submissions of assignments within specific department.
    */
   departmentId = '-1';
   /**
    * Variable used to store submission name to filter submissions by name
    */
   submissionName: string;
   /**
    * Variable used to store assignment name to filter submissions by assignment name
    */
   assignmentName: string;
   /**
    * Varibale used to store student name that used to filter submissions of specific students
    */
   studentName: string;
   /**
    * Variable used to store assignment type that has been used to filter assignments
    */
   assignmentType = '-1';
   /**
    * Variable used to store professor id that used to retrieve assignments of specific professor
    */
   professorId: any;
   /**
    * Variable used to store subject name that has been used to filter assignments by a subject
    */
   subjectName: string;
   getFiltersState = true;
   /**
    * Variable used to store information that a thesis is been clicked for expand or not
    */
   expandInfo: any;
   /**
    * Variable used to store submissions
    */
   submissions;
   /**
    * Variable used to store student id that used to expand specific row on HTML
    */
   studentId;
   /**
    * Symbolizing the page of pagination
    */
   page: number = 1;
   /**
    * Timer used for search delay
    */
   timer: any;
   /**
    * Variable used to store number of submissions
    */
   submissionsNumber;
   /**
    * Variabels used to retrieve faculties of insitution from store.
    */
   faculties;
   faculties$;
   /**
    * Variabels used to retrieve professors of insitution from store.
    */
   professors;
   professors$;
   getFiltersStateDetails$: any;
   getInstitutionFacultiesStateDetails$: any;
   getInstitutionProfessorsStateDetails$: any;
   getAssignmentSubmissionsDetails$: any;
   constructor(
      private store: Store,
      private departmentService: DepartmentService
   ) {}
   ngOnDestroy(): void {
      this.getFiltersStateDetails$.unsubscribe();
      this.getInstitutionFacultiesStateDetails$.unsubscribe();
      this.getInstitutionProfessorsStateDetails$.unsubscribe();
      this.getAssignmentSubmissionsDetails$.unsubscribe();
   }

   ngOnInit(): void {
      this.getFiltersStateDetails$ = this.store
         .select(getFiltersStateDetails)
         .subscribe((data) => {
            if (data.assignmentSubmissionsFilters.faculty != null) {
               this.facultyId = data.assignmentSubmissionsFilters.faculty;
               this.departmentService
                  .getDepartmentsOfFaculty(this.facultyId)
                  .pipe(first())
                  .subscribe(
                     (data) => {
                        this.departments = data.departments;
                     },
                     (error) => {
                        console.log(error);
                     }
                  );
            }
            if (data.assignmentSubmissionsFilters.department != null) {
               this.departmentId = data.assignmentSubmissionsFilters.department;
            }
            if (data.assignmentSubmissionsFilters.subject != null) {
               this.subjectName = data.assignmentSubmissionsFilters.subject;
            }
            if (data.assignmentSubmissionsFilters.assignment != null) {
               this.assignmentName =
                  data.assignmentSubmissionsFilters.assignment;
            }
            if (data.assignmentSubmissionsFilters.student != null) {
               this.studentName = data.assignmentSubmissionsFilters.student;
            }
         });

      this.store.dispatch(
         assignmentSubmissionsActions.loadAssignmentSubmissionsStatistics({
            page: this.page,
            facultyId: this.facultyId == '-1' ? undefined : this.facultyId,
            departmentId:
               this.departmentId == '-1' ? undefined : this.departmentId,
            submissionName: this.submissionName,
            assignmentName: this.assignmentName,
            studentName: this.studentName,
            assignmentType:
               this.assignmentType == '-1' ? undefined : this.assignmentType,
            subjectName: this.subjectName,
         })
      );

      this.store.dispatch(facultyActions.loadFacultiesDetails({ page: 0 }));

      this.getInstitutionFacultiesStateDetails$ = this.store
         .select(getInstitutionFacultiesStateDetails)
         .subscribe((data) => {
            if (data.faculties !== null && this.faculties == undefined) {
               this.faculties = data.faculties;
            }
         });

      this.store.dispatch(professorActions.loadProfessorsDetails({ page: 0 }));

      this.getInstitutionProfessorsStateDetails$ = this.store
         .select(getInstitutionProfessorsStateDetails)
         .subscribe((data) => {
            if (data !== null) {
               this.professors = data.professors;
            }
         });

      this.getAssignmentSubmissionsDetails$ = this.store
         .select(getAssignmentSubmissionsDetails)
         .subscribe((data) => {
            if (data !== null) {
               this.submissions = data.assignmentSubmissions;
               this.submissionsNumber = data.submissionsNumber;
            }
         });
   }
   /**
    * Function is used to filter by title
    */
   filterWithTimeout() {
      this.getFiltersState = false;
      this.store.dispatch(
         filtersActions.assignmentSubmissionsFilters({
            subject: this.subjectName,
            assignment: this.assignmentName,
            student: this.studentName,
            department: this.departmentId,
            faculty: this.facultyId,
         })
      );
      let time;
      time = 500;
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
         this.store.dispatch(
            assignmentSubmissionsActions.loadAssignmentSubmissionsStatistics({
               page: this.page,
               facultyId: this.facultyId == '-1' ? undefined : this.facultyId,
               departmentId:
                  this.departmentId == '-1' ? undefined : this.departmentId,
               submissionName: this.submissionName,
               assignmentName: this.assignmentName,
               studentName: this.studentName,
               assignmentType:
                  this.assignmentType == '-1' ? undefined : this.assignmentType,
               subjectName: this.subjectName,
            })
         );
      }, time);
   }
   /**
    * Function is used to filter by selected Faculty
    */
   selectedFaculty() {
      this.getFiltersState = false;
      this.departmentId = '-1';
      this.store.dispatch(
         filtersActions.assignmentSubmissionsFilters({
            subject: this.subjectName,
            assignment: this.assignmentName,
            student: this.studentName,
            department: this.departmentId,
            faculty: this.facultyId,
         })
      );
      this.store.dispatch(
         assignmentSubmissionsActions.loadAssignmentSubmissionsStatistics({
            page: this.page,
            facultyId: this.facultyId == '-1' ? undefined : this.facultyId,
            departmentId:
               this.departmentId == '-1' ? undefined : this.departmentId,
            submissionName: this.submissionName,
            assignmentName: this.assignmentName,
            studentName: this.studentName,
            assignmentType:
               this.assignmentType == '-1' ? undefined : this.assignmentType,
            subjectName: this.subjectName,
         })
      );

      if (this.facultyId !== '') {
         this.departmentService
            .getDepartmentsOfFaculty(this.facultyId)
            .pipe(first())
            .subscribe(
               (data) => {
                  this.departments = data.departments;
               },
               (error) => {
                  console.log(error);
               }
            );
      }
   }
   /**
    * Function is used to filter by selected department
    */
   selectedDepartment() {
      this.getFiltersState = false;
      this.store.dispatch(
         filtersActions.assignmentSubmissionsFilters({
            subject: this.subjectName,
            assignment: this.assignmentName,
            student: this.studentName,
            department: this.departmentId,
            faculty: this.facultyId,
         })
      );
      this.store.dispatch(
         assignmentSubmissionsActions.loadAssignmentSubmissionsStatistics({
            page: this.page,
            facultyId: this.facultyId == '-1' ? undefined : this.facultyId,
            departmentId:
               this.departmentId == '-1' ? undefined : this.departmentId,
            submissionName: this.submissionName,
            assignmentName: this.assignmentName,
            studentName: this.studentName,
            assignmentType:
               this.assignmentType == '-1' ? undefined : this.assignmentType,
            subjectName: this.subjectName,
         })
      );
   }
   /**
    * Function is used to filter by assignment type
    * @param type
    */
   filterByType(type) {
      this.assignmentType = type;
      this.store.dispatch(
         assignmentSubmissionsActions.loadAssignmentSubmissionsStatistics({
            page: this.page,
            facultyId: this.facultyId == '-1' ? undefined : this.facultyId,
            departmentId:
               this.departmentId == '-1' ? undefined : this.departmentId,
            submissionName: this.submissionName,
            assignmentName: this.assignmentName,
            studentName: this.studentName,
            assignmentType:
               this.assignmentType == '-1' ? undefined : this.assignmentType,
            subjectName: this.subjectName,
         })
      );
   }

   /**
    * Method used to change pagination page. It dispatches an action with page as param.
    * @param event
    */
   changePage(event): void {
      this.page = event;
      this.store.dispatch(
         assignmentSubmissionsActions.loadAssignmentSubmissionsStatistics({
            page: this.page,
            facultyId: this.facultyId == '-1' ? undefined : this.facultyId,
            departmentId:
               this.departmentId == '-1' ? undefined : this.departmentId,
            submissionName: this.submissionName,
            assignmentName: this.assignmentName,
            studentName: this.studentName,
            assignmentType:
               this.assignmentType == '-1' ? undefined : this.assignmentType,
            subjectName: this.subjectName,
         })
      );
   }
   /**
    * Function is used to exapnd information
    * @param studentId
    */
   expand(studentId) {
      this.studentId = studentId;
      if (this.expandInfo !== studentId) {
         this.expandInfo = studentId;
      } else {
         this.expandInfo = -1;
      }
   }

   goToLink(id: string): void {
      let url = `${environment.currentUrl}/administrator/submission/${id}/report`;
      window.open(url, '_blank');
   }
   /**
    * Function is used to reset filters
    */
   resetFilter() {
      this.page = 1;
      this.facultyId = '-1';
      this.departmentId = '-1';
      this.assignmentType = '-1';
      this.subjectName = undefined;
      this.submissionName = undefined;
      this.assignmentName = undefined;
      this.studentName = undefined;
      this.store.dispatch(
         filtersActions.assignmentSubmissionsFilters({
            subject: this.subjectName,
            assignment: this.assignmentName,
            student: this.studentName,
            department: this.departmentId,
            faculty: this.facultyId,
         })
      );
      this.store.dispatch(
         assignmentSubmissionsActions.loadAssignmentSubmissionsStatistics({
            page: this.page,
            facultyId: this.facultyId == '-1' ? undefined : this.facultyId,
            departmentId:
               this.departmentId == '-1' ? undefined : this.departmentId,
            submissionName: this.submissionName,
            assignmentName: this.assignmentName,
            studentName: this.studentName,
            assignmentType:
               this.assignmentType == '-1' ? undefined : this.assignmentType,
            subjectName: this.subjectName,
         })
      );
   }
}
