<div class="documents-2 shadow white-bg">
   <h3
      *ngIf="
         !(
            this.professorsNumber == 0 &&
            professorName == undefined &&
            facultyId == '-1' &&
            departmentId == '-1' &&
            professorSubject == undefined
         )
      "
      class="filtersContainer"
   >
      <h4>Overview</h4>
      <div class="selectors">
         <div>
            <select [(ngModel)]="facultyId" (change)="selectedFaculty()">
               <option value="-1" selected disabled>
                  Filter by school
               </option>
               <ng-container *ngFor="let faculty of this.faculties">
                  <option value="{{ faculty.id }}">{{ faculty.name }}</option>
               </ng-container>
               <option value="">All</option>
            </select>
         </div>
         <div>
            <select
               disabled
               *ngIf="facultyId == '-1' || facultyId == ''"
               name=""
               id=""
            >
               <option value="">Select school first</option>
            </select>
            <select
               [(ngModel)]="departmentId"
               *ngIf="facultyId !== '-1' && facultyId !== ''"
               (change)="selectedDepartment()"
            >
               <option value="-1" selected disabled>
                  Filter by department
               </option>
               <ng-container *ngFor="let department of this.departments">
                  <option value="{{ department.id }}">
                     {{ department.name }}
                  </option>
               </ng-container>
               <option value="">All</option>
            </select>
         </div>
         <div>
            <input
               placeholder="Search by subject"
               [(ngModel)]="professorSubject"
               (ngModelChange)="filterWithTimeout()"
               type="text"
               name="searchByName"
            />
         </div>
         <div>
            <input
               placeholder="Search by name"
               [(ngModel)]="professorName"
               (ngModelChange)="filterWithTimeout()"
               type="text"
               name="searchByName"
            />
         </div>
         <button class="reportBtn" (click)="resetFilter()">Reset</button>
      </div>
   </h3>

   <div
      *ngIf="this.professorsNumber != 0"
      class="tableWithExpandedInfoAssignments"
   >
      <ng-container
         *ngFor="
            let professor of this.professors
               | paginate
                  : {
                       itemsPerPage: 5,
                       currentPage: this.page,
                       totalItems: this.professorsNumber
                    };
            let i = index
         "
      >
         <div>
            <div>
               <h4 class="blueText bold">
                  {{ professor.professorInfo.name }}
               </h4>
               <div>
                  <div>
                     <span>School</span>
                     <span>Department</span>
                     <span>Subjects</span>
                     <span>Assignments</span>
                     <span>Submissions</span>
                     <!-- <span>Submissions Checked</span> -->
                     <span>Theses</span>
                     <!-- <span>Students</span> -->
                  </div>
                  <div>
                     <span class="bold">{{ professor.faculty.name }}</span>
                     <span class="bold">{{ professor.department.name }}</span>
                     <span
                        (click)="subjectsFilter(professor.id)"
                        class="bold clickable"
                        >{{ professor.subjects }}</span
                     >
                     <span
                        (click)="assignmentFilter(professor.id)"
                        class="bold clickable"
                        >{{ professor.assignments }}</span
                     >
                     <span (click)="submissionsFilter(professor.id)" class="bold clickable">{{
                        professor.totalSubmissions
                     }}</span>
                     <!-- <span class="bold">{{
                        professor.submissionsChecked
                     }}</span> -->
                     <span
                        (click)="thesisFilter(professor.id)"
                        class="bold clickable"
                        >{{
                           professor.bachelor + professor.master + professor.phd
                        }}</span
                     >
                     <!-- <span class="bold">{{
                        professor.students
                     }}</span> -->
                  </div>
               </div>
               <!-- <a
                  *ngIf="expandInfo !== i"
                  class="blueText underline vold"
                  (click)="expand(i)"
                  >Expand</a
               >
               <a
                  *ngIf="expandInfo === i"
                  class="blueText underline vold"
                  (click)="expand(i)"
                  >Collapse</a
               > -->
            </div>
            <div
               [ngClass]="{ expandedInfo: expandInfo == i }"
               class="moreInfo"
               style="grid-template: none !important"
            >
               <div style="display: flex; justify-content: space-between">
                  <div style="grid-template: none">
                     <span>
                        Students:
                        <b>{{ professor.students }}</b></span
                     >
                     <span>
                        Assignments per month:
                        <b>{{ professor.avgAssignmentsMonth }}</b></span
                     >
                     <span>
                        Avg. Words per Assignment:
                        <b>{{
                           (
                              professor.submissions._avg.wordCount /
                              professor.assignments
                           ).toFixed(2)
                        }}</b>
                     </span>
                  </div>
                  <div style="grid-template: none">
                     <span>
                        Evaluation rate:
                        <b>{{ professor.evaluationRate }}%</b>
                     </span>
                     <!-- <span>
                        Average grade:
                        <b>75%</b>
                     </span> -->
                  </div>

                  <div style="grid-template: none">
                     <span>
                        Bachelor theses:
                        <b>{{ professor.bachelor }}</b></span
                     >
                     <span>
                        Master theses:
                        <b>{{ professor.master }}</b></span
                     >
                     <span>
                        Ph.D theses:
                        <b>{{ professor.phd }}</b></span
                     >
                  </div>

                  <div style="grid-template: none">
                     <span>
                        High similarity submissions:
                        <b>{{ professor.highSimilarity }}</b></span
                     >
                     <span>
                        Medium similarity submissions:
                        <b>{{ professor.mediumSimilarity }}</b></span
                     >
                     <span>
                        Low similarity submissions:
                        <b>{{ professor.lowSimilarity }}</b></span
                     >
                  </div>
               </div>
            </div>
         </div>
      </ng-container>
   </div>
   <div
      class="zeroState"
      *ngIf="
         this.professorsNumber == 0 &&
         (professorName != undefined ||
            facultyId != '-1' ||
            departmentId != '-1' ||
            professorSubject != undefined)
      "
   >
      No users found
   </div>
   <pagination-controls
      *ngIf="this.professorsNumber != 0"
      class="num"
      (pageChange)="changePage($event)"
      responsive="true"
      previousLabel=""
      nextLabel=""
   ></pagination-controls>
   <div
      class="zero-state"
      *ngIf="
         this.professorsNumber == 0 &&
         professorName == undefined &&
         facultyId == '-1' &&
         departmentId == '-1' &&
         professorSubject == undefined
      "
   >
      <!-- <img src="../../assets/images/icona-image.png" alt="" /> -->
      <h5 class="bold" style="z-index: 1;">There are no educators registered in your institution.
         <a
           routerLink ="/administrator/register-module/professor-register"
           routerLinkAction="active">Click here</a>
          to begin registering educators.</h5>
   </div>
</div>
