import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, catchError, concatMap } from 'rxjs/operators';
import * as facultiesActions from '../action/units-statistics-faculties.actions';
import { AdministratorStatisticsService } from '../../../../../../services/administrator-statistics.service';

@Injectable()
export class UnitsStatisticsFaculties {
   constructor(
      private actions$: Actions,
      private administratorStatistics: AdministratorStatisticsService
   ) {}

   /**
    * Effect listening for facultiesStatistics Action, when facultiesStatistics is dispatched the effect with call create() from courseService.
    * In case of success response the effect it will trigger facultiesStatisticsSuccess.
    * In case of Error it will trigger facultiesStatisticsFailure with error property.
    */
   facultiesStatistics$ = createEffect(() => {
      return this.actions$.pipe(
         ofType(facultiesActions.loadUnitsStatisticsFaculties),
         concatMap((action) => {
            return this.administratorStatistics
               .academicUnitsFacultiesStatistics(action.page, action.title)
               .pipe(
                  map((data) => {
                     return facultiesActions.loadUnitsStatisticsFacultiesSuccess(
                        {
                           data: data,
                        }
                     );
                  }),
                  catchError((error) =>
                     of(
                        facultiesActions.loadUnitsStatisticsFacultiesFailure({
                           error,
                        })
                     )
                  )
               );
         })
      );
   });
}
