import { ReportEffects } from './state/effects/report.effects';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ReportComponent } from './report/report.component';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { AddCreditsComponent } from './add-credits/add-credits.component';
import { HelpCenterComponent } from './help-center/help-center.component';
import { HowToUseComponent } from './help-center/how-to-use/how-to-use.component';
import { FaqsComponent } from './help-center/faqs/faqs.component';
import { ContactUsComponent } from './help-center/contact-us/contact-us.component';
import { NavbarComponent } from './navbar/navbar.component';
import { HeaderComponent } from './header/header.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { NotAuthorizedComponent } from './notauthorized/not-authorized.component';
import { Routes, RouterModule } from '@angular/router';
import { SettingsComponent } from './profile/settings/settings.component';
import { BillingInformationComponent } from './profile/billing-information/billing-information.component';
import { PersonalInformationComponent } from './profile/personal-information/personal-information.component';
import { ProfileComponent } from './profile/profile.component';
import { TransactionComponent } from './profile/transaction/transaction.component';
import { TranslateModule } from '@ngx-translate/core';
import { SentenceInformationModalComponent } from './report/sentence-information-modal/sentence-information-modal.component';
import { reportReducer } from './state/reducer/report.reducer';
import { MatDialogModule } from '@angular/material/dialog';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgxMarkjsModule } from 'ngx-markjs';
import { NgxPaginationModule } from 'ngx-pagination';
import { ContactUsBankComponent } from './add-credits/contact-us-bank/contact-us-bank.component';
import { NavbarAdministratorComponent } from './navbar-administrator/navbar-administrator.component';
import { UploadComponent } from './upload/upload.component';
import { InputTextComponent } from './upload/input-text/input-text.component';
import { UploadFileComponent } from './upload/upload-file/upload-file.component';
import { WebImportComponent } from './upload/web-import/web-import.component';
import { NavbarIndividualAdministratorComponent } from './navbar-individual-administrator/navbar-individual-administrator.component';
import { SourcePreviewComponent } from './report/sourcePreview/source-preview/source-preview.component';
import { NavbarStudentComponent } from './navbar-student/navbar-student.component';
import { DeleteModalComponent } from './report/delete-modal/delete-modal.component';
import { ProfessorDeleteModalComponent } from './report/professor-delete-modal/professor-delete-modal.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxFileDropModule } from 'ngx-file-drop';
import { FilePreviewComponent } from './upload/file-preview/file-preview.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

const sharedRoutes: Routes = [
   {
      path: 'add-credits',
      component: AddCreditsComponent,
      children: [{ path: 'contact-us', component: ContactUsBankComponent }],
   },
   {
      path: 'submission/:id/report',
      component: ReportComponent,
   },
   {
      path: 'profile',
      component: ProfileComponent,
      children: [
         { path: '', pathMatch: 'full', redirectTo: 'personal-information' },
         {
            path: 'personal-information',
            component: PersonalInformationComponent,
         },
         {
            path: 'settings',
            component: SettingsComponent,
         },
         {
            path: 'billing-information',
            component: BillingInformationComponent,
         },
         {
            path: 'transaction',
            component: TransactionComponent,
         },
      ],
   },
   {
      path: 'help-center',
      component: HelpCenterComponent,
      children: [
         { path: '', pathMatch: 'full', redirectTo: 'how-to-use' },
         {
            path: 'how-to-use',
            component: HowToUseComponent,
         },
         {
            path: 'settings',
            component: SettingsComponent,
         },
         { path: 'faqs', component: FaqsComponent, data: { roles: '2' } },
         {
            path: 'contact-us',
            component: ContactUsComponent,
         },
      ],
   },

   {
      path: 'upload',
      component: UploadComponent,
      children: [
         { path: '', pathMatch: 'full', redirectTo: 'upload-file' },
         {
            path: 'upload-file',
            component: UploadFileComponent,
         },
         {
            path: 'web-import',
            component: WebImportComponent,
         },
         {
            path: 'text-input',
            component: InputTextComponent,
         },
      ],
   },
];
@NgModule({
   declarations: [
      HeaderComponent,
      NavbarComponent,
      NavbarAdministratorComponent,
      AddCreditsComponent,
      HelpCenterComponent,
      HowToUseComponent,
      FaqsComponent,
      ContactUsComponent,
      NotAuthorizedComponent,
      NotFoundComponent,
      ReportComponent,
      SentenceInformationModalComponent,
      ContactUsBankComponent,
      UploadComponent,
      InputTextComponent,
      UploadFileComponent,
      WebImportComponent,
      NavbarIndividualAdministratorComponent,
      SourcePreviewComponent,
      NavbarStudentComponent,
      DeleteModalComponent,
      ProfessorDeleteModalComponent,
      FilePreviewComponent,
   ],
   imports: [
      CommonModule,
      TranslateModule,
      MatDialogModule,
      NgxPaginationModule,
      RouterModule.forChild(sharedRoutes),
      StoreModule.forFeature('currentSubmission', reportReducer),
      EffectsModule.forFeature([ReportEffects]),
      PdfViewerModule,
      NgxMarkjsModule,
      FormsModule,
      ReactiveFormsModule,
      MatTooltipModule,
      NgxFileDropModule,
      MatProgressSpinnerModule
   ],
   exports: [TranslateModule],
})
export class SharedModule {}
