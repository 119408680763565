import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Observable, Subject } from 'rxjs';
/**
 * Tawk Service used to integrate tawk to angular.
 */
declare var Tawk_API: any;

@Injectable()
export class TawkService {
   // For more details refer to this : https://jamesmundy.net/writing/2020/10/3/integrating-tawkto-live-chat-into-an-angular-app
   /**
    * Check if the chat is loaded
    */
   private loaded: boolean;
   /**
    *  loadSubject is an Observable boolean that allows values to be multicasted to many Observers. Subjects are like EventEmitters.
    */
   private loadSubject: Subject<boolean> = new Subject<boolean>();

   /**
    * Extend this base class to implement custom rendering. By default, Angular renders a template into DOM.
    *  You can use custom rendering to intercept rendering calls, or to render to something other than DOM.
    */
   private renderer: Renderer2;
   /**
    * Service Constructor
    * @param rendererFactory
    * @param _document
    */
   constructor(
      private rendererFactory: RendererFactory2,
      @Inject(DOCUMENT) private _document: Document
   ) {
      this.renderer = rendererFactory.createRenderer(null, null);
      this.load();
   }
   /**
    * Method used to load tawk.
    */
   private load() {
      if (this.loaded) return;

      const s = this.renderer.createElement('script');
      const s0 = document.getElementsByTagName('script')[0];
      s.type = 'text/javascript';
      s.text = `var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
      (function(){
         var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
         s1.async=true;
         s1.src='https://embed.tawk.to/6048c1f8385de407571ea01c/1f0e3bcio';
         s1.charset='UTF-8';
         s1.setAttribute('crossorigin','*');
         s0.parentNode.insertBefore(s1,s0);
      })();`;
      this.renderer.appendChild(this._document.body, s);
      Tawk_API.onLoad = this.loadedEvent.bind(this);
   }
   /**
    * Used to load TawkApi.
    */
   private loadedEvent() {
      this.loaded = true;
      this.loadSubject.next(this.loaded);
   }
   /**
    * Method used to updated user attributes automatically.
    * @param user
    */
   public UpdateTawkUser(user: any) {
      this.loadedWrapper(() => {
         this.updateAtrributes(user);
      });
   }

   private loadedWrapper(func: any) {
      if (!this.loaded) {
         var sub = this.loadSubject.asObservable().subscribe({
            next: () => {
               func();
               sub.unsubscribe();
            },
            error: () => {},
         });
      } else {
         func();
      }
   }
   /**
    * Method used to expand tawk chat window
    * @param show
    */
   public ExpandChatWindow(show: boolean = false) {
      this.loadedWrapper(() =>
         show ? Tawk_API.maximize() : Tawk_API.minimize()
      );
   }
   /**
    * Used to change the visibility of the tawk chat
    * @param show
    */
   public SetChatVisibility(show: boolean = false) {
      this.loadedWrapper(() =>
         show ? Tawk_API.showWidget() : Tawk_API.hideWidget()
      );
   }
   /**
    * Used to update user attributes
    * @param user
    */
   private updateAtrributes(user: any) {
      Tawk_API.setAttributes(
         {
            name: `${user.name}`,
            email: user.email,
            id: user.id,
         },
         function (error) {}
      );
   }
}
