import { Component, Inject, OnInit } from '@angular/core';
import {
   MatDialog,
   MatDialogRef,
   MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { PDFDocumentProxy } from 'ng2-pdf-viewer';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
   selector: 'app-source-preview',
   templateUrl: './source-preview.component.html',
   styleUrls: ['./source-preview.component.scss'],
})
export class SourcePreviewComponent implements OnInit {
   pdfSrc;
   constructor(
      public dialogRef: MatDialogRef<SourcePreviewComponent>,
      public dialog: MatDialog,
      private spinner: NgxSpinnerService,
      /**
       * Used to pass data in component through dialog
       */
      @Inject(MAT_DIALOG_DATA) public data: any
   ) {}

   ngOnInit(): void {
      this.spinner.show();
      this.pdfSrc = this.data.presignedS3UrlPreview;
   }

   callBackFn(pdf: PDFDocumentProxy) {
      this.spinner.hide();
   }
}
