import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
   selector: 'app-individual-uploads',
   templateUrl: './individual-uploads.component.html',
   styleUrls: ['./individual-uploads.component.scss'],
})
export class IndividualUploadsComponent implements OnInit {
   /**
    * Component constructor
    * @param router
    * @param route
    */
   constructor(private router: Router, public route: ActivatedRoute) {}
   /**
    * Method used to be called whenever the component is loaded and all the methods in it, will be triggered.
    */
   ngOnInit(): void {}
}
