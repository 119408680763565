import { createAction, props } from '@ngrx/store';

/**
 * Action used to load professor subjects.
 */

export const loadProfessorSubjects = createAction(
   '[Subject] Load Professor Subjects',
   props<{
      data?: any;
      page?: any;
      status?: any;
      title?: any;
      generation?: any;
   }>()
);

/**
 * Action is called when there is a success status returned from API.
 */

export const loadProfessorSubjectsSuccess = createAction(
   '[Subject] Load Professor Subjects Success',
   props<{ data: any }>()
);

/**
 * Action is called when there is a failure status returned from API.
 */

export const loadProfessorSubjectsFailure = createAction(
   '[Subject] Load Professor Subjects Failure',
   props<{ error: any }>()
);

/**
 * Action used to register a subject.
 */

export const registerSubjects = createAction(
   '[Subject] Register Subject',
   props<{ data: any ;
    }>()
);

/**
 * Action is called when there is a success status returned from API.
 */

export const registerSubjectsSuccess = createAction(
   '[Subject] Register Subjects Success',
   props<{ data: any }>()
);

/**
 * Action is called when there is a failure status returned from API.
 */

export const registerSubjectsFailure = createAction(
   '[Subject] Register Subjects Failure',
   props<{ error: any }>()
);

/**
 * Action used to edit a subject.
 */

export const editSubjects = createAction(
   '[Subject] Edit Subject',
   props<{ data: any; id: any ;      page?: any;
      status?: any;
      title?: any;
      generation?: any; }>()
);

/**
 * Action is called when there is a success status returned from API.
 */

export const editSubjectsSuccess = createAction(
   '[Subject] Edit Subjects Success',
   props<{ data: any }>()
);

/**
 * Action is called when there is a failure status returned from API.
 */

export const editSubjectsFailure = createAction(
   '[Subject] Edit Subjects Failure',
   props<{ error: any }>()
);

export const deleteSubjects = createAction(
   '[Subject] Delete Subject',
   props<{ id: any ,       page?: any;
      status?: any;
      title?: any;
      generation?: any; }>()
);

/**
 * Action is called when there is a success status returned from API.
 */

export const deleteSubjectsSuccess = createAction(
   '[Subject] Delete Subjects Success',
   props<{ data: any }>()
);

/**
 * Action is called when there is a failure status returned from API.
 */

export const deleteSubjectsFailure = createAction(
   '[Subject] Delete Subjects Failure',
   props<{ error: any }>()
);

export const duplicateSubjects = createAction(
   '[Subject] Duplicate Subject',
   props<{ data: any; id: any ;      page?: any;
    status?: any;
    title?: any;
    generation?: any; }>()
);

/**
 * Action is called when there is a success status returned from API.
 */

export const duplicateSubjectsSuccess = createAction(
   '[Subject] Duplicate Subjects Success',
   props<{ data: any }>()
);

/**
 * Action is called when there is a failure status returned from API.
 */

export const duplicateSubjectsFailure = createAction(
   '[Subject] Duplicate Subjects Failure',
   props<{ error: any }>()
);

export const setSubjectRegisterToNull = createAction(
   '[Subject] Set Professor Subject Register To Null'
);
