import * as AppState from '../../../store/app.state';
import { Submissions } from '../../../models/submissions';
import { createReducer, on } from '@ngrx/store';
import * as submissionsActions from '../actions/submissions.actions';

/**
 * Submission reducer used to updated store values based on certain action.
 */

/**
 * State interface extends App state State and add user state to the main state.
 */
export interface State extends AppState.State {
   submissions: SubmissionsState;
}

/**
 * An Submission State interface, which presents the blueprint of the UserState.
 */
export interface SubmissionsState {
   totalSubmissions: number;
   userSuspiciousSubmissions: number;
   userTotalCredits: number;
   userUsedCredits: number;
   totalUserDocuments: number;
   totalUserDocumentsUsed: number;
   totalUserDrafts: number;
   totalUserDraftsUsed: number;
   totalUserSubmissions: number;
   totalUserSubmissionsAll: number;
   userReSubmissions: number;
   submissions: Submissions[];
   pages: any;
   submissionsNumber: number;
   submissionsStatistics: any;
   errorMessage: string;
}

/**
 * A constant that represent the initial state of Submissions State.
 */
export const initialState: SubmissionsState = {
   totalSubmissions: null,
   userSuspiciousSubmissions: null,
   userTotalCredits: null,
   userUsedCredits: null,
   totalUserDocuments: null,
   totalUserDocumentsUsed: null,
   totalUserDrafts: null,
   totalUserDraftsUsed: null,
   totalUserSubmissions: null,
   totalUserSubmissionsAll: null,
   userReSubmissions: null,
   submissions: null,
   pages: null,
   submissionsNumber: null,
   submissionsStatistics: null,
   errorMessage: null,
};

/**
 * Submissions Reducer used to update the state upon submissions actions.
 */
export const submissionsReducer = createReducer<SubmissionsState>(
   initialState,
   /**
    * On loadSubmissionsDetailsSuccess action updated current state with the following properties.
    */
   on(
      submissionsActions.loadSubmissionsDetailsSuccess,
      (state, action): SubmissionsState => {
         return {
            ...state,
            totalSubmissions: action.totalSubmissions,
            userSuspiciousSubmissions: action.userSuspiciousSubmissions,
            userTotalCredits: action.userTotalCredits,
            userUsedCredits: action.userUsedCredits,
            totalUserDocuments: action.totalUserDocuments,
            totalUserDocumentsUsed: action.totalUserDocumentsUsed,
            totalUserDrafts: action.totalUserDrafts,
            totalUserDraftsUsed: action.totalUserDraftsUsed,
            totalUserSubmissions: action.totalUserSubmissions,
            totalUserSubmissionsAll: action.totalUserSubmissionsAll,
            userReSubmissions: action.userReSubmissions,
         };
      }
   ),
   /**
    * On loadSubmissionsDetailsFailure action updated current state with the following properties.
    */
   on(
      submissionsActions.loadSubmissionsDetailsFailure,
      (state, action): SubmissionsState => {
         return {
            ...state,
            errorMessage: action.error,
         };
      }
   ),
   /**
    * On loadSubmissionsSuccess action updated current state with the following properties.
    */
   on(
      submissionsActions.loadSubmissionsSuccess,
      (state, action): SubmissionsState => {
         return {
            ...state,
            submissions: action.submissions,
            pages: action.pages,
            submissionsNumber: action.submissionsNumber,
         };
      }
   ),
   /**
    * On loadSubmissionsFailure action updated current state with the following properties.
    */
   on(
      submissionsActions.loadSubmissionsFailure,
      (state, action): SubmissionsState => {
         return {
            ...state,
            errorMessage: action.error,
         };
      }
   ),
   /**
    * On loadSubmissionsGraphSuccess action updated current state with the following properties.
    */
   on(
      submissionsActions.loadSubmissionsGraphSuccess,
      (state, action): SubmissionsState => {
         return {
            ...state,
            submissionsStatistics: action.submissionsGraph,
         };
      }
   ),

   /**
    * On loadSubmissionsGraphIndividualSuccess action updated current state with the following properties.
    */
   on(
      submissionsActions.loadSubmissionsGraphIndividualSuccess,
      (state, action): SubmissionsState => {
         return {
            ...state,
            submissionsStatistics: action.graph,
         };
      }
   ),
   /**
    * On loadSubmissionsGraphFailure action updated current state with the following properties.
    */
   on(
      submissionsActions.loadSubmissionsGraphFailure,
      (state, action): SubmissionsState => {
         return {
            ...state,
            errorMessage: action.error,
         };
      }
   ),
   /**
    * On deleteSubmissionSuccess action updated current state with the following properties.
    */
   on(submissionsActions.deleteSubmissionSuccess, (state, action) => {
      let updatedSubmissions = state.submissions.map((submission) => {
         let submissionCopy = { ...submission };
         if (submissionCopy.id == action.submission.id) {
            submissionCopy.showSubmission = !submissionCopy.showSubmission;
         }
         return submissionCopy;
      });

      return {
         ...state,
         submissions: updatedSubmissions,
      };
   }),
   /**
    * On deleteSubmissionFailure action updated current state with the following properties.
    */
   on(submissionsActions.deleteSubmissionFailure, (state, action) => {
      return {
         ...state,
         errorMessage: action.error,
      };
   })
);
