<div class="help-center">
   <main>
      <div>
         <h4 (click)="guide()">
            <a class="active" [ngClass]="{ active: guideIndex == 1 }">
               {{ 'help_center.how_to_use_crossplag.guide' | translate }}
            </a>
         </h4>
         <h4 (click)="video()">
            <a [ngClass]="{ active: videoIndex == 1 }">
               {{ 'help_center.how_to_use_crossplag.video' | translate }}
            </a>
         </h4>
      </div>
      <div *ngIf="guideIndex == 1" class="video">
         <ng-container>
            <div
               style="
                  display: block;
                  overflow: auto;
                  height: 100%;
                  scroll-behavior: smooth;
                  border-radius: 20px;
               "
            >
               <pdf-viewer
                  id="viewer"
                  [src]="pdfSrc"
                  [render-text]="true"
                  [fit-to-page]="true"
                  [zoom]="0.75"
               ></pdf-viewer>
            </div>
         </ng-container>
      </div>
      <div *ngIf="videoIndex == 1" class="video">
         <ng-container
            *ngIf="this.user.institutionId !== null && this.user.roleId === 6"
         >
            <iframe
               width="100%"
               height="100%"
               src="https://www.youtube.com/embed/iWAhGQCieSk"
               title="YouTube video player"
               frameborder="0"
               allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
               allowfullscreen
            ></iframe>
         </ng-container>

         <ng-container
            *ngIf="this.user.institutionId !== null && this.user.roleId === 2"
         >
            <!-- <iframe
               width="100%"
               height="100%"
               src="https://www.youtube.com/embed/QuXzZZBKx1Q"
               title="YouTube video player"
               frameborder="0"
               allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
               allowfullscreen
            ></iframe> -->
            <iframe
               width="100%"
               height="100%"
               src="https://www.youtube.com/embed/VwzY_C_CzTg"
               title="YouTube video player"
               frameborder="0"
               allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
               allowfullscreen
            ></iframe>
         </ng-container>

         <ng-container
         *ngIf="this.user.institutionId == null && this.user.roleId === 2"
      >
         <!-- <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/QuXzZZBKx1Q"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
         ></iframe> -->
         <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/FgwHem5Bfqg"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
         ></iframe>
      </ng-container>

      <ng-container *ngIf="!(this.user.institutionId !== null && this.user.roleId === 6) &&
                  !(this.user.institutionId !== null && this.user.roleId === 2) &&
                  !(this.user.institutionId == null && this.user.roleId === 2)">
        <p class="no_video_available">No video available for this case.</p>
      </ng-container>
      </div>
   </main>
</div>
