import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { UploadfileService } from './Service/uploadfile.service';
import {
   FormArray,
   FormBuilder,
   FormControl,
   FormGroup,
   Validators,
} from '@angular/forms';
import { decode } from 'base64-arraybuffer';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from '../../../services/auth.service';
import { User } from 'src/app/models/user';
import { SubmissionsService } from 'src/app/services/submissions.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import swal from 'sweetalert2';
import { Store } from '@ngrx/store';
import { getCurrentUser } from 'src/app/authModule/state/authentication.selectors';

/**
 * Component used to upload files
 */
@Component({
   selector: 'app-upload-file',
   templateUrl: './upload-file.component.html',
   styleUrls: ['./upload-file.component.scss'],
})
export class UploadFileComponent implements OnInit, OnDestroy {
   uploaded = 0;
   /**
    * Used to store uploaded file.
    */
   fileToUpload: File = null;
   /**
    * Array of uploaded files.
    */
   files = [];
   /**
    * Variables used to allow/deny user document upload.
    */
   allowToSubmitDocument: boolean = false;
   /**
    * Used to check if used trial has ended or not.
    */
   trial: boolean = false;
   /**
    * User document preview url.
    */
   previewSrc: any;
   /**
    * Upload from used to get user input from forms.
    */
   uploadForm: any;
   /**
    * Language that user want to check their document similarity in a translated language.
    */
   documentTranslatedLanguage: string = undefined;

   /**
    * Key that used to generate preview url for document preview.
    */
   previewKey: any;
   /**
    * Boolean value used to show/hide advanced options of the form.
    */
   advancedOptionsIndex: boolean = false;
   /**
    * Boolean value used to get user consent to archive the file.
    */
   archive: boolean = true;
   /**
    * Document citation style picked by user.
    */
   citationStyle: string = '';
   /**
    * Language of the document selected by user.
    */
   languageSelected: boolean = false;
   /**
    * List of the available languages.
    */
   languages = [
      {
         text: 'English',
         value: 'en',
      },
      {
         text: 'Albanian',
         value: 'sq',
      },
      {
         text: 'German',
         value: 'de',
      },
      {
         text: 'Italian',
         value: 'it',
      },
      {
         text: 'French',
         value: 'fr',
      },
      {
         text: 'Spanish',
         value: 'es',
      },
      {
         text: 'Greek',
         value: 'el',
      },
      {
         text: 'Czech',
         value: 'cs',
      },
      {
         text: 'Turkish',
         value: 'tr',
      },
      {
         text: 'Slovak',
         value: 'sk',
      },
      {
         text: 'Lithuanian',
         value: 'lt',
      },
      {
         text: 'Latvian',
         value: 'lv',
      },
      {
         text: 'Polish',
         value: 'pl',
      },
      {
         text: 'Serbian',
         value: 'sr',
      },
      {
         text: 'Macedonian',
         value: 'mk',
      },
      {
         text: 'Portuguese',
         value: 'pt',
      },
      {
         text: 'Dutch',
         value: 'nl',
      },
      {
         text: 'Russian',
         value: 'ru',
      },
      {
         text: 'Bulgarian',
         value: 'bg',
      },
      {
         text: 'Hungarian',
         value: 'hu',
      },
      {
         text: 'Romanian',
         value: 'ro',
      },
      {
         text: 'Slovenian',
         value: 'sl',
      },
      {
         text: 'Swedish',
         value: 'sv',
      },
      {
         text: 'Finnish',
         value: 'fi',
      },
      {
         text: 'Croatian',
         value: 'hr',
      },
      {
         text: 'Bosnian',
         value: 'bs',
      },
      {
         text: 'Norwegian',
         value: 'no',
      },
   ];
   /**
    * List of third party libraries to check for document.
    */
   thirdPartyLib: Array<any> = [
      {
         description: 'ScienceDirect',
         isChecked: false,
         information:
            'Searching ScienceDirect content may involve delays on checking your document.',
      },
      {
         description: 'Scopus',
         isChecked: false,
         information:
            'Searching Scopus metadata and abstracts may involve delays on checking your document.',
      },
      {
         description: 'CORE',
         isChecked: false,
         information:
            'Searching CORE content may involve delays on checking your document. ',
      },
      {
         description: 'IEEE',
         isChecked: false,
         information:
            'Searching IEEE metadata and abstracts may involve delays on checking your document.',
      },
      {
         description: 'arXiv.org',
         isChecked: false,
         information:
            'Searching arXiv metadata and abstracts may involve delays on checking your document.',
      },
      {
         description: 'Crossref',
         isChecked: false,
         information:
            'Searching Crossref metadata and abstracts may involve delays on checking your document.',
      },
      {
         description: 'Europeana',
         isChecked: false,
         information:
            'Searching Europeana metadata and abstracts may involve delays on checking your document.',
      },
      {
         description: 'CaseLaw Access Project',
         isChecked: false,
         information:
            'Searching CaseLaw content may involve delays on checking your document.',
      },
   ];

   thirdPartyLibraries: any = this.thirdPartyLib;

   uploadedFile: any;
   /**
    * Used to check if the file is converted from docx to pdf.
    */
   converted: any;

   /**
    * Is the current pdf producer allowed
    */
   allowedProducer;
   /**
    * User from store
    */
   user;
   /**
    * User Subscriber
    */
   user$;
   documentCredits;
   /**
    * Component constructor
    * @param fileUpload
    * @param fb
    * @param spinner
    * @param authService
    * @param submissionService
    */
   constructor(
      private fileUpload: UploadfileService,
      private fb: FormBuilder,
      private spinner: NgxSpinnerService,
      private authService: AuthService,
      private submissionService: SubmissionsService,
      private toastrService: ToastrService,
      private router: Router,
      private store: Store
   ) {}
   /**
    * Method used to be called whenever the component is loaded and all the methods in it, will be triggered.
    */
   ngOnInit(): void {
      this.uploadForm = this.fb.group({
         documentTitle: ['', [Validators.required]],
         fullName: ['', [Validators.required]],
         dateOfUpload: ['', [Validators.required]],
         documentSize: ['', [Validators.required]],
         documentCredits: ['', [Validators.required]],
         documentLanguage: ['', [Validators.required]],
         documentTranslatedLanguage: [''],
         aiDetection:[''],
         thirdPartyLibs: this.fb.array(this.thirdPartyLib),
      });
      this.user$ = this.store.select(getCurrentUser).subscribe((data) => {
         if (data !== null) {
            this.user = data;
         }
      });
   }
   /**
    * Method used to trigger file upload.
    */
   upload() {
      $('.dropzone').trigger('click');
   }
   /**
    * Method used to upload, update & preview file at upload form.
    */
   onFileSelected(event: any) {
      this.fileToUpload = <File>event.target.files[0];
      const firstName = this.user?.name.split(' ')[0];
      const lastName = this.user?.name.split(' ')[1];
      this.form.fullName.setValue(this.user?.name);
      let fileSize = this.formatBytes(this.fileToUpload.size);
      this.form.documentSize.setValue(fileSize);

      let date = new Date();
      let dateString =
         date.getDate() +
         '/' +
         (date.getMonth() + 1) +
         '/' +
         date.getFullYear();
      this.form.dateOfUpload.setValue(dateString);
      this.spinner.show();
      this.fileUpload
         .generatePresignedURL(this.fileToUpload.type, firstName, lastName)
         .pipe(first())
         .subscribe(
            (data) => {
               if (data?.showAlert) {
                  this.spinner.hide();
               } else {
                  this.previewKey = data.key;
                  this.fileUpload
                     .uploadfileAWSS3(data.presignedS3Url, this.fileToUpload)
                     .pipe(first())
                     .subscribe(
                        (data1) => {
                           this.fileUpload
                              .generatePresignedURLPreview(
                                 this.previewKey,
                                 this.fileToUpload.type,
                                 firstName,
                                 lastName
                              )
                              .pipe(first())
                              .subscribe((data) => {
                                 this.documentCredits = data.documentCredits;
                                 setTimeout(() => {
                                    this.previewSrc =
                                       data.presignedS3UrlPreview;
                                    this.uploadedFile = data.Key;
                                    this.converted = data.converted;
                                    this.allowToSubmitDocument =
                                       data.allowSubmission.allowToSubmitDocument;
                                    this.trial = data.allowSubmission.trail;
                                    this.allowedProducer = data.allowedProducer;
                                    if (this.allowedProducer) {
                                       this.showNotAllowedProducerMessage();
                                    }
                                    this.form.documentCredits.setValue(
                                       this.documentCredits
                                    );
                                    this.form.documentTranslatedLanguage.setValue(
                                       this.user.allowedLanguages.targetLanguage
                                    );
                                    this.form.documentLanguage.setValue(
                                       this.user.allowedLanguages
                                          .documentLanguage
                                    );
                                 }, 500);

                                 this.uploaded = 1;
                                 this.spinner.hide();
                              }),
                              (error) => {
                                 this.spinner.hide();
                                 console.log('error3', error);
                              };
                        },
                        (error) => {
                           this.spinner.hide();
                           console.log('error2', error);
                        }
                     );
               }
            },
            (error) => {
               this.spinner.hide();
               console.log('error1', error);
            }
         );
   }
   /**
    * Method used to get controls from formGroup
    */
   get form() {
      return this.uploadForm.controls;
   }
   /**
    * Method used to get libraries from array from upload from.
    */
   get libraries() {
      return <FormArray>this.uploadForm.get('thirdPartyLibs');
   }

   /**
    * Method used to show advanced options at file upload.
    */
   advancedOptions() {
      if (this.advancedOptionsIndex == false) {
         this.advancedOptionsIndex = true;
      } else {
         this.advancedOptionsIndex = false;
      }
   }
   /**
    * Method used to format bytes for the file upload.
    * @param bytes
    */
   formatBytes(bytes) {
      var marker = 1024; // Change to 1000 if required
      var decimal = 2; // Change as required
      var kiloBytes = marker; // One Kilobyte is 1024 bytes
      var megaBytes = marker * marker; // One MB is 1024 KB
      var gigaBytes = marker * marker * marker; // One GB is 1024 MB

      if (bytes < kiloBytes) return bytes + ' Bytes';
      else if (bytes < megaBytes)
         return (bytes / kiloBytes).toFixed(decimal) + ' KB';
      else if (bytes < gigaBytes)
         return (bytes / megaBytes).toFixed(decimal) + ' MB';
   }
   /**
    * Method used to create submission when uploading a document.
    */
   // async createSubmission() {
   //    let withCredits = undefined;

   //    const result = await swal.fire({
   //       title: 'Choose how to proceed',
   //       text: 'Would you like to use credits or  number of documents?',
   //       icon: 'question',
   //       cancelButtonText: 'Close',
   //       confirmButtonText: 'With credits',
   //       denyButtonText: 'With documents',
   //       denyButtonColor: '#2455C2',
   //       confirmButtonColor: '#F3001E',
   //       cancelButtonColor: '#8F00F1',
   //       showDenyButton: true,
   //       showCancelButton: true,
   //       allowOutsideClick: false,
   //    });
   //    if (result.isConfirmed) {
   //       withCredits = true;
   //    }
   //    if (result.isDenied) {
   //       withCredits = false;
   //    }
   //    if (result.isDismissed) {
   //       return;
   //    }

   //    this.spinner.show();
   //    this.submissionService
   //       .createSubmission(
   //          this.form.documentTitle.value,
   //          this.uploadedFile,
   //          this.form.fullName.value,
   //          this.form.dateOfUpload.value,
   //          this.form.documentSize.value,
   //          this.form.documentLanguage.value,
   //          this.form.documentTranslatedLanguage.value,
   //          this.archive,
   //          this.citationStyle,
   //          this.thirdPartyLibraries,
   //          this.converted,
   //          withCredits
   //       )
   //       .subscribe(
   //          (data: any) => {
   //             if (data.showAlert == true) {
   //                this.trial = data.allowSubmission.trial;
   //                this.showAlertMessageTrial();
   //                setTimeout(() => {
   //                   this.router.navigateByUrl('/individual/dashboard');
   //                }, 1500);
   //                this.toastrService.success(
   //                   'Document is uploaded successfully.'
   //                );
   //                this.spinner.hide();
   //             } else {
   //                setTimeout(() => {
   //                   this.router.navigateByUrl('/individual/dashboard');
   //                }, 1500);
   //                this.toastrService.success(
   //                   'Document is uploaded successfully.'
   //                );
   //                this.spinner.hide();
   //             }
   //          },
   //          (error) => {
   //             console.log('error Submit', error);
   //             this.showAlertMessage();
   //             this.spinner.hide();
   //          }
   //       );
   // }
   /**
    * Method used to select document original language.
    * @param event
    */
   selectLanguage(event) {
      if (event == 'undefined') {
         this.showWarningMessage('Please select a language!');
         this.form.documentLanguage.setErrors({
            required: true,
            invalid: true,
            touched: true,
         });
         this.languageSelected = true;
      } else {
         this.languageSelected = false;
         this.form.documentLanguage.setValue(event.target.value);
      }
   }
   /**
    * Method used to select document translated language
    * @param event
    */
   selectTranslatedLanguage(event) {
      this.form.documentTranslatedLanguage.setValue(event.target.value);
   }
   /**
    * Method used to change value of @archive. We archive document based on the value of the variable.
    * @param event
    */
   archiveDocument() {
      this.archive = !this.archive;
   }
   /**
    * Method used to select citation style.
    * @param event
    */
   selectCitationStyle(event) {
      this.citationStyle = event.target.value;
   }
   /**
    * Method used to check third party libraries.
    * @param e
    */
   onCheckboxChange(e) {
      if (e.target.checked) {
         this.thirdPartyLib.forEach((item) => {
            if (e.target.value == item.description) {
               item.isChecked = true;
            }
         });
      } else {
         this.thirdPartyLib.forEach((item) => {
            if (e.target.value == item.description) {
               item.isChecked = false;
            }
         });
      }
   }
   /**
    * A lifecycle hook that is called when a directive, pipe, or service is destroyed.
    * Use for any custom cleanup that needs to occur when the instance is destroyed.
    */
   ngOnDestroy() {
      this.user$.unsubscribe();
   }

   /**
    * Method to display modal that the producer is not allowed
    */
   async showNotAllowedProducerMessage() {
      const result = await swal.fire({
         title: 'This file has some issues',
         text: 'Try converting your file to .docx and then convert it to pdf',
         icon: 'warning',
         confirmButtonColor: '#3085d6',
         cancelButtonColor: '#b5adad',
         confirmButtonText: 'I understand',
         allowOutsideClick: false,
      });
      if (result.isConfirmed) {
         this.router.navigateByUrl('/individual/dashboard');
      }
   }
   /**
    * Method used to show user different messages based on @uploadFrom control state.
    */
   showFormMessages() {
      if (this.uploadForm.invalid) {
         if (this.form.documentLanguage.invalid) {
            this.showWarningMessage('Please select a language!');
            this.form.documentLanguage.setErrors({
               required: true,
               invalid: true,
               touched: true,
            });
         }
         if (this.form.documentTitle.invalid) {
            this.showWarningMessage('Document should have a title!');
            this.form.documentTitle.setErrors({
               required: true,
               invalid: true,
               touched: true,
            });
         }

         if (this.form.fullName.invalid) {
            this.showWarningMessage('Author should have a name!');
            this.form.fullName.setErrors({
               required: true,
               invalid: true,
               touched: true,
            });
         }
      }
   }
   /**
    * Method used to show warning toaster.
    * @param message
    */
   showWarningMessage(message) {
      this.toastrService.warning(message);
   }
   /**
    * Method used to inform user that he can't upload document.
    */
   async showAlertMessage() {
      let title = `Not enough credits!`;
      let message = `To submit your document you need ${Math.abs(
         this.user.wordsToUse - this.documentCredits
      )} more credits.`;
      const result = await swal.fire({
         title: title,
         text: message,
         icon: 'warning',
         showCancelButton: true,
         confirmButtonColor: '#3085d6',
         cancelButtonColor: '#b5adad',
         confirmButtonText: 'Buy more credits',
         allowOutsideClick: false,
      });
      if (result.isConfirmed) {
         this.router.navigateByUrl('/individual/add-credits');
      } else if (result.isDismissed) {
         this.router.navigateByUrl('/individual/dashboard');
      }
   }

   async showAlertMessageTrial() {
      let title = 'Document suspended from processing!';
      let message = `Your document has been submitted but will be suspended due to low amount of credits. Total credits required: ${this.documentCredits}`;

      const result = await swal.fire({
         title: title,
         text: message,
         icon: 'info',
         showCancelButton: true,
         confirmButtonColor: '#3085d6',
         cancelButtonColor: '#b5adad',
         confirmButtonText: 'Buy more credits',
         allowOutsideClick: false,
      });
      if (result.isConfirmed) {
         this.router.navigateByUrl('/individual/add-credits');
      } else if (result.isDismissed) {
         this.router.navigateByUrl('/individual/dashboard');
      }
   }
}
