<main class="log-in">
   <div class="left-side">
      <img src="../../assets/images/Group (2)-white.png" alt="" />
      <div>
         <h1>The most advanced & affordable plagiarism checking tool.</h1>
         <p [innerHtml]="'login.crossplag_description' | translate">
         </p>
      </div>
      <h6>© All rights reserved - Inspera AS</h6>
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      viewBox="0 0 1030 1030" style="enable-background:new 0 0 1030 1030;" xml:space="preserve">
  <style type="text/css">
     .st0{fill:#ffffff36;}

  </style>
  <g>
     <g>
        <circle class="st0" cx="137" cy="716.5" r="115.1"/>
        <circle class="st0" cx="410.7" cy="460.1" r="139"/>
        <circle class="st0" cx="834.3" cy="292.9" r="182.5"/>
     </g>
  </g>
  <g>
     <path class="st0" d="M131.9,232.2c-0.2,0-0.4-0.1-0.6-0.3c-0.3-0.3-0.3-0.9,0-1.2l44-44c0.3-0.3,0.9-0.3,1.2,0
        c0.3,0.3,0.3,0.9,0,1.2l-44,44C132.3,232.1,132.1,232.2,131.9,232.2z"/>
  </g>
  <g>
     <path class="st0" d="M27.2,129.2c-0.2,0-0.4-0.1-0.6-0.3c-0.3-0.3-0.3-0.9,0-1.2l98.7-98.7c0.3-0.3,0.9-0.3,1.2,0
        c0.3,0.3,0.3,0.9,0,1.2L27.8,129C27.6,129.1,27.4,129.2,27.2,129.2z"/>
  </g>
  </svg>
   </div>
   <div class="right-side">
      <img
         src="../../assets/images/Crossplag_Logo.png"
         alt=""
      />
      <span routerLink="/login" class="go-back"> <i class="fas fa-arrow-left"></i> Go back</span>
<div class="form-container">
   <h3>Reset Password</h3>
   <div>
      <p>{{ 'login.reset_link' | translate }}</p>
   </div>
   <div [formGroup]="forgotForm">
      <input formControlName="email" placeholder="Email" />
   </div>
   <div
      *ngIf="FormValues.email.touched && FormValues.email.invalid"
      class="alert alert-danger"
   >
      <div class="invalid" *ngIf="FormValues.email.errors.required">
         {{ 'validators.enter_email' | translate }}
      </div>
      <div class="invalid" *ngIf="FormValues.email.errors.email">
         {{ 'validators.valid_email' | translate }}
      </div>
   </div>

   <ng-container *ngIf="forgotForm.valid">
      <button (click)="submit()" class="submit" type="submit">
         {{ 'general.reset_password' | translate }}
      </button>
   </ng-container>

   <ng-container *ngIf="forgotForm.invalid">
<div class="center">
   <button
   (click)="showToasterWarning('Please enter a valid email')"
   class="submit"
   type="submit"
>
   {{ 'general.reset_password' | translate }}
</button>
</div>
   </ng-container>
</div>
   </div>
</main>
