import { createAction, props } from '@ngrx/store';

/**
 * Action used to register a student.
 */
export const studentRegister = createAction(
   '[Student] Student Register',
   props<{
      studentName;
      studentFaculty;
      studentDepartment;
      studentIndexID;
      studentEmail;
   }>()
);

/**
 * Action is called when there is a success status returned from API.
 */
export const studentRegisterSuccess = createAction(
   '[Student] Student Register Success',
   props<{
      message;
   }>()
);

/**
 * Action is called when there is a failure status returned from API.
 */
export const studentRegisterFailure = createAction(
   '[Student] Student Register Failure',
   props<{ error: any }>()
);

/**
 * Action used to load Students of institution.
 */
export const loadStudentsDetails = createAction(
   '[Student] Load Students Details',
   props<{
      page;
      faculty?;
      department?;
      name?;
   }>()
);

/**
 * Action is called when there is a success status returned from API.
 */
export const loadStudentsDetailsSuccess = createAction(
   '[Student] Load Students Details Success',
   props<{
      students;
      studentsNumber;
   }>()
);

/**
 * Action is called when there is a failure status returned from API.
 */
export const loadStudentsDetailsFailure = createAction(
   '[Student] Load Students Details Failure',
   props<{ error: any }>()
);

/**
 * Action used to edit Student details.
 */
export const editStudentDetails = createAction(
   '[Student] Edit Student Details',
   props<{
      studentId;
      studentName;
      studentEmail;
      studentPhoneNumber;
      studentIndexID;
      studentFaculty;
      studentDepartment;
      studentStatus;
      page?;
      faculty?;
      department?;
      name?;
   }>()
);

/**
 * Action is called when there is a success status returned from API.
 */
export const editStudentDetailsSuccess = createAction(
   '[Student] Edit Student Details Success',
   props<{
      data;
   }>()
);

/**
 * Action is called when there is a failure status returned from API.
 */
export const editStudentDetailsFailure = createAction(
   '[Student] Edit Student Details Failure',
   props<{ error: any }>()
);

/**
 * Action used to remove Student from institution.
 */
export const removeStudent = createAction(
   '[Student] Remove Student',
   props<{
      studentId;
      page?;
      faculty?;
      department?;
      name?;
   }>()
);

/**
 * Action is called when there is a success status returned from API.
 */
export const removeStudentSuccess = createAction(
   '[Student] Remove Student Success',
   props<{
      data;
   }>()
);

/**
 * Action is called when there is a failure status returned from API.
 */
export const removeStudentFailure = createAction(
   '[Student] Remove Student Failure',
   props<{ error: any }>()
);

export const setStudentRegisterToNull = createAction(
   '[Student] Set Student Register To Null'
);
